@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  list-style: none;

}

b , strong {
  font-weight: 600 !important;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */


.bg-orange{
  background: #ff8e6f;
}
.bg-light-pink{
  background: #ff5d9f;
}
.bg-pink{
  background: #e354d4;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-label {
  font-size: 10.5px;
  color: rgb(170, 170, 170);
}


.input-label span {
  color: red;
}

.input {
  width: 100%;
  display: inline;
}

.btn-iconn {
  border: 1px solid rgb(161, 161, 161);
  font-size: 27px;
  padding: 3px;
  margin-left: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: rgb(161, 161, 161);
}

#searchList {
  max-height: 600px;
  overflow-y: scroll;
  margin-top: 5px;
}

.loading-gradient-text {
  background: linear-gradient(90deg, #f42f25, #f49725);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  margin-left: 15px;
}

.loadingg {
  height: 10vh;
  padding: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsla(0, 0%, 100%, 0.692);
}

.submitloading {
  height: 10vh;
  padding: 10px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsl(0, 0%, 100%);
}

.submit-loading-gradient-text {
  background: linear-gradient(90deg, #f42f25, #f49725);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  margin-left: 15px;
}

/*Loader start*/
.loading {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsla(0, 0%, 100%, 0.692);
}

.pl {
  width: 6em;
  height: 6em;
}

.pl__ring {
  animation: ringA 2s linear infinite;
}

.pl__ring--a {
  stroke: #f42f25;
}

.pl__ring--b {
  animation-name: ringB;
  stroke: #f49725;
}

.pl__ring--c {
  animation-name: ringC;
  stroke: #255ff4;
}

.pl__ring--d {
  animation-name: ringD;
  stroke: #f42582;
}

/* Animations */
@keyframes ringA {

  from,
  4% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }

  12% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -335;
  }

  32% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -595;
  }

  40%,
  54% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -660;
  }

  62% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -665;
  }

  82% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -925;
  }

  90%,
  to {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -990;
  }
}

@keyframes ringB {

  from,
  12% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -110;
  }

  20% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -115;
  }

  40% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -195;
  }

  48%,
  62% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  70% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  90% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -305;
  }

  98%,
  to {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }
}

@keyframes ringC {
  from {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  8% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  28% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  36%,
  58% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  66% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  86% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  94%,
  to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}

@keyframes ringD {

  from,
  8% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  16% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  36% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  44%,
  50% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  58% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  78% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  86%,
  to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}

/*Loader end*/

.inner-bg-white>div {
  background-color: white;
}

.closeModalBtn {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 25px;
  cursor: pointer;
  color: rgb(238, 129, 129);
}

.closeModalBtn:hover {
  background-color: rgb(255, 223, 223);
  border-radius: 3px;
}

.btn-1 {
  position: relative;
  display: inline-block;
  margin: 0.5rem 0rem;
  padding: 0.5rem 01rem;
  text-align: center;
  letter-spacing: 1px;
  text-decoration: none;
  color: #015289;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 1px solid #015289;
  border-radius: 5px;
  box-shadow: inset 0 0 0 0 #015289;
}

.btn-1:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #015289;
}

.btn-1:active {
  transform: scale(0.9);
}

.strikethrough-row {
  position: relative;
  /* background-color: lightcoral;  */
  color: red;
}

.hover-div {
  transition: all 200ms ease-in;
}

.hover-div:hover {
  background: #dbdfe29c;
}

#c_table tbody tr td {
  background-color: transparent !important;
}

#c_table tr th {
  background-color: #00528a;
  color: white;
  border: 0.1px solid rgb(235, 235, 235);
}

#c_table tbody tr:hover {
  background-color: #d8d8d86b;
}

#c_table tr th {
  background-color: #00528a;
  color: white;
  border: 0.1px solid rgb(235, 235, 235);
}

#c_table .label {
  border-radius: 7px;
  text-transform: capitalize;
}

#c_table .label.red_label,
.label.red_label {
  border: 1px solid rgb(255, 181, 181);
  background-color: rgb(255, 237, 237);
  color: rgb(240, 19, 19);
}

#c_table .label.blue {
  border: 1px solid blue;
  background-color: rgba(0, 0, 255, 0.425);
  color: blue;
}

#c_table .label.green_label,
.label.green_label {
  border: 1px solid #b6ebc3;
  background-color: #e6ffec;
  color: green;
}

#product_card .label {
  border-radius: 7px;
  text-transform: capitalize;
}

#product_card .label.red_label {
  border: 1px solid rgb(255, 181, 181);
  background-color: rgb(255, 237, 237);
  color: rgb(240, 19, 19);
}

#product_card .label.green_label {
  border: 1px solid #b6ebc3;
  background-color: #e6ffec;
  color: green;
}

/* @media (max-width: 767px) {
  #c_table thead {
    display: block;
  }
} */
.MuiInputBase-multiline {
  padding: 0px 0px !important;
}

.notfound_img_div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56vh;
}

.notfound_img {
  background-image: url("./Assets/Img/notfound.gif");
  width: 50rem;
  mix-blend-mode: multiply;
  height: 30rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingfound_img {
  background-image: url("./Assets/Img/loading.gif");
  width: 40rem;
  mix-blend-mode: multiply;
  height: 30rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .notfound_img {
    width: 500px;
    /* Optional: sets a maximum width */
    height: 500px;
    /* Optional: sets a maximum height */
  }
}

.icon-phone,
.icon-email,
.icon-location {
  color: #424446fa;
  margin-right: 0.2rem;
  font-size: 12px;
}

.product-contact,
.product-address {
  font-size: 0.72rem;
}

.product-card {
  position: relative;
  display: flex;
  margin: 2px 0;
  border: 1px solid rgb(233, 233, 233);
  padding: 5px;
  border-radius: 5px;
  background-color: white;
}

.product-card h5 {
  margin: 0;
}

.product-img {
  font-size: 40px;
  color: rgb(209, 209, 209);
}

.product-card-sku {
  font-size: 0.8rem;
}

.product-card-name {
  color: rgb(36, 36, 36);
  font-size: 1rem;
  text-transform: uppercase;
}

.product-card-partNo {
  font-size: 0.8rem;
  color: #121212;

}

.product-card-mrp {
  font-size: 12px;
  color: rgb(41, 41, 41);
}

.product-card-label {
  font-size: 0.8rem;
  color: grey;
  margin: 0;
}

.product-card-type {
  font-size: 0.9rem;
  margin: 0;
}

.product-card .product-quantities {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.product-card .quantity {
  flex: 1;
  text-align: center;
}

/*Sale Card*/
.sale-card-customer {
  font-size: 15px;
  color: rgb(36, 36, 36);
}

.sale-card-date {
  font-size: 11px;
  color: rgb(73, 73, 73);
}

.sale-card-mrp {
  font-size: 12px;
  color: rgb(41, 41, 41);
}

/*Warehouses*/
.warehouse-img {
  font-size: 42px;
  color: rgb(209, 209, 209);
}

.warehouse-card-address {
  font-size: 11.5px;
}

/*adjustments*/
.warehouse-card-label {
  position: absolute;
  right: 15px;
}

.scannerDiv {
  position: fixed;
  z-index: 5;
  width: 92%;
  padding: 10px;
  border: 1px solid grey;
  background-color: white;
  border-radius: 6px;
}

.custom_textareafield {
  border: 1px solid rgb(196, 196, 196);
  padding: 7px;
  border-radius: 3px;
}

/* ============================ simple-table =================  */

.simple-table {
  width: 100%;
  max-height: 250px;
  overflow: auto;
}

.simple-table table {
  width: 100%;
  background-color: rgb(245, 245, 245);
}

.simple-table thead {
  position: sticky;
  top: 0.1px;
  text-align: left;
  background: rgb(235, 235, 235);
}

.simple-table table tr td {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}

.simple-table table tr:hover {
  background: #b7b8b928;
}

.simple-table table tr th {
  /* border: none !important; */
  background-color: transparent !important;
  color: black !important;
}

.transaction-buttons button {
  font-size: 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  justify-content: flex-start !important;
}

.bg-grey {
  background-color: #f8f9fa;
}

.navigate-other {
  font-size: 1.3rem;
  background: #00528a;
  color: #f8f9fa;
  margin-left: 0.5rem;
  float: right;
  padding: 2px 5px;
  border-radius: 2px;
  padding-top: 0rem;
  transition: all 300ms ease-in;
  border: none;
  outline: none;
}

.navigate-other-mobile {
  font-size: 1rem;
  background: #00528a;
  color: #f8f9fa;
  margin-left: 0.5rem;
  position: absolute;
  top: 0%;
  right: 0%;
  padding: 2px 5px;
  border-radius: 3px;
  padding-top: 0rem;
  transition: all 300ms ease-in;
  border: none;
  outline: none;
}

.navigate-other:hover {
  color: #00528a;
  background: #b7b8b9;
}

/* ========== Mobile ======  */

.iicon {
  cursor: pointer;
  font-size: 25px;
  border: 1px solid orange;
  border-radius: 5px;
  padding: 5px;
  color: orange;
}

.iicon.iicon-edit {
  border: 1px solid orange;
  color: orange;
}

.iicon.iicon-delete {
  border: 1px solid red;
  color: red;
}

.iicon.iicon-success {
  border: 1px solid green;
  color: green;
}

/* ================ Modal Open Icon ==========  */
.enlarge-modal {
  position: absolute;
  top: 10%;
  right: 5%;
  background: #015289;
  color: white;
  padding: 0.5rem;
  padding-top: 0.2rem;
  cursor: pointer;
}

.filterbtn {
  font-size: 28px;
  padding: 5px;
  border: 1px solid rgba(25, 118, 210, 0.5);
  border-radius: 5px;
  color: #064f97;
  cursor: pointer;
}

.meter {
  height: 4px;
  background-color: #49a8e0;
  position: absolute;
  top: "180px";
}

/* ============== Filter Modal Selection =================  */
.filter-modal {
  position: absolute;
  top: 100%;
  margin-top: 6px;
  margin-left: -5px;
  border-radius: 5px;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 5000;
  width: 200px;
  text-align: start;
}

.filter-modal-date {
  position: absolute;
  top: 100%;
  border-radius: 5px;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
  width: max-content;
  text-align: start;
}

.filter-modal div {
  margin-bottom: 5px;
  color: black;
}

.filter-modal .filter-options,
.filter-options {
  background: whitesmoke;
  border-radius: 4px;
  padding: 0.5rem;
  max-height: 180px;
  overflow: auto;
}

.searchInput {
  max-width: 100%;
  height: auto;
}

/* ==== Navbar  ========  */
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .has-inner-dropdown {
  position: relative;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .has-inner-dropdown .inner-dropdown {
  position: absolute;
  background-color: white;
  right: 90%;
  top: 0%;
  transition: all 300ms ease-in;
  visibility: hidden;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .has-inner-dropdown:hover .inner-dropdown {
  right: 103%;
  visibility: visible;

  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  min-width: 180px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .has-inner-dropdown .dropdown-item {
  gap: 1rem;
}

/*  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .has-inner-dropdown .dropdown-item:hover {
  color: #00528a;
} */

.expanded-content {
  background-color: #f3f3f3;
  padding: 5px;
  margin-top: 1rem;
  border-radius: 5px;
}

.expanded-content .transactions {
  max-height: 250px;
  overflow: auto;
}

.expanded-content .transaction-card {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #fff;
}

.expanded-content .transaction-info {
  display: flex;
  flex-direction: column;
}