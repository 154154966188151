@charset "UTF-8";

@-moz-keyframes flash {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flash {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes flash {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  80% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  80% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  80% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  80% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  80% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  30% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  70% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  30% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  70% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  30% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  70% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  30% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  70% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  30% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  70% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-o-keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-ms-keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@keyframes tada {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  40% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  50% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  70% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-moz-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center 50%;
    -moz-transform-origin: top center 50%;
    -ms-transform-origin: top center 50%;
    -o-transform-origin: top center 50%;
    transform-origin: top center 50%;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-webkit-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center 50%;
    -moz-transform-origin: top center 50%;
    -ms-transform-origin: top center 50%;
    -o-transform-origin: top center 50%;
    transform-origin: top center 50%;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-o-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center 50%;
    -moz-transform-origin: top center 50%;
    -ms-transform-origin: top center 50%;
    -o-transform-origin: top center 50%;
    transform-origin: top center 50%;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-ms-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center 50%;
    -moz-transform-origin: top center 50%;
    -ms-transform-origin: top center 50%;
    -o-transform-origin: top center 50%;
    transform-origin: top center 50%;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center 50%;
    -moz-transform-origin: top center 50%;
    -ms-transform-origin: top center 50%;
    -o-transform-origin: top center 50%;
    transform-origin: top center 50%;
  }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-moz-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-o-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-ms-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-ms-keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    -moz-transform: skewX(9deg);
    -ms-transform: skewX(9deg);
    -o-transform: skewX(9deg);
    transform: skewX(9deg);
  }
  10% {
    -webkit-transform: skewX(-8deg);
    -moz-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    -o-transform: skewX(-8deg);
    transform: skewX(-8deg);
  }
  20% {
    -webkit-transform: skewX(7deg);
    -moz-transform: skewX(7deg);
    -ms-transform: skewX(7deg);
    -o-transform: skewX(7deg);
    transform: skewX(7deg);
  }
  30% {
    -webkit-transform: skewX(-6deg);
    -moz-transform: skewX(-6deg);
    -ms-transform: skewX(-6deg);
    -o-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }
  40% {
    -webkit-transform: skewX(5deg);
    -moz-transform: skewX(5deg);
    -ms-transform: skewX(5deg);
    -o-transform: skewX(5deg);
    transform: skewX(5deg);
  }
  50% {
    -webkit-transform: skewX(-4deg);
    -moz-transform: skewX(-4deg);
    -ms-transform: skewX(-4deg);
    -o-transform: skewX(-4deg);
    transform: skewX(-4deg);
  }
  60% {
    -webkit-transform: skewX(3deg);
    -moz-transform: skewX(3deg);
    -ms-transform: skewX(3deg);
    -o-transform: skewX(3deg);
    transform: skewX(3deg);
  }
  70% {
    -webkit-transform: skewX(-2deg);
    -moz-transform: skewX(-2deg);
    -ms-transform: skewX(-2deg);
    -o-transform: skewX(-2deg);
    transform: skewX(-2deg);
  }
  80% {
    -webkit-transform: skewX(1deg);
    -moz-transform: skewX(1deg);
    -ms-transform: skewX(1deg);
    -o-transform: skewX(1deg);
    transform: skewX(1deg);
  }
  90% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
  100% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
}
@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    -moz-transform: skewX(9deg);
    -ms-transform: skewX(9deg);
    -o-transform: skewX(9deg);
    transform: skewX(9deg);
  }
  10% {
    -webkit-transform: skewX(-8deg);
    -moz-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    -o-transform: skewX(-8deg);
    transform: skewX(-8deg);
  }
  20% {
    -webkit-transform: skewX(7deg);
    -moz-transform: skewX(7deg);
    -ms-transform: skewX(7deg);
    -o-transform: skewX(7deg);
    transform: skewX(7deg);
  }
  30% {
    -webkit-transform: skewX(-6deg);
    -moz-transform: skewX(-6deg);
    -ms-transform: skewX(-6deg);
    -o-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }
  40% {
    -webkit-transform: skewX(5deg);
    -moz-transform: skewX(5deg);
    -ms-transform: skewX(5deg);
    -o-transform: skewX(5deg);
    transform: skewX(5deg);
  }
  50% {
    -webkit-transform: skewX(-4deg);
    -moz-transform: skewX(-4deg);
    -ms-transform: skewX(-4deg);
    -o-transform: skewX(-4deg);
    transform: skewX(-4deg);
  }
  60% {
    -webkit-transform: skewX(3deg);
    -moz-transform: skewX(3deg);
    -ms-transform: skewX(3deg);
    -o-transform: skewX(3deg);
    transform: skewX(3deg);
  }
  70% {
    -webkit-transform: skewX(-2deg);
    -moz-transform: skewX(-2deg);
    -ms-transform: skewX(-2deg);
    -o-transform: skewX(-2deg);
    transform: skewX(-2deg);
  }
  80% {
    -webkit-transform: skewX(1deg);
    -moz-transform: skewX(1deg);
    -ms-transform: skewX(1deg);
    -o-transform: skewX(1deg);
    transform: skewX(1deg);
  }
  90% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
  100% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
}
@-o-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    -moz-transform: skewX(9deg);
    -ms-transform: skewX(9deg);
    -o-transform: skewX(9deg);
    transform: skewX(9deg);
  }
  10% {
    -webkit-transform: skewX(-8deg);
    -moz-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    -o-transform: skewX(-8deg);
    transform: skewX(-8deg);
  }
  20% {
    -webkit-transform: skewX(7deg);
    -moz-transform: skewX(7deg);
    -ms-transform: skewX(7deg);
    -o-transform: skewX(7deg);
    transform: skewX(7deg);
  }
  30% {
    -webkit-transform: skewX(-6deg);
    -moz-transform: skewX(-6deg);
    -ms-transform: skewX(-6deg);
    -o-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }
  40% {
    -webkit-transform: skewX(5deg);
    -moz-transform: skewX(5deg);
    -ms-transform: skewX(5deg);
    -o-transform: skewX(5deg);
    transform: skewX(5deg);
  }
  50% {
    -webkit-transform: skewX(-4deg);
    -moz-transform: skewX(-4deg);
    -ms-transform: skewX(-4deg);
    -o-transform: skewX(-4deg);
    transform: skewX(-4deg);
  }
  60% {
    -webkit-transform: skewX(3deg);
    -moz-transform: skewX(3deg);
    -ms-transform: skewX(3deg);
    -o-transform: skewX(3deg);
    transform: skewX(3deg);
  }
  70% {
    -webkit-transform: skewX(-2deg);
    -moz-transform: skewX(-2deg);
    -ms-transform: skewX(-2deg);
    -o-transform: skewX(-2deg);
    transform: skewX(-2deg);
  }
  80% {
    -webkit-transform: skewX(1deg);
    -moz-transform: skewX(1deg);
    -ms-transform: skewX(1deg);
    -o-transform: skewX(1deg);
    transform: skewX(1deg);
  }
  90% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
  100% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
}
@-ms-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    -moz-transform: skewX(9deg);
    -ms-transform: skewX(9deg);
    -o-transform: skewX(9deg);
    transform: skewX(9deg);
  }
  10% {
    -webkit-transform: skewX(-8deg);
    -moz-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    -o-transform: skewX(-8deg);
    transform: skewX(-8deg);
  }
  20% {
    -webkit-transform: skewX(7deg);
    -moz-transform: skewX(7deg);
    -ms-transform: skewX(7deg);
    -o-transform: skewX(7deg);
    transform: skewX(7deg);
  }
  30% {
    -webkit-transform: skewX(-6deg);
    -moz-transform: skewX(-6deg);
    -ms-transform: skewX(-6deg);
    -o-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }
  40% {
    -webkit-transform: skewX(5deg);
    -moz-transform: skewX(5deg);
    -ms-transform: skewX(5deg);
    -o-transform: skewX(5deg);
    transform: skewX(5deg);
  }
  50% {
    -webkit-transform: skewX(-4deg);
    -moz-transform: skewX(-4deg);
    -ms-transform: skewX(-4deg);
    -o-transform: skewX(-4deg);
    transform: skewX(-4deg);
  }
  60% {
    -webkit-transform: skewX(3deg);
    -moz-transform: skewX(3deg);
    -ms-transform: skewX(3deg);
    -o-transform: skewX(3deg);
    transform: skewX(3deg);
  }
  70% {
    -webkit-transform: skewX(-2deg);
    -moz-transform: skewX(-2deg);
    -ms-transform: skewX(-2deg);
    -o-transform: skewX(-2deg);
    transform: skewX(-2deg);
  }
  80% {
    -webkit-transform: skewX(1deg);
    -moz-transform: skewX(1deg);
    -ms-transform: skewX(1deg);
    -o-transform: skewX(1deg);
    transform: skewX(1deg);
  }
  90% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
  100% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
}
@keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
    -moz-transform: skewX(9deg);
    -ms-transform: skewX(9deg);
    -o-transform: skewX(9deg);
    transform: skewX(9deg);
  }
  10% {
    -webkit-transform: skewX(-8deg);
    -moz-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    -o-transform: skewX(-8deg);
    transform: skewX(-8deg);
  }
  20% {
    -webkit-transform: skewX(7deg);
    -moz-transform: skewX(7deg);
    -ms-transform: skewX(7deg);
    -o-transform: skewX(7deg);
    transform: skewX(7deg);
  }
  30% {
    -webkit-transform: skewX(-6deg);
    -moz-transform: skewX(-6deg);
    -ms-transform: skewX(-6deg);
    -o-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }
  40% {
    -webkit-transform: skewX(5deg);
    -moz-transform: skewX(5deg);
    -ms-transform: skewX(5deg);
    -o-transform: skewX(5deg);
    transform: skewX(5deg);
  }
  50% {
    -webkit-transform: skewX(-4deg);
    -moz-transform: skewX(-4deg);
    -ms-transform: skewX(-4deg);
    -o-transform: skewX(-4deg);
    transform: skewX(-4deg);
  }
  60% {
    -webkit-transform: skewX(3deg);
    -moz-transform: skewX(3deg);
    -ms-transform: skewX(3deg);
    -o-transform: skewX(3deg);
    transform: skewX(3deg);
  }
  70% {
    -webkit-transform: skewX(-2deg);
    -moz-transform: skewX(-2deg);
    -ms-transform: skewX(-2deg);
    -o-transform: skewX(-2deg);
    transform: skewX(-2deg);
  }
  80% {
    -webkit-transform: skewX(1deg);
    -moz-transform: skewX(1deg);
    -ms-transform: skewX(1deg);
    -o-transform: skewX(1deg);
    transform: skewX(1deg);
  }
  90% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
  100% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
}
@-moz-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.95, 0.95);
    -ms-transform: scale(0.95, 0.95);
    -o-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
}
@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.95, 0.95);
    -ms-transform: scale(0.95, 0.95);
    -o-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
}
@-o-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.95, 0.95);
    -ms-transform: scale(0.95, 0.95);
    -o-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
}
@-ms-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.95, 0.95);
    -ms-transform: scale(0.95, 0.95);
    -o-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
}
@keyframes bounceOut {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.95, 0.95);
    -ms-transform: scale(0.95, 0.95);
    -o-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
}
@-moz-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-o-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-ms-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}
@-moz-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-o-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-ms-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-moz-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-o-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-ms-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-moz-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-o-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-ms-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
  }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    -moz-transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    -o-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
  }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    -moz-transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    -o-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
  }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    -moz-transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    -o-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-ms-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
  }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    -moz-transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    -o-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
  }
  70% {
    -webkit-transform: scale(0.9, 0.9);
    -moz-transform: scale(0.9, 0.9);
    -ms-transform: scale(0.9, 0.9);
    -o-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}
@-webkit-keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}
@-o-keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}
@-ms-keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}
@keyframes fadeOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}
@-moz-keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}
@-webkit-keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}
@-o-keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}
@-ms-keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}
@keyframes fadeOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}
@-moz-keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}
@-webkit-keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}
@-o-keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}
@-ms-keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}
@keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}
@-moz-keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}
@-webkit-keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}
@-o-keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}
@-ms-keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}
@keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}
@-moz-keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0;
  }
}
@-webkit-keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0;
  }
}
@-o-keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0;
  }
}
@-ms-keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0;
  }
}
@keyframes fadeOutUpBig {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
    opacity: 0;
  }
}
@-moz-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-o-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-ms-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}
@-moz-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-o-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-ms-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}
@-moz-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-o-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-ms-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-o-keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-ms-keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-moz-keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-o-keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-ms-keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-moz-keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-o-keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-ms-keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-o-keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-ms-keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-moz-keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-o-keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-ms-keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeInUpBig {
  0% {
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-o-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-ms-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg)
      scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-moz-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@-o-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@-ms-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@-moz-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-o-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-ms-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-moz-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@-o-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@-ms-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@-moz-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-o-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-ms-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-moz-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@-o-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@-ms-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}
@-moz-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-webkit-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-o-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-ms-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-moz-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}
@-ms-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}
@keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}
@-ms-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}
@-ms-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}
@-ms-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}
@-ms-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-ms-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center 50%;
    -moz-transform-origin: center center 50%;
    -ms-transform-origin: center center 50%;
    -o-transform-origin: center center 50%;
    transform-origin: center center 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-ms-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-ms-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom 50%;
    -moz-transform-origin: left bottom 50%;
    -ms-transform-origin: left bottom 50%;
    -o-transform-origin: left bottom 50%;
    transform-origin: left bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-ms-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-ms-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom 50%;
    -moz-transform-origin: right bottom 50%;
    -ms-transform-origin: right bottom 50%;
    -o-transform-origin: right bottom 50%;
    transform-origin: right bottom 50%;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-webkit-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-o-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-ms-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform-origin: top left 50%;
    -moz-transform-origin: top left 50%;
    -ms-transform-origin: top left 50%;
    -o-transform-origin: top left 50%;
    transform-origin: top left 50%;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
@-moz-keyframes rollIn {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@-webkit-keyframes rollIn {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@-o-keyframes rollIn {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@-ms-keyframes rollIn {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@keyframes rollIn {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@-moz-keyframes rollOut {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
}
@-webkit-keyframes rollOut {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
}
@-o-keyframes rollOut {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
}
@-ms-keyframes rollOut {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
}
@keyframes rollOut {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg);
  }
}
/*-------------------------------------------------------------------*/
/* === Boostrap Main SCSS === */
/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme="light"] {
  --bs-blue: #5e50f9;
  --bs-indigo: #6610f2;
  --bs-purple: #6a008a;
  --bs-pink: #e91e63;
  --bs-red: #f96868;
  --bs-orange: #f2a654;
  --bs-yellow: #f6e84e;
  --bs-green: #46c35f;
  --bs-teal: #58d8a3;
  --bs-cyan: #57c7d4;
  --bs-white: #ffffff;
  --bs-gray: #434a54;
  --bs-gray-light: #aab2bd;
  --bs-gray-lighter: #e8eff4;
  --bs-gray-lightest: #e6e9ed;
  --bs-gray-dark: #0f1531;
  --bs-black: #000000;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #3762c0;
  --bs-secondary: #8e94a9;
  --bs-success: #337533;
  --bs-info: #0062ff;
  --bs-warning: #ffc542;
  --bs-danger: #fc5a5a;
  --bs-light: #aab2bd;
  --bs-dark: #00406a;
  --bs-primary-rgb: 164, 97, 216;
  --bs-secondary-rgb: 142, 148, 169;
  --bs-success-rgb: 68, 206, 66;
  --bs-info-rgb: 0, 98, 255;
  --bs-warning-rgb: 255, 197, 66;
  --bs-danger-rgb: 252, 90, 90;
  --bs-light-rgb: 170, 178, 189;
  --bs-dark-rgb: 0, 23, 55;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-size: 0.6rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #a7afb7;
  --bs-body-color-rgb: 167, 175, 183;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: rgba(151, 151, 151, 0.3);
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #66a1ff;
  --bs-secondary-text-emphasis: #bbbfcb;
  --bs-success-text-emphasis: #8fe28e;
  --bs-info-text-emphasis: #c8a0e8;
  --bs-warning-text-emphasis: #ffdc8e;
  --bs-danger-text-emphasis: #fd9c9c;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #001433;
  --bs-secondary-bg-subtle: #1c1e22;
  --bs-success-bg-subtle: #0e290d;
  --bs-info-bg-subtle: #21132b;
  --bs-warning-bg-subtle: #33270d;
  --bs-danger-bg-subtle: #321212;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #003b99;
  --bs-secondary-border-subtle: #555965;
  --bs-success-border-subtle: #297c28;
  --bs-info-border-subtle: #623a82;
  --bs-warning-border-subtle: #997628;
  --bs-danger-border-subtle: #973636;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #66a1ff;
  --bs-link-hover-color: #85b4ff;
  --bs-link-color-rgb: 102, 161, 255;
  --bs-link-hover-color-rgb: 133, 180, 255;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

/* table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
} */

/* thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
} */

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not(
    [type="week"]
  ):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 18px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6,
.lightGallery .image-tile {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3,
  .lightGallery .image-tile {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4,
  .lightGallery .image-tile {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3,
  .lightGallery .image-tile {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3,
  .lightGallery .image-tile {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table,
.jsgrid .jsgrid-table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: rgba(151, 151, 151, 0.3);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: #f0f1f6;
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > *,
.jsgrid .jsgrid-table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(
    --bs-table-color-state,
    var(--bs-table-color-type, var(--bs-table-color))
  );
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px
    var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody,
.jsgrid .jsgrid-table > tbody {
  vertical-align: inherit;
}
.table > thead,
.jsgrid .jsgrid-table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.94rem + var(--bs-border-width));
  padding-bottom: calc(0.94rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1;
}

.col-form-label-lg {
  padding-top: calc(0.94rem + var(--bs-border-width));
  padding-bottom: calc(0.94rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control,
.typeahead,
.tt-query,
.tt-hint,
.select2-container--default .select2-selection--single .select2-search__field,
.select2-container--default .select2-selection--single,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"],
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row select,
.dataTables_wrapper select,
.asColorPicker-input {
  display: block;
  width: 100%;
  padding: 0.94rem 1.375rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1;
  color: var(--bs-body-color);
  appearance: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control,
  .typeahead,
  .tt-query,
  .tt-hint,
  .select2-container--default .select2-selection--single .select2-search__field,
  .select2-container--default .select2-selection--single,
  .jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"],
  .jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"],
  .jsgrid .jsgrid-table .jsgrid-filter-row select,
  .dataTables_wrapper select,
  .asColorPicker-input {
    transition: none;
  }
}
.form-control[type="file"],
[type="file"].typeahead,
[type="file"].tt-query,
[type="file"].tt-hint,
.select2-container--default
  .select2-selection--single
  [type="file"].select2-search__field,
.select2-container--default [type="file"].select2-selection--single,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="file"][type="number"],
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="file"][type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row select[type="file"],
.dataTables_wrapper select[type="file"],
[type="file"].asColorPicker-input {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]),
[type="file"].typeahead:not(:disabled):not([readonly]),
[type="file"].tt-query:not(:disabled):not([readonly]),
[type="file"].tt-hint:not(:disabled):not([readonly]),
.select2-container--default
  .select2-selection--single
  [type="file"].select2-search__field:not(:disabled):not([readonly]),
.select2-container--default
  [type="file"].select2-selection--single:not(:disabled):not([readonly]),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="file"][type="number"]:not(:disabled):not([readonly]),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="file"][type="text"]:not(:disabled):not([readonly]),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select[type="file"]:not(:disabled):not([readonly]),
.dataTables_wrapper select[type="file"]:not(:disabled):not([readonly]),
[type="file"].asColorPicker-input:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus,
.typeahead:focus,
.tt-query:focus,
.tt-hint:focus,
.select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.select2-container--default .select2-selection--single:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"]:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"]:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select:focus,
.dataTables_wrapper select:focus,
.asColorPicker-input:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value,
.typeahead::-webkit-date-and-time-value,
.tt-query::-webkit-date-and-time-value,
.tt-hint::-webkit-date-and-time-value,
.select2-container--default
  .select2-selection--single
  .select2-search__field::-webkit-date-and-time-value,
.select2-container--default
  .select2-selection--single::-webkit-date-and-time-value,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]::-webkit-date-and-time-value,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]::-webkit-date-and-time-value,
.jsgrid .jsgrid-table .jsgrid-filter-row select::-webkit-date-and-time-value,
.dataTables_wrapper select::-webkit-date-and-time-value,
.asColorPicker-input::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1em;
  margin: 0;
}
.form-control::-webkit-datetime-edit,
.typeahead::-webkit-datetime-edit,
.tt-query::-webkit-datetime-edit,
.tt-hint::-webkit-datetime-edit,
.select2-container--default
  .select2-selection--single
  .select2-search__field::-webkit-datetime-edit,
.select2-container--default .select2-selection--single::-webkit-datetime-edit,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]::-webkit-datetime-edit,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]::-webkit-datetime-edit,
.jsgrid .jsgrid-table .jsgrid-filter-row select::-webkit-datetime-edit,
.dataTables_wrapper select::-webkit-datetime-edit,
.asColorPicker-input::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder,
.typeahead::placeholder,
.tt-query::placeholder,
.tt-hint::placeholder,
.select2-container--default
  .select2-selection--single
  .select2-search__field::placeholder,
.select2-container--default .select2-selection--single::placeholder,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"]::placeholder,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"]::placeholder,
.jsgrid .jsgrid-table .jsgrid-filter-row select::placeholder,
.dataTables_wrapper select::placeholder,
.asColorPicker-input::placeholder {
  color: #00406a;
  opacity: 1;
}
.form-control:disabled,
.typeahead:disabled,
.tt-query:disabled,
.tt-hint:disabled,
.select2-container--default
  .select2-selection--single
  .select2-search__field:disabled,
.select2-container--default .select2-selection--single:disabled,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"]:disabled,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"]:disabled,
.jsgrid .jsgrid-table .jsgrid-filter-row select:disabled,
.dataTables_wrapper select:disabled,
.asColorPicker-input:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button,
.typeahead::file-selector-button,
.tt-query::file-selector-button,
.tt-hint::file-selector-button,
.select2-container--default
  .select2-selection--single
  .select2-search__field::file-selector-button,
.select2-container--default .select2-selection--single::file-selector-button,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]::file-selector-button,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]::file-selector-button,
.jsgrid .jsgrid-table .jsgrid-filter-row select::file-selector-button,
.dataTables_wrapper select::file-selector-button,
.asColorPicker-input::file-selector-button {
  padding: 0.94rem 1.375rem;
  margin: -0.94rem -1.375rem;
  margin-inline-end: 1.375rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button,
  .typeahead::file-selector-button,
  .tt-query::file-selector-button,
  .tt-hint::file-selector-button,
  .select2-container--default
    .select2-selection--single
    .select2-search__field::file-selector-button,
  .select2-container--default .select2-selection--single::file-selector-button,
  .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    input[type="number"]::file-selector-button,
  .jsgrid
    .jsgrid-table
    .jsgrid-filter-row
    input[type="text"]::file-selector-button,
  .jsgrid .jsgrid-table .jsgrid-filter-row select::file-selector-button,
  .dataTables_wrapper select::file-selector-button,
  .asColorPicker-input::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button,
.typeahead:hover:not(:disabled):not([readonly])::file-selector-button,
.tt-query:hover:not(:disabled):not([readonly])::file-selector-button,
.tt-hint:hover:not(:disabled):not([readonly])::file-selector-button,
.select2-container--default
  .select2-selection--single
  .select2-search__field:hover:not(:disabled):not(
    [readonly]
  )::file-selector-button,
.select2-container--default
  .select2-selection--single:hover:not(:disabled):not(
    [readonly]
  )::file-selector-button,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:hover:not(:disabled):not(
    [readonly]
  )::file-selector-button,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:hover:not(:disabled):not([readonly])::file-selector-button,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:hover:not(:disabled):not([readonly])::file-selector-button,
.dataTables_wrapper
  select:hover:not(:disabled):not([readonly])::file-selector-button,
.asColorPicker-input:hover:not(:disabled):not(
    [readonly]
  )::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.94rem 0;
  margin-bottom: 0;
  line-height: 1;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: 2.575rem;
  padding: 0.5rem 0.81rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.5rem 0.81rem;
  margin: -0.5rem -0.81rem;
  margin-inline-end: 0.81rem;
}

.form-control-lg {
  min-height: 3.175rem;
  padding: 0.94rem 1.94rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.94rem 1.94rem;
  margin: -0.94rem -1.94rem;
  margin-inline-end: 1.94rem;
}

textarea.form-control,
textarea.typeahead,
textarea.tt-query,
textarea.tt-hint,
.select2-container--default
  .select2-selection--single
  textarea.select2-search__field,
.select2-container--default textarea.select2-selection--single,
textarea.asColorPicker-input {
  min-height: 2.875rem;
}
textarea.form-control-sm {
  min-height: 2.575rem;
}
textarea.form-control-lg {
  min-height: 3.175rem;
}

.form-control-color {
  width: 3rem;
  height: 2.875rem;
  padding: 0.94rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 2px;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 2px;
}
.form-control-color.form-control-sm {
  height: 2.575rem;
}
.form-control-color.form-control-lg {
  height: 3.175rem;
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn,
.wizard > .actions .btn-check[disabled] + a,
.swal2-modal .swal2-buttonswrapper .btn-check[disabled] + .swal2-styled,
.ajax-upload-dragdrop .btn-check[disabled] + .ajax-file-upload,
.fc .btn-check[disabled] + button,
.btn-check:disabled + .btn,
.wizard > .actions .btn-check:disabled + a,
.swal2-modal .swal2-buttonswrapper .btn-check:disabled + .swal2-styled,
.ajax-upload-dragdrop .btn-check:disabled + .ajax-file-upload,
.fc .btn-check:disabled + button {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme="dark"]
  .form-switch
  .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .typeahead,
.form-floating > .tt-query,
.form-floating > .tt-hint,
.select2-container--default
  .select2-selection--single
  .form-floating
  > .select2-search__field,
.select2-container--default .form-floating > .select2-selection--single,
.jsgrid .jsgrid-table .jsgrid-filter-row .form-floating > input[type="number"],
.jsgrid .jsgrid-table .jsgrid-filter-row .form-floating > input[type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row .form-floating > select,
.dataTables_wrapper .form-floating > select,
.form-floating > .asColorPicker-input,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .typeahead,
.form-floating > .tt-query,
.form-floating > .tt-hint,
.select2-container--default
  .select2-selection--single
  .form-floating
  > .select2-search__field,
.select2-container--default .form-floating > .select2-selection--single,
.jsgrid .jsgrid-table .jsgrid-filter-row .form-floating > input[type="number"],
.jsgrid .jsgrid-table .jsgrid-filter-row .form-floating > input[type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row .form-floating > select,
.dataTables_wrapper .form-floating > select,
.form-floating > .asColorPicker-input,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .typeahead::placeholder,
.form-floating > .tt-query::placeholder,
.form-floating > .tt-hint::placeholder,
.select2-container--default
  .select2-selection--single
  .form-floating
  > .select2-search__field::placeholder,
.select2-container--default
  .form-floating
  > .select2-selection--single::placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="number"]::placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="text"]::placeholder,
.jsgrid .jsgrid-table .jsgrid-filter-row .form-floating > select::placeholder,
.dataTables_wrapper .form-floating > select::placeholder,
.form-floating > .asColorPicker-input::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus,
.form-floating > .typeahead:focus,
.form-floating > .tt-query:focus,
.form-floating > .tt-hint:focus,
.select2-container--default
  .select2-selection--single
  .form-floating
  > .select2-search__field:focus,
.select2-container--default .form-floating > .select2-selection--single:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="number"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="text"]:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row .form-floating > select:focus,
.dataTables_wrapper .form-floating > select:focus,
.form-floating > .asColorPicker-input:focus,
.form-floating > .form-control:not(:placeholder-shown),
.form-floating > .typeahead:not(:placeholder-shown),
.form-floating > .tt-query:not(:placeholder-shown),
.form-floating > .tt-hint:not(:placeholder-shown),
.select2-container--default
  .select2-selection--single
  .form-floating
  > .select2-search__field:not(:placeholder-shown),
.select2-container--default
  .form-floating
  > .select2-selection--single:not(:placeholder-shown),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="number"]:not(:placeholder-shown),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="text"]:not(:placeholder-shown),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > select:not(:placeholder-shown),
.dataTables_wrapper .form-floating > select:not(:placeholder-shown),
.form-floating > .asColorPicker-input:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .typeahead:-webkit-autofill,
.form-floating > .tt-query:-webkit-autofill,
.form-floating > .tt-hint:-webkit-autofill,
.select2-container--default
  .select2-selection--single
  .form-floating
  > .select2-search__field:-webkit-autofill,
.select2-container--default
  .form-floating
  > .select2-selection--single:-webkit-autofill,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="number"]:-webkit-autofill,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="text"]:-webkit-autofill,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > select:-webkit-autofill,
.dataTables_wrapper .form-floating > select:-webkit-autofill,
.form-floating > .asColorPicker-input:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .typeahead:focus ~ label,
.form-floating > .tt-query:focus ~ label,
.form-floating > .tt-hint:focus ~ label,
.select2-container--default
  .select2-selection--single
  .form-floating
  > .select2-search__field:focus
  ~ label,
.select2-container--default
  .form-floating
  > .select2-selection--single:focus
  ~ label,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="number"]:focus
  ~ label,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="text"]:focus
  ~ label,
.jsgrid .jsgrid-table .jsgrid-filter-row .form-floating > select:focus ~ label,
.dataTables_wrapper .form-floating > select:focus ~ label,
.form-floating > .asColorPicker-input:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .typeahead:not(:placeholder-shown) ~ label,
.form-floating > .tt-query:not(:placeholder-shown) ~ label,
.form-floating > .tt-hint:not(:placeholder-shown) ~ label,
.select2-container--default
  .select2-selection--single
  .form-floating
  > .select2-search__field:not(:placeholder-shown)
  ~ label,
.select2-container--default
  .form-floating
  > .select2-selection--single:not(:placeholder-shown)
  ~ label,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="number"]:not(:placeholder-shown)
  ~ label,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="text"]:not(:placeholder-shown)
  ~ label,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > select:not(:placeholder-shown)
  ~ label,
.dataTables_wrapper .form-floating > select:not(:placeholder-shown) ~ label,
.form-floating > .asColorPicker-input:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .typeahead:focus ~ label::after,
.form-floating > .tt-query:focus ~ label::after,
.form-floating > .tt-hint:focus ~ label::after,
.select2-container--default
  .select2-selection--single
  .form-floating
  > .select2-search__field:focus
  ~ label::after,
.select2-container--default
  .form-floating
  > .select2-selection--single:focus
  ~ label::after,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="number"]:focus
  ~ label::after,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="text"]:focus
  ~ label::after,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > select:focus
  ~ label::after,
.dataTables_wrapper .form-floating > select:focus ~ label::after,
.form-floating > .asColorPicker-input:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .typeahead:not(:placeholder-shown) ~ label::after,
.form-floating > .tt-query:not(:placeholder-shown) ~ label::after,
.form-floating > .tt-hint:not(:placeholder-shown) ~ label::after,
.select2-container--default
  .select2-selection--single
  .form-floating
  > .select2-search__field:not(:placeholder-shown)
  ~ label::after,
.select2-container--default
  .form-floating
  > .select2-selection--single:not(:placeholder-shown)
  ~ label::after,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="number"]:not(:placeholder-shown)
  ~ label::after,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="text"]:not(:placeholder-shown)
  ~ label::after,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > select:not(:placeholder-shown)
  ~ label::after,
.dataTables_wrapper
  .form-floating
  > select:not(:placeholder-shown)
  ~ label::after,
.form-floating > .asColorPicker-input:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #ffffff;
  border-radius: 2px;
}
.form-floating > .form-control:-webkit-autofill ~ label,
.form-floating > .typeahead:-webkit-autofill ~ label,
.form-floating > .tt-query:-webkit-autofill ~ label,
.form-floating > .tt-hint:-webkit-autofill ~ label,
.select2-container--default
  .select2-selection--single
  .form-floating
  > .select2-search__field:-webkit-autofill
  ~ label,
.select2-container--default
  .form-floating
  > .select2-selection--single:-webkit-autofill
  ~ label,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="number"]:-webkit-autofill
  ~ label,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="text"]:-webkit-autofill
  ~ label,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > select:-webkit-autofill
  ~ label,
.dataTables_wrapper .form-floating > select:-webkit-autofill ~ label,
.form-floating > .asColorPicker-input:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label,
.select2-container--default
  .select2-selection--single
  .form-floating
  > .select2-search__field:disabled
  ~ label,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="number"]:disabled
  ~ label,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="text"]:disabled
  ~ label,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > select:disabled
  ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after,
.select2-container--default
  .select2-selection--single
  .form-floating
  > .select2-search__field:disabled
  ~ label::after,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="number"]:disabled
  ~ label::after,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > input[type="text"]:disabled
  ~ label::after,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-floating
  > select:disabled
  ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group,
.asColorPicker-wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .typeahead,
.input-group > .tt-query,
.input-group > .tt-hint,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field,
.select2-container--default .input-group > .select2-selection--single,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > input[type="number"],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > input[type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > select,
.dataTables_wrapper .input-group > select,
.asColorPicker-wrap > .form-control,
.asColorPicker-wrap > .typeahead,
.asColorPicker-wrap > .tt-query,
.asColorPicker-wrap > .tt-hint,
.select2-container--default
  .select2-selection--single
  .asColorPicker-wrap
  > .select2-search__field,
.select2-container--default .asColorPicker-wrap > .select2-selection--single,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > input[type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row .asColorPicker-wrap > select,
.dataTables_wrapper .asColorPicker-wrap > select,
.input-group > .asColorPicker-input,
.asColorPicker-wrap > .asColorPicker-input,
.input-group > .form-select,
.asColorPicker-wrap > .form-select,
.input-group > .form-floating,
.asColorPicker-wrap > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .typeahead:focus,
.input-group > .tt-query:focus,
.input-group > .tt-hint:focus,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field:focus,
.select2-container--default .input-group > .select2-selection--single:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type="number"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type="text"]:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > select:focus,
.dataTables_wrapper .input-group > select:focus,
.asColorPicker-wrap > .form-control:focus,
.asColorPicker-wrap > .typeahead:focus,
.asColorPicker-wrap > .tt-query:focus,
.asColorPicker-wrap > .tt-hint:focus,
.select2-container--default
  .select2-selection--single
  .asColorPicker-wrap
  > .select2-search__field:focus,
.select2-container--default
  .asColorPicker-wrap
  > .select2-selection--single:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > input[type="number"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > input[type="text"]:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row .asColorPicker-wrap > select:focus,
.dataTables_wrapper .asColorPicker-wrap > select:focus,
.input-group > .asColorPicker-input:focus,
.asColorPicker-wrap > .asColorPicker-input:focus,
.input-group > .form-select:focus,
.asColorPicker-wrap > .form-select:focus,
.input-group > .form-floating:focus-within,
.asColorPicker-wrap > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn,
.input-group .wizard > .actions a,
.wizard > .actions .input-group a,
.input-group .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .input-group .swal2-styled,
.input-group .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .input-group .ajax-file-upload,
.input-group .fc button,
.fc .input-group button,
.asColorPicker-wrap .btn,
.asColorPicker-wrap .wizard > .actions a,
.wizard > .actions .asColorPicker-wrap a,
.asColorPicker-wrap .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .asColorPicker-wrap .swal2-styled,
.asColorPicker-wrap .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .asColorPicker-wrap .ajax-file-upload,
.asColorPicker-wrap .fc button,
.fc .asColorPicker-wrap button {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus,
.input-group .wizard > .actions a:focus,
.wizard > .actions .input-group a:focus,
.input-group .swal2-modal .swal2-buttonswrapper .swal2-styled:focus,
.swal2-modal .swal2-buttonswrapper .input-group .swal2-styled:focus,
.input-group .ajax-upload-dragdrop .ajax-file-upload:focus,
.ajax-upload-dragdrop .input-group .ajax-file-upload:focus,
.input-group .fc button:focus,
.fc .input-group button:focus,
.asColorPicker-wrap .btn:focus,
.asColorPicker-wrap .wizard > .actions a:focus,
.wizard > .actions .asColorPicker-wrap a:focus,
.asColorPicker-wrap .swal2-modal .swal2-buttonswrapper .swal2-styled:focus,
.swal2-modal .swal2-buttonswrapper .asColorPicker-wrap .swal2-styled:focus,
.asColorPicker-wrap .ajax-upload-dragdrop .ajax-file-upload:focus,
.ajax-upload-dragdrop .asColorPicker-wrap .ajax-file-upload:focus,
.asColorPicker-wrap .fc button:focus,
.fc .asColorPicker-wrap button:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 2px;
}

.input-group-lg > .form-control,
.input-group-lg > .typeahead,
.input-group-lg > .tt-query,
.input-group-lg > .tt-hint,
.select2-container--default
  .select2-selection--single
  .input-group-lg
  > .select2-search__field,
.select2-container--default .input-group-lg > .select2-selection--single,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-lg > input[type="number"],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-lg > input[type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-lg > select,
.dataTables_wrapper .input-group-lg > select,
.input-group-lg > .asColorPicker-input,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.wizard > .actions .input-group-lg > a,
.swal2-modal .swal2-buttonswrapper .input-group-lg > .swal2-styled,
.ajax-upload-dragdrop .input-group-lg > .ajax-file-upload,
.fc .input-group-lg > button {
  padding: 0.94rem 1.94rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .typeahead,
.input-group-sm > .tt-query,
.input-group-sm > .tt-hint,
.select2-container--default
  .select2-selection--single
  .input-group-sm
  > .select2-search__field,
.select2-container--default .input-group-sm > .select2-selection--single,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-sm > input[type="number"],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-sm > input[type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-sm > select,
.dataTables_wrapper .input-group-sm > select,
.input-group-sm > .asColorPicker-input,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.wizard > .actions .input-group-sm > a,
.swal2-modal .swal2-buttonswrapper .input-group-sm > .swal2-styled,
.ajax-upload-dragdrop .input-group-sm > .ajax-file-upload,
.fc .input-group-sm > button {
  padding: 0.5rem 0.81rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.asColorPicker-wrap:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.asColorPicker-wrap:not(.has-validation)
  > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .typeahead,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .tt-query,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .tt-hint,
.select2-container--default
  .select2-selection--single
  .input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .select2-search__field,
.select2-container--default
  .input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .select2-selection--single,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > select,
.dataTables_wrapper
  .input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > select,
.asColorPicker-wrap:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.asColorPicker-wrap:not(.has-validation)
  > .form-floating:not(:last-child)
  > .typeahead,
.asColorPicker-wrap:not(.has-validation)
  > .form-floating:not(:last-child)
  > .tt-query,
.asColorPicker-wrap:not(.has-validation)
  > .form-floating:not(:last-child)
  > .tt-hint,
.select2-container--default
  .select2-selection--single
  .asColorPicker-wrap:not(.has-validation)
  > .form-floating:not(:last-child)
  > .select2-search__field,
.select2-container--default
  .asColorPicker-wrap:not(.has-validation)
  > .form-floating:not(:last-child)
  > .select2-selection--single,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap:not(.has-validation)
  > .form-floating:not(:last-child)
  > input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap:not(.has-validation)
  > .form-floating:not(:last-child)
  > input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap:not(.has-validation)
  > .form-floating:not(:last-child)
  > select,
.dataTables_wrapper
  .asColorPicker-wrap:not(.has-validation)
  > .form-floating:not(:last-child)
  > select,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .asColorPicker-input,
.asColorPicker-wrap:not(.has-validation)
  > .form-floating:not(:last-child)
  > .asColorPicker-input,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select,
.asColorPicker-wrap:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.has-validation.asColorPicker-wrap
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.has-validation.asColorPicker-wrap > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .typeahead,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .tt-query,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .tt-hint,
.select2-container--default
  .select2-selection--single
  .input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .select2-search__field,
.select2-container--default
  .input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .select2-selection--single,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > select,
.dataTables_wrapper
  .input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > select,
.has-validation.asColorPicker-wrap
  > .form-floating:nth-last-child(n + 3)
  > .form-control,
.has-validation.asColorPicker-wrap
  > .form-floating:nth-last-child(n + 3)
  > .typeahead,
.has-validation.asColorPicker-wrap
  > .form-floating:nth-last-child(n + 3)
  > .tt-query,
.has-validation.asColorPicker-wrap
  > .form-floating:nth-last-child(n + 3)
  > .tt-hint,
.select2-container--default
  .select2-selection--single
  .has-validation.asColorPicker-wrap
  > .form-floating:nth-last-child(n + 3)
  > .select2-search__field,
.select2-container--default
  .has-validation.asColorPicker-wrap
  > .form-floating:nth-last-child(n + 3)
  > .select2-selection--single,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .has-validation.asColorPicker-wrap
  > .form-floating:nth-last-child(n + 3)
  > input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .has-validation.asColorPicker-wrap
  > .form-floating:nth-last-child(n + 3)
  > input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .has-validation.asColorPicker-wrap
  > .form-floating:nth-last-child(n + 3)
  > select,
.dataTables_wrapper
  .has-validation.asColorPicker-wrap
  > .form-floating:nth-last-child(n + 3)
  > select,
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .asColorPicker-input,
.has-validation.asColorPicker-wrap
  > .form-floating:nth-last-child(n + 3)
  > .asColorPicker-input,
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-select,
.has-validation.asColorPicker-wrap
  > .form-floating:nth-last-child(n + 3)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback),
.asColorPicker-wrap
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .typeahead,
.input-group > .form-floating:not(:first-child) > .tt-query,
.input-group > .form-floating:not(:first-child) > .tt-hint,
.select2-container--default
  .select2-selection--single
  .input-group
  > .form-floating:not(:first-child)
  > .select2-search__field,
.select2-container--default
  .input-group
  > .form-floating:not(:first-child)
  > .select2-selection--single,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > .form-floating:not(:first-child)
  > input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > .form-floating:not(:first-child)
  > input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > .form-floating:not(:first-child)
  > select,
.dataTables_wrapper .input-group > .form-floating:not(:first-child) > select,
.asColorPicker-wrap > .form-floating:not(:first-child) > .form-control,
.asColorPicker-wrap > .form-floating:not(:first-child) > .typeahead,
.asColorPicker-wrap > .form-floating:not(:first-child) > .tt-query,
.asColorPicker-wrap > .form-floating:not(:first-child) > .tt-hint,
.select2-container--default
  .select2-selection--single
  .asColorPicker-wrap
  > .form-floating:not(:first-child)
  > .select2-search__field,
.select2-container--default
  .asColorPicker-wrap
  > .form-floating:not(:first-child)
  > .select2-selection--single,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > .form-floating:not(:first-child)
  > input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > .form-floating:not(:first-child)
  > input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > .form-floating:not(:first-child)
  > select,
.dataTables_wrapper
  .asColorPicker-wrap
  > .form-floating:not(:first-child)
  > select,
.input-group > .form-floating:not(:first-child) > .asColorPicker-input,
.asColorPicker-wrap > .form-floating:not(:first-child) > .asColorPicker-input,
.input-group > .form-floating:not(:first-child) > .form-select,
.asColorPicker-wrap > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.was-validated .typeahead:valid,
.was-validated .tt-query:valid,
.was-validated .tt-hint:valid,
.was-validated
  .select2-container--default
  .select2-selection--single
  .select2-search__field:valid,
.select2-container--default
  .select2-selection--single
  .was-validated
  .select2-search__field:valid,
.was-validated .select2-container--default .select2-selection--single:valid,
.select2-container--default .was-validated .select2-selection--single:valid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input[type="number"]:valid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input[type="text"]:valid,
.was-validated .jsgrid .jsgrid-table .jsgrid-filter-row select:valid,
.jsgrid .jsgrid-table .jsgrid-filter-row .was-validated select:valid,
.was-validated .dataTables_wrapper select:valid,
.dataTables_wrapper .was-validated select:valid,
.was-validated .asColorPicker-input:valid,
.form-control.is-valid,
.is-valid.typeahead,
.is-valid.tt-query,
.is-valid.tt-hint,
.select2-container--default
  .select2-selection--single
  .is-valid.select2-search__field,
.select2-container--default .is-valid.select2-selection--single,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-valid[type="number"],
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-valid[type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row select.is-valid,
.dataTables_wrapper select.is-valid,
.is-valid.asColorPicker-input {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.was-validated .typeahead:valid:focus,
.was-validated .tt-query:valid:focus,
.was-validated .tt-hint:valid:focus,
.was-validated
  .select2-container--default
  .select2-selection--single
  .select2-search__field:valid:focus,
.select2-container--default
  .select2-selection--single
  .was-validated
  .select2-search__field:valid:focus,
.was-validated
  .select2-container--default
  .select2-selection--single:valid:focus,
.select2-container--default
  .was-validated
  .select2-selection--single:valid:focus,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:valid:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input[type="number"]:valid:focus,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:valid:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input[type="text"]:valid:focus,
.was-validated .jsgrid .jsgrid-table .jsgrid-filter-row select:valid:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row .was-validated select:valid:focus,
.was-validated .dataTables_wrapper select:valid:focus,
.dataTables_wrapper .was-validated select:valid:focus,
.was-validated .asColorPicker-input:valid:focus,
.form-control.is-valid:focus,
.is-valid.typeahead:focus,
.is-valid.tt-query:focus,
.is-valid.tt-hint:focus,
.select2-container--default
  .select2-selection--single
  .is-valid.select2-search__field:focus,
.select2-container--default .is-valid.select2-selection--single:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-valid[type="number"]:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-valid[type="text"]:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.is-valid:focus,
.dataTables_wrapper select.is-valid:focus,
.is-valid.asColorPicker-input:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid,
.was-validated textarea.typeahead:valid,
.was-validated textarea.tt-query:valid,
.was-validated textarea.tt-hint:valid,
.was-validated
  .select2-container--default
  .select2-selection--single
  textarea.select2-search__field:valid,
.select2-container--default
  .select2-selection--single
  .was-validated
  textarea.select2-search__field:valid,
.was-validated
  .select2-container--default
  textarea.select2-selection--single:valid,
.select2-container--default
  .was-validated
  textarea.select2-selection--single:valid,
.was-validated textarea.asColorPicker-input:valid,
textarea.form-control.is-valid,
textarea.is-valid.typeahead,
textarea.is-valid.tt-query,
textarea.is-valid.tt-hint,
.select2-container--default
  .select2-selection--single
  textarea.is-valid.select2-search__field,
.select2-container--default textarea.is-valid.select2-selection--single,
textarea.is-valid.asColorPicker-input {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid,
.was-validated .input-group > .typeahead:not(:focus):valid,
.was-validated .input-group > .tt-query:not(:focus):valid,
.was-validated .input-group > .tt-hint:not(:focus):valid,
.was-validated
  .select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field:not(:focus):valid,
.select2-container--default
  .select2-selection--single
  .was-validated
  .input-group
  > .select2-search__field:not(:focus):valid,
.was-validated
  .select2-container--default
  .input-group
  > .select2-selection--single:not(:focus):valid,
.select2-container--default
  .was-validated
  .input-group
  > .select2-selection--single:not(:focus):valid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type="number"]:not(:focus):valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  .input-group
  > input[type="number"]:not(:focus):valid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type="text"]:not(:focus):valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  .input-group
  > input[type="text"]:not(:focus):valid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > select:not(:focus):valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  .input-group
  > select:not(:focus):valid,
.was-validated .dataTables_wrapper .input-group > select:not(:focus):valid,
.dataTables_wrapper .was-validated .input-group > select:not(:focus):valid,
.was-validated .asColorPicker-wrap > .form-control:not(:focus):valid,
.was-validated .asColorPicker-wrap > .typeahead:not(:focus):valid,
.was-validated .asColorPicker-wrap > .tt-query:not(:focus):valid,
.was-validated .asColorPicker-wrap > .tt-hint:not(:focus):valid,
.was-validated
  .select2-container--default
  .select2-selection--single
  .asColorPicker-wrap
  > .select2-search__field:not(:focus):valid,
.select2-container--default
  .select2-selection--single
  .was-validated
  .asColorPicker-wrap
  > .select2-search__field:not(:focus):valid,
.was-validated
  .select2-container--default
  .asColorPicker-wrap
  > .select2-selection--single:not(:focus):valid,
.select2-container--default
  .was-validated
  .asColorPicker-wrap
  > .select2-selection--single:not(:focus):valid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > input[type="number"]:not(:focus):valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  .asColorPicker-wrap
  > input[type="number"]:not(:focus):valid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > input[type="text"]:not(:focus):valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  .asColorPicker-wrap
  > input[type="text"]:not(:focus):valid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > select:not(:focus):valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  .asColorPicker-wrap
  > select:not(:focus):valid,
.was-validated
  .dataTables_wrapper
  .asColorPicker-wrap
  > select:not(:focus):valid,
.dataTables_wrapper
  .was-validated
  .asColorPicker-wrap
  > select:not(:focus):valid,
.was-validated .input-group > .asColorPicker-input:not(:focus):valid,
.was-validated .asColorPicker-wrap > .asColorPicker-input:not(:focus):valid,
.input-group > .form-control:not(:focus).is-valid,
.input-group > .typeahead:not(:focus).is-valid,
.input-group > .tt-query:not(:focus).is-valid,
.input-group > .tt-hint:not(:focus).is-valid,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field:not(:focus).is-valid,
.select2-container--default
  .input-group
  > .select2-selection--single:not(:focus).is-valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type="number"]:not(:focus).is-valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type="text"]:not(:focus).is-valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > select:not(:focus).is-valid,
.dataTables_wrapper .input-group > select:not(:focus).is-valid,
.asColorPicker-wrap > .form-control:not(:focus).is-valid,
.asColorPicker-wrap > .typeahead:not(:focus).is-valid,
.asColorPicker-wrap > .tt-query:not(:focus).is-valid,
.asColorPicker-wrap > .tt-hint:not(:focus).is-valid,
.select2-container--default
  .select2-selection--single
  .asColorPicker-wrap
  > .select2-search__field:not(:focus).is-valid,
.select2-container--default
  .asColorPicker-wrap
  > .select2-selection--single:not(:focus).is-valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > input[type="number"]:not(:focus).is-valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > input[type="text"]:not(:focus).is-valid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > select:not(:focus).is-valid,
.dataTables_wrapper .asColorPicker-wrap > select:not(:focus).is-valid,
.input-group > .asColorPicker-input:not(:focus).is-valid,
.asColorPicker-wrap > .asColorPicker-input:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.was-validated .asColorPicker-wrap > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.asColorPicker-wrap > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.was-validated .asColorPicker-wrap > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid,
.asColorPicker-wrap > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.was-validated .typeahead:invalid,
.was-validated .tt-query:invalid,
.was-validated .tt-hint:invalid,
.was-validated
  .select2-container--default
  .select2-selection--single
  .select2-search__field:invalid,
.select2-container--default
  .select2-selection--single
  .was-validated
  .select2-search__field:invalid,
.was-validated .select2-container--default .select2-selection--single:invalid,
.select2-container--default .was-validated .select2-selection--single:invalid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input[type="number"]:invalid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input[type="text"]:invalid,
.was-validated .jsgrid .jsgrid-table .jsgrid-filter-row select:invalid,
.jsgrid .jsgrid-table .jsgrid-filter-row .was-validated select:invalid,
.was-validated .dataTables_wrapper select:invalid,
.dataTables_wrapper .was-validated select:invalid,
.was-validated .asColorPicker-input:invalid,
.form-control.is-invalid,
.is-invalid.typeahead,
.is-invalid.tt-query,
.is-invalid.tt-hint,
.select2-container--default
  .select2-selection--single
  .is-invalid.select2-search__field,
.select2-container--default .is-invalid.select2-selection--single,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-invalid[type="number"],
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-invalid[type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row select.is-invalid,
.dataTables_wrapper select.is-invalid,
.is-invalid.asColorPicker-input {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.was-validated .typeahead:invalid:focus,
.was-validated .tt-query:invalid:focus,
.was-validated .tt-hint:invalid:focus,
.was-validated
  .select2-container--default
  .select2-selection--single
  .select2-search__field:invalid:focus,
.select2-container--default
  .select2-selection--single
  .was-validated
  .select2-search__field:invalid:focus,
.was-validated
  .select2-container--default
  .select2-selection--single:invalid:focus,
.select2-container--default
  .was-validated
  .select2-selection--single:invalid:focus,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:invalid:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input[type="number"]:invalid:focus,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:invalid:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input[type="text"]:invalid:focus,
.was-validated .jsgrid .jsgrid-table .jsgrid-filter-row select:invalid:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row .was-validated select:invalid:focus,
.was-validated .dataTables_wrapper select:invalid:focus,
.dataTables_wrapper .was-validated select:invalid:focus,
.was-validated .asColorPicker-input:invalid:focus,
.form-control.is-invalid:focus,
.is-invalid.typeahead:focus,
.is-invalid.tt-query:focus,
.is-invalid.tt-hint:focus,
.select2-container--default
  .select2-selection--single
  .is-invalid.select2-search__field:focus,
.select2-container--default .is-invalid.select2-selection--single:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-invalid[type="number"]:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-invalid[type="text"]:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.is-invalid:focus,
.dataTables_wrapper select.is-invalid:focus,
.is-invalid.asColorPicker-input:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
.was-validated textarea.typeahead:invalid,
.was-validated textarea.tt-query:invalid,
.was-validated textarea.tt-hint:invalid,
.was-validated
  .select2-container--default
  .select2-selection--single
  textarea.select2-search__field:invalid,
.select2-container--default
  .select2-selection--single
  .was-validated
  textarea.select2-search__field:invalid,
.was-validated
  .select2-container--default
  textarea.select2-selection--single:invalid,
.select2-container--default
  .was-validated
  textarea.select2-selection--single:invalid,
.was-validated textarea.asColorPicker-input:invalid,
textarea.form-control.is-invalid,
textarea.is-invalid.typeahead,
textarea.is-invalid.tt-query,
textarea.is-invalid.tt-hint,
.select2-container--default
  .select2-selection--single
  textarea.is-invalid.select2-search__field,
.select2-container--default textarea.is-invalid.select2-selection--single,
textarea.is-invalid.asColorPicker-input {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid,
.was-validated .input-group > .typeahead:not(:focus):invalid,
.was-validated .input-group > .tt-query:not(:focus):invalid,
.was-validated .input-group > .tt-hint:not(:focus):invalid,
.was-validated
  .select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field:not(:focus):invalid,
.select2-container--default
  .select2-selection--single
  .was-validated
  .input-group
  > .select2-search__field:not(:focus):invalid,
.was-validated
  .select2-container--default
  .input-group
  > .select2-selection--single:not(:focus):invalid,
.select2-container--default
  .was-validated
  .input-group
  > .select2-selection--single:not(:focus):invalid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type="number"]:not(:focus):invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  .input-group
  > input[type="number"]:not(:focus):invalid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type="text"]:not(:focus):invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  .input-group
  > input[type="text"]:not(:focus):invalid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > select:not(:focus):invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  .input-group
  > select:not(:focus):invalid,
.was-validated .dataTables_wrapper .input-group > select:not(:focus):invalid,
.dataTables_wrapper .was-validated .input-group > select:not(:focus):invalid,
.was-validated .asColorPicker-wrap > .form-control:not(:focus):invalid,
.was-validated .asColorPicker-wrap > .typeahead:not(:focus):invalid,
.was-validated .asColorPicker-wrap > .tt-query:not(:focus):invalid,
.was-validated .asColorPicker-wrap > .tt-hint:not(:focus):invalid,
.was-validated
  .select2-container--default
  .select2-selection--single
  .asColorPicker-wrap
  > .select2-search__field:not(:focus):invalid,
.select2-container--default
  .select2-selection--single
  .was-validated
  .asColorPicker-wrap
  > .select2-search__field:not(:focus):invalid,
.was-validated
  .select2-container--default
  .asColorPicker-wrap
  > .select2-selection--single:not(:focus):invalid,
.select2-container--default
  .was-validated
  .asColorPicker-wrap
  > .select2-selection--single:not(:focus):invalid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > input[type="number"]:not(:focus):invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  .asColorPicker-wrap
  > input[type="number"]:not(:focus):invalid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > input[type="text"]:not(:focus):invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  .asColorPicker-wrap
  > input[type="text"]:not(:focus):invalid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > select:not(:focus):invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  .asColorPicker-wrap
  > select:not(:focus):invalid,
.was-validated
  .dataTables_wrapper
  .asColorPicker-wrap
  > select:not(:focus):invalid,
.dataTables_wrapper
  .was-validated
  .asColorPicker-wrap
  > select:not(:focus):invalid,
.was-validated .input-group > .asColorPicker-input:not(:focus):invalid,
.was-validated .asColorPicker-wrap > .asColorPicker-input:not(:focus):invalid,
.input-group > .form-control:not(:focus).is-invalid,
.input-group > .typeahead:not(:focus).is-invalid,
.input-group > .tt-query:not(:focus).is-invalid,
.input-group > .tt-hint:not(:focus).is-invalid,
.select2-container--default
  .select2-selection--single
  .input-group
  > .select2-search__field:not(:focus).is-invalid,
.select2-container--default
  .input-group
  > .select2-selection--single:not(:focus).is-invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type="number"]:not(:focus).is-invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type="text"]:not(:focus).is-invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > select:not(:focus).is-invalid,
.dataTables_wrapper .input-group > select:not(:focus).is-invalid,
.asColorPicker-wrap > .form-control:not(:focus).is-invalid,
.asColorPicker-wrap > .typeahead:not(:focus).is-invalid,
.asColorPicker-wrap > .tt-query:not(:focus).is-invalid,
.asColorPicker-wrap > .tt-hint:not(:focus).is-invalid,
.select2-container--default
  .select2-selection--single
  .asColorPicker-wrap
  > .select2-search__field:not(:focus).is-invalid,
.select2-container--default
  .asColorPicker-wrap
  > .select2-selection--single:not(:focus).is-invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > input[type="number"]:not(:focus).is-invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > input[type="text"]:not(:focus).is-invalid,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .asColorPicker-wrap
  > select:not(:focus).is-invalid,
.dataTables_wrapper .asColorPicker-wrap > select:not(:focus).is-invalid,
.input-group > .asColorPicker-input:not(:focus).is-invalid,
.asColorPicker-wrap > .asColorPicker-input:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.was-validated .asColorPicker-wrap > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.asColorPicker-wrap > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.was-validated .asColorPicker-wrap > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid,
.asColorPicker-wrap > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn,
.wizard > .actions a,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-cancel,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-confirm,
.swal2-modal .swal2-buttonswrapper .swal2-styled,
.ajax-upload-dragdrop .ajax-file-upload,
.fc button {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.775rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.1875rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);

  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn,
  .wizard > .actions a,
  .swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-cancel,
  .swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-confirm,
  .swal2-modal .swal2-buttonswrapper .swal2-styled,
  .ajax-upload-dragdrop .ajax-file-upload,
  .fc button {
    transition: none;
  }
}
.btn:hover,
.wizard > .actions a:hover,
.swal2-modal .swal2-buttonswrapper .swal2-styled:hover,
.ajax-upload-dragdrop .ajax-file-upload:hover,
.fc button:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover,
.wizard > .actions .btn-check + a:hover,
.swal2-modal .swal2-buttonswrapper .btn-check + .swal2-styled:hover,
.ajax-upload-dragdrop .btn-check + .ajax-file-upload:hover,
.fc .btn-check + button:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible,
.wizard > .actions a:focus-visible,
.swal2-modal .swal2-buttonswrapper .swal2-styled:focus-visible,
.ajax-upload-dragdrop .ajax-file-upload:focus-visible,
.fc button:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn,
.wizard > .actions .btn-check:focus-visible + a,
.swal2-modal .swal2-buttonswrapper .btn-check:focus-visible + .swal2-styled,
.ajax-upload-dragdrop .btn-check:focus-visible + .ajax-file-upload,
.fc .btn-check:focus-visible + button {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn,
.wizard > .actions .btn-check:checked + a,
.swal2-modal .swal2-buttonswrapper .btn-check:checked + .swal2-styled,
.ajax-upload-dragdrop .btn-check:checked + .ajax-file-upload,
.fc .btn-check:checked + button,
:not(.btn-check) + .btn:active,
.wizard > .actions :not(.btn-check) + a:active,
.swal2-modal .swal2-buttonswrapper :not(.btn-check) + .swal2-styled:active,
.ajax-upload-dragdrop :not(.btn-check) + .ajax-file-upload:active,
.fc :not(.btn-check) + button:active,
.btn:first-child:active,
.wizard > .actions a:first-child:active,
.swal2-modal .swal2-buttonswrapper .swal2-styled:first-child:active,
.ajax-upload-dragdrop .ajax-file-upload:first-child:active,
.fc button:first-child:active,
.btn.active,
.wizard > .actions a.active,
.swal2-modal .swal2-buttonswrapper .active.swal2-styled,
.ajax-upload-dragdrop .active.ajax-file-upload,
.fc button.active,
.btn.show,
.wizard > .actions a.show,
.swal2-modal .swal2-buttonswrapper .show.swal2-styled,
.ajax-upload-dragdrop .show.ajax-file-upload,
.fc button.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible,
.wizard > .actions .btn-check:checked + a:focus-visible,
.swal2-modal
  .swal2-buttonswrapper
  .btn-check:checked
  + .swal2-styled:focus-visible,
.ajax-upload-dragdrop .btn-check:checked + .ajax-file-upload:focus-visible,
.fc .btn-check:checked + button:focus-visible,
:not(.btn-check) + .btn:active:focus-visible,
.wizard > .actions :not(.btn-check) + a:active:focus-visible,
.swal2-modal
  .swal2-buttonswrapper
  :not(.btn-check)
  + .swal2-styled:active:focus-visible,
.ajax-upload-dragdrop :not(.btn-check) + .ajax-file-upload:active:focus-visible,
.fc :not(.btn-check) + button:active:focus-visible,
.btn:first-child:active:focus-visible,
.wizard > .actions a:first-child:active:focus-visible,
.swal2-modal
  .swal2-buttonswrapper
  .swal2-styled:first-child:active:focus-visible,
.ajax-upload-dragdrop .ajax-file-upload:first-child:active:focus-visible,
.fc button:first-child:active:focus-visible,
.btn.active:focus-visible,
.wizard > .actions a.active:focus-visible,
.swal2-modal .swal2-buttonswrapper .active.swal2-styled:focus-visible,
.ajax-upload-dragdrop .active.ajax-file-upload:focus-visible,
.fc button.active:focus-visible,
.btn.show:focus-visible,
.wizard > .actions a.show:focus-visible,
.swal2-modal .swal2-buttonswrapper .show.swal2-styled:focus-visible,
.ajax-upload-dragdrop .show.ajax-file-upload:focus-visible,
.fc button.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled,
.wizard > .actions a:disabled,
.swal2-modal .swal2-buttonswrapper .swal2-styled:disabled,
.ajax-upload-dragdrop .ajax-file-upload:disabled,
.fc button:disabled,
.btn.disabled,
.wizard > .actions a.disabled,
.swal2-modal .swal2-buttonswrapper .disabled.swal2-styled,
.ajax-upload-dragdrop .disabled.ajax-file-upload,
.fc button.disabled,
fieldset:disabled .btn,
fieldset:disabled .wizard > .actions a,
.wizard > .actions fieldset:disabled a,
fieldset:disabled .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper fieldset:disabled .swal2-styled,
fieldset:disabled .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop fieldset:disabled .ajax-file-upload,
fieldset:disabled .fc button,
.fc fieldset:disabled button {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary,
.wizard > .actions a {
  --bs-btn-color: #000;
  --bs-btn-bg: #3762c0;
  --bs-btn-border-color: #3762c0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #607fb9;
  --bs-btn-hover-border-color: #607fb9;
  --bs-btn-focus-shadow-rgb: 139, 82, 184;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #b681e0;
  --bs-btn-active-border-color: #607fb9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #3762c0;
  --bs-btn-disabled-border-color: #3762c0;
}

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #8e94a9;
  --bs-btn-border-color: #8e94a9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #9fa4b6;
  --bs-btn-hover-border-color: #999fb2;
  --bs-btn-focus-shadow-rgb: 121, 126, 144;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #a5a9ba;
  --bs-btn-active-border-color: #999fb2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #8e94a9;
  --bs-btn-disabled-border-color: #8e94a9;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #44ce42;
  --bs-btn-border-color: #44ce42;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #60d55e;
  --bs-btn-hover-border-color: #57d355;
  --bs-btn-focus-shadow-rgb: 58, 175, 56;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #69d868;
  --bs-btn-active-border-color: #57d355;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #44ce42;
  --bs-btn-disabled-border-color: #44ce42;
}

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0062ff;
  --bs-btn-border-color: #0062ff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0053d9;
  --bs-btn-hover-border-color: #004ecc;
  --bs-btn-focus-shadow-rgb: 38, 122, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #004ecc;
  --bs-btn-active-border-color: #004abf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0062ff;
  --bs-btn-disabled-border-color: #0062ff;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc542;
  --bs-btn-border-color: #ffc542;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffce5e;
  --bs-btn-hover-border-color: #ffcb55;
  --bs-btn-focus-shadow-rgb: 217, 167, 56;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffd168;
  --bs-btn-active-border-color: #ffcb55;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc542;
  --bs-btn-disabled-border-color: #ffc542;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #fc5a5a;
  --bs-btn-border-color: #fc5a5a;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fc7373;
  --bs-btn-hover-border-color: #fc6b6b;
  --bs-btn-focus-shadow-rgb: 214, 77, 77;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fd7b7b;
  --bs-btn-active-border-color: #fc6b6b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fc5a5a;
  --bs-btn-disabled-border-color: #fc5a5a;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #aab2bd;
  --bs-btn-border-color: #aab2bd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #9197a1;
  --bs-btn-hover-border-color: #888e97;
  --bs-btn-focus-shadow-rgb: 145, 151, 161;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #888e97;
  --bs-btn-active-border-color: #80868e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #aab2bd;
  --bs-btn-disabled-border-color: #aab2bd;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #001737;
  --bs-btn-border-color: #001737;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #263a55;
  --bs-btn-hover-border-color: #1a2e4b;
  --bs-btn-focus-shadow-rgb: 38, 58, 85;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #33455f;
  --bs-btn-active-border-color: #1a2e4b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #001737;
  --bs-btn-disabled-border-color: #001737;
}

.btn-outline-primary,
.wizard > .actions .disabled a {
  --bs-btn-color: #3762c0;
  --bs-btn-border-color: #3762c0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #3762c0;
  --bs-btn-hover-border-color: #3762c0;
  --bs-btn-focus-shadow-rgb: 164, 97, 216;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3762c0;
  --bs-btn-active-border-color: #3762c0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3762c0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3762c0;
  --bs-gradient: none;
}

.btn-outline-secondary,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-cancel {
  --bs-btn-color: #8e94a9;
  --bs-btn-border-color: #8e94a9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #8e94a9;
  --bs-btn-hover-border-color: #8e94a9;
  --bs-btn-focus-shadow-rgb: 142, 148, 169;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #8e94a9;
  --bs-btn-active-border-color: #8e94a9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #8e94a9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8e94a9;
  --bs-gradient: none;
}

.btn-outline-success,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-submit {
  --bs-btn-color: #44ce42;
  --bs-btn-border-color: #44ce42;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #44ce42;
  --bs-btn-hover-border-color: #44ce42;
  --bs-btn-focus-shadow-rgb: 68, 206, 66;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #44ce42;
  --bs-btn-active-border-color: #44ce42;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #44ce42;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #44ce42;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0062ff;
  --bs-btn-border-color: #0062ff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0062ff;
  --bs-btn-hover-border-color: #0062ff;
  --bs-btn-focus-shadow-rgb: 0, 98, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0062ff;
  --bs-btn-active-border-color: #0062ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0062ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0062ff;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc542;
  --bs-btn-border-color: #ffc542;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc542;
  --bs-btn-hover-border-color: #ffc542;
  --bs-btn-focus-shadow-rgb: 255, 197, 66;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc542;
  --bs-btn-active-border-color: #ffc542;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc542;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc542;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #fc5a5a;
  --bs-btn-border-color: #fc5a5a;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fc5a5a;
  --bs-btn-hover-border-color: #fc5a5a;
  --bs-btn-focus-shadow-rgb: 252, 90, 90;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fc5a5a;
  --bs-btn-active-border-color: #fc5a5a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fc5a5a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fc5a5a;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #aab2bd;
  --bs-btn-border-color: #aab2bd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #aab2bd;
  --bs-btn-hover-border-color: #aab2bd;
  --bs-btn-focus-shadow-rgb: 170, 178, 189;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #aab2bd;
  --bs-btn-active-border-color: #aab2bd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #aab2bd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #aab2bd;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #001737;
  --bs-btn-border-color: #001737;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #001737;
  --bs-btn-hover-border-color: #001737;
  --bs-btn-focus-shadow-rgb: 0, 23, 55;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #001737;
  --bs-btn-active-border-color: #001737;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #001737;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #001737;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg,
.btn-group-lg > .btn,
.wizard > .actions .btn-group-lg > a,
.swal2-modal .swal2-buttonswrapper .btn-group-lg > .swal2-styled,
.ajax-upload-dragdrop .btn-group-lg > .ajax-file-upload,
.fc .btn-group-lg > button {
  --bs-btn-padding-y: 1rem;
  --bs-btn-padding-x: 3rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.1875rem;
}

.btn-sm,
.fc button,
.btn-group-sm > .btn,
.wizard > .actions .btn-group-sm > a,
.swal2-modal .swal2-buttonswrapper .btn-group-sm > .swal2-styled,
.ajax-upload-dragdrop .btn-group-sm > .ajax-file-upload,
.fc .btn-group-sm > button {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 0.81rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.1875rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: rgba(151, 151, 151, 0.3);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(
    var(--bs-border-radius) - var(--bs-border-width)
  );
  --bs-dropdown-divider-bg: rgba(151, 151, 151, 0.3);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: #a7afb7;
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #a7afb7;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y)
    var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.fc .fc-button-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.wizard > .actions .btn-group > a,
.swal2-modal .swal2-buttonswrapper .btn-group > .swal2-styled,
.ajax-upload-dragdrop .btn-group > .ajax-file-upload,
.fc .fc-button-group > .btn,
.fc .wizard > .actions .fc-button-group > a,
.wizard > .actions .fc .fc-button-group > a,
.fc .swal2-modal .swal2-buttonswrapper .fc-button-group > .swal2-styled,
.swal2-modal .swal2-buttonswrapper .fc .fc-button-group > .swal2-styled,
.fc .ajax-upload-dragdrop .fc-button-group > .ajax-file-upload,
.ajax-upload-dragdrop .fc .fc-button-group > .ajax-file-upload,
.fc .btn-group > button,
.fc .fc-button-group > button,
.btn-group-vertical > .btn,
.wizard > .actions .btn-group-vertical > a,
.swal2-modal .swal2-buttonswrapper .btn-group-vertical > .swal2-styled,
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload,
.fc .btn-group-vertical > button {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.wizard > .actions .btn-group > .btn-check:checked + a,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .btn-check:checked
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .btn-check:checked
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .btn-check:checked
  + .swal2-styled,
.ajax-upload-dragdrop .btn-group > .btn-check:checked + .ajax-file-upload,
.fc .fc-button-group > .btn-check:checked + .btn,
.fc .wizard > .actions .fc-button-group > .btn-check:checked + a,
.wizard > .actions .fc .fc-button-group > .btn-check:checked + a,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .btn-check:checked
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .btn-check:checked
  + .swal2-styled.swal2-cancel,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .btn-check:checked
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .btn-check:checked
  + .swal2-styled.swal2-confirm,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .btn-check:checked
  + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .btn-check:checked
  + .swal2-styled,
.fc
  .ajax-upload-dragdrop
  .fc-button-group
  > .btn-check:checked
  + .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  > .btn-check:checked
  + .ajax-file-upload,
.fc .btn-group > .btn-check:checked + button,
.fc .fc-button-group > .btn-check:checked + button,
.btn-group > .btn-check:focus + .btn,
.wizard > .actions .btn-group > .btn-check:focus + a,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .btn-check:focus
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .btn-check:focus
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .btn-check:focus
  + .swal2-styled,
.ajax-upload-dragdrop .btn-group > .btn-check:focus + .ajax-file-upload,
.fc .fc-button-group > .btn-check:focus + .btn,
.fc .wizard > .actions .fc-button-group > .btn-check:focus + a,
.wizard > .actions .fc .fc-button-group > .btn-check:focus + a,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .btn-check:focus
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .btn-check:focus
  + .swal2-styled.swal2-cancel,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .btn-check:focus
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .btn-check:focus
  + .swal2-styled.swal2-confirm,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .btn-check:focus
  + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .btn-check:focus
  + .swal2-styled,
.fc
  .ajax-upload-dragdrop
  .fc-button-group
  > .btn-check:focus
  + .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  > .btn-check:focus
  + .ajax-file-upload,
.fc .btn-group > .btn-check:focus + button,
.fc .fc-button-group > .btn-check:focus + button,
.btn-group > .btn:hover,
.wizard > .actions .btn-group > a:hover,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .swal2-styled.swal2-cancel:hover,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .swal2-styled.swal2-confirm:hover,
.swal2-modal .swal2-buttonswrapper .btn-group > .swal2-styled:hover,
.ajax-upload-dragdrop .btn-group > .ajax-file-upload:hover,
.fc .fc-button-group > .btn:hover,
.fc .wizard > .actions .fc-button-group > a:hover,
.wizard > .actions .fc .fc-button-group > a:hover,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .swal2-styled.swal2-cancel:hover,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .swal2-styled.swal2-cancel:hover,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .swal2-styled.swal2-confirm:hover,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .swal2-styled.swal2-confirm:hover,
.fc .swal2-modal .swal2-buttonswrapper .fc-button-group > .swal2-styled:hover,
.swal2-modal .swal2-buttonswrapper .fc .fc-button-group > .swal2-styled:hover,
.fc .ajax-upload-dragdrop .fc-button-group > .ajax-file-upload:hover,
.ajax-upload-dragdrop .fc .fc-button-group > .ajax-file-upload:hover,
.fc .btn-group > button:hover,
.fc .fc-button-group > button:hover,
.btn-group > .btn:focus,
.wizard > .actions .btn-group > a:focus,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .swal2-styled.swal2-cancel:focus,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .swal2-styled.swal2-confirm:focus,
.swal2-modal .swal2-buttonswrapper .btn-group > .swal2-styled:focus,
.ajax-upload-dragdrop .btn-group > .ajax-file-upload:focus,
.fc .fc-button-group > .btn:focus,
.fc .wizard > .actions .fc-button-group > a:focus,
.wizard > .actions .fc .fc-button-group > a:focus,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .swal2-styled.swal2-cancel:focus,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .swal2-styled.swal2-cancel:focus,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .swal2-styled.swal2-confirm:focus,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .swal2-styled.swal2-confirm:focus,
.fc .swal2-modal .swal2-buttonswrapper .fc-button-group > .swal2-styled:focus,
.swal2-modal .swal2-buttonswrapper .fc .fc-button-group > .swal2-styled:focus,
.fc .ajax-upload-dragdrop .fc-button-group > .ajax-file-upload:focus,
.ajax-upload-dragdrop .fc .fc-button-group > .ajax-file-upload:focus,
.fc .btn-group > button:focus,
.fc .fc-button-group > button:focus,
.btn-group > .btn:active,
.wizard > .actions .btn-group > a:active,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .swal2-styled.swal2-cancel:active,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .swal2-styled.swal2-confirm:active,
.swal2-modal .swal2-buttonswrapper .btn-group > .swal2-styled:active,
.ajax-upload-dragdrop .btn-group > .ajax-file-upload:active,
.fc .fc-button-group > .btn:active,
.fc .wizard > .actions .fc-button-group > a:active,
.wizard > .actions .fc .fc-button-group > a:active,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .swal2-styled.swal2-cancel:active,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .swal2-styled.swal2-cancel:active,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .swal2-styled.swal2-confirm:active,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .swal2-styled.swal2-confirm:active,
.fc .swal2-modal .swal2-buttonswrapper .fc-button-group > .swal2-styled:active,
.swal2-modal .swal2-buttonswrapper .fc .fc-button-group > .swal2-styled:active,
.fc .ajax-upload-dragdrop .fc-button-group > .ajax-file-upload:active,
.ajax-upload-dragdrop .fc .fc-button-group > .ajax-file-upload:active,
.fc .btn-group > button:active,
.fc .fc-button-group > button:active,
.btn-group > .btn.active,
.wizard > .actions .btn-group > a.active,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .active.swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .active.swal2-styled.swal2-confirm,
.swal2-modal .swal2-buttonswrapper .btn-group > .active.swal2-styled,
.ajax-upload-dragdrop .btn-group > .active.ajax-file-upload,
.fc .fc-button-group > .btn.active,
.fc .wizard > .actions .fc-button-group > a.active,
.wizard > .actions .fc .fc-button-group > a.active,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .active.swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .active.swal2-styled.swal2-cancel,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .active.swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .active.swal2-styled.swal2-confirm,
.fc .swal2-modal .swal2-buttonswrapper .fc-button-group > .active.swal2-styled,
.swal2-modal .swal2-buttonswrapper .fc .fc-button-group > .active.swal2-styled,
.fc .ajax-upload-dragdrop .fc-button-group > .active.ajax-file-upload,
.ajax-upload-dragdrop .fc .fc-button-group > .active.ajax-file-upload,
.fc .btn-group > button.active,
.fc .fc-button-group > button.active,
.btn-group-vertical > .btn-check:checked + .btn,
.wizard > .actions .btn-group-vertical > .btn-check:checked + a,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .btn-check:checked
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .btn-check:checked
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .btn-check:checked
  + .swal2-styled,
.ajax-upload-dragdrop
  .btn-group-vertical
  > .btn-check:checked
  + .ajax-file-upload,
.fc .btn-group-vertical > .btn-check:checked + button,
.btn-group-vertical > .btn-check:focus + .btn,
.wizard > .actions .btn-group-vertical > .btn-check:focus + a,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .btn-check:focus
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .btn-check:focus
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .btn-check:focus
  + .swal2-styled,
.ajax-upload-dragdrop
  .btn-group-vertical
  > .btn-check:focus
  + .ajax-file-upload,
.fc .btn-group-vertical > .btn-check:focus + button,
.btn-group-vertical > .btn:hover,
.wizard > .actions .btn-group-vertical > a:hover,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .swal2-styled.swal2-cancel:hover,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .swal2-styled.swal2-confirm:hover,
.swal2-modal .swal2-buttonswrapper .btn-group-vertical > .swal2-styled:hover,
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload:hover,
.fc .btn-group-vertical > button:hover,
.btn-group-vertical > .btn:focus,
.wizard > .actions .btn-group-vertical > a:focus,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .swal2-styled.swal2-cancel:focus,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .swal2-styled.swal2-confirm:focus,
.swal2-modal .swal2-buttonswrapper .btn-group-vertical > .swal2-styled:focus,
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload:focus,
.fc .btn-group-vertical > button:focus,
.btn-group-vertical > .btn:active,
.wizard > .actions .btn-group-vertical > a:active,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .swal2-styled.swal2-cancel:active,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .swal2-styled.swal2-confirm:active,
.swal2-modal .swal2-buttonswrapper .btn-group-vertical > .swal2-styled:active,
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload:active,
.fc .btn-group-vertical > button:active,
.btn-group-vertical > .btn.active,
.wizard > .actions .btn-group-vertical > a.active,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .active.swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .active.swal2-styled.swal2-confirm,
.swal2-modal .swal2-buttonswrapper .btn-group-vertical > .active.swal2-styled,
.ajax-upload-dragdrop .btn-group-vertical > .active.ajax-file-upload,
.fc .btn-group-vertical > button.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group,
.btn-toolbar .asColorPicker-wrap {
  width: auto;
}

.btn-group,
.fc .fc-button-group {
  border-radius: 0.1875rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.wizard > .actions .btn-group > :not(.btn-check:first-child) + a,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > :not(.btn-check:first-child)
  + .swal2-styled,
.ajax-upload-dragdrop
  .btn-group
  > :not(.btn-check:first-child)
  + .ajax-file-upload,
.fc .fc-button-group > :not(.btn-check:first-child) + .btn,
.fc .wizard > .actions .fc-button-group > :not(.btn-check:first-child) + a,
.wizard > .actions .fc .fc-button-group > :not(.btn-check:first-child) + a,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > :not(.btn-check:first-child)
  + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > :not(.btn-check:first-child)
  + .swal2-styled,
.fc
  .ajax-upload-dragdrop
  .fc-button-group
  > :not(.btn-check:first-child)
  + .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  > :not(.btn-check:first-child)
  + .ajax-file-upload,
.fc .btn-group > :not(.btn-check:first-child) + button,
.fc .fc-button-group > :not(.btn-check:first-child) + button,
.btn-group > .btn-group:not(:first-child),
.fc .fc-button-group > .btn-group:not(:first-child),
.fc .btn-group > .fc-button-group:not(:first-child),
.fc .fc-button-group > .fc-button-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.wizard > .actions .btn-group > a:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .swal2-styled:not(:last-child):not(.dropdown-toggle),
.ajax-upload-dragdrop
  .btn-group
  > .ajax-file-upload:not(:last-child):not(.dropdown-toggle),
.fc .fc-button-group > .btn:not(:last-child):not(.dropdown-toggle),
.fc
  .wizard
  > .actions
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .fc
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .swal2-styled:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .swal2-styled:not(:last-child):not(.dropdown-toggle),
.fc
  .ajax-upload-dragdrop
  .fc-button-group
  > .ajax-file-upload:not(:last-child):not(.dropdown-toggle),
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  > .ajax-file-upload:not(:last-child):not(.dropdown-toggle),
.fc .btn-group > button:not(:last-child):not(.dropdown-toggle),
.fc .fc-button-group > button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.wizard > .actions .btn-group > a.dropdown-toggle-split:first-child,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .dropdown-toggle-split.swal2-styled:first-child,
.ajax-upload-dragdrop
  .btn-group
  > .dropdown-toggle-split.ajax-file-upload:first-child,
.fc .fc-button-group > .btn.dropdown-toggle-split:first-child,
.fc .wizard > .actions .fc-button-group > a.dropdown-toggle-split:first-child,
.wizard > .actions .fc .fc-button-group > a.dropdown-toggle-split:first-child,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .dropdown-toggle-split.swal2-styled:first-child,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .dropdown-toggle-split.swal2-styled:first-child,
.fc
  .ajax-upload-dragdrop
  .fc-button-group
  > .dropdown-toggle-split.ajax-file-upload:first-child,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  > .dropdown-toggle-split.ajax-file-upload:first-child,
.fc .btn-group > button.dropdown-toggle-split:first-child,
.fc .fc-button-group > button.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn,
.wizard > .actions .btn-group > .btn-group:not(:last-child) > a,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .btn-group:not(:last-child)
  > .swal2-styled,
.ajax-upload-dragdrop
  .btn-group
  > .btn-group:not(:last-child)
  > .ajax-file-upload,
.fc .fc-button-group > .btn-group:not(:last-child) > .btn,
.fc .wizard > .actions .fc-button-group > .btn-group:not(:last-child) > a,
.wizard > .actions .fc .fc-button-group > .btn-group:not(:last-child) > a,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .btn-group:not(:last-child)
  > .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .btn-group:not(:last-child)
  > .swal2-styled,
.fc
  .ajax-upload-dragdrop
  .fc-button-group
  > .btn-group:not(:last-child)
  > .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  > .btn-group:not(:last-child)
  > .ajax-file-upload,
.fc .btn-group > .fc-button-group:not(:last-child) > .btn,
.fc .wizard > .actions .btn-group > .fc-button-group:not(:last-child) > a,
.wizard > .actions .fc .btn-group > .fc-button-group:not(:last-child) > a,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .fc-button-group:not(:last-child)
  > .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .btn-group
  > .fc-button-group:not(:last-child)
  > .swal2-styled,
.fc
  .ajax-upload-dragdrop
  .btn-group
  > .fc-button-group:not(:last-child)
  > .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .btn-group
  > .fc-button-group:not(:last-child)
  > .ajax-file-upload,
.fc .fc-button-group > .fc-button-group:not(:last-child) > .btn,
.fc .wizard > .actions .fc-button-group > .fc-button-group:not(:last-child) > a,
.wizard > .actions .fc .fc-button-group > .fc-button-group:not(:last-child) > a,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .swal2-styled,
.fc
  .ajax-upload-dragdrop
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  > .fc-button-group:not(:last-child)
  > .ajax-file-upload,
.fc .btn-group > .btn-group:not(:last-child) > button,
.fc .fc-button-group > .btn-group:not(:last-child) > button,
.fc .btn-group > .fc-button-group:not(:last-child) > button,
.fc .fc-button-group > .fc-button-group:not(:last-child) > button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n + 3),
.wizard > .actions .btn-group > a:nth-child(n + 3),
.swal2-modal .swal2-buttonswrapper .btn-group > .swal2-styled:nth-child(n + 3),
.ajax-upload-dragdrop .btn-group > .ajax-file-upload:nth-child(n + 3),
.fc .fc-button-group > .btn:nth-child(n + 3),
.fc .wizard > .actions .fc-button-group > a:nth-child(n + 3),
.wizard > .actions .fc .fc-button-group > a:nth-child(n + 3),
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .swal2-styled:nth-child(n + 3),
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .swal2-styled:nth-child(n + 3),
.fc .ajax-upload-dragdrop .fc-button-group > .ajax-file-upload:nth-child(n + 3),
.ajax-upload-dragdrop .fc .fc-button-group > .ajax-file-upload:nth-child(n + 3),
.fc .btn-group > button:nth-child(n + 3),
.fc .fc-button-group > button:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.wizard > .actions .btn-group > :not(.btn-check) + a,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > :not(.btn-check)
  + .swal2-styled,
.ajax-upload-dragdrop .btn-group > :not(.btn-check) + .ajax-file-upload,
.fc .fc-button-group > :not(.btn-check) + .btn,
.fc .wizard > .actions .fc-button-group > :not(.btn-check) + a,
.wizard > .actions .fc .fc-button-group > :not(.btn-check) + a,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > :not(.btn-check)
  + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > :not(.btn-check)
  + .swal2-styled,
.fc
  .ajax-upload-dragdrop
  .fc-button-group
  > :not(.btn-check)
  + .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  > :not(.btn-check)
  + .ajax-file-upload,
.fc .btn-group > :not(.btn-check) + button,
.fc .fc-button-group > :not(.btn-check) + button,
.btn-group > .btn-group:not(:first-child) > .btn,
.wizard > .actions .btn-group > .btn-group:not(:first-child) > a,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .btn-group:not(:first-child)
  > .swal2-styled,
.ajax-upload-dragdrop
  .btn-group
  > .btn-group:not(:first-child)
  > .ajax-file-upload,
.fc .fc-button-group > .btn-group:not(:first-child) > .btn,
.fc .wizard > .actions .fc-button-group > .btn-group:not(:first-child) > a,
.wizard > .actions .fc .fc-button-group > .btn-group:not(:first-child) > a,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .btn-group:not(:first-child)
  > .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .btn-group:not(:first-child)
  > .swal2-styled,
.fc
  .ajax-upload-dragdrop
  .fc-button-group
  > .btn-group:not(:first-child)
  > .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  > .btn-group:not(:first-child)
  > .ajax-file-upload,
.fc .btn-group > .fc-button-group:not(:first-child) > .btn,
.fc .wizard > .actions .btn-group > .fc-button-group:not(:first-child) > a,
.wizard > .actions .fc .btn-group > .fc-button-group:not(:first-child) > a,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group
  > .fc-button-group:not(:first-child)
  > .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .btn-group
  > .fc-button-group:not(:first-child)
  > .swal2-styled,
.fc
  .ajax-upload-dragdrop
  .btn-group
  > .fc-button-group:not(:first-child)
  > .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .btn-group
  > .fc-button-group:not(:first-child)
  > .ajax-file-upload,
.fc .fc-button-group > .fc-button-group:not(:first-child) > .btn,
.fc
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .swal2-styled,
.fc
  .ajax-upload-dragdrop
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > .ajax-file-upload,
.fc .btn-group > .btn-group:not(:first-child) > button,
.fc .fc-button-group > .btn-group:not(:first-child) > button,
.fc .btn-group > .fc-button-group:not(:first-child) > button,
.fc .fc-button-group > .fc-button-group:not(:first-child) > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.fc button + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.wizard > .actions .btn-group-sm > a + .dropdown-toggle-split,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-sm
  > .swal2-styled
  + .dropdown-toggle-split,
.ajax-upload-dragdrop
  .btn-group-sm
  > .ajax-file-upload
  + .dropdown-toggle-split {
  padding-right: 0.6075rem;
  padding-left: 0.6075rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.wizard > .actions .btn-group-lg > a + .dropdown-toggle-split,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-lg
  > .swal2-styled
  + .dropdown-toggle-split,
.ajax-upload-dragdrop
  .btn-group-lg
  > .ajax-file-upload
  + .dropdown-toggle-split,
.fc .btn-group-lg > button + .dropdown-toggle-split {
  padding-right: 2.25rem;
  padding-left: 2.25rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.wizard > .actions .btn-group-vertical > a,
.swal2-modal .swal2-buttonswrapper .btn-group-vertical > .swal2-styled,
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload,
.fc .btn-group-vertical > button,
.btn-group-vertical > .btn-group,
.fc .btn-group-vertical > .fc-button-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.wizard > .actions .btn-group-vertical > a:not(:first-child),
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .swal2-styled:not(:first-child),
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload:not(:first-child),
.fc .btn-group-vertical > button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child),
.fc .btn-group-vertical > .fc-button-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .btn-group-vertical
  > a:not(:last-child):not(.dropdown-toggle),
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .swal2-styled:not(:last-child):not(.dropdown-toggle),
.ajax-upload-dragdrop
  .btn-group-vertical
  > .ajax-file-upload:not(:last-child):not(.dropdown-toggle),
.fc .btn-group-vertical > button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.wizard > .actions .btn-group-vertical > .btn-group:not(:last-child) > a,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .btn-group:not(:last-child)
  > .swal2-styled,
.ajax-upload-dragdrop
  .btn-group-vertical
  > .btn-group:not(:last-child)
  > .ajax-file-upload,
.fc .btn-group-vertical > .fc-button-group:not(:last-child) > .btn,
.fc
  .wizard
  > .actions
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > a,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > .swal2-styled,
.fc
  .ajax-upload-dragdrop
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > .ajax-file-upload,
.fc .btn-group-vertical > .btn-group:not(:last-child) > button,
.fc .btn-group-vertical > .fc-button-group:not(:last-child) > button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.wizard > .actions .btn-group-vertical > a ~ .btn,
.wizard > .actions .btn-group-vertical > .btn ~ a,
.wizard > .actions .btn-group-vertical > a ~ a,
.swal2-modal .swal2-buttonswrapper .btn-group-vertical > .swal2-styled ~ .btn,
.swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  .btn-group-vertical
  > .swal2-styled
  ~ a,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .swal2-styled
  ~ a,
.swal2-modal .swal2-buttonswrapper .btn-group-vertical > .btn ~ .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  .btn-group-vertical
  > a
  ~ .swal2-styled,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > a
  ~ .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .swal2-styled
  ~ .swal2-styled,
.ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload ~ .btn,
.ajax-upload-dragdrop
  .wizard
  > .actions
  .btn-group-vertical
  > .ajax-file-upload
  ~ a,
.wizard
  > .actions
  .ajax-upload-dragdrop
  .btn-group-vertical
  > .ajax-file-upload
  ~ a,
.ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .ajax-file-upload
  ~ .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .btn-group-vertical
  > .ajax-file-upload
  ~ .swal2-styled,
.ajax-upload-dragdrop .btn-group-vertical > .btn ~ .ajax-file-upload,
.ajax-upload-dragdrop
  .wizard
  > .actions
  .btn-group-vertical
  > a
  ~ .ajax-file-upload,
.wizard
  > .actions
  .ajax-upload-dragdrop
  .btn-group-vertical
  > a
  ~ .ajax-file-upload,
.ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .swal2-styled
  ~ .ajax-file-upload,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .btn-group-vertical
  > .swal2-styled
  ~ .ajax-file-upload,
.ajax-upload-dragdrop
  .btn-group-vertical
  > .ajax-file-upload
  ~ .ajax-file-upload,
.fc .btn-group-vertical > button ~ .btn,
.fc .wizard > .actions .btn-group-vertical > button ~ a,
.wizard > .actions .fc .btn-group-vertical > button ~ a,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > button
  ~ .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .btn-group-vertical
  > button
  ~ .swal2-styled,
.fc .ajax-upload-dragdrop .btn-group-vertical > button ~ .ajax-file-upload,
.ajax-upload-dragdrop .fc .btn-group-vertical > button ~ .ajax-file-upload,
.fc .btn-group-vertical > .btn ~ button,
.fc .wizard > .actions .btn-group-vertical > a ~ button,
.wizard > .actions .fc .btn-group-vertical > a ~ button,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .swal2-styled
  ~ button,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .btn-group-vertical
  > .swal2-styled
  ~ button,
.fc .ajax-upload-dragdrop .btn-group-vertical > .ajax-file-upload ~ button,
.ajax-upload-dragdrop .fc .btn-group-vertical > .ajax-file-upload ~ button,
.fc .btn-group-vertical > button ~ button,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.wizard > .actions .btn-group-vertical > .btn-group:not(:first-child) > a,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .btn-group:not(:first-child)
  > .swal2-styled,
.ajax-upload-dragdrop
  .btn-group-vertical
  > .btn-group:not(:first-child)
  > .ajax-file-upload,
.fc .btn-group-vertical > .fc-button-group:not(:first-child) > .btn,
.fc
  .wizard
  > .actions
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > a,
.fc
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > .swal2-styled,
.fc
  .ajax-upload-dragdrop
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > .ajax-file-upload,
.fc .btn-group-vertical > .btn-group:not(:first-child) > button,
.fc .btn-group-vertical > .fc-button-group:not(:first-child) > button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.scrollNav {
  height: 85vh;
  overflow: auto;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled,
.nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: #e3e5ef;
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: #e3e5ef #e3e5ef #e3e5ef;
  --bs-nav-tabs-link-active-color: #a7afb7;
  --bs-nav-tabs-link-active-bg: #ffffff;
  --bs-nav-tabs-link-active-border-color: #e3e5ef #e3e5ef #ffffff;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover,
.nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: #001737;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.3125rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(
    var(--bs-border-radius) - (var(--bs-border-width))
  );
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 0.5rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 10px;
  color: rgb(94, 94, 94);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(
    var(--bs-border-radius) - (var(--bs-border-width))
  );
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2366a1ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2366a1ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 1.13rem;
  --bs-breadcrumb-padding-y: 0.56rem;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-font-size: 0.875rem;
  --bs-breadcrumb-bg: transparent;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #495057;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination,
.jsgrid .jsgrid-pager {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-page a {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid
    var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link,
  .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
  .jsgrid .jsgrid-pager .jsgrid-pager-page a {
    transition: none;
  }
}
.page-link:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-page a:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:focus,
.jsgrid .jsgrid-pager .jsgrid-pager-page a:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a.active,
.jsgrid .jsgrid-pager .jsgrid-pager-page a.active,
.active > .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .active > a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .active > a {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a.disabled,
.jsgrid .jsgrid-pager .jsgrid-pager-page a.disabled,
.disabled > .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .disabled > a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .disabled > a {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link,
.page-item:not(:first-child) .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item:not(:first-child) a,
.page-item:not(:first-child) .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item:not(:first-child) a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:not(:first-child) .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:not(:first-child) a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:not(:first-child) .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-page:not(:first-child) a {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link,
.page-item:first-child .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item:first-child a,
.page-item:first-child .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item:first-child a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:first-child .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:first-child a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:first-child .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-page:first-child a {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link,
.page-item:last-child .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item:last-child a,
.page-item:last-child .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item:last-child a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:last-child .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:last-child a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:last-child .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-page:last-child a {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge,
.wizard > .actions a .badge,
.swal2-modal .swal2-buttonswrapper .swal2-styled .badge,
.ajax-upload-dragdrop .ajax-file-upload .badge,
.fc button .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y)
    var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid
    var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid
    var(--bs-toast-header-border-color);
  border-top-left-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
  border-top-right-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 0.9375rem;
  --bs-modal-margin: 10px;
  --bs-modal-color: ;
  --bs-modal-bg: #f0f1f6;
  --bs-modal-border-color: rgba(151, 151, 151, 0.3);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  --bs-modal-inner-border-radius: calc(
    var(--bs-border-radius-lg) - (var(--bs-border-width))
  );
  --bs-modal-header-padding-x: 26px;
  --bs-modal-header-padding-y: 25px;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: rgba(151, 151, 151, 0.3);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: rgba(151, 151, 151, 0.3);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.4s ease;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid
    var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5)
    calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y))
    calc(-0.5 * var(--bs-modal-header-padding-x))
    calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid
    var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 30px;
    --bs-modal-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 90%;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.75rem;
  --bs-tooltip-padding-y: 0.4rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.75rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height)
    calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0
    calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(
    var(--bs-border-radius-lg) - var(--bs-border-width)
  );
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height)
    calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0
    calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon,
[data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme="dark"] .carousel .carousel-caption,
[data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite
    var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5)
    calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before,
.wizard > .actions a.placeholder::before,
.swal2-modal .swal2-buttonswrapper .placeholder.swal2-styled::before,
.ajax-upload-dragdrop .placeholder.ajax-file-upload::before,
.fc button.placeholder::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(
    130deg,
    #000000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000000 95%
  );
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(
    var(--bs-success-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(
    var(--bs-info-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(
    var(--bs-danger-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(
    var(--bs-light-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(
    var(--bs-dark-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-primary-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-primary:hover,
.link-primary:focus {
  color: RGBA(182, 129, 224, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    182,
    129,
    224,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-secondary-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-secondary:hover,
.link-secondary:focus {
  color: RGBA(165, 169, 186, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    165,
    169,
    186,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-success-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-success:hover,
.link-success:focus {
  color: RGBA(105, 216, 104, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    105,
    216,
    104,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-info-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-info:hover,
.link-info:focus {
  color: RGBA(0, 78, 204, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    0,
    78,
    204,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-warning-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-warning:hover,
.link-warning:focus {
  color: RGBA(255, 209, 104, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    255,
    209,
    104,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-danger-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-danger:hover,
.link-danger:focus {
  color: RGBA(253, 123, 123, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    253,
    123,
    123,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-light-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-light:hover,
.link-light:focus {
  color: RGBA(187, 193, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    187,
    193,
    202,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-dark-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-dark:hover,
.link-dark:focus {
  color: RGBA(0, 18, 44, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    0,
    18,
    44,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}

.link-body-emphasis {
  color: RGBA(
    var(--bs-emphasis-color-rgb),
    var(--bs-link-opacity, 1)
  ) !important;
  text-decoration-color: RGBA(
    var(--bs-emphasis-color-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-body-emphasis:hover,
.link-body-emphasis:focus {
  color: RGBA(
    var(--bs-emphasis-color-rgb),
    var(--bs-link-opacity, 0.75)
  ) !important;
  text-decoration-color: RGBA(
    var(--bs-emphasis-color-rgb),
    var(--bs-link-underline-opacity, 0.75)
  ) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0)
    var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width)
    var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(
    var(--bs-link-color-rgb),
    var(--bs-link-opacity, 0.5)
  );
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi,
.icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 12;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 12;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 12;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 12;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 12;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 12;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 12;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 12;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 12;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 12;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 12;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 12;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-language .nav-link,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item,
.swal2-modal,
.lightGallery .image-tile .demo-gallery-poster,
.email-wrapper .message-body .attachments-sections ul li .details .buttons,
.email-wrapper .message-body .attachments-sections ul li .thumb,
.email-wrapper .mail-list-container .mail-list .details,
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user,
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a,
.list-wrapper ul li,
.loader-demo-box,
.page-header {
  display: flex !important;
}

.d-inline-flex,
.email-wrapper .message-body .attachments-sections ul li {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-focus-ring-opacity)
  );
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-focus-ring-opacity)
  );
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(
    var(--bs-success-rgb),
    var(--bs-focus-ring-opacity)
  );
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-focus-ring-opacity)
  );
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(
    var(--bs-danger-rgb),
    var(--bs-focus-ring-opacity)
  );
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(
    var(--bs-light-rgb),
    var(--bs-focus-ring-opacity)
  );
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative,
.loader-demo-box {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border,
.loader-demo-box {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-secondary,
.loader-demo-box {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-success,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-confirm {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-success-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row,
.email-wrapper .message-body .attachments-sections ul li {
  flex-direction: row !important;
}

.flex-column,
.nav-pills.nav-pills-vertical,
.nav-tabs.nav-tabs-vertical,
.nav-tabs.nav-tabs-vertical-custom,
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start,
.list-wrapper ul li {
  justify-content: flex-start !important;
}

.justify-content-end,
.email-wrapper .mail-list-container .mail-list .details {
  justify-content: flex-end !important;
}

.justify-content-center,
.email-wrapper .message-body .attachments-sections ul li .thumb {
  justify-content: center !important;
}

.justify-content-between,
.page-header {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item,
.email-wrapper .message-body .attachments-sections ul li .details .buttons,
.email-wrapper .message-body .attachments-sections ul li .thumb,
.list-wrapper ul li,
.loader-demo-box,
.page-header {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch,
.email-wrapper .message-body .attachments-sections ul li {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0,
.demo-modal .modal-dialog {
  margin-top: 0 !important;
}

.mt-1,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-cancel,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-submit {
  margin-top: 0.25rem !important;
}

.mt-2,
.template-demo .circle-progress,
.template-demo > .btn-group,
.fc .template-demo > .fc-button-group,
.template-demo > .btn-group-vertical {
  margin-top: 0.5rem !important;
}

.mt-3,
.template-demo > .btn-toolbar,
.template-demo > .btn,
.wizard > .actions .template-demo > a,
.swal2-modal .swal2-buttonswrapper .template-demo > .swal2-styled,
.ajax-upload-dragdrop .template-demo > .ajax-file-upload,
.fc .template-demo > button {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0,
.rtl
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item
  i,
.rtl .settings-panel .sidebar-bg-options .rounded-circle,
.rtl .settings-panel .sidebar-bg-options .color-tiles .tiles,
.rtl .settings-panel .color-tiles .sidebar-bg-options .tiles {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2,
.template-demo > .dropdown,
.template-demo > .btn-group,
.fc .template-demo > .fc-button-group,
.template-demo > .btn-group-vertical {
  margin-right: 0.5rem !important;
}

.me-3,
.template-demo > .btn-toolbar,
.template-demo > .btn,
.wizard > .actions .template-demo > a,
.swal2-modal .swal2-buttonswrapper .template-demo > .swal2-styled,
.ajax-upload-dragdrop .template-demo > .ajax-file-upload,
.fc .template-demo > button {
  margin-right: 1rem !important;
}

.me-4,
.rtl .navbar .navbar-menu-wrapper .search-field {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto,
.rtl .list-wrapper .remove {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3,
.lock-screen .card .card-body p,
.lock-screen .card .card-body img,
.template-demo .circle-progress-block {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0,
.rtl .navbar .navbar-menu-wrapper .search-field,
.rtl .list-wrapper .remove,
.rtl .preview-list .preview-item .preview-thumbnail .preview-icon i {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2,
.btn-toolbar .btn-group + .btn-group,
.btn-toolbar .fc .fc-button-group + .btn-group,
.fc .btn-toolbar .fc-button-group + .btn-group,
.btn-toolbar .fc .btn-group + .fc-button-group,
.fc .btn-toolbar .btn-group + .fc-button-group,
.btn-toolbar .fc .fc-button-group + .fc-button-group,
.fc .btn-toolbar .fc-button-group + .fc-button-group {
  margin-left: 0.5rem !important;
}

.ms-3,
.rtl .settings-panel .sidebar-bg-options .rounded-circle,
.rtl .settings-panel .sidebar-bg-options .color-tiles .tiles,
.rtl .settings-panel .color-tiles .sidebar-bg-options .tiles {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto,
.list-wrapper .remove {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2,
.template-demo .circle-progress-block {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary,
.list-wrapper .completed .remove {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary,
.list-wrapper .remove {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white,
.navbar.navbar-dark .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-light .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-danger .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-warning .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-info .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-success .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-secondary .navbar-menu-wrapper .nav-profile-text p,
.navbar.navbar-primary .navbar-menu-wrapper .nav-profile-text p {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted,
.email-wrapper .mail-list-container .mail-list .details .date,
.email-wrapper .mail-list-container .mail-list .content .message_text,
.email-wrapper
  .mail-sidebar
  .menu-bar
  .profile-list-item
  a
  .user
  .u-designation,
.preview-list .preview-item .preview-item-content p .content-category {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-success-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-info-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-danger-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-light-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-dark-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-link-color-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary,
.settings-panel .color-tiles .tiles.primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-success,
.settings-panel .color-tiles .tiles.success,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-confirm {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-info,
.settings-panel .color-tiles .tiles.info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning,
.settings-panel .color-tiles .tiles.warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-danger,
.settings-panel .color-tiles .tiles.danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light,
.settings-panel .color-tiles .tiles.light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark,
.settings-panel .color-tiles .tiles.dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-body-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-tertiary-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded,
.loader-demo-box {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle,
.settings-panel .color-tiles .tiles {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*-------------------------------------------------------------------*/
/* === Template mixins === */
/* Miscellaneous Mixins */
.settings-panel .chat-list .list .info p,
.email-wrapper .message-body .attachments-sections ul li .details p.file-name,
.email-wrapper .mail-list-container .mail-list .content .sender-name,
.email-wrapper
  .mail-sidebar
  .menu-bar
  .profile-list-item
  a
  .user
  .u-designation,
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user .u-name,
.list-wrapper ul li .form-check .form-check-label,
.list-wrapper ul li .form-check {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

/* Animation Mixins */
@keyframes dropdownAnimation {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    transform: none;
    transform: translate3d(0, 0px, 0);
  }
}
.dropdownAnimation,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
  animation-name: dropdownAnimation;
  -webkit-animation-duration: 0.25s;
  -moz-animation-duration: 0.25s;
  -ms-animation-duration: 0.25s;
  -o-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}

.infinite-spin,
#settings-trigger i {
  animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInUp,
#settings-trigger {
  animation-name: fadeInUp;
}

/*-------------------------------------------------------------------*/
/* === Core Styles === */
/* Reset Styles */
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.form-control,
.typeahead,
.tt-query,
.tt-hint,
.select2-container--default .select2-selection--single .select2-search__field,
.select2-container--default .select2-selection--single,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"],
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row select,
.dataTables_wrapper select,
.asColorPicker-input,
.form-control:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.form-control,
.typeahead,
.tt-query,
.tt-hint,
.select2-container--default .select2-selection--single .select2-search__field,
.select2-container--default .select2-selection--single,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"],
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row select,
.dataTables_wrapper select,
.asColorPicker-input {
  box-shadow: none;
}

.form-control:focus,
.typeahead:focus,
.tt-query:focus,
.tt-hint:focus,
.select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.select2-container--default .select2-selection--single:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"]:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"]:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select:focus,
.dataTables_wrapper select:focus,
.asColorPicker-input:focus {
  outline: 0;
  box-shadow: none;
}

a,
div,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
p,
span {
  text-shadow: none;
}

[type="button"]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner,
select::-moz-focus-inner {
  outline: 0;
}

input,
.form-control:focus,
.typeahead:focus,
.tt-query:focus,
.tt-hint:focus,
.select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.select2-container--default .select2-selection--single:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"]:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"]:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select:focus,
.asColorPicker-input:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  outline-width: 0;
  outline-color: transparent;
  box-shadow: none;
  outline-style: none;
}

textarea {
  resize: none;
  overflow-x: hidden;
}

.btn,
.wizard > .actions a,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-cancel,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-confirm,
.swal2-modal .swal2-buttonswrapper .swal2-styled,
.ajax-upload-dragdrop .ajax-file-upload,
.fc button,
.btn-group.open .dropdown-toggle,
.fc .open.fc-button-group .dropdown-toggle,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.wizard > .actions a.focus,
.swal2-modal .swal2-buttonswrapper .focus.swal2-styled,
.ajax-upload-dragdrop .focus.ajax-file-upload,
.fc button.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
.wizard > .actions a:focus,
.swal2-modal .swal2-buttonswrapper .swal2-styled:focus,
.ajax-upload-dragdrop .ajax-file-upload:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
  outline: 0;
  outline-offset: 0;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: 0;
  outline-offset: 0;
}

.dropdown-menu > li > a:active,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:visited {
  outline: 0;
}

a:focus,
input:focus {
  border-color: transparent;
  outline: none;
}

/* Fonts */

.text-twitter {
  color: #2caae1;
}

.text-facebook {
  color: #3b579d;
}

.text-google {
  color: #dc4a38;
}

.text-linkedin {
  color: #0177b5;
}

.text-pinterest {
  color: #cc2127;
}

.text-youtube {
  color: #e52d27;
}

.text-github {
  color: #333333;
}

.text-behance {
  color: #1769ff;
}

.text-dribbble {
  color: #ea4c89;
}

.text-reddit {
  color: #ff4500;
}

.bg-twitter {
  background: #2caae1;
}

.bg-facebook {
  background: #3b579d;
}

.bg-google {
  background: #dc4a38;
}

.bg-linkedin {
  background: #0177b5;
}

.bg-pinterest {
  background: #cc2127;
}

.bg-youtube {
  background: #e52d27;
}

.bg-github {
  background: #333333;
}

.bg-behance {
  background: #1769ff;
}

.bg-dribbble {
  background: #ea4c89;
}

.bg-reddit {
  background: #ff4500;
}

/* Backgrounds  */
.bg-gradient-primary,
#theme-settings .settings-close {
  background: linear-gradient(to right, #da8cff, #9a55ff);
}

.bg-gradient-secondary {
  background: linear-gradient(to right, #e7ebf0, #868e96);
}

.bg-gradient-success,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  tbody
  tr
  td.day.today:before {
  background: linear-gradient(to right, #84d9d2, #07cdae);
}

.bg-gradient-info {
  background: linear-gradient(to right, #90caf9, #047edf 99%);
}

.bg-gradient-warning,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  tbody
  tr
  td.day.active:before {
  background: linear-gradient(to right, #f6e384, #ffd500);
}

.bg-gradient-danger {
  background: linear-gradient(to right, #ffbf96, #fe7096);
}

.bg-gradient-light {
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
}

.bg-gradient-dark {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
}

.sidebar-grdient-dark {
  background: rgb(106, 0, 138);
  background: -moz-linear-gradient(
    top,
    rgb(106, 0, 138) 0%,
    rgb(106, 0, 138) 20%,
    rgb(250, 0, 83) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgb(106, 0, 138)),
    color-stop(20%, rgb(106, 0, 138)),
    color-stop(100%, rgb(250, 0, 83))
  );
  background: -webkit-linear-gradient(
    top,
    rgb(106, 0, 138) 0%,
    rgb(106, 0, 138) 20%,
    rgb(250, 0, 83) 100%
  );
  background: -o-linear-gradient(
    top,
    rgb(106, 0, 138) 0%,
    rgb(106, 0, 138) 20%,
    rgb(250, 0, 83) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgb(106, 0, 138) 0%,
    rgb(106, 0, 138) 20%,
    rgb(250, 0, 83) 100%
  );
  background: linear-gradient(
    to bottom,
    rgb(106, 0, 138) 0%,
    rgb(106, 0, 138) 20%,
    rgb(250, 0, 83) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#6a008a", endColorstr="#fa0053", GradientType=0 );
  color: #ffffff;
}

/* Typography */
body {
  font-size: 1rem;
  
  font-weight: initial;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
}

h1,
.h1,
h2,
h3,
h4,
h5,
h6,
.h2,
.h3,
.h4,
.h5,
.h6 {
  
  font-weight: 600;
}

p {
  font-size: 0.875rem;
}

h1,
.h1 {
  font-size: 1.875rem;
}

h2,
.h2 {
  font-size: 1.5rem;
}

h3,
.h3 {
  font-size: 1.25rem;
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.875rem;
}

p {
  font-size: 0.875rem;
  line-height: 1.5;
}

.display-1 {
  font-size: 3.75rem;
}
@media (max-width: 991px) {
  .display-1 {
    font-size: 3rem;
  }
}

.display-2 {
  font-size: 3.125rem;
}
@media (max-width: 991px) {
  .display-2 {
    font-size: 2.5rem;
  }
}

.display-3 {
  font-size: 2.5rem;
}
@media (max-width: 991px) {
  .display-3 {
    font-size: 2rem;
  }
}

.display-4 {
  font-size: 1.875rem;
}
@media (max-width: 991px) {
  .display-4 {
    font-size: 1.5rem;
  }
}

.display-5 {
  font-size: 1.25rem;
}
@media (max-width: 991px) {
  .display-5 {
    font-size: 1rem;
  }
}

.blockquote {
  padding: 1.25rem;
  border: 1px solid rgba(151, 151, 151, 0.3);
}

address p {
  margin-bottom: 0;
}

.text-light {
  color: #aab2bd !important;
}

.blockquote-primary {
  border-color: #3762c0;
}
.blockquote-primary .blockquote-footer {
  color: #3762c0;
}

.blockquote-secondary {
  border-color: #8e94a9;
}
.blockquote-secondary .blockquote-footer {
  color: #8e94a9;
}

.blockquote-success {
  border-color: #44ce42;
}
.blockquote-success .blockquote-footer {
  color: #44ce42;
}

.blockquote-info {
  border-color: #0062ff;
}
.blockquote-info .blockquote-footer {
  color: #0062ff;
}

.blockquote-warning {
  border-color: #ffc542;
}
.blockquote-warning .blockquote-footer {
  color: #ffc542;
}

.blockquote-danger {
  border-color: #fc5a5a;
}
.blockquote-danger .blockquote-footer {
  color: #fc5a5a;
}

.blockquote-light {
  border-color: #aab2bd;
}
.blockquote-light .blockquote-footer {
  color: #aab2bd;
}

.blockquote-dark {
  border-color: #001737;
}
.blockquote-dark .blockquote-footer {
  color: #001737;
}

.error-page h1,
.error-page .h1 {
  font-size: 12rem;
}
@media (max-width: 991px) {
  .error-page h1,
  .error-page .h1 {
    font-size: 8rem;
  }
}

.icon-lg {
  font-size: 3.438rem;
}

.icon-md {
  font-size: 1.875rem;
}

.icon-sm {
  font-size: 1rem;
}

/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0 !important;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.container-scroller {
  overflow: hidden;
}

pre {
  background: #e8eff4;
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  color: #fc5a5a;
  font-size: 0.875rem;
  border-radius: 4px;
}

.page-header {
  margin: 0 1rem 1.5rem 1rem;
}
.page-header .breadcrumb {
  border: 0;
  margin-bottom: 0;
}

.page-title {
  color: #111;
  font-size: 1.125rem;
  margin-bottom: 10px;
}
.page-title .page-title-icon {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
}
.page-title .page-title-icon i {
  font-size: 0.9375rem;
  line-height: 36px;
}

.media {
  display: flex;
}

/* Footer */
.footer {
  background: #f0f1f6;
  color: #aab2bd;
  border-top: none;
  padding: 1.5rem 3rem 1.5rem 3rem;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  font-size: 0.875rem;
  
}
.footer .footer-inner-wraper {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
.footer a {
  color: #a7afb7;
  font-size: inherit;
}
@media (max-width: 991px) {
  .footer {
    margin-left: 0;
    width: 100%;
  }
}

/* Utilities */
.grid-margin {
  margin-bottom: 30px;
}

@media (min-width: 576px) {
  .grid-margin-sm-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .grid-margin-md-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .grid-margin-lg-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .grid-margin-xl-0 {
    margin-bottom: 0;
  }
}

.img-lg {
  width: 92px;
  height: 92px;
}

.img-sm {
  width: 43px;
  height: 43px;
}

.img-xs {
  width: 37px;
  height: 37px;
}

.img-ss,
.settings-panel .color-tiles .tiles,
.image-grouped .text-avatar,
.image-grouped img {
  width: 26px;
  height: 26px;
}

.stretch-card {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: stretch;
  justify-content: stretch;
}
.stretch-card > .card {
  width: 100%;
  min-width: 100%;
}

@media (min-width: 576px) {
  .border-right-sm {
    border-right: 1px solid rgba(151, 151, 151, 0.3);
  }
}

@media (min-width: 768px) {
  .border-right-md {
    border-right: 1px solid rgba(151, 151, 151, 0.3);
  }
}

@media (min-width: 992px) {
  .border-right-lg {
    border-right: 1px solid rgba(151, 151, 151, 0.3);
  }
}

@media (min-width: 576px) {
  .border-left-sm {
    border-left: 1px solid rgba(151, 151, 151, 0.3);
  }
}

@media (min-width: 768px) {
  .border-left-md {
    border-left: 1px solid rgba(151, 151, 151, 0.3);
  }
}

@media (min-width: 992px) {
  .border-left-lg {
    border-left: 1px solid rgba(151, 151, 151, 0.3);
  }
}

.text-gray,
.card .card-subtitle {
  color: #8c8c8c;
}

.text-black {
  color: #000000;
}

.text-small {
  font-size: 12px;
}

.flex-grow {
  flex-grow: 1;
}




.absolute-center {
  position: absolute;
  top: 51%;
  right: 0;
  left: 0;
  margin: auto;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  display: block;
}

.bg-dark-grey {
  background: #fff;
}

.image-grouped {
  display: flex;
}
.image-grouped .text-avatar,
.image-grouped img {
  border-radius: 100%;
  margin-left: -10px;
  z-index: 0;
  border: 4px solid #fff;
  transform: scale(1);
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: "box-shadow", "z-index", "transform", "border-width";
}
.image-grouped .text-avatar:first-child,
.image-grouped img:first-child {
  margin-left: 0;
}
.image-grouped .text-avatar:hover,
.image-grouped img:hover {
  z-index: 1;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
  transform: scale(1.05);
  border-width: 0;
}
.image-grouped .text-avatar {
  color: #0062ff;
  font-size: 11px;
  font-weight: 600;
}

.text-transparent {
  opacity: 0.6;
}

.text-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.user-avatar {
  position: relative;
}
.user-avatar .edit-avatar-icon {
  position: absolute;
  bottom: 8%;
  left: 70px;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #aab2bd;
  color: #001737;
  border: 3px solid #ffffff;
}
.user-avatar .edit-avatar-icon i {
  font-size: 15px;
}

.dot-indicator {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.dot-indicator.dot-indicator-sm {
  width: 6px;
  height: 6px;
}

.border-indicator {
  width: 10px;
  height: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(151, 151, 151, 0.3);
  border-radius: 100%;
}

.dropdown-divider {
  border-color: rgba(151, 151, 151, 0.3);
}

.aligner-wrapper {
  position: relative;
}

.img-avatar {
  display: inline-block !important;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.img-avatar.img-avatar48 {
  width: 48px;
  height: 48px;
}
.img-avatar.img-avatar-thumb {
  margin: 0.25rem;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.3);
}

.aligner-wrapper {
  position: relative;
}
.aligner-wrapper .absolute {
  position: absolute;
}
.aligner-wrapper .absolute.absolute-center {
  top: 50%;
  transform: translateY(-50%);
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.aligner-wrapper .absolute.left {
  left: 0;
}
.aligner-wrapper .absolute.right {
  right: 0;
}
.aligner-wrapper .absolute.bottom {
  bottom: 0;
}
.aligner-wrapper .absolute.top {
  top: 0;
}

.v-strock-1 {
  width: 2px;
}

.v-strock-2 {
  width: 3px;
}

.v-strock-3 {
  width: 4px;
}

.v-strock-4 {
  width: 5px;
}

.text-muted,
.email-wrapper .mail-list-container .mail-list .details .date,
.email-wrapper .mail-list-container .mail-list .content .message_text,
.email-wrapper
  .mail-sidebar
  .menu-bar
  .profile-list-item
  a
  .user
  .u-designation,
.preview-list .preview-item .preview-item-content p .content-category {
  color: #a7afb7 !important;
}

/* Demo Styles */
.template-demo .slider-wrap {
  height: 100px;
}
.template-demo .progress {
  margin-top: 1.5rem;
}
.template-demo > h2,
.template-demo > .h2,
.template-demo > h3,
.template-demo > .h3,
.template-demo > h4,
.template-demo > .h4,
.template-demo > h5,
.template-demo > .h5,
.template-demo > h6,
.template-demo > .h6,
.template-demo > h1,
.template-demo > .h1 {
  border-top: 1px solid rgba(151, 151, 151, 0.3);
  padding: 0.5rem 0 0;
}
.template-demo .ul-slider.noUi-horizontal {
  margin-top: 2rem;
}
.template-demo .ul-slider.noUi-vertical {
  margin-right: 2rem;
}
.template-demo > .dropdown {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.template-demo nav .breadcrumb {
  margin-bottom: 1.375rem;
}
.template-demo nav:last-child .breadcrumb {
  margin-bottom: 0;
}
.template-demo .editable-form > .form-group {
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem;
}
.template-demo .circle-progress {
  padding: 15px;
}
.demo-modal {
  position: static;
  display: block;
}
.demo-modal .modal-dialog.modal-lg {
  max-width: 100%;
}

.loader-demo-box {
  width: 100%;
  height: 200px;
}

.dropdown-menu-static-demo {
  height: 250px;
  margin-bottom: 20px;
}

.rounded-legend ul li {
  list-style-type: none;
  color: #a7afb7;
  font-size: 0.75rem;
}
.rounded-legend ul li .legend-dots {
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 0.5rem;
}
.rtl .rounded-legend ul li .legend-dots {
  margin-left: 0.5rem;
}
.rounded-legend.legend-horizontal {
  display: -webkit-flex;
  display: flex;
}
.rounded-legend.legend-horizontal ul li {
  display: inline-block;
  margin-right: 1.5rem;
}
.rtl .rounded-legend.legend-horizontal ul li {
  margin-right: auto;
  margin-left: 1.5rem;
}
.rounded-legend.legend-top-right ul {
  float: right;
}
.rtl .rounded-legend.legend-top-right ul {
  float: left;
}
.rounded-legend.legend-vertical ul li {
  margin-top: 1rem;
}

#proBanner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  background: #000;
  height: 64px;
}
#proBanner .card-body-padding {
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (max-width: 766px) {
  #proBanner .card-body-padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
#proBanner .buy-now-text {
  color: #ffffff;
}
@media (max-width: 766px) {
  #proBanner .buy-now-text {
    font-size: 10px;
    line-height: 1.5;
  }
}
#proBanner .btn.buy-now-btn,
#proBanner .wizard > .actions a.buy-now-btn,
.wizard > .actions #proBanner a.buy-now-btn,
#proBanner .swal2-modal .swal2-buttonswrapper .buy-now-btn.swal2-styled,
.swal2-modal .swal2-buttonswrapper #proBanner .buy-now-btn.swal2-styled,
#proBanner .ajax-upload-dragdrop .buy-now-btn.ajax-file-upload,
.ajax-upload-dragdrop #proBanner .buy-now-btn.ajax-file-upload,
#proBanner .fc button.buy-now-btn,
.fc #proBanner button.buy-now-btn {
  background: transparent;
  color: #88b6ff;
  border-radius: 10px;
  padding: 1rem 0;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1rem;
}
@media (max-width: 766px) {
  #proBanner .btn.buy-now-btn,
  #proBanner .wizard > .actions a.buy-now-btn,
  .wizard > .actions #proBanner a.buy-now-btn,
  #proBanner .swal2-modal .swal2-buttonswrapper .buy-now-btn.swal2-styled,
  .swal2-modal .swal2-buttonswrapper #proBanner .buy-now-btn.swal2-styled,
  #proBanner .ajax-upload-dragdrop .buy-now-btn.ajax-file-upload,
  .ajax-upload-dragdrop #proBanner .buy-now-btn.ajax-file-upload,
  #proBanner .fc button.buy-now-btn,
  .fc #proBanner button.buy-now-btn {
    min-width: 80px;
    padding: 1rem 0.5rem;
    font-size: 12px;
  }
}
#proBanner .btn#bannerClose i,
#proBanner .wizard > .actions a#bannerClose i,
.wizard > .actions #proBanner a#bannerClose i,
#proBanner .swal2-modal .swal2-buttonswrapper #bannerClose.swal2-styled i,
.swal2-modal .swal2-buttonswrapper #proBanner #bannerClose.swal2-styled i,
#proBanner .ajax-upload-dragdrop #bannerClose.ajax-file-upload i,
.ajax-upload-dragdrop #proBanner #bannerClose.ajax-file-upload i,
#proBanner .fc button#bannerClose i,
.fc #proBanner button#bannerClose i {
  margin-right: 1rem;
  font-size: 1.5rem;
}
@media (max-width: 766px) {
  #proBanner .btn#bannerClose i,
  #proBanner .wizard > .actions a#bannerClose i,
  .wizard > .actions #proBanner a#bannerClose i,
  #proBanner .swal2-modal .swal2-buttonswrapper #bannerClose.swal2-styled i,
  .swal2-modal .swal2-buttonswrapper #proBanner #bannerClose.swal2-styled i,
  #proBanner .ajax-upload-dragdrop #bannerClose.ajax-file-upload i,
  .ajax-upload-dragdrop #proBanner #bannerClose.ajax-file-upload i,
  #proBanner .fc button#bannerClose i,
  .fc #proBanner button#bannerClose i {
    font-size: 1rem;
    margin-right: 0;
  }
}
#proBanner a {
  text-decoration: none;
}
#proBanner a i {
  font-size: 1.5rem;
}
@media (max-width: 766px) {
  #proBanner a i {
    font-size: 1rem;
  }
}

.proBanner-padding-top {
  padding-top: 64px !important;
}

.chart-wrapper-height-sm {
  height: 70px;
  margin-top: 2rem;
}

/*-------------------------------------------------------------------*/
/* === Components === */
/* Badges */
.badge {
  border-radius: 0.25rem;
  font-size: 11px;
  font-weight: initial;
  line-height: 1;
  padding: 0.375rem 0.5625rem;
}
.badge.badge-pill {
  border-radius: 10rem;
}

/*Badge variations*/
.badge-primary {
  border: 1px solid #3762c0;
  color: #ffffff;
  background: #3762c0;
}

.badge-secondary {
  border: 1px solid #8e94a9;
  color: #ffffff;
  background: #8e94a9;
}

.badge-success,
.preview-list .preview-item .preview-thumbnail .badge.badge-online {
  border: 1px solid #44ce42;
  color: #ffffff;
  background: #44ce42;
}

.badge-info,
.preview-list .preview-item .preview-thumbnail .badge.badge-offline {
  border: 1px solid #0062ff;
  color: #ffffff;
  background: #0062ff;
}

.badge-warning,
.preview-list .preview-item .preview-thumbnail .badge.badge-busy {
  border: 1px solid #ffc542;
  color: #ffffff;
  background: #ffc542;
}

.badge-danger {
  border: 1px solid #fc5a5a;
  color: #ffffff;
  background: #fc5a5a;
}

.badge-light {
  border: 1px solid #aab2bd;
  color: #ffffff;
  background: #aab2bd;
}

.badge-dark {
  border: 1px solid #001737;
  color: #ffffff;
  background: #001737;
}

/*Badge gradient variations*/
.badge-gradient-primary {
  background: linear-gradient(to right, #da8cff, #9a55ff);
  color: #ffffff;
}

.badge-gradient-secondary {
  background: linear-gradient(to right, #e7ebf0, #868e96);
  color: #ffffff;
}

.badge-gradient-success {
  background: linear-gradient(to right, #84d9d2, #07cdae);
  color: #ffffff;
}

.badge-gradient-info {
  background: linear-gradient(to right, #90caf9, #047edf 99%);
  color: #ffffff;
}

.badge-gradient-warning {
  background: linear-gradient(to right, #f6e384, #ffd500);
  color: #ffffff;
}

.badge-gradient-danger {
  background: linear-gradient(to right, #ffbf96, #fe7096);
  color: #ffffff;
}

.badge-gradient-light {
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
  color: #ffffff;
}

.badge-gradient-dark {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
  color: #ffffff;
}

/*Badge outlined variations*/
.badge-outline-primary {
  color: #3762c0;
  border: 1px solid #3762c0;
  background: #ebdcf6;
  margin-bottom: 0.5rem;
}

.badge-outline-secondary {
  color: #8e94a9;
  border: 1px solid #8e94a9;
  background: #e5e6eb;
  margin-bottom: 0.5rem;
}

.badge-outline-success {
  color: #44ce42;
  border: 1px solid #44ce42;
  background: #bceebc;
  margin-bottom: 0.5rem;
}

.badge-outline-info {
  color: #0062ff;
  border: 1px solid #0062ff;
  background: #99c0ff;
  margin-bottom: 0.5rem;
}

.badge-outline-warning {
  color: #ffc542;
  border: 1px solid #ffc542;
  background: #fff4db;
  margin-bottom: 0.5rem;
}

.badge-outline-danger {
  color: #fc5a5a;
  border: 1px solid #fc5a5a;
  background: #fff0f0;
  margin-bottom: 0.5rem;
}

.badge-outline-light {
  color: #aab2bd;
  border: 1px solid #aab2bd;
  background: white;
  margin-bottom: 0.5rem;
}

.badge-outline-dark {
  color: #001737;
  border: 1px solid #001737;
  background: #0057d0;
  margin-bottom: 0.5rem;
}

/*Badge inverse variations*/
.badge-inverse-primary {
  background: rgba(164, 97, 216, 0.3);
  color: #3762c0;
}

.badge-inverse-secondary {
  background: rgba(142, 148, 169, 0.3);
  color: #8e94a9;
}

.badge-inverse-success {
  background: rgba(68, 206, 66, 0.3);
  color: #44ce42;
}

.badge-inverse-info {
  background: rgba(0, 98, 255, 0.3);
  color: #0062ff;
}

.badge-inverse-warning {
  background: rgba(255, 197, 66, 0.3);
  color: #ffc542;
}

.badge-inverse-danger {
  background: rgba(252, 90, 90, 0.3);
  color: #fc5a5a;
}

.badge-inverse-light {
  background: rgba(170, 178, 189, 0.3);
  color: #aab2bd;
}

.badge-inverse-dark {
  background: rgba(0, 23, 55, 0.3);
  color: #001737;
}

/* Bootstrap Alerts */
.alert {
  font-size: 0.875rem;
}
.alert i {
  font-size: 1.25rem;
  margin-right: 1.25rem;
  vertical-align: middle;
  line-height: 0.5;
}

.alert-primary {
  color: #3762c0;
}

.alert-secondary {
  color: #8e94a9;
}

.alert-success {
  color: #44ce42;
}

.alert-info {
  color: #0062ff;
}

.alert-warning {
  color: #ffc542;
}

.alert-danger {
  color: #fc5a5a;
}

.alert-light {
  color: #aab2bd;
}

.alert-dark {
  color: #001737;
}

.alert-fill-primary {
  color: white;
  background: #3762c0;
}

.alert-fill-secondary {
  color: white;
  background: #8e94a9;
}

.alert-fill-success {
  color: white;
  background: #44ce42;
}

.alert-fill-info {
  color: white;
  background: #0062ff;
}

.alert-fill-warning {
  color: white;
  background: #ffc542;
}

.alert-fill-danger {
  color: white;
  background: #fc5a5a;
}

.alert-fill-light {
  color: white;
  background: #aab2bd;
}

.alert-fill-dark {
  color: #d0e4ff;
  background: #001737;
}

/* Bootstrap Progress */
.progress {
  border-radius: 3px;
  height: 8px;
}
.progress .progress-bar {
  border-radius: 3px;
}
.progress.grouped .progress-bar {
  border-radius: 0px;
}
.progress.grouped .progress-bar:last-child {
  border-radius: 0px 3px 3px 0px;
}
.progress.progress-sm {
  height: 0.375rem;
}
.progress.progress-md {
  height: 8px;
}
.progress.progress-lg {
  height: 15px;
}
.progress.progress-xl {
  height: 18px;
}

/* Buttons */
.btn,
.wizard > .actions a,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-cancel,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-confirm,
.swal2-modal .swal2-buttonswrapper .swal2-styled,
.ajax-upload-dragdrop .ajax-file-upload,
.fc button {
  font-size: 12px;
  line-height: 1;
  
  font-weight: 600;
  /* Buttons with only icons */
  /* Buttons with icon and text */
}
.btn i,
.wizard > .actions a i,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-cancel i,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-confirm i,
.swal2-modal .swal2-buttonswrapper .swal2-styled i,
.ajax-upload-dragdrop .ajax-file-upload i,
.fc button i {
  font-size: 12px;
}
.btn.btn-rounded,
.wizard > .actions a.btn-rounded,
.swal2-modal .swal2-buttonswrapper .btn-rounded.swal2-styled,
.ajax-upload-dragdrop .btn-rounded.ajax-file-upload,
.fc button.btn-rounded {
  border-radius: 50px;
}
.btn.btn-fw,
.wizard > .actions a.btn-fw,
.swal2-modal .swal2-buttonswrapper .btn-fw.swal2-styled,
.ajax-upload-dragdrop .btn-fw.ajax-file-upload,
.fc button.btn-fw {
  min-width: 150px;
}
.btn.btn-sm,
.wizard > .actions a.btn-sm,
.swal2-modal .swal2-buttonswrapper .btn-sm.swal2-styled,
.ajax-upload-dragdrop .btn-sm.ajax-file-upload,
.fc button,
.btn-group-sm > .btn,
.wizard > .actions .btn-group-sm > a,
.swal2-modal .swal2-buttonswrapper .btn-group-sm > .swal2-styled,
.ajax-upload-dragdrop .btn-group-sm > .ajax-file-upload,
.fc .btn-group-sm > button {
  font-size: 0.875rem;
}
.btn.btn-lg,
.wizard > .actions a.btn-lg,
.swal2-modal .swal2-buttonswrapper .btn-lg.swal2-styled,
.ajax-upload-dragdrop .btn-lg.ajax-file-upload,
.fc button.btn-lg,
.btn-group-lg > .btn,
.wizard > .actions .btn-group-lg > a,
.swal2-modal .swal2-buttonswrapper .btn-group-lg > .swal2-styled,
.ajax-upload-dragdrop .btn-group-lg > .ajax-file-upload,
.fc .btn-group-lg > button {
  font-size: 0.875rem;
}
.btn.btn-xs,
.wizard > .actions a.btn-xs,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .btn.editable-cancel,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .wizard
  > .actions
  a.editable-cancel,
.wizard
  > .actions
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  a.editable-cancel,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .btn.editable-submit,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .wizard
  > .actions
  a.editable-submit,
.wizard
  > .actions
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  a.editable-submit,
.swal2-modal .swal2-buttonswrapper .btn-xs.swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .swal2-styled.editable-cancel,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.editable-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .swal2-styled.editable-submit,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.editable-submit,
.ajax-upload-dragdrop .btn-xs.ajax-file-upload,
.ajax-upload-dragdrop
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .ajax-file-upload.editable-cancel,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .ajax-upload-dragdrop
  .ajax-file-upload.editable-cancel,
.ajax-upload-dragdrop
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .ajax-file-upload.editable-submit,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .ajax-upload-dragdrop
  .ajax-file-upload.editable-submit,
.fc button.btn-xs,
.fc
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  button.editable-cancel,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .fc
  button.editable-cancel,
.fc
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  button.editable-submit,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .fc
  button.editable-submit {
  padding: 0.5rem 0.75rem;
  font-size: 0.625rem;
}
.btn.btn-icon,
.wizard > .actions a.btn-icon,
.swal2-modal .swal2-buttonswrapper .btn-icon.swal2-styled,
.ajax-upload-dragdrop .btn-icon.ajax-file-upload,
.fc button.btn-icon {
  width: 42px;
  height: 42px;
  padding: 0;
}
.btn.btn-icon-text .btn-icon-prepend,
.wizard > .actions a.btn-icon-text .btn-icon-prepend,
.swal2-modal
  .swal2-buttonswrapper
  .btn-icon-text.swal2-styled
  .btn-icon-prepend,
.ajax-upload-dragdrop .btn-icon-text.ajax-file-upload .btn-icon-prepend,
.fc button.btn-icon-text .btn-icon-prepend {
  margin-right: 0.5rem;
}
.btn.btn-icon-text .btn-icon-append,
.wizard > .actions a.btn-icon-text .btn-icon-append,
.swal2-modal .swal2-buttonswrapper .btn-icon-text.swal2-styled .btn-icon-append,
.ajax-upload-dragdrop .btn-icon-text.ajax-file-upload .btn-icon-append,
.fc button.btn-icon-text .btn-icon-append {
  margin-left: 0.5rem;
}
.btn.btn-social-icon,
.wizard > .actions a.btn-social-icon,
.swal2-modal .swal2-buttonswrapper .btn-social-icon.swal2-styled,
.ajax-upload-dragdrop .btn-social-icon.ajax-file-upload,
.fc button.btn-social-icon {
  width: 50px;
  height: 50px;
  padding: 0;
}
.btn.sidebar-btn,
.wizard > .actions a.sidebar-btn,
.swal2-modal .swal2-buttonswrapper .sidebar-btn.swal2-styled,
.ajax-upload-dragdrop .sidebar-btn.ajax-file-upload,
.fc button.sidebar-btn {
  font-size: 1.25rem;
  font-weight: bold;
}

.btn-group .btn + .btn,
.btn-group .wizard > .actions a + .btn,
.wizard > .actions .btn-group a + .btn,
.btn-group .wizard > .actions .btn + a,
.wizard > .actions .btn-group .btn + a,
.btn-group .wizard > .actions a + a,
.wizard > .actions .btn-group a + a,
.btn-group .swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-cancel + .btn,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  .swal2-styled.swal2-cancel
  + a,
.wizard
  > .actions
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + a,
.swal2-modal .swal2-buttonswrapper .btn-group .swal2-styled.swal2-cancel + .btn,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .wizard
  > .actions
  .swal2-styled.swal2-cancel
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .swal2-styled.swal2-cancel
  + a,
.btn-group .swal2-modal .swal2-buttonswrapper .btn + .swal2-styled.swal2-cancel,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  a
  + .swal2-styled.swal2-cancel,
.wizard
  > .actions
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  a
  + .swal2-styled.swal2-cancel,
.swal2-modal .swal2-buttonswrapper .btn-group .btn + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .wizard
  > .actions
  a
  + .swal2-styled.swal2-cancel,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group
  a
  + .swal2-styled.swal2-cancel,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .swal2-styled.swal2-cancel
  + .swal2-styled.swal2-cancel,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + .btn,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  .swal2-styled.swal2-confirm
  + a,
.wizard
  > .actions
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + a,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .swal2-styled.swal2-confirm
  + .btn,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .wizard
  > .actions
  .swal2-styled.swal2-confirm
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .swal2-styled.swal2-confirm
  + a,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .swal2-styled.swal2-confirm
  + .swal2-styled.swal2-cancel,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .btn
  + .swal2-styled.swal2-confirm,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  a
  + .swal2-styled.swal2-confirm,
.wizard
  > .actions
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  a
  + .swal2-styled.swal2-confirm,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .btn
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .wizard
  > .actions
  a
  + .swal2-styled.swal2-confirm,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group
  a
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .swal2-styled.swal2-cancel
  + .swal2-styled.swal2-confirm,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .swal2-styled.swal2-confirm
  + .swal2-styled.swal2-confirm,
.btn-group .swal2-modal .swal2-buttonswrapper .swal2-styled + .btn,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  .swal2-styled
  + a,
.wizard
  > .actions
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled
  + a,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled
  + .swal2-styled.swal2-cancel,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled
  + .swal2-styled.swal2-confirm,
.swal2-modal .swal2-buttonswrapper .btn-group .swal2-styled + .btn,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .wizard
  > .actions
  .swal2-styled
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .swal2-styled
  + a,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .swal2-styled
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .swal2-styled
  + .swal2-styled.swal2-confirm,
.btn-group .swal2-modal .swal2-buttonswrapper .btn + .swal2-styled,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  a
  + .swal2-styled,
.wizard
  > .actions
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  a
  + .swal2-styled,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + .swal2-styled,
.btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + .swal2-styled,
.swal2-modal .swal2-buttonswrapper .btn-group .btn + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .wizard
  > .actions
  a
  + .swal2-styled,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .btn-group
  a
  + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .swal2-styled.swal2-cancel
  + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .swal2-styled.swal2-confirm
  + .swal2-styled,
.btn-group .swal2-modal .swal2-buttonswrapper .swal2-styled + .swal2-styled,
.swal2-modal .swal2-buttonswrapper .btn-group .swal2-styled + .swal2-styled,
.btn-group .ajax-upload-dragdrop .ajax-file-upload + .btn,
.btn-group .ajax-upload-dragdrop .wizard > .actions .ajax-file-upload + a,
.wizard > .actions .btn-group .ajax-upload-dragdrop .ajax-file-upload + a,
.btn-group
  .ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .ajax-file-upload
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .ajax-upload-dragdrop
  .ajax-file-upload
  + .swal2-styled.swal2-cancel,
.btn-group
  .ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .ajax-file-upload
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .ajax-upload-dragdrop
  .ajax-file-upload
  + .swal2-styled.swal2-confirm,
.btn-group
  .ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .ajax-file-upload
  + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .ajax-upload-dragdrop
  .ajax-file-upload
  + .swal2-styled,
.ajax-upload-dragdrop .btn-group .ajax-file-upload + .btn,
.ajax-upload-dragdrop .btn-group .wizard > .actions .ajax-file-upload + a,
.wizard > .actions .ajax-upload-dragdrop .btn-group .ajax-file-upload + a,
.ajax-upload-dragdrop
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .ajax-file-upload
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .btn-group
  .ajax-file-upload
  + .swal2-styled.swal2-cancel,
.ajax-upload-dragdrop
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .ajax-file-upload
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .btn-group
  .ajax-file-upload
  + .swal2-styled.swal2-confirm,
.ajax-upload-dragdrop
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .ajax-file-upload
  + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .btn-group
  .ajax-file-upload
  + .swal2-styled,
.btn-group .ajax-upload-dragdrop .btn + .ajax-file-upload,
.btn-group .ajax-upload-dragdrop .wizard > .actions a + .ajax-file-upload,
.wizard > .actions .btn-group .ajax-upload-dragdrop a + .ajax-file-upload,
.btn-group
  .ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + .ajax-file-upload,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .ajax-upload-dragdrop
  .swal2-styled.swal2-cancel
  + .ajax-file-upload,
.btn-group
  .ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + .ajax-file-upload,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .ajax-upload-dragdrop
  .swal2-styled.swal2-confirm
  + .ajax-file-upload,
.btn-group
  .ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled
  + .ajax-file-upload,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .ajax-upload-dragdrop
  .swal2-styled
  + .ajax-file-upload,
.ajax-upload-dragdrop .btn-group .btn + .ajax-file-upload,
.ajax-upload-dragdrop .btn-group .wizard > .actions a + .ajax-file-upload,
.wizard > .actions .ajax-upload-dragdrop .btn-group a + .ajax-file-upload,
.ajax-upload-dragdrop
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + .ajax-file-upload,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .btn-group
  .swal2-styled.swal2-cancel
  + .ajax-file-upload,
.ajax-upload-dragdrop
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + .ajax-file-upload,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .btn-group
  .swal2-styled.swal2-confirm
  + .ajax-file-upload,
.ajax-upload-dragdrop
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled
  + .ajax-file-upload,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .btn-group
  .swal2-styled
  + .ajax-file-upload,
.btn-group .ajax-upload-dragdrop .ajax-file-upload + .ajax-file-upload,
.ajax-upload-dragdrop .btn-group .ajax-file-upload + .ajax-file-upload,
.fc .fc-button-group .btn + .btn,
.fc .fc-button-group .wizard > .actions a + .btn,
.wizard > .actions .fc .fc-button-group a + .btn,
.fc .fc-button-group .wizard > .actions .btn + a,
.wizard > .actions .fc .fc-button-group .btn + a,
.fc .fc-button-group .wizard > .actions a + a,
.wizard > .actions .fc .fc-button-group a + a,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + .btn,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  .swal2-styled.swal2-cancel
  + a,
.wizard
  > .actions
  .fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + a,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled.swal2-cancel
  + .btn,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .wizard
  > .actions
  .swal2-styled.swal2-cancel
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled.swal2-cancel
  + a,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .btn
  + .swal2-styled.swal2-cancel,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  a
  + .swal2-styled.swal2-cancel,
.wizard
  > .actions
  .fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  a
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .btn
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .wizard
  > .actions
  a
  + .swal2-styled.swal2-cancel,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  a
  + .swal2-styled.swal2-cancel,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled.swal2-cancel
  + .swal2-styled.swal2-cancel,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + .btn,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  .swal2-styled.swal2-confirm
  + a,
.wizard
  > .actions
  .fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + a,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled.swal2-confirm
  + .btn,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .wizard
  > .actions
  .swal2-styled.swal2-confirm
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled.swal2-confirm
  + a,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled.swal2-confirm
  + .swal2-styled.swal2-cancel,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .btn
  + .swal2-styled.swal2-confirm,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  a
  + .swal2-styled.swal2-confirm,
.wizard
  > .actions
  .fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  a
  + .swal2-styled.swal2-confirm,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .btn
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .wizard
  > .actions
  a
  + .swal2-styled.swal2-confirm,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  a
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled.swal2-cancel
  + .swal2-styled.swal2-confirm,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled.swal2-confirm
  + .swal2-styled.swal2-confirm,
.fc .fc-button-group .swal2-modal .swal2-buttonswrapper .swal2-styled + .btn,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  .swal2-styled
  + a,
.wizard
  > .actions
  .fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled
  + a,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled
  + .swal2-styled.swal2-cancel,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled
  + .swal2-styled.swal2-confirm,
.swal2-modal .swal2-buttonswrapper .fc .fc-button-group .swal2-styled + .btn,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .wizard
  > .actions
  .swal2-styled
  + a,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled
  + a,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled
  + .swal2-styled.swal2-confirm,
.fc .fc-button-group .swal2-modal .swal2-buttonswrapper .btn + .swal2-styled,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .wizard
  > .actions
  a
  + .swal2-styled,
.wizard
  > .actions
  .fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  a
  + .swal2-styled,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + .swal2-styled,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + .swal2-styled,
.swal2-modal .swal2-buttonswrapper .fc .fc-button-group .btn + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .wizard
  > .actions
  a
  + .swal2-styled,
.wizard
  > .actions
  .swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  a
  + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled.swal2-cancel
  + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled.swal2-confirm
  + .swal2-styled,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled
  + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled
  + .swal2-styled,
.fc .fc-button-group .ajax-upload-dragdrop .ajax-file-upload + .btn,
.fc
  .fc-button-group
  .ajax-upload-dragdrop
  .wizard
  > .actions
  .ajax-file-upload
  + a,
.wizard
  > .actions
  .fc
  .fc-button-group
  .ajax-upload-dragdrop
  .ajax-file-upload
  + a,
.fc
  .fc-button-group
  .ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .ajax-file-upload
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .ajax-upload-dragdrop
  .ajax-file-upload
  + .swal2-styled.swal2-cancel,
.fc
  .fc-button-group
  .ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .ajax-file-upload
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .ajax-upload-dragdrop
  .ajax-file-upload
  + .swal2-styled.swal2-confirm,
.fc
  .fc-button-group
  .ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .ajax-file-upload
  + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .ajax-upload-dragdrop
  .ajax-file-upload
  + .swal2-styled,
.ajax-upload-dragdrop .fc .fc-button-group .ajax-file-upload + .btn,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  .wizard
  > .actions
  .ajax-file-upload
  + a,
.wizard
  > .actions
  .ajax-upload-dragdrop
  .fc
  .fc-button-group
  .ajax-file-upload
  + a,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .ajax-file-upload
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .fc
  .fc-button-group
  .ajax-file-upload
  + .swal2-styled.swal2-cancel,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .ajax-file-upload
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .fc
  .fc-button-group
  .ajax-file-upload
  + .swal2-styled.swal2-confirm,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .ajax-file-upload
  + .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .fc
  .fc-button-group
  .ajax-file-upload
  + .swal2-styled,
.fc .fc-button-group .ajax-upload-dragdrop .btn + .ajax-file-upload,
.fc
  .fc-button-group
  .ajax-upload-dragdrop
  .wizard
  > .actions
  a
  + .ajax-file-upload,
.wizard
  > .actions
  .fc
  .fc-button-group
  .ajax-upload-dragdrop
  a
  + .ajax-file-upload,
.fc
  .fc-button-group
  .ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + .ajax-file-upload,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .ajax-upload-dragdrop
  .swal2-styled.swal2-cancel
  + .ajax-file-upload,
.fc
  .fc-button-group
  .ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + .ajax-file-upload,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .ajax-upload-dragdrop
  .swal2-styled.swal2-confirm
  + .ajax-file-upload,
.fc
  .fc-button-group
  .ajax-upload-dragdrop
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled
  + .ajax-file-upload,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .ajax-upload-dragdrop
  .swal2-styled
  + .ajax-file-upload,
.ajax-upload-dragdrop .fc .fc-button-group .btn + .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  .wizard
  > .actions
  a
  + .ajax-file-upload,
.wizard
  > .actions
  .ajax-upload-dragdrop
  .fc
  .fc-button-group
  a
  + .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + .ajax-file-upload,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .fc
  .fc-button-group
  .swal2-styled.swal2-cancel
  + .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + .ajax-file-upload,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .fc
  .fc-button-group
  .swal2-styled.swal2-confirm
  + .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled
  + .ajax-file-upload,
.swal2-modal
  .swal2-buttonswrapper
  .ajax-upload-dragdrop
  .fc
  .fc-button-group
  .swal2-styled
  + .ajax-file-upload,
.fc
  .fc-button-group
  .ajax-upload-dragdrop
  .ajax-file-upload
  + .ajax-file-upload,
.ajax-upload-dragdrop
  .fc
  .fc-button-group
  .ajax-file-upload
  + .ajax-file-upload,
.btn-group .fc button + .btn,
.btn-group .fc .wizard > .actions button + a,
.wizard > .actions .btn-group .fc button + a,
.btn-group
  .fc
  .swal2-modal
  .swal2-buttonswrapper
  button
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .fc
  button
  + .swal2-styled.swal2-cancel,
.btn-group
  .fc
  .swal2-modal
  .swal2-buttonswrapper
  button
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .fc
  button
  + .swal2-styled.swal2-confirm,
.btn-group .fc .swal2-modal .swal2-buttonswrapper button + .swal2-styled,
.swal2-modal .swal2-buttonswrapper .btn-group .fc button + .swal2-styled,
.btn-group .fc .ajax-upload-dragdrop button + .ajax-file-upload,
.ajax-upload-dragdrop .btn-group .fc button + .ajax-file-upload,
.fc .btn-group button + .btn,
.fc .btn-group .wizard > .actions button + a,
.wizard > .actions .fc .btn-group button + a,
.fc
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  button
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .btn-group
  button
  + .swal2-styled.swal2-cancel,
.fc
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  button
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .btn-group
  button
  + .swal2-styled.swal2-confirm,
.fc .btn-group .swal2-modal .swal2-buttonswrapper button + .swal2-styled,
.swal2-modal .swal2-buttonswrapper .fc .btn-group button + .swal2-styled,
.fc .btn-group .ajax-upload-dragdrop button + .ajax-file-upload,
.ajax-upload-dragdrop .fc .btn-group button + .ajax-file-upload,
.fc .fc-button-group button + .btn,
.fc .fc-button-group .wizard > .actions button + a,
.wizard > .actions .fc .fc-button-group button + a,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  button
  + .swal2-styled.swal2-cancel,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  button
  + .swal2-styled.swal2-cancel,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  button
  + .swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  button
  + .swal2-styled.swal2-confirm,
.fc .fc-button-group .swal2-modal .swal2-buttonswrapper button + .swal2-styled,
.swal2-modal .swal2-buttonswrapper .fc .fc-button-group button + .swal2-styled,
.fc .fc-button-group .ajax-upload-dragdrop button + .ajax-file-upload,
.ajax-upload-dragdrop .fc .fc-button-group button + .ajax-file-upload,
.btn-group .fc .btn + button,
.btn-group .fc .wizard > .actions a + button,
.wizard > .actions .btn-group .fc a + button,
.btn-group
  .fc
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + button,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .fc
  .swal2-styled.swal2-cancel
  + button,
.btn-group
  .fc
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + button,
.swal2-modal
  .swal2-buttonswrapper
  .btn-group
  .fc
  .swal2-styled.swal2-confirm
  + button,
.btn-group .fc .swal2-modal .swal2-buttonswrapper .swal2-styled + button,
.swal2-modal .swal2-buttonswrapper .btn-group .fc .swal2-styled + button,
.btn-group .fc .ajax-upload-dragdrop .ajax-file-upload + button,
.ajax-upload-dragdrop .btn-group .fc .ajax-file-upload + button,
.fc .btn-group .btn + button,
.fc .btn-group .wizard > .actions a + button,
.wizard > .actions .fc .btn-group a + button,
.fc
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + button,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .btn-group
  .swal2-styled.swal2-cancel
  + button,
.fc
  .btn-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + button,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .btn-group
  .swal2-styled.swal2-confirm
  + button,
.fc .btn-group .swal2-modal .swal2-buttonswrapper .swal2-styled + button,
.swal2-modal .swal2-buttonswrapper .fc .btn-group .swal2-styled + button,
.fc .btn-group .ajax-upload-dragdrop .ajax-file-upload + button,
.ajax-upload-dragdrop .fc .btn-group .ajax-file-upload + button,
.fc .fc-button-group .btn + button,
.fc .fc-button-group .wizard > .actions a + button,
.wizard > .actions .fc .fc-button-group a + button,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-cancel
  + button,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled.swal2-cancel
  + button,
.fc
  .fc-button-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled.swal2-confirm
  + button,
.swal2-modal
  .swal2-buttonswrapper
  .fc
  .fc-button-group
  .swal2-styled.swal2-confirm
  + button,
.fc .fc-button-group .swal2-modal .swal2-buttonswrapper .swal2-styled + button,
.swal2-modal .swal2-buttonswrapper .fc .fc-button-group .swal2-styled + button,
.fc .fc-button-group .ajax-upload-dragdrop .ajax-file-upload + button,
.ajax-upload-dragdrop .fc .fc-button-group .ajax-file-upload + button,
.btn-group .fc button + button,
.fc .btn-group button + button,
.fc .fc-button-group button + button {
  border-left: 0;
}

/*social buttons*/
.btn-twitter {
  background: #2caae1;
  color: #ffffff;
}
.btn-twitter:hover,
.btn-twitter:focus {
  background: #1b8dbf;
  color: #ffffff;
}
.btn-twitter.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #59bce7;
}
.btn-twitter.btn-social-icon-text i {
  background: #2caae1;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-twitter {
  border: 1px solid #2caae1;
  color: #2caae1;
}
.btn-outline-twitter:hover {
  background: #2caae1;
  color: #ffffff;
}

.btn-facebook {
  background: #3b579d;
  color: #ffffff;
}
.btn-facebook:hover,
.btn-facebook:focus {
  background: #2d4278;
  color: #ffffff;
}
.btn-facebook.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #4e6ebd;
}
.btn-facebook.btn-social-icon-text i {
  background: #3b579d;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-facebook {
  border: 1px solid #3b579d;
  color: #3b579d;
}
.btn-outline-facebook:hover {
  background: #3b579d;
  color: #ffffff;
}

.btn-google {
  background: #dc4a38;
  color: #ffffff;
}
.btn-google:hover,
.btn-google:focus {
  background: #bf3322;
  color: #ffffff;
}
.btn-google.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #e47163;
}
.btn-google.btn-social-icon-text i {
  background: #dc4a38;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-google {
  border: 1px solid #dc4a38;
  color: #dc4a38;
}
.btn-outline-google:hover {
  background: #dc4a38;
  color: #ffffff;
}

.btn-linkedin {
  background: #0177b5;
  color: #ffffff;
}
.btn-linkedin:hover,
.btn-linkedin:focus {
  background: #015682;
  color: #ffffff;
}
.btn-linkedin.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #0198e8;
}
.btn-linkedin.btn-social-icon-text i {
  background: #0177b5;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-linkedin {
  border: 1px solid #0177b5;
  color: #0177b5;
}
.btn-outline-linkedin:hover {
  background: #0177b5;
  color: #ffffff;
}

.btn-pinterest {
  background: #cc2127;
  color: #ffffff;
}
.btn-pinterest:hover,
.btn-pinterest:focus {
  background: #a01a1f;
  color: #ffffff;
}
.btn-pinterest.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #e04046;
}
.btn-pinterest.btn-social-icon-text i {
  background: #cc2127;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-pinterest {
  border: 1px solid #cc2127;
  color: #cc2127;
}
.btn-outline-pinterest:hover {
  background: #cc2127;
  color: #ffffff;
}

.btn-youtube {
  background: #e52d27;
  color: #ffffff;
}
.btn-youtube:hover,
.btn-youtube:focus {
  background: #c21d17;
  color: #ffffff;
}
.btn-youtube.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #ea5955;
}
.btn-youtube.btn-social-icon-text i {
  background: #e52d27;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-youtube {
  border: 1px solid #e52d27;
  color: #e52d27;
}
.btn-outline-youtube:hover {
  background: #e52d27;
  color: #ffffff;
}

.btn-github {
  background: #333333;
  color: #ffffff;
}
.btn-github:hover,
.btn-github:focus {
  background: #1a1a1a;
  color: #ffffff;
}
.btn-github.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #4d4d4d;
}
.btn-github.btn-social-icon-text i {
  background: #333333;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-github {
  border: 1px solid #333333;
  color: #333333;
}
.btn-outline-github:hover {
  background: #333333;
  color: #ffffff;
}

.btn-behance {
  background: #1769ff;
  color: #ffffff;
}
.btn-behance:hover,
.btn-behance:focus {
  background: #0050e3;
  color: #ffffff;
}
.btn-behance.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #4a8aff;
}
.btn-behance.btn-social-icon-text i {
  background: #1769ff;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-behance {
  border: 1px solid #1769ff;
  color: #1769ff;
}
.btn-outline-behance:hover {
  background: #1769ff;
  color: #ffffff;
}

.btn-dribbble {
  background: #ea4c89;
  color: #ffffff;
}
.btn-dribbble:hover,
.btn-dribbble:focus {
  background: #e51e6b;
  color: #ffffff;
}
.btn-dribbble.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #ef7aa7;
}
.btn-dribbble.btn-social-icon-text i {
  background: #ea4c89;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-dribbble {
  border: 1px solid #ea4c89;
  color: #ea4c89;
}
.btn-outline-dribbble:hover {
  background: #ea4c89;
  color: #ffffff;
}

.btn-reddit {
  background: #ff4500;
  color: #ffffff;
}
.btn-reddit:hover,
.btn-reddit:focus {
  background: #cc3700;
  color: #ffffff;
}
.btn-reddit.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #ff6a33;
}
.btn-reddit.btn-social-icon-text i {
  background: #ff4500;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-reddit {
  border: 1px solid #ff4500;
  color: #ff4500;
}
.btn-outline-reddit:hover {
  background: #ff4500;
  color: #ffffff;
}

/* inverse buttons */
.btn-inverse-primary {
  background-color: rgba(164, 97, 216, 0.2);
  background-image: none;
  border-color: rgba(164, 97, 216, 0);
}
.btn-inverse-primary:not(.btn-inverse-light) {
  color: #3762c0;
}
.btn-inverse-primary:hover {
  color: #ffffff;
  background-color: #3762c0;
  border-color: #3762c0;
}
.btn-inverse-primary.focus,
.btn-inverse-primary:focus {
  box-shadow: 0 0 0 3px rgba(164, 97, 216, 0.5);
}
.btn-inverse-primary.disabled,
.btn-inverse-primary:disabled {
  color: #3762c0;
  background-color: transparent;
}
.btn-inverse-primary.active,
.btn-inverse-primary:active,
.show > .btn-inverse-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #3762c0;
  border-color: #3762c0;
}

.btn-primary:not(.btn-light),
.wizard > .actions a:not(.btn-light) {
  color: #ffffff;
}
.btn-primary:not(.btn-light):hover,
.wizard > .actions a:not(.btn-light):hover,
.btn-primary:not(.btn-light):focus,
.wizard > .actions a:not(.btn-light):focus,
.btn-primary:not(.btn-light):active,
.wizard > .actions a:not(.btn-light):active {
  color: #ffffff;
}
.btn-primary:not(.btn-light):focus,
.wizard > .actions a:not(.btn-light):focus,
.btn-primary:not(.btn-light):active,
.wizard > .actions a:not(.btn-light):active {
  background: #3762c0;
  border-color: #3762c0;
}

.btn-outline-primary:hover,
.wizard > .actions .disabled a:hover,
.btn-outline-primary:focus,
.wizard > .actions .disabled a:focus,
.btn-outline-primary:active,
.wizard > .actions .disabled a:active {
  background: linear-gradient(to right, #da8cff, #9a55ff);
  color: #ffffff;
}

.btn-inverse-secondary {
  background-color: rgba(142, 148, 169, 0.2);
  background-image: none;
  border-color: rgba(142, 148, 169, 0);
}
.btn-inverse-secondary:not(.btn-inverse-light) {
  color: #8e94a9;
}
.btn-inverse-secondary:hover {
  color: #ffffff;
  background-color: #8e94a9;
  border-color: #8e94a9;
}
.btn-inverse-secondary.focus,
.btn-inverse-secondary:focus {
  box-shadow: 0 0 0 3px rgba(142, 148, 169, 0.5);
}
.btn-inverse-secondary.disabled,
.btn-inverse-secondary:disabled {
  color: #8e94a9;
  background-color: transparent;
}
.btn-inverse-secondary.active,
.btn-inverse-secondary:active,
.show > .btn-inverse-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #8e94a9;
  border-color: #8e94a9;
}

.btn-secondary:not(.btn-light) {
  color: #ffffff;
}
.btn-secondary:not(.btn-light):hover,
.btn-secondary:not(.btn-light):focus,
.btn-secondary:not(.btn-light):active {
  color: #ffffff;
}
.btn-secondary:not(.btn-light):focus,
.btn-secondary:not(.btn-light):active {
  background: #8e94a9;
  border-color: #8e94a9;
}

.btn-outline-secondary:hover,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-cancel:hover,
.btn-outline-secondary:focus,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-cancel:focus,
.btn-outline-secondary:active,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-cancel:active {
  background: linear-gradient(to right, #e7ebf0, #868e96);
  color: #ffffff;
}

.btn-inverse-success {
  background-color: rgba(68, 206, 66, 0.2);
  background-image: none;
  border-color: rgba(68, 206, 66, 0);
}
.btn-inverse-success:not(.btn-inverse-light) {
  color: #44ce42;
}
.btn-inverse-success:hover {
  color: #ffffff;
  background-color: #44ce42;
  border-color: #44ce42;
}
.btn-inverse-success.focus,
.btn-inverse-success:focus {
  box-shadow: 0 0 0 3px rgba(68, 206, 66, 0.5);
}
.btn-inverse-success.disabled,
.btn-inverse-success:disabled {
  color: #44ce42;
  background-color: transparent;
}
.btn-inverse-success.active,
.btn-inverse-success:active,
.show > .btn-inverse-success.dropdown-toggle {
  color: #ffffff;
  background-color: #44ce42;
  border-color: #44ce42;
}

.btn-success:not(.btn-light) {
  color: #ffffff;
}
.btn-success:not(.btn-light):hover,
.btn-success:not(.btn-light):focus,
.btn-success:not(.btn-light):active {
  color: #ffffff;
}
.btn-success:not(.btn-light):focus,
.btn-success:not(.btn-light):active {
  background: #44ce42;
  border-color: #44ce42;
}

.btn-outline-success:hover,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-submit:hover,
.btn-outline-success:focus,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-submit:focus,
.btn-outline-success:active,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-buttons
  .editable-submit:active {
  background: linear-gradient(to right, #84d9d2, #07cdae);
  color: #ffffff;
}

.btn-inverse-info {
  background-color: rgba(0, 98, 255, 0.2);
  background-image: none;
  border-color: rgba(0, 98, 255, 0);
}
.btn-inverse-info:not(.btn-inverse-light) {
  color: #0062ff;
}
.btn-inverse-info:hover {
  color: #ffffff;
  background-color: #0062ff;
  border-color: #0062ff;
}
.btn-inverse-info.focus,
.btn-inverse-info:focus {
  box-shadow: 0 0 0 3px rgba(0, 98, 255, 0.5);
}
.btn-inverse-info.disabled,
.btn-inverse-info:disabled {
  color: #0062ff;
  background-color: transparent;
}
.btn-inverse-info.active,
.btn-inverse-info:active,
.show > .btn-inverse-info.dropdown-toggle {
  color: #ffffff;
  background-color: #0062ff;
  border-color: #0062ff;
}

.btn-info:not(.btn-light) {
  color: #ffffff;
}
.btn-info:not(.btn-light):hover,
.btn-info:not(.btn-light):focus,
.btn-info:not(.btn-light):active {
  color: #ffffff;
}
.btn-info:not(.btn-light):focus,
.btn-info:not(.btn-light):active {
  background: #0062ff;
  border-color: #0062ff;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active {
  background: linear-gradient(to right, #90caf9, #047edf 99%);
  color: #ffffff;
}

.btn-inverse-warning {
  background-color: rgba(255, 197, 66, 0.2);
  background-image: none;
  border-color: rgba(255, 197, 66, 0);
}
.btn-inverse-warning:not(.btn-inverse-light) {
  color: #ffc542;
}
.btn-inverse-warning:hover {
  color: #ffffff;
  background-color: #ffc542;
  border-color: #ffc542;
}
.btn-inverse-warning.focus,
.btn-inverse-warning:focus {
  box-shadow: 0 0 0 3px rgba(255, 197, 66, 0.5);
}
.btn-inverse-warning.disabled,
.btn-inverse-warning:disabled {
  color: #ffc542;
  background-color: transparent;
}
.btn-inverse-warning.active,
.btn-inverse-warning:active,
.show > .btn-inverse-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #ffc542;
  border-color: #ffc542;
}

.btn-warning:not(.btn-light) {
  color: #ffffff;
}
.btn-warning:not(.btn-light):hover,
.btn-warning:not(.btn-light):focus,
.btn-warning:not(.btn-light):active {
  color: #ffffff;
}
.btn-warning:not(.btn-light):focus,
.btn-warning:not(.btn-light):active {
  background: #ffc542;
  border-color: #ffc542;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active {
  background: linear-gradient(to right, #f6e384, #ffd500);
  color: #ffffff;
}

.btn-inverse-danger {
  background-color: rgba(252, 90, 90, 0.2);
  background-image: none;
  border-color: rgba(252, 90, 90, 0);
}
.btn-inverse-danger:not(.btn-inverse-light) {
  color: #fc5a5a;
}
.btn-inverse-danger:hover {
  color: #ffffff;
  background-color: #fc5a5a;
  border-color: #fc5a5a;
}
.btn-inverse-danger.focus,
.btn-inverse-danger:focus {
  box-shadow: 0 0 0 3px rgba(252, 90, 90, 0.5);
}
.btn-inverse-danger.disabled,
.btn-inverse-danger:disabled {
  color: #fc5a5a;
  background-color: transparent;
}
.btn-inverse-danger.active,
.btn-inverse-danger:active,
.show > .btn-inverse-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #fc5a5a;
  border-color: #fc5a5a;
}

.btn-danger:not(.btn-light) {
  color: #ffffff;
}
.btn-danger:not(.btn-light):hover,
.btn-danger:not(.btn-light):focus,
.btn-danger:not(.btn-light):active {
  color: #ffffff;
}
.btn-danger:not(.btn-light):focus,
.btn-danger:not(.btn-light):active {
  background: #fc5a5a;
  border-color: #fc5a5a;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active {
  background: linear-gradient(to right, #ffbf96, #fe7096);
  color: #ffffff;
}

.btn-inverse-light {
  background-color: rgba(170, 178, 189, 0.2);
  background-image: none;
  border-color: rgba(170, 178, 189, 0);
}
.btn-inverse-light:not(.btn-inverse-light) {
  color: #aab2bd;
}
.btn-inverse-light:hover {
  color: #ffffff;
  background-color: #aab2bd;
  border-color: #aab2bd;
}
.btn-inverse-light.focus,
.btn-inverse-light:focus {
  box-shadow: 0 0 0 3px rgba(170, 178, 189, 0.5);
}
.btn-inverse-light.disabled,
.btn-inverse-light:disabled {
  color: #aab2bd;
  background-color: transparent;
}
.btn-inverse-light.active,
.btn-inverse-light:active,
.show > .btn-inverse-light.dropdown-toggle {
  color: #ffffff;
  background-color: #aab2bd;
  border-color: #aab2bd;
}

.btn-light:not(.btn-light) {
  color: #ffffff;
}
.btn-light:not(.btn-light):hover,
.btn-light:not(.btn-light):focus,
.btn-light:not(.btn-light):active {
  color: #ffffff;
}
.btn-light:not(.btn-light):focus,
.btn-light:not(.btn-light):active {
  background: #aab2bd;
  border-color: #aab2bd;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active {
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
  color: #ffffff;
}

.btn-inverse-dark {
  background-color: rgba(0, 23, 55, 0.2);
  background-image: none;
  border-color: rgba(0, 23, 55, 0);
}
.btn-inverse-dark:not(.btn-inverse-light) {
  color: #001737;
}
.btn-inverse-dark:hover {
  color: #ffffff;
  background-color: #001737;
  border-color: #001737;
}
.btn-inverse-dark.focus,
.btn-inverse-dark:focus {
  box-shadow: 0 0 0 3px rgba(0, 23, 55, 0.5);
}
.btn-inverse-dark.disabled,
.btn-inverse-dark:disabled {
  color: #001737;
  background-color: transparent;
}
.btn-inverse-dark.active,
.btn-inverse-dark:active,
.show > .btn-inverse-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #001737;
  border-color: #001737;
}

.btn-dark:not(.btn-light) {
  color: #ffffff;
}
.btn-dark:not(.btn-light):hover,
.btn-dark:not(.btn-light):focus,
.btn-dark:not(.btn-light):active {
  color: #ffffff;
}
.btn-dark:not(.btn-light):focus,
.btn-dark:not(.btn-light):active {
  background: #001737;
  border-color: #001737;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
  color: #ffffff;
}

/* gradient buttons */
.btn-gradient-primary {
  background: linear-gradient(to right, #da8cff, #9a55ff);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.btn-gradient-primary:not([disabled]):not(.disabled):active,
.btn-gradient-primary:not([disabled]):not(.disabled).active,
.show > .btn-gradient-primary.dropdown-toggle {
  background: linear-gradient(to right, #da8cff, #9a55ff);
}
.btn-gradient-primary:hover {
  opacity: 0.8;
}
.btn-gradient-primary:not(.btn-gradient-light) {
  color: #ffffff;
}
.btn-gradient-primary:not(.btn-gradient-light):hover,
.btn-gradient-primary:not(.btn-gradient-light):focus,
.btn-gradient-primary:not(.btn-gradient-light):active {
  color: #ffffff;
}

.btn-gradient-secondary {
  background: linear-gradient(to right, #e7ebf0, #868e96);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.btn-gradient-secondary:not([disabled]):not(.disabled):active,
.btn-gradient-secondary:not([disabled]):not(.disabled).active,
.show > .btn-gradient-secondary.dropdown-toggle {
  background: linear-gradient(to right, #e7ebf0, #868e96);
}
.btn-gradient-secondary:hover {
  opacity: 0.8;
}
.btn-gradient-secondary:not(.btn-gradient-light) {
  color: #ffffff;
}
.btn-gradient-secondary:not(.btn-gradient-light):hover,
.btn-gradient-secondary:not(.btn-gradient-light):focus,
.btn-gradient-secondary:not(.btn-gradient-light):active {
  color: #ffffff;
}

.btn-gradient-success {
  background: linear-gradient(to right, #84d9d2, #07cdae);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.btn-gradient-success:not([disabled]):not(.disabled):active,
.btn-gradient-success:not([disabled]):not(.disabled).active,
.show > .btn-gradient-success.dropdown-toggle {
  background: linear-gradient(to right, #84d9d2, #07cdae);
}
.btn-gradient-success:hover {
  opacity: 0.8;
}
.btn-gradient-success:not(.btn-gradient-light) {
  color: #ffffff;
}
.btn-gradient-success:not(.btn-gradient-light):hover,
.btn-gradient-success:not(.btn-gradient-light):focus,
.btn-gradient-success:not(.btn-gradient-light):active {
  color: #ffffff;
}

.btn-gradient-info {
  background: linear-gradient(to right, #90caf9, #047edf 99%);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.btn-gradient-info:not([disabled]):not(.disabled):active,
.btn-gradient-info:not([disabled]):not(.disabled).active,
.show > .btn-gradient-info.dropdown-toggle {
  background: linear-gradient(to right, #90caf9, #047edf 99%);
}
.btn-gradient-info:hover {
  opacity: 0.8;
}
.btn-gradient-info:not(.btn-gradient-light) {
  color: #ffffff;
}
.btn-gradient-info:not(.btn-gradient-light):hover,
.btn-gradient-info:not(.btn-gradient-light):focus,
.btn-gradient-info:not(.btn-gradient-light):active {
  color: #ffffff;
}

.btn-gradient-warning {
  background: linear-gradient(to right, #f6e384, #ffd500);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.btn-gradient-warning:not([disabled]):not(.disabled):active,
.btn-gradient-warning:not([disabled]):not(.disabled).active,
.show > .btn-gradient-warning.dropdown-toggle {
  background: linear-gradient(to right, #f6e384, #ffd500);
}
.btn-gradient-warning:hover {
  opacity: 0.8;
}
.btn-gradient-warning:not(.btn-gradient-light) {
  color: #ffffff;
}
.btn-gradient-warning:not(.btn-gradient-light):hover,
.btn-gradient-warning:not(.btn-gradient-light):focus,
.btn-gradient-warning:not(.btn-gradient-light):active {
  color: #ffffff;
}

.btn-gradient-danger {
  background: linear-gradient(to right, #ffbf96, #fe7096);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.btn-gradient-danger:not([disabled]):not(.disabled):active,
.btn-gradient-danger:not([disabled]):not(.disabled).active,
.show > .btn-gradient-danger.dropdown-toggle {
  background: linear-gradient(to right, #ffbf96, #fe7096);
}
.btn-gradient-danger:hover {
  opacity: 0.8;
}
.btn-gradient-danger:not(.btn-gradient-light) {
  color: #ffffff;
}
.btn-gradient-danger:not(.btn-gradient-light):hover,
.btn-gradient-danger:not(.btn-gradient-light):focus,
.btn-gradient-danger:not(.btn-gradient-light):active {
  color: #ffffff;
}

.btn-gradient-light {
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.btn-gradient-light:not([disabled]):not(.disabled):active,
.btn-gradient-light:not([disabled]):not(.disabled).active,
.show > .btn-gradient-light.dropdown-toggle {
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
}
.btn-gradient-light:hover {
  opacity: 0.8;
}
.btn-gradient-light:not(.btn-gradient-light) {
  color: #ffffff;
}
.btn-gradient-light:not(.btn-gradient-light):hover,
.btn-gradient-light:not(.btn-gradient-light):focus,
.btn-gradient-light:not(.btn-gradient-light):active {
  color: #ffffff;
}

.btn-gradient-dark {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
  border: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.btn-gradient-dark:not([disabled]):not(.disabled):active,
.btn-gradient-dark:not([disabled]):not(.disabled).active,
.show > .btn-gradient-dark.dropdown-toggle {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
}
.btn-gradient-dark:hover {
  opacity: 0.8;
}
.btn-gradient-dark:not(.btn-gradient-light) {
  color: #ffffff;
}
.btn-gradient-dark:not(.btn-gradient-light):hover,
.btn-gradient-dark:not(.btn-gradient-light):focus,
.btn-gradient-dark:not(.btn-gradient-light):active {
  color: #ffffff;
}

/* Breadcrumbs */
.breadcrumb {
  border: 1px solid rgba(151, 151, 151, 0.3);
}
.breadcrumb .breadcrumb-item {
  font-size: 0.875rem;
}
.breadcrumb .breadcrumb-item.active {
  color: rgba(167, 175, 183, 0.8);
}
.breadcrumb.breadcrumb-custom {
  padding: 0 0;
  border-color: #dbe3e6;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item {
  font-size: 0.875rem;
  background: #dbe3e6;
  padding: 0.56rem 10px;
  color: #000000;
  display: inline-block;
  vertical-align: top;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item:last-child {
  background: transparent;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item:before {
  content: "";
  float: none;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item a {
  position: relative;
  color: inherit;
  border: 1px solid #dbe3e6;
  display: inline-block;
  vertical-align: top;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item a:before,
.breadcrumb.breadcrumb-custom .breadcrumb-item a:after {
  position: absolute;
  top: -9px;
  width: 0;
  height: 0;
  content: "";
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item a:before {
  right: -22px;
  z-index: 3;
  border-left-color: #dbe3e6;
  border-left-style: solid;
  border-left-width: 12px;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item a:after {
  border-top: 21px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 12px solid #ffffff;
  top: -9px;
  right: -23px;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item span {
  display: inline-block;
  vertical-align: top;
}
.breadcrumb.breadcrumb-custom .breadcrumb-item.active {
  color: rgba(167, 175, 183, 0.8);
}
.breadcrumb.bg-success,
.settings-panel .color-tiles .breadcrumb.tiles.success,
.swal2-modal .swal2-buttonswrapper .breadcrumb.swal2-styled.swal2-confirm,
.breadcrumb.bg-dark,
.settings-panel .color-tiles .breadcrumb.tiles.dark,
.breadcrumb.bg-danger,
.settings-panel .color-tiles .breadcrumb.tiles.danger,
.breadcrumb.bg-warning,
.settings-panel .color-tiles .breadcrumb.tiles.warning,
.breadcrumb.bg-primary,
.settings-panel .color-tiles .breadcrumb.tiles.primary,
.breadcrumb.bg-info,
.settings-panel .color-tiles .breadcrumb.tiles.info {
  border: none;
}
.breadcrumb.bg-success .breadcrumb-item,
.settings-panel .color-tiles .breadcrumb.tiles.success .breadcrumb-item,
.swal2-modal
  .swal2-buttonswrapper
  .breadcrumb.swal2-styled.swal2-confirm
  .breadcrumb-item,
.breadcrumb.bg-dark .breadcrumb-item,
.settings-panel .color-tiles .breadcrumb.tiles.dark .breadcrumb-item,
.breadcrumb.bg-danger .breadcrumb-item,
.settings-panel .color-tiles .breadcrumb.tiles.danger .breadcrumb-item,
.breadcrumb.bg-warning .breadcrumb-item,
.settings-panel .color-tiles .breadcrumb.tiles.warning .breadcrumb-item,
.breadcrumb.bg-primary .breadcrumb-item,
.settings-panel .color-tiles .breadcrumb.tiles.primary .breadcrumb-item,
.breadcrumb.bg-info .breadcrumb-item,
.settings-panel .color-tiles .breadcrumb.tiles.info .breadcrumb-item {
  color: #ffffff;
}
.breadcrumb.bg-success .breadcrumb-item:before,
.settings-panel .color-tiles .breadcrumb.tiles.success .breadcrumb-item:before,
.swal2-modal
  .swal2-buttonswrapper
  .breadcrumb.swal2-styled.swal2-confirm
  .breadcrumb-item:before,
.breadcrumb.bg-dark .breadcrumb-item:before,
.settings-panel .color-tiles .breadcrumb.tiles.dark .breadcrumb-item:before,
.breadcrumb.bg-danger .breadcrumb-item:before,
.settings-panel .color-tiles .breadcrumb.tiles.danger .breadcrumb-item:before,
.breadcrumb.bg-warning .breadcrumb-item:before,
.settings-panel .color-tiles .breadcrumb.tiles.warning .breadcrumb-item:before,
.breadcrumb.bg-primary .breadcrumb-item:before,
.settings-panel .color-tiles .breadcrumb.tiles.primary .breadcrumb-item:before,
.breadcrumb.bg-info .breadcrumb-item:before,
.settings-panel .color-tiles .breadcrumb.tiles.info .breadcrumb-item:before {
  color: inherit;
}
.breadcrumb.bg-success .breadcrumb-item a,
.settings-panel .color-tiles .breadcrumb.tiles.success .breadcrumb-item a,
.swal2-modal
  .swal2-buttonswrapper
  .breadcrumb.swal2-styled.swal2-confirm
  .breadcrumb-item
  a,
.breadcrumb.bg-success .breadcrumb-item span,
.settings-panel .color-tiles .breadcrumb.tiles.success .breadcrumb-item span,
.swal2-modal
  .swal2-buttonswrapper
  .breadcrumb.swal2-styled.swal2-confirm
  .breadcrumb-item
  span,
.breadcrumb.bg-dark .breadcrumb-item a,
.settings-panel .color-tiles .breadcrumb.tiles.dark .breadcrumb-item a,
.breadcrumb.bg-dark .breadcrumb-item span,
.settings-panel .color-tiles .breadcrumb.tiles.dark .breadcrumb-item span,
.breadcrumb.bg-danger .breadcrumb-item a,
.settings-panel .color-tiles .breadcrumb.tiles.danger .breadcrumb-item a,
.breadcrumb.bg-danger .breadcrumb-item span,
.settings-panel .color-tiles .breadcrumb.tiles.danger .breadcrumb-item span,
.breadcrumb.bg-warning .breadcrumb-item a,
.settings-panel .color-tiles .breadcrumb.tiles.warning .breadcrumb-item a,
.breadcrumb.bg-warning .breadcrumb-item span,
.settings-panel .color-tiles .breadcrumb.tiles.warning .breadcrumb-item span,
.breadcrumb.bg-primary .breadcrumb-item a,
.settings-panel .color-tiles .breadcrumb.tiles.primary .breadcrumb-item a,
.breadcrumb.bg-primary .breadcrumb-item span,
.settings-panel .color-tiles .breadcrumb.tiles.primary .breadcrumb-item span,
.breadcrumb.bg-info .breadcrumb-item a,
.settings-panel .color-tiles .breadcrumb.tiles.info .breadcrumb-item a,
.breadcrumb.bg-info .breadcrumb-item span,
.settings-panel .color-tiles .breadcrumb.tiles.info .breadcrumb-item span {
  color: inherit;
}

/* inverse breadcrumb */
.bg-inverse-primary {
  background: #dfc7f1;
  border-color: #3762c0;
}
.bg-inverse-primary .breadcrumb-item {
  color: #3762c0;
}
.bg-inverse-primary .breadcrumb-item:before {
  color: inherit;
}
.bg-inverse-primary .breadcrumb-item a {
  color: inherit;
}
.bg-inverse-primary.breadcrumb-custom {
  background: transparent;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item {
  background: #dfc7f1;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item a {
  color: #3762c0;
  border: none;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item a:before {
  border-left-color: #dfc7f1;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item span {
  color: #3762c0;
}
.bg-inverse-primary.breadcrumb-custom .breadcrumb-item:last-child {
  background: transparent;
}

.bg-inverse-secondary {
  background: #d6d9e0;
  border-color: #8e94a9;
}
.bg-inverse-secondary .breadcrumb-item {
  color: #8e94a9;
}
.bg-inverse-secondary .breadcrumb-item:before {
  color: inherit;
}
.bg-inverse-secondary .breadcrumb-item a {
  color: inherit;
}
.bg-inverse-secondary.breadcrumb-custom {
  background: transparent;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item {
  background: #d6d9e0;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item a {
  color: #8e94a9;
  border: none;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item a:before {
  border-left-color: #d6d9e0;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item span {
  color: #8e94a9;
}
.bg-inverse-secondary.breadcrumb-custom .breadcrumb-item:last-child {
  background: transparent;
}

.bg-inverse-success {
  background: #a8e8a7;
  border-color: #44ce42;
}
.bg-inverse-success .breadcrumb-item {
  color: #44ce42;
}
.bg-inverse-success .breadcrumb-item:before {
  color: inherit;
}
.bg-inverse-success .breadcrumb-item a {
  color: inherit;
}
.bg-inverse-success.breadcrumb-custom {
  background: transparent;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item {
  background: #a8e8a7;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item a {
  color: #44ce42;
  border: none;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item a:before {
  border-left-color: #a8e8a7;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item span {
  color: #44ce42;
}
.bg-inverse-success.breadcrumb-custom .breadcrumb-item:last-child {
  background: transparent;
}

.bg-inverse-info {
  background: #80b1ff;
  border-color: #0062ff;
}
.bg-inverse-info .breadcrumb-item {
  color: #0062ff;
}
.bg-inverse-info .breadcrumb-item:before {
  color: inherit;
}
.bg-inverse-info .breadcrumb-item a {
  color: inherit;
}
.bg-inverse-info.breadcrumb-custom {
  background: transparent;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item {
  background: #80b1ff;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item a {
  color: #0062ff;
  border: none;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item a:before {
  border-left-color: #80b1ff;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item span {
  color: #0062ff;
}
.bg-inverse-info.breadcrumb-custom .breadcrumb-item:last-child {
  background: transparent;
}

.bg-inverse-warning {
  background: #ffecc2;
  border-color: #ffc542;
}
.bg-inverse-warning .breadcrumb-item {
  color: #ffc542;
}
.bg-inverse-warning .breadcrumb-item:before {
  color: inherit;
}
.bg-inverse-warning .breadcrumb-item a {
  color: inherit;
}
.bg-inverse-warning.breadcrumb-custom {
  background: transparent;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item {
  background: #ffecc2;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item a {
  color: #ffc542;
  border: none;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item a:before {
  border-left-color: #ffecc2;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item span {
  color: #ffc542;
}
.bg-inverse-warning.breadcrumb-custom .breadcrumb-item:last-child {
  background: transparent;
}

.bg-inverse-danger {
  background: #fed7d7;
  border-color: #fc5a5a;
}
.bg-inverse-danger .breadcrumb-item {
  color: #fc5a5a;
}
.bg-inverse-danger .breadcrumb-item:before {
  color: inherit;
}
.bg-inverse-danger .breadcrumb-item a {
  color: inherit;
}
.bg-inverse-danger.breadcrumb-custom {
  background: transparent;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item {
  background: #fed7d7;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item a {
  color: #fc5a5a;
  border: none;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item a:before {
  border-left-color: #fed7d7;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item span {
  color: #fc5a5a;
}
.bg-inverse-danger.breadcrumb-custom .breadcrumb-item:last-child {
  background: transparent;
}

.bg-inverse-light,
.image-grouped .text-avatar {
  background: #f2f3f5;
  border-color: #aab2bd;
}
.bg-inverse-light .breadcrumb-item,
.image-grouped .text-avatar .breadcrumb-item {
  color: #aab2bd;
}
.bg-inverse-light .breadcrumb-item:before,
.image-grouped .text-avatar .breadcrumb-item:before {
  color: inherit;
}
.bg-inverse-light .breadcrumb-item a,
.image-grouped .text-avatar .breadcrumb-item a {
  color: inherit;
}
.bg-inverse-light.breadcrumb-custom,
.image-grouped .breadcrumb-custom.text-avatar {
  background: transparent;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item {
  background: #f2f3f5;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item a,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item a {
  color: #aab2bd;
  border: none;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item a:before,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item a:before {
  border-left-color: #f2f3f5;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item span,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item span {
  color: #aab2bd;
}
.bg-inverse-light.breadcrumb-custom .breadcrumb-item:last-child,
.image-grouped .breadcrumb-custom.text-avatar .breadcrumb-item:last-child {
  background: transparent;
}

.bg-inverse-dark {
  background: #004cb7;
  border-color: #001737;
}
.bg-inverse-dark .breadcrumb-item {
  color: #001737;
}
.bg-inverse-dark .breadcrumb-item:before {
  color: inherit;
}
.bg-inverse-dark .breadcrumb-item a {
  color: inherit;
}
.bg-inverse-dark.breadcrumb-custom {
  background: transparent;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item {
  background: #004cb7;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item a {
  color: #001737;
  border: none;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item a:before {
  border-left-color: #004cb7;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item span {
  color: #001737;
}
.bg-inverse-dark.breadcrumb-custom .breadcrumb-item:last-child {
  background: transparent;
}

/* Cards */
.card {
  border: 0;
  border-radius: 0;
}
.card .card-body {
  padding: 2.5rem 2.5rem;
}
.card .card-body + .card-body {
  padding-top: 1rem;
}
.card .card-title {
  color: #001737;
  margin-bottom: 1.625rem;
  text-transform: capitalize;
  
  font-size: 1.125rem;
  font-weight: 600;
}
.card .card-subtitle {
  
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.card .card-description {
  color: #76838f;
  margin-bottom: 1.5rem;
  
}
.card.card-outline-success {
  border: 1px solid #44ce42;
}
.card.card-outline-primary {
  border: 1px solid #3762c0;
}
.card.card-outline-warning {
  border: 1px solid #ffc542;
}
.card.card-outline-danger {
  border: 1px solid #fc5a5a;
}
.card.card-rounded {
  border-radius: 5px;
}
.card.card-faded {
  background: #b5b0b2;
  border-color: #b5b0b2;
}
.card.card-circle-progress {
  color: #ffffff;
  text-align: center;
}
.card.card-img-holder {
  position: relative;
}
.card.card-img-holder .card-img-absolute {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.card.card-circle-progress {
  color: #ffffff;
  text-align: center;
}
.card.card-no-shadow {
  box-shadow: none;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
    margin-bottom: 0.75rem;
  }
}
.card-inverse-primary {
  background: rgba(164, 97, 216, 0.2);
  border: 1px solid theme-color-level(primary, 1);
  color: theme-color-level(primary, 3);
}

.card-inverse-secondary {
  background: rgba(142, 148, 169, 0.2);
  border: 1px solid theme-color-level(secondary, 1);
  color: theme-color-level(secondary, 3);
}

.card-inverse-success {
  background: rgba(68, 206, 66, 0.2);
  border: 1px solid theme-color-level(success, 1);
  color: theme-color-level(success, 3);
}

.card-inverse-info {
  background: rgba(0, 98, 255, 0.2);
  border: 1px solid theme-color-level(info, 1);
  color: theme-color-level(info, 3);
}

.card-inverse-warning {
  background: rgba(255, 197, 66, 0.2);
  border: 1px solid theme-color-level(warning, 1);
  color: theme-color-level(warning, 3);
}

.card-inverse-danger {
  background: rgba(252, 90, 90, 0.2);
  border: 1px solid theme-color-level(danger, 1);
  color: theme-color-level(danger, 3);
}

.card-inverse-light {
  background: rgba(170, 178, 189, 0.2);
  border: 1px solid theme-color-level(light, 1);
  color: theme-color-level(light, 3);
}

.card-inverse-dark {
  background: rgba(0, 23, 55, 0.2);
  border: 1px solid theme-color-level(dark, 1);
  color: theme-color-level(dark, 3);
}

.chat-app-wrapper {
  position: relative;
  overflow: hidden;
}
.chat-app-wrapper .chat-list-wrapper {
  overflow-y: auto;
  border-right: 1px solid rgba(151, 151, 151, 0.3);
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .chat-app-wrapper .chat-list-wrapper {
    position: absolute;
    left: -300px;
    top: 0;
    z-index: 99;
    bottom: 0px;
    background: #fff;
    width: 300px;
    height: 100%;
    border-right: none;
    transition-duration: 0.3s;
    transition-property: "box-shadow", "left";
  }
  .chat-app-wrapper .chat-list-wrapper.menu-open {
    left: 0px;
    box-shadow: 6px 4px 15px -5px rgba(0, 0, 0, 0.25);
  }
}
.chat-app-wrapper .chat-list-wrapper .chat-list-item-wrapper {
  padding-top: 20px;
  padding-bottom: 40px;
}
.chat-app-wrapper .chat-list-wrapper .chat-list-item-wrapper .list-item {
  display: grid;
  grid-template-columns: 45px auto auto;
  grid-template-rows: 30px auto;
  padding: 15px 20px;
  transition-duration: 0.5s;
  transition-property: "background";
}
.chat-app-wrapper
  .chat-list-wrapper
  .chat-list-item-wrapper
  .list-item
  .profile-image {
  position: relative;
}
.chat-app-wrapper
  .chat-list-wrapper
  .chat-list-item-wrapper
  .list-item
  .profile-image
  .dot-indicator {
  position: absolute;
  top: 15px;
  right: -5px;
  z-index: 1;
  height: 12px;
  width: 12px;
  border: 2px solid #fff;
  border-radius: 100%;
}
.chat-app-wrapper
  .chat-list-wrapper
  .chat-list-item-wrapper
  .list-item
  .user-name {
  color: #a7afb7;
  padding-left: 20px;
  margin-bottom: 0;
}
.chat-app-wrapper
  .chat-list-wrapper
  .chat-list-item-wrapper
  .list-item
  .chat-time {
  color: #a7afb7;
  text-align: right;
}
.chat-app-wrapper
  .chat-list-wrapper
  .chat-list-item-wrapper
  .list-item
  .chat-text {
  padding-left: 20px;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 2;
  margin-bottom: 0;
}
.chat-app-wrapper .chat-list-wrapper .chat-list-item-wrapper .list-item:hover {
  background: rgba(0, 98, 255, 0.1);
}
.chat-app-wrapper .chat-search-input {
  padding-top: 30px;
  padding-bottom: 10px;
}
.chat-app-wrapper .chat-search-input .form-control,
.chat-app-wrapper .chat-search-input .typeahead,
.chat-app-wrapper .chat-search-input .tt-query,
.chat-app-wrapper .chat-search-input .tt-hint,
.chat-app-wrapper
  .chat-search-input
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .chat-app-wrapper
  .chat-search-input
  .select2-search__field,
.chat-app-wrapper
  .chat-search-input
  .select2-container--default
  .select2-selection--single,
.select2-container--default
  .chat-app-wrapper
  .chat-search-input
  .select2-selection--single,
.chat-app-wrapper
  .chat-search-input
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .chat-app-wrapper
  .chat-search-input
  input[type="number"],
.chat-app-wrapper
  .chat-search-input
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .chat-app-wrapper
  .chat-search-input
  input[type="text"],
.chat-app-wrapper
  .chat-search-input
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .chat-app-wrapper
  .chat-search-input
  select,
.chat-app-wrapper .chat-search-input .dataTables_wrapper select,
.dataTables_wrapper .chat-app-wrapper .chat-search-input select,
.chat-app-wrapper .chat-search-input .asColorPicker-input {
  height: 40px;
  border-right: none;
  border-radius: 4px 0px 0px 4px;
  color: #a7afb7;
}
.chat-app-wrapper .chat-search-input .input-group-text {
  padding: 8px 15px;
  font-size: 20px;
  border-radius: 0px 4px 4px 0px;
}
.chat-app-wrapper .chat-search-input .input-group-text i {
  color: #a7afb7;
}
.chat-app-wrapper .sidebar-spacer {
  padding-left: 20px;
  padding-right: 20px;
}
.chat-app-wrapper .chat-container-wrapper {
  height: 100%;
  min-height: 100vh;
  max-height: 950px;
  overflow-y: auto;
  padding-bottom: 20px;
  padding: 0 15px 30px 15px;
}
.chat-app-wrapper .chat-container-wrapper .chat-bubble {
  margin-top: 30px;
}
.chat-app-wrapper .chat-container-wrapper .chat-bubble .chat-message {
  border-radius: 4px;
  padding: 20px;
}
.chat-app-wrapper
  .chat-container-wrapper
  .chat-bubble
  .chat-message
  p:last-child {
  margin-bottom: 0;
}
.chat-app-wrapper .chat-container-wrapper .chat-bubble .sender-details {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.chat-app-wrapper
  .chat-container-wrapper
  .chat-bubble
  .sender-details
  .seen-text {
  margin-bottom: 0;
  color: #a7afb7;
}
.chat-app-wrapper
  .chat-container-wrapper
  .chat-bubble.incoming-chat
  .chat-message {
  background: #f0f1f6;
  margin-left: 45px;
}
.chat-app-wrapper
  .chat-container-wrapper
  .chat-bubble.incoming-chat
  .sender-details
  .seen-text {
  padding-left: 10px;
}
.chat-app-wrapper
  .chat-container-wrapper
  .chat-bubble.outgoing-chat
  .chat-message {
  background: #0062ff;
  margin-left: auto;
  max-width: 290px;
  margin-right: 45px;
}
.chat-app-wrapper
  .chat-container-wrapper
  .chat-bubble.outgoing-chat
  .chat-message
  p {
  color: #ffffff;
}
.chat-app-wrapper
  .chat-container-wrapper
  .chat-bubble.outgoing-chat
  .sender-details {
  flex-direction: row-reverse;
}
.chat-app-wrapper
  .chat-container-wrapper
  .chat-bubble.outgoing-chat
  .sender-details
  .seen-text {
  padding-right: 10px;
}
.chat-app-wrapper .chat-sidebar {
  height: 100%;
  max-height: 950px;
  overflow-y: auto;
  border-left: 1px solid rgba(151, 151, 151, 0.3);
}
.chat-app-wrapper .chat-text-field {
  border-top: 1px solid rgba(151, 151, 151, 0.3);
  padding: 10px 0;
}
.chat-app-wrapper
  .chat-text-field
  .input-group
  .input-group-prepend
  .input-group-text,
.chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  .input-group-prepend
  .input-group-text,
.chat-app-wrapper
  .chat-text-field
  .input-group
  .input-group-append
  .input-group-text,
.chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  .input-group-append
  .input-group-text {
  border: none;
}
.chat-app-wrapper .chat-text-field .input-group .form-control,
.chat-app-wrapper .chat-text-field .input-group .typeahead,
.chat-app-wrapper .chat-text-field .input-group .tt-query,
.chat-app-wrapper .chat-text-field .input-group .tt-hint,
.chat-app-wrapper
  .chat-text-field
  .input-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .chat-app-wrapper
  .chat-text-field
  .input-group
  .select2-search__field,
.chat-app-wrapper
  .chat-text-field
  .input-group
  .select2-container--default
  .select2-selection--single,
.select2-container--default
  .chat-app-wrapper
  .chat-text-field
  .input-group
  .select2-selection--single,
.chat-app-wrapper
  .chat-text-field
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .chat-app-wrapper
  .chat-text-field
  .input-group
  input[type="number"],
.chat-app-wrapper
  .chat-text-field
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .chat-app-wrapper
  .chat-text-field
  .input-group
  input[type="text"],
.chat-app-wrapper
  .chat-text-field
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .chat-app-wrapper
  .chat-text-field
  .input-group
  select,
.chat-app-wrapper .chat-text-field .input-group .dataTables_wrapper select,
.dataTables_wrapper .chat-app-wrapper .chat-text-field .input-group select,
.chat-app-wrapper .chat-text-field .asColorPicker-wrap .form-control,
.chat-app-wrapper .chat-text-field .asColorPicker-wrap .typeahead,
.chat-app-wrapper .chat-text-field .asColorPicker-wrap .tt-query,
.chat-app-wrapper .chat-text-field .asColorPicker-wrap .tt-hint,
.chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  .select2-search__field,
.chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single,
.select2-container--default
  .chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  .select2-selection--single,
.chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  input[type="number"],
.chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  input[type="text"],
.chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  select,
.chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  .dataTables_wrapper
  select,
.dataTables_wrapper
  .chat-app-wrapper
  .chat-text-field
  .asColorPicker-wrap
  select,
.chat-app-wrapper .chat-text-field .input-group .asColorPicker-input,
.chat-app-wrapper .chat-text-field .asColorPicker-wrap .asColorPicker-input {
  height: 45px;
  border: none;
}
.chat-app-wrapper .list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
}

/* Checkboxes and Radios */
.form-check {
  position: relative;
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-left: 0;
}
.form-check .form-check-label {
  display: block;
  margin-left: 1.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.rtl .form-check .form-check-label {
  margin-left: 0;
  margin-right: 1.75rem;
}
.form-check .form-check-label input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.rtl .form-check .form-check-label input {
  left: auto;
  right: 0;
}
.form-check .form-check-label input[type="checkbox"] + .input-helper:before,
.form-check .form-check-label input[type="checkbox"] + .input-helper:after {
  position: absolute;
  top: 0;
  left: 0;
}
.rtl
  .form-check
  .form-check-label
  input[type="checkbox"]
  + .input-helper:before,
.rtl
  .form-check
  .form-check-label
  input[type="checkbox"]
  + .input-helper:after {
  left: auto;
  right: 0;
}
.form-check .form-check-label input[type="checkbox"] + .input-helper:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: solid #0062ff;
  border-width: 2px;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.form-check .form-check-label input[type="checkbox"] + .input-helper:after {
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  font-family: Material Design Icons;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  content: "\f012c";
  font-size: 0.9375rem;
  font-weight: bold;
  color: #ffffff;
}
.form-check
  .form-check-label
  input[type="checkbox"]:checked
  + .input-helper:before {
  background: #0062ff;
  border-width: 0;
}
.form-check
  .form-check-label
  input[type="checkbox"]:checked
  + .input-helper:after {
  width: 18px;
  opacity: 1;
  line-height: 18px;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.form-check
  .form-check-label
  input[type="checkbox"]:disabled
  + .input-helper:before {
  border-color: rgba(151, 151, 151, 0.3);
}
.form-check
  .form-check-label
  input[type="checkbox"]:disabled:checked
  + .input-helper:after {
  background: rgba(151, 151, 151, 0.3);
  color: #ffffff;
}
.form-check .form-check-label input[type="radio"] + .input-helper:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border: solid #0062ff;
  border-width: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.rtl .form-check .form-check-label input[type="radio"] + .input-helper:before {
  left: auto;
  right: 0;
}
.form-check .form-check-label input[type="radio"] + .input-helper:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
  top: 6px;
  left: 6px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  position: absolute;
}
.rtl .form-check .form-check-label input[type="radio"] + .input-helper:after {
  left: auto;
  right: 6px;
}
.form-check
  .form-check-label
  input[type="radio"]:checked
  + .input-helper:before {
  background: #0062ff;
  border-width: 0;
}
.form-check
  .form-check-label
  input[type="radio"]:checked
  + .input-helper:after {
  opacity: 1;
  line-height: 1.5;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.form-check
  .form-check-label
  input[type="radio"]:disabled
  + .input-helper:before {
  border-color: rgba(151, 151, 151, 0.3);
}
.form-check
  .form-check-label
  input[type="radio"]:disabled:checked
  + .input-helper:before {
  background: rgba(151, 151, 151, 0.3);
}
.form-check
  .form-check-label
  input[type="radio"]:disabled:checked
  + .input-helper:after {
  background: #ffffff;
}

.form-check-primary.form-check
  label
  input[type="checkbox"]
  + .input-helper:before,
.form-check-primary.form-check
  label
  input[type="radio"]
  + .input-helper:before {
  border-color: primary;
}
.form-check-primary.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-primary.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: linear-gradient(to right, #da8cff, #9a55ff);
}

.form-check-secondary.form-check
  label
  input[type="checkbox"]
  + .input-helper:before,
.form-check-secondary.form-check
  label
  input[type="radio"]
  + .input-helper:before {
  border-color: secondary;
}
.form-check-secondary.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-secondary.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: linear-gradient(to right, #e7ebf0, #868e96);
}

.form-check-success.form-check
  label
  input[type="checkbox"]
  + .input-helper:before,
.form-check-success.form-check
  label
  input[type="radio"]
  + .input-helper:before {
  border-color: success;
}
.form-check-success.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-success.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: linear-gradient(to right, #84d9d2, #07cdae);
}

.form-check-info.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-info.form-check label input[type="radio"] + .input-helper:before {
  border-color: info;
}
.form-check-info.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-info.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: linear-gradient(to right, #90caf9, #047edf 99%);
}

.form-check-warning.form-check
  label
  input[type="checkbox"]
  + .input-helper:before,
.form-check-warning.form-check
  label
  input[type="radio"]
  + .input-helper:before {
  border-color: warning;
}
.form-check-warning.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-warning.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: linear-gradient(to right, #f6e384, #ffd500);
}

.form-check-danger.form-check
  label
  input[type="checkbox"]
  + .input-helper:before,
.form-check-danger.form-check label input[type="radio"] + .input-helper:before {
  border-color: danger;
}
.form-check-danger.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-danger.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: linear-gradient(to right, #ffbf96, #fe7096);
}

.form-check-light.form-check
  label
  input[type="checkbox"]
  + .input-helper:before,
.form-check-light.form-check label input[type="radio"] + .input-helper:before {
  border-color: light;
}
.form-check-light.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-light.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
}

.form-check-dark.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-dark.form-check label input[type="radio"] + .input-helper:before {
  border-color: dark;
}
.form-check-dark.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-dark.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
}

.map-container {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  display: block;
  padding: 35% 0;
  overflow: hidden;
  border-radius: 5px;
}
.map-container .google-map {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.map-container .google-map.no-controls .gmnoprint,
.map-container .google-map.no-controls .gm-style-cc {
  display: none;
}

.board-wrapper {
  overflow-x: auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}
@media (max-width: 991px) {
  .board-wrapper {
    display: block;
  }
}
.board-wrapper .board-portlet {
  background: #e5e7ef;
  margin: 0px 5px;
  padding: 22px 20px;
  min-width: 300px;
  width: 33%;
  border-radius: 4px;
}
@media (max-width: 991px) {
  .board-wrapper .board-portlet {
    width: 100%;
  }
}
.board-wrapper .portlet-heading {
  font-weight: 600;
  margin-bottom: 5px;
}
.board-wrapper .task-number {
  color: #a7afb7;
}
.board-wrapper .portlet-card-list {
  padding-left: 0;
  list-style: none;
  min-height: 70px;
}
.board-wrapper .portlet-card {
  width: 100%;
  border-radius: 4px;
  padding: 20px 20px 20px 20px;
  background: #fff;
  display: grid;
  grid-template-rows: 5;
  grid-template-columns: 2;
  border-radius: 6px;
  position: relative;
  margin-bottom: 15px;
  cursor: grab;
}
.board-wrapper .portlet-card .progress {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  border-radius: 6px 6px 0px 0px;
  height: 4px;
}
.board-wrapper .portlet-card .progress .progress-bar {
  border-radius: 0px;
}
.board-wrapper .portlet-card .task-date {
  margin-bottom: 5px;
  color: #a7afb7;
  grid-row-start: 1;
  grid-column-start: 1;
}
.board-wrapper .portlet-card .action-dropdown {
  grid-row-start: 1;
  grid-column-start: 2;
  margin-left: auto;
}
.board-wrapper .portlet-card .action-dropdown .dropdown-toggle {
  border: none;
  background: #fff;
}
.board-wrapper .portlet-card .action-dropdown .dropdown-toggle i {
  font-size: 20px;
  line-height: 20px;
}
.board-wrapper .portlet-card .action-dropdown .dropdown-toggle i:before {
  margin-right: -10px;
}
.board-wrapper .portlet-card .action-dropdown .dropdown-toggle:after {
  display: none;
}
.board-wrapper .portlet-card .action-dropdown .dropdown-menu-end {
  left: 0 !important;
}
.board-wrapper .portlet-card .task-title {
  font-weight: 600;
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  font-size: 16px;
  margin-bottom: 15px;
}
.board-wrapper .portlet-card .image-grouped {
  grid-row-start: 3;
  grid-column-start: 1;
  grid-column-end: 3;
}
.board-wrapper .portlet-card .portlet-image {
  width: 100%;
  display: block;
  grid-row-start: 4;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 5px;
  margin-left: -20px;
  margin-right: -20px;
  width: calc(100% + 40px);
}
.board-wrapper .portlet-card .badge {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row-start: 5;
  grid-column-start: 1;
  grid-column-end: 2;
  margin-top: 20px;
}
.board-wrapper .portlet-card .due-date {
  grid-row-start: 5;
  grid-column-start: 2;
  grid-column-end: 3;
  margin-bottom: 0;
  text-align: right;
  margin-top: 20px;
  color: #a7afb7;
  font-weight: 500;
}
.board-wrapper .add-portlet {
  background: #fff;
  border-radius: 6px;
  padding: 25px 10px;
  text-align: center;
  color: #a7afb7;
  font-weight: 500;
  border: none;
  width: 100%;
  transition-duration: 0.4s;
  transition-property: "background";
}
.board-wrapper .add-portlet:hover {
  background: #f2f2f2;
}

.kanban-toolbar .btn,
.kanban-toolbar .wizard > .actions a,
.wizard > .actions .kanban-toolbar a,
.kanban-toolbar .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .kanban-toolbar .swal2-styled,
.kanban-toolbar .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .kanban-toolbar .ajax-file-upload,
.kanban-toolbar .fc button,
.fc .kanban-toolbar button {
  margin-left: 10px;
}

/* Loaders */
#pgloading {
  position: static;
}
#pgloading .bokeh {
  font-size: 100px;
  width: 1em;
  height: 1em;
  position: relative;
  margin: 0 auto;
  border-radius: 50%;
  border: 0.01em solid rgba(151, 151, 151, 0.3);
  list-style: none;
  padding: 0;
}
#pgloading .bokeh li {
  position: absolute;
  width: 0.2em;
  height: 0.2em;
  border-radius: 50%;
}
#pgloading .bokeh li:nth-child(1) {
  left: 50%;
  top: 0;
  margin: 0 0 0 -0.1em;
  background: #44ce42;
  -webkit-transform-origin: 50% 250%;
  transform-origin: 50% 250%;
  -webkit-animation: rota 1.13s linear infinite,
    opa 3.67s ease-in-out infinite alternate;
  animation: rota 1.13s linear infinite,
    opa 3.67s ease-in-out infinite alternate;
}
#pgloading .bokeh li:nth-child(2) {
  top: 50%;
  right: 0;
  margin: -0.1em 0 0 0;
  background: #fc5a5a;
  -webkit-transform-origin: -150% 50%;
  transform-origin: -150% 50%;
  -webkit-animation: rota 1.86s linear infinite,
    opa 4.29s ease-in-out infinite alternate;
  animation: rota 1.86s linear infinite,
    opa 4.29s ease-in-out infinite alternate;
}
#pgloading .bokeh li:nth-child(3) {
  left: 50%;
  bottom: 0;
  margin: 0 0 0 -0.1em;
  background: #ffc542;
  -webkit-transform-origin: 50% -150%;
  transform-origin: 50% -150%;
  -webkit-animation: rota 1.45s linear infinite,
    opa 5.12s ease-in-out infinite alternate;
  animation: rota 1.45s linear infinite,
    opa 5.12s ease-in-out infinite alternate;
}
#pgloading .bokeh li:nth-child(4) {
  top: 50%;
  left: 0;
  margin: -0.1em 0 0 0;
  background: #0062ff;
  -webkit-transform-origin: 250% 50%;
  transform-origin: 250% 50%;
  -webkit-animation: rota 1.72s linear infinite,
    opa 5.25s ease-in-out infinite alternate;
  animation: rota 1.72s linear infinite,
    opa 5.25s ease-in-out infinite alternate;
}

@-webkit-keyframes rota {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rota {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes opa {
  12.0% {
    opacity: 0.8;
  }
  19.5% {
    opacity: 0.88;
  }
  37.2% {
    opacity: 0.64;
  }
  40.5% {
    opacity: 0.52;
  }
  52.7% {
    opacity: 0.69;
  }
  60.2% {
    opacity: 0.6;
  }
  66.6% {
    opacity: 0.52;
  }
  70.0% {
    opacity: 0.63;
  }
  79.9% {
    opacity: 0.6;
  }
  84.2% {
    opacity: 0.75;
  }
  91.0% {
    opacity: 0.87;
  }
}
@keyframes opa {
  12.0% {
    opacity: 0.8;
  }
  19.5% {
    opacity: 0.88;
  }
  37.2% {
    opacity: 0.64;
  }
  40.5% {
    opacity: 0.52;
  }
  52.7% {
    opacity: 0.69;
  }
  60.2% {
    opacity: 0.6;
  }
  66.6% {
    opacity: 0.52;
  }
  70.0% {
    opacity: 0.63;
  }
  79.9% {
    opacity: 0.6;
  }
  84.2% {
    opacity: 0.75;
  }
  91.0% {
    opacity: 0.87;
  }
}
.glowing-loader {
  position: relative;
}

.s2 {
  position: absolute;
  height: 70px;
  width: 70px;
  top: 50px;
  background-color: transparent;
  left: 50%;
  transform: translate(-50%, -50%);
}

.s1 {
  position: absolute;
  height: 70px;
  width: 70px;
  left: 50%;
  top: 50px;
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: transparent;
}

.bigcon {
  position: absolute;
  height: 75px;
  width: 75px;
  left: 50%;
  top: 50px;
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(-45deg);
  background-color: transparent;
  animation: bigcon 2s infinite linear;
  animation-delay: 0.25s;
}

.b {
  border-radius: 50%;
  position: absolute;
}

.s {
  width: 15px;
  height: 15px;
  animation: small 2s infinite ease;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.3);
  background-color: #0062ff;
}

.s:nth-child(1) {
  top: 0%;
  left: 0%;
}

.s:nth-child(2) {
  top: 0%;
  right: 0%;
}

.s:nth-child(3) {
  right: 0%;
  bottom: 0%;
}

.s:nth-child(4) {
  bottom: 0%;
  left: 0%;
}

.big {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #3762c0, 0px 0px 20px #3762c0, 0px 0px 30px #3762c0,
    0px 0px 50px #3762c0, 0px 0px 60px #3762c0;
  z-index: 1;
  background-color: #0062ff;
  animation: bigball 1s infinite linear;
}

.sb1 {
  animation-delay: -1.75s;
}

.sb6 {
  animation-delay: -1.5s;
}

.sb2 {
  animation-delay: -1.25s;
}

.sb7 {
  animation-delay: -1s;
}

.sb3 {
  animation-delay: -0.75s;
}

.sb8 {
  animation-delay: -0.5s;
}

.sb4 {
  animation-delay: -0.25s;
}

.sb5 {
  animation-delay: 0s;
}

@keyframes bigcon {
  0% {
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  100% {
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(405deg);
  }
}
@keyframes small {
  0% {
    transform: scale(1);
    background-color: #66a1ff;
  }
  10% {
    transform: scale(1.3);
    background-color: #0062ff;
  }
  15% {
    transform: scale(1);
  }
  25% {
    transform: scale(1);
    background-color: #0062ff;
  }
  100% {
    transform: scale(1);
    background-color: #0062ff;
  }
}
/* Pixel loader */
.pixel-loader {
  width: 10px;
  height: 10px;
  background: #ffc542;
  color: #ffc542;
  margin: 80px auto;
  box-shadow: 15px 15px 0 0, -15px -15px 0 0, 15px -15px 0 0, -15px 15px 0 0,
    0 15px 0 0, 15px 0 0 0, -15px 0 0 0, 0 -15px 0 0;
  animation: anim 2s linear infinite;
}

@keyframes anim {
  0% {
    -webkit-filter: hue-rotate(0deg);
  }
  50% {
    box-shadow: 20px 20px 0 0, -20px -20px 0 0, 20px -20px 0 0, -20px 20px 0 0,
      0 10px 0 0, 10px 0 0 0, -10px 0 0 0, 0 -10px 0 0;
  }
  75% {
    box-shadow: 20px 20px 0 0, -20px -20px 0 0, 20px -20px 0 0, -20px 20px 0 0,
      0 10px 0 0, 10px 0 0 0, -10px 0 0 0, 0 -10px 0 0;
  }
  100% {
    transform: rotate(360deg);
    -webkit-filter: hue-rotate(360deg);
  }
}
/* Square box loader */
@keyframes dyinglight {
  15% {
    transform: scale(1.6);
  }
  50% {
    transform: rotate(-89deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}
.square-box-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.square-box-loader-square {
  display: block;
  width: 30px;
  height: 30px;
  background: #0062ff;
}

.square-box-loader-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transform-origin: 50% 50% 0;
  animation: dyinglight 1s ease infinite;
  backface-visibility: hidden;
}

.square-box-loader-corner-top:before,
.square-box-loader-corner-top:after,
.square-box-loader-corner-bottom:before,
.square-box-loader-corner-bottom:after {
  position: absolute;
  width: 10px;
  height: 10px;
  color: #0062ff;
  content: "";
}

.square-box-loader-corner-top:before {
  border-left: 1px solid;
  border-top: 1px solid;
  top: -4px;
  left: -4px;
}
.square-box-loader-corner-top:after {
  border-right: 1px solid;
  border-top: 1px solid;
  top: -4px;
  right: -4px;
}

.square-box-loader-corner-bottom:before {
  border-left: 1px solid;
  border-bottom: 1px solid;
  bottom: -4px;
  left: -4px;
}
.square-box-loader-corner-bottom:after {
  border-right: 1px solid;
  border-bottom: 1px solid;
  bottom: -4px;
  right: -4px;
}

/* Circle loader */
.circle-loader {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.circle-loader:before,
.circle-loader:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #ffc542;
}

.circle-loader:before {
  z-index: 100;
  animation: spin 1s infinite;
}

.circle-loader:after {
  border: 10px solid #8e94a9;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* Jumping dots loader */
.jumping-dots-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.jumping-dots-loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: rgba(252, 90, 90, 0.8);
  margin: 35px 5px;
}

.jumping-dots-loader span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}

.jumping-dots-loader span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}

.jumping-dots-loader span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}

@keyframes bounce {
  0%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
/* Bar loader */
.bar-loader {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.bar-loader span {
  display: inline-block;
  width: 5px;
  height: 30px;
  background-color: rgba(252, 90, 90, 0.7);
}

.bar-loader span:nth-child(1) {
  animation: grow 1s ease-in-out infinite;
}

.bar-loader span:nth-child(2) {
  animation: grow 1s ease-in-out 0.15s infinite;
}

.bar-loader span:nth-child(3) {
  animation: grow 1s ease-in-out 0.3s infinite;
}

.bar-loader span:nth-child(4) {
  animation: grow 1s ease-in-out 0.45s infinite;
}

@keyframes grow {
  0%,
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }
  50% {
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}
/* Square path loader */
.square-path-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.square-path-loader:before,
.square-path-loader:after {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: calc(50% - 10px);
  background-color: rgba(68, 206, 66, 0.7);
  animation: squaremove 1s ease-in-out infinite;
}

.square-path-loader:after {
  bottom: 0;
  animation-delay: 0.5s;
}

@keyframes squaremove {
  0%,
  100% {
    -webkit-transform: translate(0, 0) rotate(0);
    -ms-transform: translate(0, 0) rotate(0);
    -o-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }
  25% {
    -webkit-transform: translate(40px, 40px) rotate(45deg);
    -ms-transform: translate(40px, 40px) rotate(45deg);
    -o-transform: translate(40px, 40px) rotate(45deg);
    transform: translate(40px, 40px) rotate(45deg);
  }
  50% {
    -webkit-transform: translate(0px, 80px) rotate(0deg);
    -ms-transform: translate(0px, 80px) rotate(0deg);
    -o-transform: translate(0px, 80px) rotate(0deg);
    transform: translate(0px, 80px) rotate(0deg);
  }
  75% {
    -webkit-transform: translate(-40px, 40px) rotate(45deg);
    -ms-transform: translate(-40px, 40px) rotate(45deg);
    -o-transform: translate(-40px, 40px) rotate(45deg);
    transform: translate(-40px, 40px) rotate(45deg);
  }
}
/* Dot opacity loader */
.dot-opacity-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.dot-opacity-loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #0062ff;
  margin: 35px 5px;
  opacity: 0;
}

.dot-opacity-loader span:nth-child(1) {
  animation: opacitychange 1s ease-in-out infinite;
}

.dot-opacity-loader span:nth-child(2) {
  animation: opacitychange 1s ease-in-out 0.33s infinite;
}

.dot-opacity-loader span:nth-child(3) {
  animation: opacitychange 1s ease-in-out 0.66s infinite;
}

@keyframes opacitychange {
  0%,
  100% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
/* Flip square */
.flip-square-loader {
  -webkit-perspective: 120px;
  -moz-perspective: 120px;
  -ms-perspective: 120px;
  perspective: 120px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.flip-square-loader:before {
  content: "";
  position: absolute;
  left: 25px;
  top: 25px;
  width: 50px;
  height: 50px;
  background-color: rgba(68, 206, 66, 0.7);
  animation: flip 1s infinite;
}

@keyframes flip {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(180deg) rotateX(180deg);
  }
}
/* Moving square loader */
.moving-square-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.moving-square-loader:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: calc(50% - 10px);
  left: 0px;
  background-color: rgba(252, 90, 90, 0.7);
  animation: rotatemove 1s infinite;
}

@keyframes rotatemove {
  0% {
    -webkit-transform: scale(1) translateX(0px);
    -ms-transform: scale(1) translateX(0px);
    -o-transform: scale(1) translateX(0px);
    transform: scale(1) translateX(0px);
  }
  100% {
    -webkit-transform: scale(2) translateX(45px);
    -ms-transform: scale(2) translateX(45px);
    -o-transform: scale(2) translateX(45px);
    transform: scale(2) translateX(45px);
  }
}
/* Modals */
.modal .modal-dialog {
  margin-top: 100px;
}
.modal .modal-dialog .modal-content .modal-header {
  padding: 25px 26px;
}
.modal .modal-dialog .modal-content .modal-header .close {
  background: transparent;
  border: none;
}
.modal .modal-dialog .modal-content .modal-header .close span {
  font-size: 20px;
  font-weight: 400;
  color: #6a6a6a;
}
.modal .modal-dialog .modal-content .modal-body {
  padding: 35px 26px;
}
.modal .modal-dialog .modal-content .modal-footer {
  padding: 15px 31px;
}

/* Portfolio */
.portfolio-grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  width: 100%;
  background: #3762c0;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 767px) {
  .portfolio-grid figure {
    width: 270px;
  }
}
.portfolio-grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  width: 100%;
  opacity: 0.8;
}
.portfolio-grid figure figcaption {
  padding: 2em;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.portfolio-grid figure figcaption:after,
.portfolio-grid figure figcaption:before {
  pointer-events: none;
}
.portfolio-grid figure.effect-text-in {
  border-radius: 0.25rem;
}
.portfolio-grid figure.effect-text-in h4,
.portfolio-grid figure.effect-text-in .h4,
.portfolio-grid figure.effect-text-in img {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}
.portfolio-grid figure.effect-text-in img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.portfolio-grid figure.effect-text-in h4,
.portfolio-grid figure.effect-text-in .h4,
.portfolio-grid figure.effect-text-in p {
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 10px;
  margin-bottom: 0;
  width: 100%;
}
.portfolio-grid figure.effect-text-in p {
  text-transform: none;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0);
}
.portfolio-grid figure.effect-text-in:hover img {
  -webkit-transform: translate3d(0, -80px, 0);
  transform: translate3d(0, -80px, 0);
}
.portfolio-grid figure.effect-text-in:hover h4,
.portfolio-grid figure.effect-text-in:hover .h4 {
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0);
}
.portfolio-grid figure.effect-text-in:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Preview */
.preview-list .preview-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
}
.preview-list .preview-item:last-child {
  border-bottom: 0;
}
.preview-list .preview-item:hover {
  background: var(--bs-tertiary-bg);
}
.preview-list .preview-item .form-check {
  margin-top: 8px;
  margin-right: 1rem;
}
.preview-list .preview-item .preview-thumbnail {
  color: #ffffff;
  position: relative;
}
.preview-list .preview-item .preview-thumbnail img,
.preview-list .preview-item .preview-thumbnail .preview-icon {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}
.preview-list .preview-item .preview-thumbnail .preview-icon {
  padding: 6px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.preview-list .preview-item .preview-thumbnail .preview-icon i {
  font-size: 1.125rem;
  margin: 0;
}
.preview-list .preview-item .preview-thumbnail .badge {
  border: 2px solid #ffffff;
  border-radius: 100%;
  bottom: 5px;
  display: block;
  height: 14px;
  left: -5px;
  padding: 0;
  position: absolute;
  width: 14px;
}
.preview-list .preview-item .preview-item-content {
  line-height: 1;
  padding-left: 15px;
}
.preview-list .preview-item .preview-item-content:first-child {
  padding-left: 0;
}
.preview-list .preview-item .preview-item-content p {
  margin-bottom: 10px;
}
.preview-list .preview-item .preview-item-content p .content-category {
  padding-right: 15px;
  border-right: 1px solid rgba(151, 151, 151, 0.3);
}
.rtl .preview-list .preview-item .preview-item-content {
  padding-left: 0;
  padding-right: 1rem;
  margin-right: 0;
  margin-left: auto;
}
.preview-list .preview-item .preview-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.preview-list .preview-item .preview-actions i {
  width: 29px;
  color: #e6e9ed;
  height: 29px;
  border: 2px solid #e6e9ed;
  border-radius: 100%;
  padding: 3px 6px;
  display: inline-block;
}
.preview-list .preview-item .preview-actions i:first-child {
  margin-right: 10px;
}
.preview-list.comment-preview .preview-item {
  padding: 0.87rem 0;
}
.preview-list.comment-preview .preview-item:first-child {
  padding-top: 0;
}
.preview-list.comment-preview .preview-item p {
  line-height: 27px;
}
.preview-list.bordered .preview-item {
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
}
.preview-list.bordered .preview-item:last-child {
  border-bottom: 0;
}

.product-nav-wrapper {
  display: flex;
  align-items: center;
}
.product-nav-wrapper .product-filter-nav {
  list-style: none;
  margin-bottom: 30px;
}
.product-nav-wrapper .product-filter-nav li {
  display: inline-block;
}
.product-nav-wrapper .product-filter-nav li a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
  color: #a7afb7;
}
.product-nav-wrapper .product-filter-nav li.active a {
  color: #0062ff;
}
.product-nav-wrapper .product-filter-options {
  margin-left: auto;
  padding-left: 0;
  display: flex;
  align-items: center;
}
.product-nav-wrapper .product-filter-options .account-user-info {
  list-style: none;
  margin-bottom: 30px;
}
.product-nav-wrapper .product-filter-options .account-user-info li {
  display: inline-block;
}
.product-nav-wrapper .product-filter-options .account-user-info li a {
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 1;
  color: #a7afb7;
  border-right: 1px solid rgba(151, 151, 151, 0.3);
}
.product-nav-wrapper
  .product-filter-options
  .account-user-info
  li:last-child
  a {
  border-right: none;
}
.product-nav-wrapper .product-filter-options .account-user-info {
  list-style: none;
  margin-bottom: 30px;
}
.product-nav-wrapper .product-filter-options .account-user-info li {
  display: inline-block;
}
.product-nav-wrapper .product-filter-options .account-user-info li a {
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 1;
  color: #a7afb7;
  border-right: 1px solid rgba(151, 151, 151, 0.3);
  text-decoration: none;
}
.product-nav-wrapper
  .product-filter-options
  .account-user-info
  li:last-child
  a {
  border-right: none;
}
.product-nav-wrapper .product-filter-options .account-user-link {
  list-style: none;
  margin-bottom: 30px;
}
.product-nav-wrapper .product-filter-options .account-user-link li {
  display: inline-block;
}
.product-nav-wrapper .product-filter-options .account-user-link li a {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  color: #a7afb7;
  text-decoration: none;
}
.product-nav-wrapper .product-filter-options .account-user-link li a:after {
  content: "/";
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.product-nav-wrapper
  .product-filter-options
  .account-user-link
  li:last-child
  a:after {
  display: none;
  margin-left: 0px;
}
.product-nav-wrapper .product-filter-options .account-user-actions {
  list-style: none;
  margin-bottom: 30px;
}
.product-nav-wrapper .product-filter-options .account-user-actions li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
}
.product-nav-wrapper .product-filter-options .account-user-actions li .badge {
  position: absolute;
  top: -12px;
  right: -7px;
}
.product-nav-wrapper .product-filter-options .account-user-actions li a {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  color: #a7afb7;
  text-decoration: none;
}
.product-nav-wrapper
  .product-filter-options
  .account-user-actions
  li:last-child {
  margin-right: 0;
}

.product-item-wrapper {
  margin-bottom: -15px;
}
.product-item-wrapper .product-item {
  box-shadow: none;
  margin-bottom: 20px;
}
.product-item-wrapper .product-item .card {
  box-shadow: none;
}
.product-item-wrapper .product-item .card-body {
  padding: 20px 25px;
  display: grid;
  position: relative;
  grid-template-columns: 70% 30%;
  grid-template-rows: auto;
  grid-template-areas: "product-image product-image" "product-title product-price" "product-variation product-actual-price" "product-description product-description";
}
.product-item-wrapper .product-item .card-body .action-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 35px 40px;
  display: flex;
  align-items: center;
  z-index: 2;
}
.product-item-wrapper .product-item .card-body .action-holder .sale-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}
.product-item-wrapper .product-item .card-body .action-holder .favorite-button {
  margin-left: auto;
  color: #fff;
  font-size: 22px;
}
.product-item-wrapper .product-item .card-body .product_image {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  grid-area: product-image;
  margin-bottom: 15px;
  transform: scale(1);
  z-index: 1;
  border-radius: 0px;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-timing-function: "ease";
  transition-property: "transform", "border-radius", "box-shadow";
}
.product-item-wrapper .product-item .card-body .product-title {
  grid-area: product-title;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.product-item-wrapper .product-item .card-body .product-price {
  grid-area: product-price;
  font-size: 20px;
  font-weight: 500;
  text-align: right;
  margin-bottom: 10px;
}
.product-item-wrapper .product-item .card-body .product-actual-price {
  grid-area: product-actual-price;
  font-size: 15px;
  text-align: right;
  text-decoration: line-through;
}
.product-item-wrapper .product-item .card-body .product-variation {
  grid-area: product-variation;
  list-style-type: none;
  display: inline-block;
  padding-left: 0;
  padding-bottom: 0;
}
.product-item-wrapper .product-item .card-body .product-variation li {
  display: inline-block;
  border: 1px solid rgba(151, 151, 151, 0.3);
  padding: 0px 15px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  transition-duration: 0.3s;
  transition-property: "background";
}
.product-item-wrapper .product-item .card-body .product-variation li a {
  color: inherit;
  text-decoration: none;
}
.product-item-wrapper .product-item .card-body .product-variation li:hover {
  background: #8e94a9;
}
.product-item-wrapper .product-item .card-body .product-description {
  grid-area: product-description;
  line-height: 1.71;
}
.product-item-wrapper .product-item:hover .product_image {
  transform: scale(1.1);
  border-radius: 4px;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.58);
}

.project-list-showcase {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}
.project-list-showcase .project-grid {
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 40px;
  overflow: hidden;
}
.project-list-showcase .project-grid .img-holder {
  width: 100%;
  height: 140px;
  border-radius: 4px 4px 0 0;
}
.project-list-showcase .project-grid .img-holder.img-bg-1 {
  /* background: url("../../images/samples/300x300/1.jpg") no-repeat center; */
  background-size: cover;
}
.project-list-showcase .project-grid .img-holder.img-bg-2 {
  /* background: url("../../images/samples/300x300/2.jpg") no-repeat center; */
  background-size: cover;
}
.project-list-showcase .project-grid .img-holder.img-bg-3 {
  /* background: url("../../images/samples/300x300/3.jpg") no-repeat center; */
  background-size: cover;
}
.project-list-showcase .project-grid .project-grid-inner {
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 0 0 4px 4px;
  padding: 30px 25px 30px 25px;
}
.project-list-showcase .project-grid .project-title {
  margin-bottom: 0;
  font-weight: 500;
}
.project-list-showcase .project-grid .project-location {
  margin-bottom: 0;
  font-weight: 500;
  color: #b5bcc2;
  margin-bottom: 15px;
}
.project-list-showcase .project-grid .action-tags {
  font-weight: 500;
  color: #a7afb7;
}

/* Tables */
.table,
.jsgrid .jsgrid-table {
  margin-bottom: 0;
}
.table thead th,
.jsgrid .jsgrid-table thead th {
  border-top: 0;
  border-bottom-width: 1px;
  font-weight: initial;
}
.table thead th i,
.jsgrid .jsgrid-table thead th i {
  margin-left: 0.325rem;
}
.table th,
.jsgrid .jsgrid-table th,
.table td,
.jsgrid .jsgrid-table td {
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 1;
  white-space: nowrap;
}
.table th img,
.jsgrid .jsgrid-table th img,
.table td img,
.jsgrid .jsgrid-table td img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}
.table th .badge,
.jsgrid .jsgrid-table th .badge,
.table td .badge,
.jsgrid .jsgrid-table td .badge {
  margin-bottom: 0;
}
.table.table-borderless,
.jsgrid .table-borderless.jsgrid-table {
  border: none;
}
.table.table-borderless tr,
.jsgrid .table-borderless.jsgrid-table tr,
.table.table-borderless td,
.jsgrid .table-borderless.jsgrid-table td,
.table.table-borderless th,
.jsgrid .table-borderless.jsgrid-table th {
  border: none;
}

.table > :not(:last-child) > :last-child > *,
.jsgrid .jsgrid-table > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(151, 151, 151, 0.3);
}

.table > :not(:first-child),
.jsgrid .jsgrid-table > :not(:first-child) {
  border-top: none;
}

.table-hover thead th td {
  color: #a7afb7;
  background-color: #fff;
}
.table-hover tbody tr:hover,
.table-hover tbody td {
  color: gray;
}

/* Tabs */
.add-items {
  margin-bottom: 1.5rem;
  overflow: hidden;
}
.add-items input[type="text"] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  background: transparent;
}
.add-items .btn,
.add-items .wizard > .actions a,
.wizard > .actions .add-items a,
.add-items .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .add-items .swal2-styled,
.add-items .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .add-items .ajax-file-upload,
.add-items .fc button,
.fc .add-items button {
  margin-left: 0.5rem;
}
.rtl .add-items .btn,
.rtl .add-items .wizard > .actions a,
.wizard > .actions .rtl .add-items a,
.rtl .add-items .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .rtl .add-items .swal2-styled,
.rtl .add-items .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .rtl .add-items .ajax-file-upload,
.rtl .add-items .fc button,
.fc .rtl .add-items button {
  margin-left: auto;
  margin-right: 0.5rem;
}

.list-wrapper {
  height: 100%;
  max-height: 100%;
  padding-bottom: 5rem;
}
.list-wrapper ul {
  padding: 0;
  text-align: left;
  list-style: none;
  margin-bottom: 0;
}
.list-wrapper ul li {
  font-size: 0.9375rem;
  padding: 0.4rem 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
}
.list-wrapper ul li:first-child {
  border-bottom: none;
}
.list-wrapper ul li .form-check {
  max-width: 90%;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.list-wrapper input[type="checkbox"] {
  margin-right: 15px;
}
.list-wrapper .remove {
  cursor: pointer;
  font-size: 1.438rem;
  font-weight: 600;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 20px;
  text-align: center;
}
.list-wrapper .completed {
  text-decoration: line-through;
  text-decoration-color: #3762c0;
}
/* Tooltips */
.tooltip-static-demo .tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  margin: 0 10px 10px 0;
}
.tooltip-static-demo .bs-tooltip-bottom-demo .arrow,
.tooltip-static-demo .bs-tooltip-top-demo .arrow {
  left: 50%;
}
.tooltip-static-demo .bs-tooltip-right-demo .arrow:before,
.tooltip-static-demo .bs-tooltip-left-demo .arrow:before {
  top: calc((100% -0.8rem) / 2);
}

.tooltip {
  font-size: 0.75rem;
  min-width: 5.625rem;
  z-index: 1029;
}
.tooltip .tooltip-inner {
  
}

.tooltip-primary .tooltip-inner {
  background: #3762c0;
  color: #ffffff;
}
.tooltip-primary.bs-tooltip-top .arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^="top"] .arrow::before {
  border-top-color: #3762c0;
}
.tooltip-primary.bs-tooltip-right .arrow::before {
  border-right-color: #3762c0;
}
.tooltip-primary.bs-tooltip-bottom .arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^="bottom"]
  .arrow::before {
  border-bottom-color: #3762c0;
}
.tooltip-primary.bs-tooltip-left .arrow::before {
  border-left-color: #3762c0;
}

.tooltip-secondary .tooltip-inner {
  background: #8e94a9;
  color: #ffffff;
}
.tooltip-secondary.bs-tooltip-top .arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^="top"]
  .arrow::before {
  border-top-color: #8e94a9;
}
.tooltip-secondary.bs-tooltip-right .arrow::before {
  border-right-color: #8e94a9;
}
.tooltip-secondary.bs-tooltip-bottom .arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^="bottom"]
  .arrow::before {
  border-bottom-color: #8e94a9;
}
.tooltip-secondary.bs-tooltip-left .arrow::before {
  border-left-color: #8e94a9;
}

.tooltip-success .tooltip-inner {
  background: #44ce42;
  color: #ffffff;
}
.tooltip-success.bs-tooltip-top .arrow::before,
.tooltip-success.bs-tooltip-auto[data-popper-placement^="top"] .arrow::before {
  border-top-color: #44ce42;
}
.tooltip-success.bs-tooltip-right .arrow::before {
  border-right-color: #44ce42;
}
.tooltip-success.bs-tooltip-bottom .arrow::before,
.tooltip-success.bs-tooltip-auto[data-popper-placement^="bottom"]
  .arrow::before {
  border-bottom-color: #44ce42;
}
.tooltip-success.bs-tooltip-left .arrow::before {
  border-left-color: #44ce42;
}

.tooltip-info .tooltip-inner {
  background: #0062ff;
  color: #ffffff;
}
.tooltip-info.bs-tooltip-top .arrow::before,
.tooltip-info.bs-tooltip-auto[data-popper-placement^="top"] .arrow::before {
  border-top-color: #0062ff;
}
.tooltip-info.bs-tooltip-right .arrow::before {
  border-right-color: #0062ff;
}
.tooltip-info.bs-tooltip-bottom .arrow::before,
.tooltip-info.bs-tooltip-auto[data-popper-placement^="bottom"] .arrow::before {
  border-bottom-color: #0062ff;
}
.tooltip-info.bs-tooltip-left .arrow::before {
  border-left-color: #0062ff;
}

.tooltip-warning .tooltip-inner {
  background: #ffc542;
  color: #ffffff;
}
.tooltip-warning.bs-tooltip-top .arrow::before,
.tooltip-warning.bs-tooltip-auto[data-popper-placement^="top"] .arrow::before {
  border-top-color: #ffc542;
}
.tooltip-warning.bs-tooltip-right .arrow::before {
  border-right-color: #ffc542;
}
.tooltip-warning.bs-tooltip-bottom .arrow::before,
.tooltip-warning.bs-tooltip-auto[data-popper-placement^="bottom"]
  .arrow::before {
  border-bottom-color: #ffc542;
}
.tooltip-warning.bs-tooltip-left .arrow::before {
  border-left-color: #ffc542;
}

.tooltip-danger .tooltip-inner {
  background: #fc5a5a;
  color: #ffffff;
}
.tooltip-danger.bs-tooltip-top .arrow::before,
.tooltip-danger.bs-tooltip-auto[data-popper-placement^="top"] .arrow::before {
  border-top-color: #fc5a5a;
}
.tooltip-danger.bs-tooltip-right .arrow::before {
  border-right-color: #fc5a5a;
}
.tooltip-danger.bs-tooltip-bottom .arrow::before,
.tooltip-danger.bs-tooltip-auto[data-popper-placement^="bottom"]
  .arrow::before {
  border-bottom-color: #fc5a5a;
}
.tooltip-danger.bs-tooltip-left .arrow::before {
  border-left-color: #fc5a5a;
}

.tooltip-light .tooltip-inner {
  background: #aab2bd;
  color: #ffffff;
}
.tooltip-light.bs-tooltip-top .arrow::before,
.tooltip-light.bs-tooltip-auto[data-popper-placement^="top"] .arrow::before {
  border-top-color: #aab2bd;
}
.tooltip-light.bs-tooltip-right .arrow::before {
  border-right-color: #aab2bd;
}
.tooltip-light.bs-tooltip-bottom .arrow::before,
.tooltip-light.bs-tooltip-auto[data-popper-placement^="bottom"] .arrow::before {
  border-bottom-color: #aab2bd;
}
.tooltip-light.bs-tooltip-left .arrow::before {
  border-left-color: #aab2bd;
}

.tooltip-dark .tooltip-inner {
  background: #001737;
  color: #ffffff;
}
.tooltip-dark.bs-tooltip-top .arrow::before,
.tooltip-dark.bs-tooltip-auto[data-popper-placement^="top"] .arrow::before {
  border-top-color: #001737;
}
.tooltip-dark.bs-tooltip-right .arrow::before {
  border-right-color: #001737;
}
.tooltip-dark.bs-tooltip-bottom .arrow::before,
.tooltip-dark.bs-tooltip-auto[data-popper-placement^="bottom"] .arrow::before {
  border-bottom-color: #001737;
}
.tooltip-dark.bs-tooltip-left .arrow::before {
  border-left-color: #001737;
}

.profile-navbar .nav-item .nav-link {
  color: #a7afb7;
}
.profile-navbar .nav-item .nav-link.active {
  color: #0062ff;
}
.profile-navbar .nav-item .nav-link i {
  font-size: 1.25rem;
}

.profile-feed-item {
  padding: 1.5rem 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
}

/* Pricing table */
.pricing-table .pricing-card .pricing-card-body {
  padding: 50px 56px 43px 56px;
}
.pricing-table .pricing-card .pricing-card-body .plan-features {
  width: 100%;
  margin-bottom: 32px;
}
.pricing-table .pricing-card .pricing-card-body .plan-features li {
  text-align: left;
  padding: 4px 0px;
  
  font-size: 0.875rem;
}

/*-------------------------------------------------------------------*/
/* === Email === */
/* Mail Sidebar */
@media (max-width: 769px) {
  .email-wrapper .mail-sidebar {
    position: relative;
  }
}
@media (max-width: 575.98px) {
  .email-wrapper .mail-sidebar {
    position: fixed;
    z-index: 99;
    background: #ffffff;
    width: 45%;
    min-width: 300px;
    left: -100%;
    display: block;
    transition: 0.4s ease;
    -webkit-transition: 0.4s ease;
    -moz-transition: 0.4s ease;
  }
}
.email-wrapper .mail-sidebar .menu-bar {
  width: 100%;
  float: right;
  height: 100%;
  min-height: 100%;
}
@media (max-width: 575.98px) {
  .email-wrapper .mail-sidebar .menu-bar {
    min-height: 100vh;
    max-height: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.email-wrapper .mail-sidebar .menu-bar .menu-items {
  padding: 0;
  margin-bottom: 0;
  height: auto;
  list-style-type: none;
}
.email-wrapper .mail-sidebar .menu-bar .menu-items li {
  padding: 10px 15px;
  transition: 0.4s;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.email-wrapper .mail-sidebar .menu-bar .menu-items li:hover {
  background: rgba(240, 244, 249, 0.8);
}
.email-wrapper .mail-sidebar .menu-bar .menu-items li a {
  color: #303a40;
  font-size: 0.875rem;
  text-decoration: none;
}
.email-wrapper .mail-sidebar .menu-bar .menu-items li a i {
  margin-right: 8px;
  font-size: 0.875rem;
  line-height: 1.5;
}
.email-wrapper .mail-sidebar .menu-bar .menu-items li.active {
  background: #e6e9ed;
  border-radius: 4px;
}
.email-wrapper .mail-sidebar .menu-bar .menu-items li.active a {
  color: #0062ff;
}
.email-wrapper .mail-sidebar .menu-bar .menu-items li.compose:hover {
  background: transparent;
}
.email-wrapper .mail-sidebar .menu-bar .online-status {
  margin-top: 1rem;
}
.email-wrapper .mail-sidebar .menu-bar .online-status .chat {
  font-size: 0.875rem;
  color: #0062ff;
  margin-bottom: 0;
  
}
.email-wrapper .mail-sidebar .menu-bar .online-status .status {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: flex-start;
  transform: translateX(-43px) translateY(2px);
}
.email-wrapper .mail-sidebar .menu-bar .online-status .status:after {
  font-size: 12px;
  color: #2e383e;
  margin: -5px 0 0 18px;
}
.email-wrapper .mail-sidebar .menu-bar .online-status .status.offline {
  background: #fc5a5a;
}
.email-wrapper .mail-sidebar .menu-bar .online-status .status.offline:after {
  content: "Offline";
}
.email-wrapper .mail-sidebar .menu-bar .online-status .status.online {
  background: #44ce42;
}
.email-wrapper .mail-sidebar .menu-bar .online-status .status.online:after {
  content: "Online";
}
.email-wrapper .mail-sidebar .menu-bar .profile-list {
  padding: 10px 0;
}
.email-wrapper .mail-sidebar .menu-bar .profile-list-item {
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  padding: 6px 0;
  display: block;
}
.email-wrapper .mail-sidebar .menu-bar .profile-list-item:last-child {
  border-bottom: 0;
}
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a {
  text-decoration: none;
}
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .pro-pic {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  width: 20%;
  max-width: 40px;
}
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .pro-pic img {
  max-width: 100%;
  width: 100%;
  border-radius: 100%;
}
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user {
  width: 100%;
  padding: 5px 10px 0 15px;
}
.email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user .u-name {
  margin: 0;
  
  font-size: 0.875rem;
  line-height: 1;
}
.email-wrapper
  .mail-sidebar
  .menu-bar
  .profile-list-item
  a
  .user
  .u-designation {
  font-size: calc(0.875rem - 0.1rem);
  margin-bottom: 0;
}
.email-wrapper .sidebar.open {
  left: 0;
}

/* Mail List Container */
.email-wrapper .mail-list-container {
  border-left: 1px solid rgba(151, 151, 151, 0.3);
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}
.email-wrapper .mail-list-container a {
  text-decoration: none;
}
.email-wrapper .mail-list-container .mail-list {
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  width: 100%;
}
.email-wrapper .mail-list-container .mail-list:last-child {
  border-bottom: none;
}
.email-wrapper .mail-list-container .mail-list .form-check {
  margin-top: 12px;
  width: 11%;
  min-width: 20px;
}
.email-wrapper .mail-list-container .mail-list .content {
  width: 83%;
  padding-left: 0;
  padding-right: 0;
}
.email-wrapper .mail-list-container .mail-list .content .sender-name {
  margin-bottom: 0;
  font-size: 0.875rem;
  
  max-width: 95%;
}
.email-wrapper .mail-list-container .mail-list .content .message_text {
  margin: 0;
  max-width: 93%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.email-wrapper .mail-list-container .mail-list .details {
  width: 5.5%;
}
.email-wrapper .mail-list-container .mail-list .details .date {
  text-align: right;
  margin: auto 15px auto 0;
  white-space: nowrap;
}
.email-wrapper .mail-list-container .mail-list .details i {
  margin: auto 0;
  color: #ddd;
}
.email-wrapper .mail-list-container .mail-list .details i.favorite {
  color: #ffc542;
}
.email-wrapper .mail-list-container .mail-list.new_mail {
  background: #e6e9ed;
}
/* Message Content */
.email-wrapper .message-body .sender-details {
  padding: 20px 15px 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  display: -webkit-flex;
  display: flex;
}
.email-wrapper .message-body .sender-details .details {
  padding-bottom: 0;
}
.email-wrapper .message-body .sender-details .details .msg-subject {
  margin-bottom: 0;
  
}
.email-wrapper .message-body .sender-details .details .sender-email {
  margin-bottom: 20px;
  
}
.email-wrapper .message-body .sender-details .details .sender-email i {
  font-size: 1rem;
  
  margin: 0 1px 0 7px;
}
.email-wrapper .message-body .message-content {
  padding: 50px 15px;
}
.email-wrapper .message-body .attachments-sections ul {
  list-style: none;
  border-top: 1px solid rgba(151, 151, 151, 0.3);
  padding: 30px 15px 20px;
}
.email-wrapper .message-body .attachments-sections ul li {
  padding: 10px;
  margin-right: 20px;
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 5px;
}
.email-wrapper .message-body .attachments-sections ul li .thumb {
  display: inline-block;
  margin-right: 10px;
}
.email-wrapper .message-body .attachments-sections ul li .thumb i {
  font-size: 30px;
  margin: 0;
  color: #2e383e;
}
.email-wrapper .message-body .attachments-sections ul li .details p.file-name {
  display: block;
  margin-bottom: 0;
  color: #2e383e;
}
.email-wrapper
  .message-body
  .attachments-sections
  ul
  li
  .details
  .buttons
  .file-size {
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 13px;
}
.email-wrapper .message-body .attachments-sections ul li .details .buttons a {
  font-size: 13px;
  margin-right: 10px;
}
.email-wrapper
  .message-body
  .attachments-sections
  ul
  li
  .details
  .buttons
  a:last-child {
  margin-right: 0;
}

/*-------------------------------------------------------------------*/
/* === Plugin overrides === */
/* Ace Editor */
.ace_editor {
  margin: auto;
  height: 300px;
  width: 100%;
  font: 14px / normal SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}
.ace_editor .ace_content {
  font-size: 0.875rem;
}

/* Avgrund Popup */
.avgrund-popin {
  position: absolute;
  background: #fff;
  padding: 37px 33px;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  top: 50%;
  left: 50%;
  z-index: 1000;
  font-size: 0.875rem;
  
  font-weight: initial;
  line-height: 1.85;
  border-radius: 10px;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}
.avgrund-popin p {
  font-size: 0.875rem;
  
  font-weight: initial;
}

.avgrund-overlay {
  background: #000000;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
}

body.avgrund-ready,
.avgrund-ready .avgrund-popin,
.avgrund-ready .avgrund-overlay {
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: 0.3s all ease-out;
  -moz-transition: 0.3s all ease-out;
  -ms-transition: 0.3s all ease-out;
  -o-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
}

body.avgrund-active {
  overflow: hidden;
}

.avgrund-active .avgrund-popin {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.avgrund-active .avgrund-overlay {
  visibility: visible;
  opacity: 0.5;
  filter: alpha(opacity=50);
  height: 20000px;
}

.avgrund-popin.stack {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
}

.avgrund-active .avgrund-popin.stack {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

/* Optional close button styles */
.avgrund-close {
  display: block;
  color: #fff;
  background: #a7afb7;
  font-size: 10px;
  text-decoration: none;
  text-transform: uppercase;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 17px;
  width: 17px;
  text-align: center;
  line-height: 16px;
  border-radius: 50px;
}
.avgrund-close:hover {
  color: #fff;
  text-decoration: none;
}

/* Chartist */
.ct-series-a .ct-line {
  stroke: #a29afc;
  stroke-width: 3px;
  stroke-dasharray: 10px 20px;
}

.ct-series-a .ct-point {
  stroke: #6355f9;
  stroke-width: 10px;
  stroke-linecap: round;
}

.ct-series-b .ct-line {
  stroke: #80d691;
  stroke-width: 3px;
  stroke-dasharray: 10px 20px;
}

.ct-series-b .ct-point {
  stroke: #4ac462;
  stroke-width: 10px;
  stroke-linecap: round;
}

.ct-series-c .ct-line {
  stroke: #f7cb9b;
  stroke-width: 3px;
  stroke-dasharray: 10px 20px;
}

.ct-series-c .ct-point {
  stroke: #f2a859;
  stroke-width: 10px;
  stroke-linecap: round;
}

.ct-series-a .ct-bar {
  stroke: #a29afc;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre;
}

.ct-series-b .ct-bar {
  stroke: #80d691;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre;
}

.ct-series-c .ct-bar {
  stroke: #f7cb9b;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre;
}

.ct-series-d .ct-bar {
  stroke: #fcb2b2;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre;
}

.ct-series-a .ct-slice-pie {
  fill: #a29afc;
  stroke-width: 4px;
}

.ct-series-b .ct-slice-pie {
  fill: #80d691;
  stroke-width: 4px;
}

.ct-series-c .ct-slice-pie {
  fill: #fcb2b2;
  stroke-width: 4px;
}

.ct-series-d .ct-slice-pie {
  fill: #f7cb9b;
  stroke-width: 4px;
}

.ct-series-a .ct-slice-donut-solid {
  fill: #a29afc;
}

.ct-series-b .ct-slice-donut-solid {
  fill: #80d691;
}

.ct-series-c .ct-slice-donut-solid {
  fill: #fcb2b2;
}

.ct-series-a .ct-slice-donut-solid {
  fill: #f7cb9b;
}

#ct-chart-dash-barChart .ct-series-a .ct-bar {
  stroke: #fb9999;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre;
}
#ct-chart-dash-barChart .ct-series-b .ct-bar {
  stroke: #8b81fb;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre;
}
#ct-chart-dash-barChart .ct-series-c .ct-bar {
  stroke: #a29afc;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre;
}
#ct-chart-dash-barChart .ct-series-d .ct-bar {
  stroke: #b9b3fc;
  stroke-width: 20px;
  stroke-dasharray: 0;
  stroke-linecap: squre;
}

/* CodeMirror */
.CodeMirror {
  font-size: 0.875rem;
  
  height: auto;
  text-align: left;
  min-height: auto;
}

.CodeMirror-scroll {
  min-height: auto;
}

/* Colcade */
* {
  box-sizing: border-box;
}

.grid {
  border: 1px solid #e6e9ed;
}

.grid:after {
  display: block;
  content: "";
  clear: both;
}

.grid-col {
  float: left;
  width: 49%;
  margin-right: 2%;
  background: rgba(0, 98, 255, 0.2);
}

.grid-col--4 {
  margin-right: 0;
}

/* hide two middle */
.grid-col--2,
.grid-col--3 {
  display: none;
}

@media (min-width: 768px) {
  .grid-col {
    width: 32%;
  }
  .grid-col--2 {
    display: block;
  }
}
@media (min-width: 1200px) {
  .grid-col {
    width: 23.5%;
  }
  .grid-col--2,
  .grid-col--3 {
    display: block;
  }
}
.grid-item {
  background: #0062ff;
  margin-bottom: 20px;
}

.grid-item--a {
  height: 80px;
}

.grid-item--b {
  height: 140px;
}

.grid-item--c {
  height: 300px;
}

/* Colorpicker */
.asColorPicker-dropdown {
  max-width: initial;
}

.asColorPicker-trigger {
  height: auto;
  border: 0;
}
.asColorPicker-trigger span {
  border-radius: 2px;
  width: 35px;
}

.asColorPicker-clear {
  margin-top: 0.75rem;
}

/* Context Menu */
.context-menu-icon:before {
  color: #000000;
  font: normal normal normal 15px/1 "Material Design Icons";
}
.context-menu-icon.context-menu-icon-cut:before {
  content: "\f190";
}
.context-menu-icon.context-menu-icon-edit:before {
  content: "\f3ef";
}
.context-menu-icon.context-menu-icon-copy:before {
  content: "\f18f";
}
.context-menu-icon.context-menu-icon-paste:before {
  content: "\f613";
}
.context-menu-icon.context-menu-icon-delete:before {
  content: "\f6cb";
}
.context-menu-icon.context-menu-icon-quit:before {
  content: "\f156";
}

.context-menu-list {
  box-shadow: none;
  border: 1px solid rgba(151, 151, 151, 0.3);
}
.context-menu-list .context-menu-item span {
  color: #000000;
  font-size: 0.75rem;
  
}
.context-menu-list .context-menu-item.context-menu-hover {
  background: #000000;
}
.context-menu-list .context-menu-item.context-menu-hover span {
  color: #ffffff;
}
.context-menu-list
  .context-menu-item.context-menu-submenu
  .context-menu-list
  .context-menu-item
  span {
  color: #000000;
  font-size: 0.75rem;
  
}
.context-menu-list
  .context-menu-item.context-menu-submenu
  .context-menu-list
  .context-menu-item.context-menu-hover {
  background: #001737;
}
.context-menu-list
  .context-menu-item.context-menu-submenu
  .context-menu-list
  .context-menu-item.context-menu-hover
  span {
  color: #ffffff;
}

/* Data Tables */
.dataTables_wrapper {
  padding-left: 0;
  padding-right: 0;
}
.dataTables_wrapper label {
  font-size: 0.8125rem;
}
.dataTables_wrapper select {
  padding: 0.4rem;
}
.dataTables_wrapper .dataTables_length select {
  min-width: 65px;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-top: 0.25rem;
}
.dataTables_wrapper .dataTable .btn,
.dataTables_wrapper .dataTable .wizard > .actions a,
.wizard > .actions .dataTables_wrapper .dataTable a,
.dataTables_wrapper .dataTable .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .dataTables_wrapper .dataTable .swal2-styled,
.dataTables_wrapper .dataTable .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .dataTables_wrapper .dataTable .ajax-file-upload,
.dataTables_wrapper .dataTable .fc button,
.fc .dataTables_wrapper .dataTable button {
  padding: 0.4rem 1rem;
  vertical-align: top;
}
.dataTables_wrapper .dataTable thead th {
  border-bottom-width: 0;
}
.dataTables_wrapper .dataTable thead .sorting:before,
.dataTables_wrapper .dataTable thead .sorting:after,
.dataTables_wrapper .dataTable thead .sorting_asc:before,
.dataTables_wrapper .dataTable thead .sorting_asc:after,
.dataTables_wrapper .dataTable thead .sorting_desc:before,
.dataTables_wrapper .dataTable thead .sorting_desc:after,
.dataTables_wrapper .dataTable thead .sorting_asc_disabled:before,
.dataTables_wrapper .dataTable thead .sorting_asc_disabled:after,
.dataTables_wrapper .dataTable thead .sorting_desc_disabled:before,
.dataTables_wrapper .dataTable thead .sorting_desc_disabled:after {
  line-height: 2.5;
  font-family: Material Design Icons;
  font-size: 0.65rem;
}
.dataTables_wrapper .dataTable thead .sorting:before,
.dataTables_wrapper .dataTable thead .sorting_asc:before,
.dataTables_wrapper .dataTable thead .sorting_desc:before,
.dataTables_wrapper .dataTable thead .sorting_asc_disabled:before,
.dataTables_wrapper .dataTable thead .sorting_desc_disabled:before {
  content: "\f0045";
  right: 1.2em;
  top: 0;
}
.dataTables_wrapper .dataTable thead .sorting:after,
.dataTables_wrapper .dataTable thead .sorting_asc:after,
.dataTables_wrapper .dataTable thead .sorting_desc:after,
.dataTables_wrapper .dataTable thead .sorting_asc_disabled:after,
.dataTables_wrapper .dataTable thead .sorting_desc_disabled:after {
  content: "\f005d";
  right: 0.2em;
  top: 0;
}
.dataTables_wrapper .dataTables_paginate {
  margin-top: 20px;
}
.dataTables_wrapper .dataTables_info {
  font-size: 0.875rem;
}

/* Datepicker */
.datepicker.datepicker-dropdown,
.datepicker.datepicker-inline {
  padding: 0 25px;
  width: 30%;
  max-width: 500px;
  min-width: 250px;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  thead
  tr
  th,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  thead
  tr
  th {
  text-align: center;
  padding: 0.5rem 0;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.prev,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.prev {
  color: #434a54;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: left;
  background: #ffffff;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.datepicker-switch,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.datepicker-switch {
  color: #434a54;
  background: #ffffff;
  padding-bottom: 1rem;
  padding-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.next,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.next {
  color: grey;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: right;
  background: #ffffff;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.dow,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.dow {
  
  color: #434a54;
  font-size: 0.875rem;
  font-weight: initial;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.dow:first-child,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.dow:first-child {
  text-align: left;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.dow:last-child,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  thead
  tr
  th.dow:last-child {
  text-align: right;
}
.datepicker.datepicker-dropdown .datepicker-days table.table-condensed tbody,
.datepicker.datepicker-inline .datepicker-days table.table-condensed tbody {
  position: relative;
  top: 13px;
}
.datepicker.datepicker-dropdown .datepicker-days table.table-condensed tbody td,
.datepicker.datepicker-inline .datepicker-days table.table-condensed tbody td {
  text-align: center;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.day,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.day {
  font-size: 0.9375rem;
  padding: 0.5rem 0;
  color: #434a54;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.day:hover,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.day:hover {
  background: #ffffff;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.day.active,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.day.active {
  color: #fff;
  background: transparent;
  position: relative;
  z-index: 1;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.day.active:before,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.day.active:before {
  content: "";
  width: 38px;
  height: 38px;
  background: #44ce42;
  border-radius: 100%;
  display: block;
  margin: auto;
  vertical-align: middle;
  position: absolute;
  top: 1px;
  bottom: 1px;
  z-index: -1;
  left: 0;
  right: 0;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.day.today,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.day.today {
  color: #fff;
  background: transparent;
  position: relative;
  z-index: 1;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.day.today:before,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.day.today:before {
  content: "";
  width: 38px;
  height: 38px;
  background: #3762c0;
  border-radius: 100%;
  display: block;
  margin: auto;
  vertical-align: middle;
  position: absolute;
  top: 1px;
  bottom: 1px;
  z-index: -1;
  left: 0;
  right: 0;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.old.day,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.old.day {
  color: #d9dde3;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.range-start,
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.range-end,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.range-start,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.range-end {
  background: transparent;
  position: relative;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.range-start::before,
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.range-end::before,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.range-start::before,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.range-end::before {
  content: "";
  width: 28px;
  height: 28px;
  background: rgba(68, 206, 66, 0.2);
  border-radius: 4px;
  display: block;
  margin: auto;
  vertical-align: middle;
  position: absolute;
  top: 6px;
  z-index: -1;
  left: 0;
  right: 0;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.range,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.range {
  position: relative;
  background: transparent;
}
.datepicker.datepicker-dropdown
  .datepicker-days
  table.table-condensed
  tbody
  td.range::before,
.datepicker.datepicker-inline
  .datepicker-days
  table.table-condensed
  tbody
  td.range::before {
  content: "";
  width: 28px;
  height: 28px;
  background: #eee;
  border-radius: 4px;
  display: block;
  margin: auto;
  vertical-align: middle;
  position: absolute;
  top: 6px;
  z-index: -1;
  left: 0;
  right: 0;
}
.datepicker.datepicker-dropdown .datepicker-days,
.datepicker.datepicker-dropdown .datepicker-months,
.datepicker.datepicker-dropdown .datepicker-years,
.datepicker.datepicker-dropdown .datepicker-decades,
.datepicker.datepicker-dropdown .datepicker-centuries,
.datepicker.datepicker-inline .datepicker-days,
.datepicker.datepicker-inline .datepicker-months,
.datepicker.datepicker-inline .datepicker-years,
.datepicker.datepicker-inline .datepicker-decades,
.datepicker.datepicker-inline .datepicker-centuries {
  padding: 0.8rem 0.7rem;
}
.datepicker.datepicker-dropdown .datepicker-days table.table-condensed,
.datepicker.datepicker-dropdown .datepicker-months table.table-condensed,
.datepicker.datepicker-dropdown .datepicker-years table.table-condensed,
.datepicker.datepicker-dropdown .datepicker-decades table.table-condensed,
.datepicker.datepicker-dropdown .datepicker-centuries table.table-condensed,
.datepicker.datepicker-inline .datepicker-days table.table-condensed,
.datepicker.datepicker-inline .datepicker-months table.table-condensed,
.datepicker.datepicker-inline .datepicker-years table.table-condensed,
.datepicker.datepicker-inline .datepicker-decades table.table-condensed,
.datepicker.datepicker-inline .datepicker-centuries table.table-condensed {
  width: 100%;
}

.datepicker.datepicker-inline {
  width: 100%;
  max-width: 100%;
  min-width: 250px;
}
.datepicker.datepicker-inline thead tr th.prev {
  color: grey;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.datepicker.datepicker-inline thead tr th.datepicker-switch {
  color: #0062ff;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.datepicker.datepicker-inline thead tr th.next {
  color: grey;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.datepicker > div {
  display: initial;
}

.datepicker-custom {
  width: 100%;
  padding: 0;
}
.datepicker-custom .datepicker.datepicker-inline {
  display: block;
  padding: 0;
  height: 100%;
}
.datepicker-custom .datepicker.datepicker-inline .datepicker-days {
  display: block;
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  tbody
  tr
  td {
  padding: 1rem;
  text-align: center;
}
@media (max-width: 991px) {
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    tbody
    tr
    td {
    padding: 1rem 0.5rem;
  }
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  tbody
  tr
  td.day.today:before {
  top: 6px;
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  tbody
  tr
  td.day.active:before {
  top: 6px;
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  tbody
  tr
  td.day:first-child {
  padding-left: 2.5rem;
}
@media (max-width: 991px) {
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    tbody
    tr
    td.day:first-child {
    padding-left: 0.5rem;
  }
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  tbody
  tr
  td.day:first-child.today:before,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  tbody
  tr
  td.day:first-child.active:before {
  left: 1.5rem;
}
@media (max-width: 991px) {
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    tbody
    tr
    td.day:first-child.today:before,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    tbody
    tr
    td.day:first-child.active:before {
    left: 0.25rem;
  }
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  tbody
  tr
  td.day:last-child {
  padding-right: 2.5rem;
}
@media (max-width: 991px) {
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    tbody
    tr
    td.day:last-child {
    padding-right: 0.5rem;
  }
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  tbody
  tr
  td.day:last-child.today:before,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  tbody
  tr
  td.day:last-child.active:before {
  right: 1.5rem;
}
.datepicker-custom .datepicker.datepicker-inline .datepicker-days,
.datepicker-custom .datepicker.datepicker-inline .datepicker-months,
.datepicker-custom .datepicker.datepicker-inline .datepicker-years,
.datepicker-custom .datepicker.datepicker-inline .datepicker-decades,
.datepicker-custom .datepicker.datepicker-inline .datepicker-centuries {
  height: 100%;
  padding: 0 0 1rem 0;
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed {
  height: 100%;
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead {
  background: linear-gradient(to right, #84d9d2, #07cdae);
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.next,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.prev,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.datepicker-switch,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.dow,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.next,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.prev,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.datepicker-switch,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.dow,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.next,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.prev,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.datepicker-switch,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.dow,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.next,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.prev,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.datepicker-switch,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.dow,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.next,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.prev,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.datepicker-switch,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.dow {
  background: transparent;
  border-radius: 0;
  color: #ffffff;
  padding: 2rem 1rem;
  
}
@media (max-width: 991px) {
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    thead
    tr
    th.next,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    thead
    tr
    th.prev,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    thead
    tr
    th.datepicker-switch,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    thead
    tr
    th.dow,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-months
    .table-condensed
    thead
    tr
    th.next,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-months
    .table-condensed
    thead
    tr
    th.prev,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-months
    .table-condensed
    thead
    tr
    th.datepicker-switch,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-months
    .table-condensed
    thead
    tr
    th.dow,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-years
    .table-condensed
    thead
    tr
    th.next,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-years
    .table-condensed
    thead
    tr
    th.prev,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-years
    .table-condensed
    thead
    tr
    th.datepicker-switch,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-years
    .table-condensed
    thead
    tr
    th.dow,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-decades
    .table-condensed
    thead
    tr
    th.next,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-decades
    .table-condensed
    thead
    tr
    th.prev,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-decades
    .table-condensed
    thead
    tr
    th.datepicker-switch,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-decades
    .table-condensed
    thead
    tr
    th.dow,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-centuries
    .table-condensed
    thead
    tr
    th.next,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-centuries
    .table-condensed
    thead
    tr
    th.prev,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-centuries
    .table-condensed
    thead
    tr
    th.datepicker-switch,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-centuries
    .table-condensed
    thead
    tr
    th.dow {
    padding: 2rem 0.5rem;
  }
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.next:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.prev:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.datepicker-switch:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.dow:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.next:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.prev:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.datepicker-switch:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.dow:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.next:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.prev:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.datepicker-switch:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.dow:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.next:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.prev:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.datepicker-switch:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.dow:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.next:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.prev:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.datepicker-switch:first-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.dow:first-child {
  padding-left: 2.5rem;
}
@media (max-width: 991px) {
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    thead
    tr
    th.next:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    thead
    tr
    th.prev:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    thead
    tr
    th.datepicker-switch:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    thead
    tr
    th.dow:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-months
    .table-condensed
    thead
    tr
    th.next:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-months
    .table-condensed
    thead
    tr
    th.prev:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-months
    .table-condensed
    thead
    tr
    th.datepicker-switch:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-months
    .table-condensed
    thead
    tr
    th.dow:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-years
    .table-condensed
    thead
    tr
    th.next:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-years
    .table-condensed
    thead
    tr
    th.prev:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-years
    .table-condensed
    thead
    tr
    th.datepicker-switch:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-years
    .table-condensed
    thead
    tr
    th.dow:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-decades
    .table-condensed
    thead
    tr
    th.next:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-decades
    .table-condensed
    thead
    tr
    th.prev:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-decades
    .table-condensed
    thead
    tr
    th.datepicker-switch:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-decades
    .table-condensed
    thead
    tr
    th.dow:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-centuries
    .table-condensed
    thead
    tr
    th.next:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-centuries
    .table-condensed
    thead
    tr
    th.prev:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-centuries
    .table-condensed
    thead
    tr
    th.datepicker-switch:first-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-centuries
    .table-condensed
    thead
    tr
    th.dow:first-child {
    padding-left: 0.5rem;
  }
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.next:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.prev:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.datepicker-switch:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.dow:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.next:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.prev:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.datepicker-switch:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.dow:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.next:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.prev:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.datepicker-switch:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.dow:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.next:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.prev:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.datepicker-switch:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.dow:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.next:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.prev:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.datepicker-switch:last-child,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.dow:last-child {
  padding-right: 2.5rem;
}
@media (max-width: 991px) {
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    thead
    tr
    th.next:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    thead
    tr
    th.prev:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    thead
    tr
    th.datepicker-switch:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    thead
    tr
    th.dow:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-months
    .table-condensed
    thead
    tr
    th.next:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-months
    .table-condensed
    thead
    tr
    th.prev:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-months
    .table-condensed
    thead
    tr
    th.datepicker-switch:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-months
    .table-condensed
    thead
    tr
    th.dow:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-years
    .table-condensed
    thead
    tr
    th.next:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-years
    .table-condensed
    thead
    tr
    th.prev:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-years
    .table-condensed
    thead
    tr
    th.datepicker-switch:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-years
    .table-condensed
    thead
    tr
    th.dow:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-decades
    .table-condensed
    thead
    tr
    th.next:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-decades
    .table-condensed
    thead
    tr
    th.prev:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-decades
    .table-condensed
    thead
    tr
    th.datepicker-switch:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-decades
    .table-condensed
    thead
    tr
    th.dow:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-centuries
    .table-condensed
    thead
    tr
    th.next:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-centuries
    .table-condensed
    thead
    tr
    th.prev:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-centuries
    .table-condensed
    thead
    tr
    th.datepicker-switch:last-child,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-centuries
    .table-condensed
    thead
    tr
    th.dow:last-child {
    padding-right: 0.5rem;
  }
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.datepicker-switch,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.datepicker-switch,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.datepicker-switch,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.datepicker-switch,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.datepicker-switch {
  font-size: 2rem;
}
@media (max-width: 991px) {
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-days
    .table-condensed
    thead
    tr
    th.datepicker-switch,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-months
    .table-condensed
    thead
    tr
    th.datepicker-switch,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-years
    .table-condensed
    thead
    tr
    th.datepicker-switch,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-decades
    .table-condensed
    thead
    tr
    th.datepicker-switch,
  .datepicker-custom
    .datepicker.datepicker-inline
    .datepicker-centuries
    .table-condensed
    thead
    tr
    th.datepicker-switch {
    font-size: 1rem;
  }
}
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.next,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-days
  .table-condensed
  thead
  tr
  th.prev,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.next,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-months
  .table-condensed
  thead
  tr
  th.prev,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.next,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-years
  .table-condensed
  thead
  tr
  th.prev,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.next,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-decades
  .table-condensed
  thead
  tr
  th.prev,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.next,
.datepicker-custom
  .datepicker.datepicker-inline
  .datepicker-centuries
  .table-condensed
  thead
  tr
  th.prev {
  font-size: 1.5rem;
}

/* Dropify */
.dropify-wrapper {
  border: 2px dashed rgba(151, 151, 151, 0.3);
  border-radius: 7px;
}
.dropify-wrapper:hover {
  background-size: 30px 30px;
  background-image: -webkit-linear-gradient(
    135deg,
    #f2f7f8 25%,
    transparent 25%,
    transparent 50%,
    #f2f7f8 50%,
    #f2f7f8 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    135deg,
    #f2f7f8 25%,
    transparent 25%,
    transparent 50%,
    #f2f7f8 50%,
    #f2f7f8 75%,
    transparent 75%,
    transparent
  );
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;
}
.dropify-wrapper .dropify-message span.file-icon:before {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  content: "\f552";
  color: #a7afb7;
  font-size: 1.25rem;
}
.dropify-wrapper .dropify-message p {
  font-size: 0.8125rem;
  color: #a7afb7;
}
.dropify-wrapper .dropify-preview .dropify-infos {
  background: rgba(255, 255, 255, 0.9);
}
.dropify-wrapper
  .dropify-preview
  .dropify-infos
  .dropify-infos-inner
  .dropify-filename {
  color: #0062ff;
  font-size: 20px;
  font-weight: 400;
}
.dropify-wrapper
  .dropify-preview
  .dropify-infos
  .dropify-infos-inner
  .dropify-infos-message {
  color: #333;
  font-weight: 500;
}
.dropify-wrapper .dropify-preview .dropify-render img {
  opacity: 0.7;
}
.dropify-wrapper .dropify-clear {
  background: #fff;
  border: none;
  font-weight: 700;
  color: #434a54;
}

/* Dropzone */
.dropzone {
  border: 1px solid rgba(151, 151, 151, 0.3);
  min-height: 200px;
}
.dropzone .dz-message {
  text-align: center;
  width: 100%;
}
.dropzone .dz-message span {
  font-size: 0.8125rem;
  color: #a7afb7;
}
.dropzone .dz-preview {
  margin: 5px;
}
.dropzone .dz-preview .dz-image {
  width: 63px;
  height: 63px;
  border-radius: 6px;
}
.dropzone .dz-preview .dz-error-message {
  top: 80px;
  left: -35px;
}
.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
  margin-left: -17px;
}
.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
  width: 35px;
  height: 35px;
}

/* Flot chart */
.flot-chart-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 100%;
}

.flot-chart {
  width: 100%;
  position: relative;
  max-width: none;
  height: 400px;
}
.flot-chart canvas {
  position: absolute;
  top: 0;
  bottom: 0;
}

/* Full Calendar */
.fc button {
  height: auto;
  background-image: none;
  text-shadow: none;
  
  background-color: #ffffff;
  border-color: rgba(151, 151, 151, 0.3);
  text-transform: capitalize;
  color: #a8b2b9;
}
.fc button .fc-icon-right-single-arrow:after,
.fc button .fc-icon-left-single-arrow:after {
  color: #a8b2b9;
  font: normal normal normal 20px/1 "Material Design Icons";
}
.fc button .fc-icon-right-single-arrow:after {
  content: "\f0142";
}
.fc button .fc-icon-left-single-arrow:after {
  content: "\f0141";
}
.fc .fc-button-group .fc-button {
  background-color: #ffffff;
  margin: 0;
}
.fc .fc-button-group .fc-button:hover {
  background-color: #434a54 !important;
}
.fc .fc-button-group .fc-button.fc-state-active {
  color: #fc5a5a;
}
.fc .fc-today-button:hover {
  background-color: #434a54 !important;
}
.fc .fc-header-toolbar {
  margin-top: 2rem;
}
.fc .fc-header-toolbar .fc-center h2,
.fc .fc-header-toolbar .fc-center .h2 {
  font-size: 1rem;
}
.fc .fc-widget-header {
  border: 0;
}
.fc .fc-widget-header table tr th {
  border-width: 0 0 1px 0;
  text-align: right;
  padding: 1rem;
}
.fc .fc-widget-header table tr th span {
  font-size: 10px;
  text-transform: uppercase;
  color: #a8b2b9;
  letter-spacing: 1px;
}
.fc .fc-content-skeleton table tr td {
  padding: 0.5rem;
  font-size: 0.875rem;
}
.fc .fc-event {
  border: 0;
  color: #ffffff;
  padding: 0.5rem;
}
.fc .fc-event.fc-start {
  background: #0062ff;
  border-radius: 4px;
  border-left: 4px solid #0062ff;
}
.fc .fc-event.fc-not-start,
.fc .fc-event.fc-not-end {
  background: #fc5a5a;
  border-left: 4px solid #fc5a5a;
  padding-left: 0.5rem;
}
.fc .fc-event.fc-not-end {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fc .fc-event .fc-title,
.fc .fc-event .fc-time {
  color: #ffffff;
}

.fc-external-events .fc-event {
  background: #ffffff;
  color: #000000;
  border-width: 0 0 0 4px;
  padding: 16px;
  border-radius: 0;
  margin-bottom: 10px;
}
.fc-external-events .fc-event:nth-child(1) {
  border-color: #0062ff;
}
.fc-external-events .fc-event:nth-child(2) {
  border-color: #fc5a5a;
}
.fc-external-events .fc-event:nth-child(3) {
  border-color: #44ce42;
}
.fc-external-events .fc-event:nth-child(4) {
  border-color: #3762c0;
}
.fc-external-events .fc-event:nth-child(4) {
  border-color: #ffc542;
}

/* Google Charts */
.google-chart-container .google-charts {
  width: 100%;
  height: 280px;
  min-height: 280px;
  min-width: 100%;
}

/* Icheck */
.icheck,
.icheck-flat,
.icheck-square,
.icheck-line {
  margin-bottom: 0.5rem;
}
.icheck label,
.icheck-flat label,
.icheck-square label,
.icheck-line label {
  font-size: 0.8125rem;
  line-height: 1.5;
  margin-left: 5px;
  margin-bottom: 0;
}

/* Jquery File Upload */
.ajax-upload-dragdrop {
  border-color: rgba(151, 151, 151, 0.3);
  border-style: dotted;
  max-width: 100%;
}
.ajax-upload-dragdrop span b {
  font-size: 0.8125rem;
  color: #a7afb7;
  font-weight: initial;
}
.ajax-upload-dragdrop .ajax-file-upload {
  background: #3762c0;
  box-shadow: none;
  height: auto;
}

.ajax-file-upload-container {
  min-height: 100px;
}
.ajax-file-upload-container .ajax-file-upload-statusbar {
  max-width: 100%;
}

/* Js-grid */
.jsgrid .jsgrid-button {
  /* background-image: url(../../images/sprites/jsgrid-icons.png); */
}
.jsgrid .jsgrid-grid-header {
  border: 1px solid rgba(151, 151, 151, 0.3);
}
.jsgrid .jsgrid-table th {
  font-weight: initial;
  
}
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"] {
  padding: 0.56rem 0;
}
.jsgrid .jsgrid-header-sort:before {
  margin-top: 10px;
  float: right;
}
.jsgrid .jsgrid-header-sort-asc:before {
  border-color: transparent transparent #a7afb7;
}
.jsgrid .jsgrid-header-sort-desc:before {
  border-color: #a7afb7 transparent transparent;
}
.jsgrid .jsgrid-pager {
  line-height: 2;
}
.jsgrid .jsgrid-pager-current-page {
  padding: 0.5rem 0.75rem;
  font-weight: initial;
  line-height: 1.25;
}

/* Jvectormap */
.jvectormap-container {
  background: #ffffff;
}

/* Light Gallery */
.lightGallery {
  width: 100%;
  margin: 0;
}
.lightGallery .image-tile {
  position: relative;
  margin-bottom: 30px;
}
.lightGallery .image-tile .demo-gallery-poster {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.lightGallery .image-tile .demo-gallery-poster img {
  display: block;
  margin: auto;
  width: 40%;
  max-width: 60px;
  min-width: 20px;
}
.lightGallery .image-tile img {
  max-width: 100%;
  width: 100%;
}

/* Listify */
.listify-list input {
  border: 1px solid #f2f7f8;
  color: #aab2bd;
  background: #fff;
}
.listify-list ul.list {
  list-style: none;
  padding-left: 0;
}
.listify-list ul.list li {
  display: block;
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  padding: 15px 10px;
}
.listify-list ul.list li h5,
.listify-list ul.list li .h5 {
  color: #3762c0;
}
.listify-list ul.list li p {
  color: #aab2bd;
  margin: 0;
}

/* No-ui-slider */
.noUi-target {
  border: none;
  box-shadow: none;
  border-radius: 0px;
}
.noUi-target .noUi-handle {
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
.noUi-target .noUi-handle:before {
  display: none;
}
.noUi-target .noUi-handle:after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  left: 4px;
  top: 4px;
}
.noUi-target.noUi-horizontal {
  height: 0.25rem;
}
.noUi-target.noUi-horizontal .noUi-handle {
  top: -8px;
}
.noUi-target.noUi-vertical {
  width: 0.25rem;
  height: 156px;
  display: inline-block;
}
.noUi-target.noUi-vertical .noUi-handle {
  left: -7px;
}
.noUi-target .noUi-base {
  background: #e6e9ed;
  box-shadow: none;
  border: none;
}
.noUi-target .noUi-base .noUi-connect {
  background: #0062ff;
  box-shadow: none;
}
.noUi-target .noUi-base .noUi-origin {
  background: #44ce42;
}
.noUi-target .noUi-pips .noUi-marker-horizontal,
.noUi-target .noUi-pips .noUi-marker-vertical {
  background: rgba(151, 151, 151, 0.3);
}
.noUi-target .noUi-pips .noUi-marker-horizontal {
  height: 9px;
  width: 1px;
}
.noUi-target .noUi-pips .noUi-marker-vertical {
  height: 1px;
  width: 9px;
}
.noUi-target .noUi-pips .noUi-value {
  color: #000000;
  font-size: 0.94rem;
  
}

/* Slider Color variations */
.slider-primary .noUi-base .noUi-connect {
  background: #3762c0;
}
.slider-primary .noUi-base .noUi-origin {
  background: #3762c0;
}
.slider-primary .noUi-base .noUi-origin .noUi-handle:after {
  background: #3762c0;
}
.slider-primary .noUi-base .noUi-origin .noUi-handle .noUi-tooltip {
  background: #3762c0;
  color: #ffffff;
}

.slider-secondary .noUi-base .noUi-connect {
  background: #8e94a9;
}
.slider-secondary .noUi-base .noUi-origin {
  background: #8e94a9;
}
.slider-secondary .noUi-base .noUi-origin .noUi-handle:after {
  background: #8e94a9;
}
.slider-secondary .noUi-base .noUi-origin .noUi-handle .noUi-tooltip {
  background: #8e94a9;
  color: #ffffff;
}

.slider-success .noUi-base .noUi-connect {
  background: #44ce42;
}
.slider-success .noUi-base .noUi-origin {
  background: #44ce42;
}
.slider-success .noUi-base .noUi-origin .noUi-handle:after {
  background: #44ce42;
}
.slider-success .noUi-base .noUi-origin .noUi-handle .noUi-tooltip {
  background: #44ce42;
  color: #ffffff;
}

.slider-info .noUi-base .noUi-connect {
  background: #0062ff;
}
.slider-info .noUi-base .noUi-origin {
  background: #0062ff;
}
.slider-info .noUi-base .noUi-origin .noUi-handle:after {
  background: #0062ff;
}
.slider-info .noUi-base .noUi-origin .noUi-handle .noUi-tooltip {
  background: #0062ff;
  color: #ffffff;
}

.slider-warning .noUi-base .noUi-connect {
  background: #ffc542;
}
.slider-warning .noUi-base .noUi-origin {
  background: #ffc542;
}
.slider-warning .noUi-base .noUi-origin .noUi-handle:after {
  background: #ffc542;
}
.slider-warning .noUi-base .noUi-origin .noUi-handle .noUi-tooltip {
  background: #ffc542;
  color: #ffffff;
}

.slider-danger .noUi-base .noUi-connect {
  background: #fc5a5a;
}
.slider-danger .noUi-base .noUi-origin {
  background: #fc5a5a;
}
.slider-danger .noUi-base .noUi-origin .noUi-handle:after {
  background: #fc5a5a;
}
.slider-danger .noUi-base .noUi-origin .noUi-handle .noUi-tooltip {
  background: #fc5a5a;
  color: #ffffff;
}

.slider-light .noUi-base .noUi-connect {
  background: #aab2bd;
}
.slider-light .noUi-base .noUi-origin {
  background: #aab2bd;
}
.slider-light .noUi-base .noUi-origin .noUi-handle:after {
  background: #aab2bd;
}
.slider-light .noUi-base .noUi-origin .noUi-handle .noUi-tooltip {
  background: #aab2bd;
  color: #ffffff;
}

.slider-dark .noUi-base .noUi-connect {
  background: #001737;
}
.slider-dark .noUi-base .noUi-origin {
  background: #001737;
}
.slider-dark .noUi-base .noUi-origin .noUi-handle:after {
  background: #001737;
}
.slider-dark .noUi-base .noUi-origin .noUi-handle .noUi-tooltip {
  background: #001737;
  color: #ffffff;
}

/* Owl-carousel */
.owl-carousel img {
  border-radius: 4px;
}
.owl-carousel.full-width .owl-nav {
  position: absolute;
  top: 30%;
  width: 100%;
  margin-top: 0;
}
.owl-carousel.full-width .owl-nav .owl-prev,
.owl-carousel.full-width .owl-nav .owl-next {
  background: transparent;
  color: #ffffff;
}
.owl-carousel.full-width .owl-nav .owl-prev i:before,
.owl-carousel.full-width .owl-nav .owl-next i:before {
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 2;
}
.owl-carousel.full-width .owl-nav .owl-prev {
  float: left;
}
.owl-carousel.full-width .owl-nav .owl-next {
  float: right;
}
.owl-carousel.full-width .owl-dots {
  margin-top: 1rem;
}
.owl-carousel .item-video {
  width: 200px;
  height: 200px;
}

/* Progressbar-js */
.progress-bar-js-line {
  height: 6px;
}

.progressbar-js-circle {
  width: 100%;
  position: relative;
}

/* Pws-tabs */
.pws_tabs_container ul.pws_tabs_controll {
  border-bottom: 1px solid #0062ff;
}
.pws_tabs_container ul.pws_tabs_controll li:first-child a {
  border-radius: 5px 0px 0px 0px;
}
.pws_tabs_container ul.pws_tabs_controll li:last-child a {
  border-right: 1px solid #0062ff;
  border-radius: 0px 5px 0px 0px;
}
.pws_tabs_container ul.pws_tabs_controll li a {
  border: 1px solid #0062ff;
  border-bottom: none;
  border-right: none;
  background: #fff;
  color: #0062ff;
  padding: 0.6em 1.3em;
  margin-right: 0;
}
.pws_tabs_container ul.pws_tabs_controll li a:hover {
  background: #fff;
  color: #0062ff;
}
.pws_tabs_container ul.pws_tabs_controll li a.pws_tab_active {
  background: linear-gradient(to right, #da8cff, #9a55ff);
  color: #fff;
}
.pws_tabs_container .demo-tabs {
  border: 1px solid #0062ff;
  border-radius: 0px 5px 5px 5px;
  overflow: hidden;
  margin-bottom: 45px;
  padding-top: 3.5rem;
}
.pws_tabs_container .demo-tabs .CodeMirror {
  margin-bottom: 20px;
}
.pws_tabs_container.pws_tabs_vertical .pws_tabs_controll {
  border-right: none;
  border-bottom: none;
}
.pws_tabs_container.pws_tabs_vertical .pws_tabs_controll li:first-child a {
  border-radius: 5px 0px 0px 0px;
}
.pws_tabs_container.pws_tabs_vertical .pws_tabs_controll li:last-child a {
  border-bottom: 1px solid #0062ff;
  border-radius: 0px 0px 0px 5px;
  border-right: 0px;
}
.pws_tabs_container.pws_tabs_vertical .pws_tabs_controll li a {
  border-radius: 0px;
  border: 1px solid #0062ff;
  border-right: none;
  border-bottom: none;
  margin-bottom: 0;
}
.pws_tabs_container.pws_tabs_horizontal_bottom .pws_tabs_list {
  margin-bottom: 0;
}
.pws_tabs_container.pws_tabs_horizontal_bottom .pws_tabs_controll {
  border-top: 1px solid #0062ff;
  border-right: none;
  border-bottom: none;
}
.pws_tabs_container.pws_tabs_horizontal_bottom
  .pws_tabs_controll
  li:first-child
  a {
  border-radius: 0px 0px 0px 5px;
}
.pws_tabs_container.pws_tabs_horizontal_bottom
  .pws_tabs_controll
  li:last-child
  a {
  border-radius: 0px 0px 5px 0px;
  border-right: 1px solid #0062ff;
}
.pws_tabs_container.pws_tabs_horizontal_bottom .pws_tabs_controll li a {
  border-radius: 0px;
  border: 1px solid #0062ff;
  border-top: none;
  border-right: none;
  margin-right: 0;
  margin-bottom: 0;
}
.pws_tabs_container.pws_tabs_rtl .pws_tabs_controll li:first-child a {
  border-radius: 0px 5px 0px 0px;
  border-right: 1px solid #0062ff;
}
.pws_tabs_container.pws_tabs_rtl .pws_tabs_controll li:last-child a {
  border-right: none;
  border-radius: 5px 0px 0px 0px;
}
.pws_tabs_container.pws_tabs_rtl .pws_tabs_controll li a {
  border: 1px solid #0062ff;
  border-bottom: none;
  border-right: none;
  margin-right: 0;
  margin-left: 0;
}

/* Quill Editor */
.quill-container {
  height: 300px;
}

.ql-toolbar.ql-snow,
.quill-container.ql-snow {
  border-color: rgba(151, 151, 151, 0.3);
}

/* Rating */
.br-theme-fontawesome-stars .br-widget a,
.br-theme-css-stars .br-widget a {
  font-size: 20px;
}
.br-theme-fontawesome-stars .br-widget a.br-selected:after,
.br-theme-fontawesome-stars .br-widget a.br-active:after,
.br-theme-css-stars .br-widget a.br-selected:after,
.br-theme-css-stars .br-widget a.br-active:after {
  color: #0062ff;
}

.br-theme-bars-1to10 .br-widget a,
.br-theme-bars-movie .br-widget a,
.br-theme-bars-pill .br-widget a,
.br-theme-bars-reversed .br-widget a,
.br-theme-bars-horizontal .br-widget a {
  background-color: rgba(0, 98, 255, 0.6);
  color: #0062ff;
}
.br-theme-bars-1to10 .br-widget a.br-selected,
.br-theme-bars-1to10 .br-widget a.br-active,
.br-theme-bars-movie .br-widget a.br-selected,
.br-theme-bars-movie .br-widget a.br-active,
.br-theme-bars-pill .br-widget a.br-selected,
.br-theme-bars-pill .br-widget a.br-active,
.br-theme-bars-reversed .br-widget a.br-selected,
.br-theme-bars-reversed .br-widget a.br-active,
.br-theme-bars-horizontal .br-widget a.br-selected,
.br-theme-bars-horizontal .br-widget a.br-active {
  background-color: #0062ff;
}

.br-theme-bars-square .br-widget a {
  border-color: rgba(0, 98, 255, 0.6);
  color: rgba(0, 98, 255, 0.6);
}
.br-theme-bars-square .br-widget a.br-selected,
.br-theme-bars-square .br-widget a.br-active {
  border-color: #0062ff;
  color: #0062ff;
}

.br-theme-bars-movie .br-widget .br-current-rating,
.br-theme-bars-reversed .br-widget .br-current-rating,
.br-theme-bars-1to10 .br-widget .br-current-rating,
.br-theme-bars-horizontal .br-widget .br-current-rating {
  color: #a7afb7;
  font-size: 0.875rem;
  font-weight: initial;
}

.br-theme-bars-1to10 {
  height: 26px;
}
.br-theme-bars-1to10 .br-widget {
  height: 24px;
}
.br-theme-bars-1to10 .br-widget .br-current-rating {
  line-height: 1;
}

.br-theme-bars-pill .br-widget a {
  line-height: 2.5;
}

/* Select2 */
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #0062ff;
}
.select2-container--default .select2-selection--single,
.select2-container--default .select2-dropdown,
.select2-container--default .select2-selection--multiple {
  border-color: rgba(151, 151, 151, 0.3);
}
.select2-container--default .select2-selection--single .select2-search__field,
.select2-container--default .select2-dropdown .select2-search__field,
.select2-container--default
  .select2-selection--multiple
  .select2-search__field {
  border-color: rgba(151, 151, 151, 0.3);
}
.select2-container--default .select2-selection--single {
  height: auto;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 14px;
}
.select2-container--default .select2-dropdown {
  font-size: 0.8125rem;
}
.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border-color: rgba(151, 151, 151, 0.3);
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  color: #ffffff;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 0.625rem;
  font-family: inherit;
  line-height: 1;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice
  .select2-selection__choice__remove {
  color: #ffffff;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice:nth-child(5n + 1) {
  background: #0062ff;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice:nth-child(5n + 2) {
  background: #44ce42;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice:nth-child(5n + 3) {
  background: #3762c0;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice:nth-child(5n + 4) {
  background: #fc5a5a;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice:nth-child(5n + 5) {
  background: #ffc542;
}
.select2-container--default .select2-selection__arrow {
  padding-top: 50px;
}

/* Summernote Editor */
.note-editor.note-frame {
  border: 1px solid rgba(151, 151, 151, 0.3);
}

.note-popover {
  border: 1px solid rgba(151, 151, 151, 0.3);
}

/* SweetAlert */
.swal2-container {
  z-index: 1500;
}

.swal2-modal {
  min-height: 315px;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  flex-direction: column;
  border-radius: 10px;
}
.swal2-modal .swal2-title {
  font-size: 25px;
  line-height: 1;
  color: #a7afb7;
  font-weight: initial;
  margin-bottom: 0;
}
.swal2-modal .swal2-icon,
.swal2-modal .swal2-success-ring {
  margin-top: 0;
  margin-bottom: 42px;
}
.swal2-modal .swal2-buttonswrapper {
  margin-top: 0;
  padding: 0;
}
.swal2-modal .swal2-buttonswrapper .swal2-styled {
  margin-top: 0;
  font-weight: initial;
}
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-confirm {
  font-weight: initial;
  margin-top: 32px;
}
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-cancel {
  margin-top: 32px;
  border: 1px solid rgba(151, 151, 151, 0.3);
  color: #ffffff;
  font-weight: initial;
}
.swal2-modal .swal2-content {
  font-size: 0.875rem;
  color: #a7afb7;
  font-weight: initial;
  margin-top: 11px;
}
.swal2-modal .swal2-close {
  font-size: 20px;
}
.swal2-modal .swal2-success-ring {
  left: -30px;
}

/* Switchery */
.switchery {
  width: 35px;
  height: 21px;
  border-radius: 11px;
}
.switchery > small,
.switchery > .small {
  width: 20px;
  height: 20px;
}

.switchery-small {
  width: 25px;
  height: 13px;
}
.switchery-small > small,
.switchery-small > .small {
  width: 13px;
  height: 13px;
}

.switchery-large {
  width: 50px;
  height: 28px;
  border-radius: 14px;
}
.switchery-large > small,
.switchery-large > .small {
  width: 27px;
  height: 27px;
}

/* Tags */
div.tagsinput {
  padding: 15px 15px 10px;
  border-color: rgba(151, 151, 151, 0.3);
}
div.tagsinput span.tag {
  background: #0062ff;
  border: 0;
  color: #ffffff;
  padding: 6px 14px;
  font-size: 0.8125rem;
  font-family: inherit;
  line-height: 1;
}
div.tagsinput span.tag a {
  color: #ffffff;
}

/* TinyMCE Editor */
.mce-tinymce.mce-panel,
.mce-tinymce .mce-panel {
  border-color: rgba(151, 151, 151, 0.3);
}

/* Toast */
.jq-toast-wrap .jq-icon-success {
  background-color: #44ce42;
}
.jq-toast-wrap .jq-icon-info {
  background-color: #0062ff;
}
.jq-toast-wrap .jq-icon-warning {
  background-color: #ffc542;
}
.jq-toast-wrap .jq-icon-error {
  background-color: #fc5a5a;
}

/* Typeahead */
.tt-menu,
.gist {
  text-align: left;
}

.twitter-typeahead {
  max-width: 100%;
}

.typeahead {
  background-color: #ffffff;
}
.typeahead:focus {
  border-color: rgba(151, 151, 151, 0.3);
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-menu {
  width: 100%;
  margin: 12px 0;
  padding: 8px 0;
  background-color: #ffffff;
  border: 1px solid rgba(151, 151, 151, 0.3);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: inherit;
}
.tt-suggestion:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #0062ff;
}
.tt-suggestion .tt-cursor {
  color: #ffffff;
  background-color: #0062ff;
}
.tt-suggestion p {
  margin: 0;
}

/* Wysi Editor */
.wysi-editor #toolbar [data-wysihtml-action] {
  float: right;
}
.wysi-editor #toolbar,
.wysi-editor textarea {
  width: 920px;
  padding: 5px;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.wysi-editor textarea {
  height: 280px;
  border: 1px solid rgba(151, 151, 151, 0.3);
  font-family: inherit;
  font-size: 1rem;
}
.wysi-editor textarea:focus {
  color: black;
  border: 1px solid rgba(151, 151, 151, 0.3);
}
.wysi-editor .wysihtml-action-active,
.wysi-editor .wysihtml-command-active {
  font-weight: bold;
}
.wysi-editor [data-wysihtml-dialog] {
  margin: 5px 0 0;
  padding: 5px;
  border: 1px solid #666;
}
.wysi-editor a[data-wysihtml-command-value="red"] {
  color: #fc5a5a;
}
.wysi-editor a[data-wysihtml-command-value="green"] {
  color: #44ce42;
}
.wysi-editor a[data-wysihtml-command-value="blue"] {
  color: #3762c0;
}
.wysi-editor .wysihtml-editor,
.wysi-editor .wysihtml-editor table td {
  outline: 1px dotted rgba(151, 151, 151, 0.3);
}
.wysi-editor code {
  background: #ddd;
  padding: 10px;
  white-space: pre;
  display: block;
  margin: 1em 0;
}
.wysi-editor .toolbar {
  display: block;
  border-radius: 3px;
  border: 1px solid #fff;
  margin-bottom: 9px;
  line-height: 1em;
}
.wysi-editor .toolbar a {
  display: inline-block;
  height: 1.5em;
  border-radius: 3px;
  font-size: 1rem;
  line-height: 1.5em;
  text-decoration: none;
  background: #ffffff;
  border: 1px solid rgba(151, 151, 151, 0.3);
  padding: 0 0.2em;
  margin: 1px 0;
  color: #0062ff;
}
.wysi-editor .toolbar .wysihtml-action-active,
.wysi-editor .toolbar a.wysihtml-command-active {
  background: #222;
  color: white;
}
.wysi-editor .toolbar .block {
  padding: 1px;
  display: inline-block;
  background: #eee;
  border-radius: 3px;
  margin: 0 1px 1px 0;
}
.wysi-editor div[data-wysihtml-dialog="createTable"] {
  position: absolute;
  background: white;
}
.wysi-editor div[data-wysihtml-dialog="createTable"] td {
  width: 10px;
  height: 5px;
  border: 1px solid rgba(151, 151, 151, 0.3);
}
.wysi-editor .wysihtml-editor table td.wysiwyg-tmp-selected-cell {
  outline: 2px solid #44ce42;
}
.wysi-editor .editor-container-tag {
  padding: 5px 10px;
  position: absolute;
  color: white;
  background: rgba(0, 0, 0, 0.8);
  width: 100px;
  margin-left: -50px;
  -webkit-transition: 0.1s left, 0.1s top;
}
.wysi-editor .wrap {
  max-width: 700px;
  margin: 40px;
}
.wysi-editor .editable .wysihtml-uneditable-container {
  outline: 1px dotted rgba(151, 151, 151, 0.3);
  position: relative;
}
.wysi-editor .editable .wysihtml-uneditable-container-right {
  float: right;
  width: 50%;
  margin-left: 2em;
  margin-bottom: 1em;
}
.wysi-editor .editable .wysihtml-uneditable-container-left {
  float: left;
  width: 50%;
  margin-right: 2em;
  margin-bottom: 1em;
}

/* X-editable */
.editable-form .editable {
  color: #a7afb7;
  font-size: 0.8125rem;
}
.editable-form .editable-click {
  border-color: #a7afb7;
}

.editable-container.editable-inline {
  max-width: 100%;
}
.editable-container.editable-inline .editableform {
  max-width: 100%;
}
.editable-container.editable-inline .editableform .control-group {
  max-width: 100%;
  white-space: initial;
}
.editable-container.editable-inline .editableform .control-group > div {
  max-width: 100%;
}
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  input,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  textarea {
  max-width: 100%;
}
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  .form-control,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  .asColorPicker-input,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  .dataTables_wrapper
  select,
.dataTables_wrapper
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  select,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  input[type="text"],
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  select,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  input[type="number"],
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  .select2-container--default
  .select2-selection--single,
.select2-container--default
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  .select2-selection--single,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  .select2-search__field,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  .typeahead,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  .tt-query,
.editable-container.editable-inline
  .editableform
  .control-group
  .editable-input
  .combodate
  .tt-hint {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 991px) {
  .editable-container.editable-inline
    .editableform
    .control-group
    .editable-buttons {
    display: block;
    margin-top: 10px;
  }
}
/* Wizard */
.wizard {
  display: block;
  width: 100%;
  overflow: hidden;
}
.wizard a {
  outline: 0;
}
.wizard ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.wizard ul > li {
  display: block;
  padding: 0;
}
.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}
.wizard > .steps .current-info {
  position: absolute;
  left: -999em;
}
.wizard > .steps .number {
  font-size: 0.875rem;
}
.wizard > .steps > ul > li {
  width: 25%;
  float: left;
  text-align: center;
}
@media (max-width: 767px) {
  .wizard > .steps > ul > li {
    width: 50%;
  }
}
.wizard > .steps a {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 0.875rem;
}
.wizard > .steps a:hover {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.wizard > .steps a:active {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.wizard > .steps .disabled a {
  background: #e6e9ed;
  color: #434a54;
  cursor: default;
}
.wizard > .steps .disabled a:hover {
  background: #e6e9ed;
  color: #434a54;
  cursor: default;
}
.wizard > .steps .disabled a:active {
  background: #e6e9ed;
  color: #434a54;
  cursor: default;
}
.wizard > .steps .current a {
  background: #0062ff;
  color: #ffffff;
  cursor: default;
}
.wizard > .steps .current a:hover {
  background: #0062ff;
  color: #ffffff;
  cursor: default;
}
.wizard > .steps .current a:active {
  background: #0062ff;
  color: #ffffff;
  cursor: default;
}
.wizard > .steps .done a {
  background: #66a1ff;
  color: #ffffff;
}
.wizard > .steps .done a:hover {
  background: #66a1ff;
  color: #ffffff;
}
.wizard > .steps .done a:active {
  background: #66a1ff;
  color: #ffffff;
}
.wizard > .steps .error a {
  background: #fc5a5a;
  color: #fff;
}
.wizard > .steps .error a:hover {
  background: #fc5a5a;
  color: #fff;
}
.wizard > .steps .error a:active {
  background: #fc5a5a;
  color: #fff;
}
.wizard > .content {
  background: #f0f1f6;
  display: block;
  margin: 0.5em;
  min-height: 24em;
  overflow: hidden;
  position: relative;
  width: auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.wizard > .content > .title {
  position: absolute;
  left: -999em;
}
.wizard > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
  overflow-y: auto;
}
.wizard > .content > .body ul {
  list-style: disc;
}
.wizard > .content > .body ul > li {
  display: list-item;
}
.wizard > .content > .body > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}
.wizard > .content > .body input {
  display: block;
  border: 1px solid rgba(151, 151, 151, 0.3);
}
.wizard > .content > .body input[type="checkbox"] {
  display: inline-block;
}
.wizard > .content > .body input.error {
  background: rgb(251, 227, 228);
  border: 1px solid #febebe;
  color: #fc5a5a;
}
.wizard > .content > .body label {
  display: inline-block;
  margin-bottom: 0.5em;
}
.wizard > .content > .body label.error {
  color: #fc5a5a;
  display: inline-block;
  margin-left: 1.5em;
}
.wizard > .actions {
  padding-top: 20px;
  position: relative;
  display: block;
  text-align: right;
  width: 100%;
}
.wizard > .actions > ul {
  display: inline-block;
  text-align: right;
}
.wizard > .actions > ul > li {
  float: left;
  margin: 0 0.5em;
}
.wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 30%;
}
@media (max-width: 767px) {
  .wizard.vertical > .steps {
    width: 42%;
  }
}
.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%;
  text-align: left;
}
.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% 0.5em 2.5%;
  width: 65%;
}
@media (max-width: 767px) {
  .wizard.vertical > .content {
    width: 52%;
  }
}
.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%;
}
@media (max-width: 767px) {
  .wizard.vertical > .actions {
    margin: 0;
    width: 100%;
  }
}
.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em;
}

.tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden;
}
.tabcontrol a {
  outline: 0;
}
.tabcontrol ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.tabcontrol ul > li {
  display: block;
  padding: 0;
}
.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%;
}
.tabcontrol > .steps .current-info {
  position: absolute;
  left: -999em;
}
.tabcontrol > .steps > ul {
  position: relative;
  margin: 6px 0 0 0;
  top: 1px;
  z-index: 1;
}
.tabcontrol > .steps > ul > li {
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.tabcontrol > .steps > ul > li:hover {
  background: #edecec;
  border: 1px solid rgba(151, 151, 151, 0.3);
  padding: 0;
}
.tabcontrol > .steps > ul > li > a {
  color: #aab2bd;
  display: inline-block;
  border: 0 none;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none;
}
.tabcontrol > .steps > ul > li > a:hover {
  text-decoration: none;
}
.tabcontrol > .steps > ul > li.current {
  background: #ffffff;
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-bottom: 0 none;
  padding: 0 0 1px 0;
  margin-top: 0;
}
.tabcontrol > .steps > ul > li.current > a {
  padding: 15px 30px 10px 30px;
}
.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 35em;
  overflow: hidden;
  border-top: 1px solid rgba(151, 151, 151, 0.3);
  padding-top: 20px;
}
.tabcontrol > .content > .title {
  position: absolute;
  left: -999em;
}
.tabcontrol > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}
.tabcontrol > .content > .body ul {
  list-style: disc;
}
.tabcontrol > .content > .body ul > li {
  display: list-item;
}

/* Accordions */
.accordion .card {
  margin-bottom: 0.75rem;
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
  border-radius: 0.25rem;
}
.accordion .card .card-header {
  background-color: transparent;
  border: none;
  padding: 2rem;
}
.accordion .card .card-header * {
  
  font-size: 1rem;
}
.accordion .card .card-header a {
  display: block;
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  position: relative;
  -webkit-transition: color 0.5s ease;
  -moz-transition: color 0.5s ease;
  -ms-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
  padding-right: 0.5rem;
}
.accordion .card .card-header a:before {
  font-family: "Material Design Icons";
  position: absolute;
  right: 7px;
  top: 0;
  font-size: 18px;
  display: block;
}
.accordion .card .card-header a[aria-expanded="true"] {
  color: #0062ff;
}
.accordion .card .card-header a[aria-expanded="true"]:before {
  content: "\f0374";
}
.accordion .card .card-header a[aria-expanded="false"]:before {
  content: "\f0415";
}
.accordion .card .card-body {
  font-size: 14px;
  padding: 0 2rem 2rem 2rem;
  
  line-height: 1.5;
}
.accordion .card .card-body i {
  font-size: 1.25rem;
}
.accordion.accordion-bordered {
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
}
.accordion.accordion-bordered .card {
  margin: 0 2rem;
  border-top: 1px solid rgba(151, 151, 151, 0.3);
  box-shadow: none;
  border-radius: 0;
}
.accordion.accordion-bordered .card .card-header,
.accordion.accordion-bordered .card .card-body {
  padding-left: 0;
  padding-right: 0;
}
.accordion.accordion-bordered .card .card-header a:before {
  color: #fc5a5a;
}
.accordion.accordion-bordered .card .card-header a[aria-expanded="true"] {
  color: inherit;
}
.accordion.accordion-bordered
  .card
  .card-header
  a[aria-expanded="true"]:before {
  content: "\f0062";
}
.accordion.accordion-bordered
  .card
  .card-header
  a[aria-expanded="false"]:before {
  content: "\f004a";
}
.accordion.accordion-bordered .card:first-child {
  border-top: 0;
}
.accordion.accordion-filled .card {
  padding: 0;
}
.accordion.accordion-filled .card .card-header {
  padding: 0;
}
.accordion.accordion-filled .card .card-header a {
  padding: 2rem 4.5rem 2rem 2rem;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.accordion.accordion-filled .card .card-header a:before {
  top: 40%;
  right: 40px;
}
.accordion.accordion-filled .card .card-header a[aria-expanded="true"] {
  background: #3762c0;
  color: #ffffff;
}
.accordion.accordion-filled .card .card-header a[aria-expanded="true"]:before {
  content: "\f0143";
  color: #ffffff;
}
.accordion.accordion-filled .card .card-header a[aria-expanded="false"]:before {
  content: "\f0140";
}
.accordion.accordion-filled .card .card-body {
  padding: 0 2rem 2rem 2rem;
  background: #3762c0;
  color: #ffffff;
}
.accordion.accordion-solid-header .card {
  padding: 0;
}
.accordion.accordion-solid-header .card .card-header {
  padding: 0;
}
.accordion.accordion-solid-header .card .card-header a {
  padding: 2rem 4.5rem 2rem 2rem;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.accordion.accordion-solid-header .card .card-header a:before {
  top: 40%;
  right: 40px;
}
.accordion.accordion-solid-header .card .card-header a[aria-expanded="true"] {
  background: #0062ff;
  color: #ffffff;
}
.accordion.accordion-solid-header
  .card
  .card-header
  a[aria-expanded="true"]:before {
  content: "\f0143";
  color: #ffffff;
}
.accordion.accordion-solid-header
  .card
  .card-header
  a[aria-expanded="false"]:before {
  content: "\f0140";
}
.accordion.accordion-solid-header .card .card-body {
  padding: 2rem;
}
.accordion.accordion-solid-content .card {
  padding: 0;
}
.accordion.accordion-solid-content .card .card-header {
  padding: 0;
}
.accordion.accordion-solid-content .card .card-header a {
  padding: 2rem 4.5rem 2rem 2rem;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.accordion.accordion-solid-content .card .card-header a:before {
  top: 40%;
  right: 40px;
}
.accordion.accordion-solid-content .card .card-header a[aria-expanded="true"] {
  color: inherit;
}
.accordion.accordion-solid-content
  .card
  .card-header
  a[aria-expanded="true"]:before {
  content: "\f0143";
}
.accordion.accordion-solid-content
  .card
  .card-header
  a[aria-expanded="false"]:before {
  content: "\f0140";
}
.accordion.accordion-solid-content .card .card-body {
  padding: 2rem;
  background: #44ce42;
  color: #ffffff;
}
.accordion.accordion-multi-colored .card .card-header,
.accordion.accordion-multi-colored .card .card-body {
  background: transparent;
  color: #ffffff;
}
.accordion.accordion-multi-colored .card:nth-child(1) {
  background: #0062ff;
}
.accordion.accordion-multi-colored .card:nth-child(2) {
  background: #44ce42;
}
.accordion.accordion-multi-colored .card:nth-child(3) {
  background: #fc5a5a;
}
.accordion.accordion-multi-colored .card:nth-child(4) {
  background: #ffc542;
}
.accordion.accordion-multi-colored .card:nth-child(5) {
  background: #3762c0;
}
.accordion.accordion-multi-colored .card .card-header a[aria-expanded="true"] {
  color: inherit;
}
.accordion.accordion-multi-colored
  .card
  .card-header
  a[aria-expanded="true"]:before {
  content: "\f0143";
}
.accordion.accordion-multi-colored
  .card
  .card-header
  a[aria-expanded="false"]:before {
  content: "\f0140";
}

/* Dropdowns */
.dropdown .dropdown-toggle:after {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  font: normal normal normal 24px/1 "Material Design Icons";
  content: "\f035d";
  width: auto;
  height: auto;
  vertical-align: baseline;
  font-size: 0.75rem;
}
.rtl .dropdown .dropdown-toggle:after {
  margin-left: 0;
  margin-right: 0.255em;
}
.dropdown .dropdown-menu {
  margin-top: 0.75rem;
  font-size: 0.875rem;
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
  border-radius: 0;
}
.dropdown .dropdown-menu .dropdown-item {
  font-size: 1rem;
  padding: 0.25rem 1.5rem;
}
.dropdown .dropdown-menu .dropdown-item:active {
  background: initial;
}
.dropdown.dropdown-arrow-none .dropdown-toggle:after {
  display: none;
}
.rtl .dropdown .dropdown-header {
  text-align: right;
}
.rtl .dropdown .dropdown-item {
  text-align: right;
}

/* Forms */
.form-group {
  margin-bottom: 1.75rem;
}

.input-group-append,
.input-group-prepend {
  color: #00406a;
  width: auto;
  border: none;
}
.input-group-append .input-group-text,
.input-group-prepend .input-group-text {
  border-color: rgba(151, 151, 151, 0.3);
  padding: 0 10px;
  color: #00406a;
  height: 100%;
}
.rtl .input-group-append .input-group-text,
.rtl .input-group-prepend .input-group-text {
  padding: 0.94rem 1rem 0.94rem 0.5rem;
}
.input-group-append .btn,
.input-group-append .fc button,
.fc .input-group-append button,
.input-group-append .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .input-group-append .ajax-file-upload,
.input-group-append .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .input-group-append .swal2-styled,
.input-group-append .wizard > .actions a,
.wizard > .actions .input-group-append a,
.input-group-prepend .btn,
.input-group-prepend .fc button,
.fc .input-group-prepend button,
.input-group-prepend .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .input-group-prepend .ajax-file-upload,
.input-group-prepend .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .input-group-prepend .swal2-styled,
.input-group-prepend .wizard > .actions a,
.wizard > .actions .input-group-prepend a {
  height: 100%;
}

.form-control,
.asColorPicker-input,
.dataTables_wrapper select,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"],
.jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"],
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-search__field,
.typeahead,
.tt-query,
.tt-hint {
  border: 1px solid rgba(151, 151, 151, 0.3);
  
  font-size: 0.8125rem;
}

.form-select {
  border: 1px solid rgba(151, 151, 151, 0.3);
  
}

select.form-control,
select.asColorPicker-input,
.dataTables_wrapper select,
.jsgrid .jsgrid-table .jsgrid-filter-row select,
.select2-container--default select.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.select2-search__field,
select.typeahead,
select.tt-query,
select.tt-hint {
  padding: 1rem 0.75rem;
  border: 0;
  outline: 1px solid rgba(151, 151, 151, 0.3);
  color: #00406a;
  appearance: none;
  /* background: url("../../../assets/images/arrow-down.svg"); */
  background-repeat: no-repeat;
  background-position: 99% center;
}
select.form-control:focus,
select.asColorPicker-input:focus,
.dataTables_wrapper select:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select:focus,
.select2-container--default select.select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  select.select2-search__field:focus,
select.typeahead:focus,
select.tt-query:focus,
select.tt-hint:focus {
  outline: 1px solid rgba(151, 151, 151, 0.3);
}
select.form-control.border-primary,
select.border-primary.asColorPicker-input,
.dataTables_wrapper select.border-primary,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-primary,
.select2-container--default select.border-primary.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-primary.select2-search__field,
select.border-primary.typeahead,
select.border-primary.tt-query,
select.border-primary.tt-hint {
  outline: 1px solid #3762c0;
}
select.form-control.border-primary:focus,
select.border-primary.asColorPicker-input:focus,
.dataTables_wrapper select.border-primary:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-primary:focus,
.select2-container--default
  select.border-primary.select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  select.border-primary.select2-search__field:focus,
select.border-primary.typeahead:focus,
select.border-primary.tt-query:focus,
select.border-primary.tt-hint:focus {
  outline: 1px solid #3762c0;
}
select.form-control.border-secondary,
select.border-secondary.asColorPicker-input,
.dataTables_wrapper select.border-secondary,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-secondary,
.select2-container--default select.border-secondary.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-secondary.select2-search__field,
select.border-secondary.typeahead,
select.border-secondary.tt-query,
select.border-secondary.tt-hint,
select.form-control.loader-demo-box,
select.asColorPicker-input.loader-demo-box,
.dataTables_wrapper select.loader-demo-box,
.jsgrid .jsgrid-table .jsgrid-filter-row select.loader-demo-box,
.select2-container--default select.select2-selection--single.loader-demo-box,
.select2-container--default
  .select2-selection--single
  select.select2-search__field.loader-demo-box,
select.typeahead.loader-demo-box,
select.tt-query.loader-demo-box,
select.tt-hint.loader-demo-box {
  outline: 1px solid #8e94a9;
}
select.form-control.border-secondary:focus,
select.border-secondary.asColorPicker-input:focus,
.dataTables_wrapper select.border-secondary:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-secondary:focus,
.select2-container--default
  select.border-secondary.select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  select.border-secondary.select2-search__field:focus,
select.border-secondary.typeahead:focus,
select.border-secondary.tt-query:focus,
select.border-secondary.tt-hint:focus,
select.form-control.loader-demo-box:focus,
select.asColorPicker-input.loader-demo-box:focus,
.dataTables_wrapper select.loader-demo-box:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.loader-demo-box:focus,
.select2-container--default
  select.select2-selection--single.loader-demo-box:focus,
.select2-container--default
  .select2-selection--single
  select.select2-search__field.loader-demo-box:focus,
select.typeahead.loader-demo-box:focus,
select.tt-query.loader-demo-box:focus,
select.tt-hint.loader-demo-box:focus {
  outline: 1px solid #8e94a9;
}
select.form-control.border-success,
select.border-success.asColorPicker-input,
.dataTables_wrapper select.border-success,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-success,
.select2-container--default select.border-success.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-success.select2-search__field,
select.border-success.typeahead,
select.border-success.tt-query,
select.border-success.tt-hint,
.swal2-modal
  .swal2-buttonswrapper
  select.form-control.swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  select.asColorPicker-input.swal2-styled.swal2-confirm,
.dataTables_wrapper
  .swal2-modal
  .swal2-buttonswrapper
  select.swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .dataTables_wrapper
  select.swal2-styled.swal2-confirm,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .swal2-modal
  .swal2-buttonswrapper
  select.swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select.swal2-styled.swal2-confirm,
.select2-container--default
  .swal2-modal
  .swal2-buttonswrapper
  select.select2-selection--single.swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .select2-container--default
  select.select2-selection--single.swal2-styled.swal2-confirm,
.select2-container--default
  .select2-selection--single
  .swal2-modal
  .swal2-buttonswrapper
  select.select2-search__field.swal2-styled.swal2-confirm,
.swal2-modal
  .swal2-buttonswrapper
  .select2-container--default
  .select2-selection--single
  select.select2-search__field.swal2-styled.swal2-confirm,
.swal2-modal .swal2-buttonswrapper select.typeahead.swal2-styled.swal2-confirm,
.swal2-modal .swal2-buttonswrapper select.tt-query.swal2-styled.swal2-confirm,
.swal2-modal .swal2-buttonswrapper select.tt-hint.swal2-styled.swal2-confirm {
  outline: 1px solid #44ce42;
}
select.form-control.border-success:focus,
select.border-success.asColorPicker-input:focus,
.dataTables_wrapper select.border-success:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-success:focus,
.select2-container--default
  select.border-success.select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  select.border-success.select2-search__field:focus,
select.border-success.typeahead:focus,
select.border-success.tt-query:focus,
select.border-success.tt-hint:focus,
.swal2-modal
  .swal2-buttonswrapper
  select.form-control.swal2-styled.swal2-confirm:focus,
.swal2-modal
  .swal2-buttonswrapper
  select.asColorPicker-input.swal2-styled.swal2-confirm:focus,
.dataTables_wrapper
  .swal2-modal
  .swal2-buttonswrapper
  select.swal2-styled.swal2-confirm:focus,
.swal2-modal
  .swal2-buttonswrapper
  .dataTables_wrapper
  select.swal2-styled.swal2-confirm:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .swal2-modal
  .swal2-buttonswrapper
  select.swal2-styled.swal2-confirm:focus,
.swal2-modal
  .swal2-buttonswrapper
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select.swal2-styled.swal2-confirm:focus,
.select2-container--default
  .swal2-modal
  .swal2-buttonswrapper
  select.select2-selection--single.swal2-styled.swal2-confirm:focus,
.swal2-modal
  .swal2-buttonswrapper
  .select2-container--default
  select.select2-selection--single.swal2-styled.swal2-confirm:focus,
.select2-container--default
  .select2-selection--single
  .swal2-modal
  .swal2-buttonswrapper
  select.select2-search__field.swal2-styled.swal2-confirm:focus,
.swal2-modal
  .swal2-buttonswrapper
  .select2-container--default
  .select2-selection--single
  select.select2-search__field.swal2-styled.swal2-confirm:focus,
.swal2-modal
  .swal2-buttonswrapper
  select.typeahead.swal2-styled.swal2-confirm:focus,
.swal2-modal
  .swal2-buttonswrapper
  select.tt-query.swal2-styled.swal2-confirm:focus,
.swal2-modal
  .swal2-buttonswrapper
  select.tt-hint.swal2-styled.swal2-confirm:focus {
  outline: 1px solid #44ce42;
}
select.form-control.border-info,
select.border-info.asColorPicker-input,
.dataTables_wrapper select.border-info,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-info,
.select2-container--default select.border-info.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-info.select2-search__field,
select.border-info.typeahead,
select.border-info.tt-query,
select.border-info.tt-hint {
  outline: 1px solid #0062ff;
}
select.form-control.border-info:focus,
select.border-info.asColorPicker-input:focus,
.dataTables_wrapper select.border-info:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-info:focus,
.select2-container--default select.border-info.select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  select.border-info.select2-search__field:focus,
select.border-info.typeahead:focus,
select.border-info.tt-query:focus,
select.border-info.tt-hint:focus {
  outline: 1px solid #0062ff;
}
select.form-control.border-warning,
select.border-warning.asColorPicker-input,
.dataTables_wrapper select.border-warning,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-warning,
.select2-container--default select.border-warning.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-warning.select2-search__field,
select.border-warning.typeahead,
select.border-warning.tt-query,
select.border-warning.tt-hint {
  outline: 1px solid #ffc542;
}
select.form-control.border-warning:focus,
select.border-warning.asColorPicker-input:focus,
.dataTables_wrapper select.border-warning:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-warning:focus,
.select2-container--default
  select.border-warning.select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  select.border-warning.select2-search__field:focus,
select.border-warning.typeahead:focus,
select.border-warning.tt-query:focus,
select.border-warning.tt-hint:focus {
  outline: 1px solid #ffc542;
}
select.form-control.border-danger,
select.border-danger.asColorPicker-input,
.dataTables_wrapper select.border-danger,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-danger,
.select2-container--default select.border-danger.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-danger.select2-search__field,
select.border-danger.typeahead,
select.border-danger.tt-query,
select.border-danger.tt-hint {
  outline: 1px solid #fc5a5a;
}
select.form-control.border-danger:focus,
select.border-danger.asColorPicker-input:focus,
.dataTables_wrapper select.border-danger:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-danger:focus,
.select2-container--default
  select.border-danger.select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  select.border-danger.select2-search__field:focus,
select.border-danger.typeahead:focus,
select.border-danger.tt-query:focus,
select.border-danger.tt-hint:focus {
  outline: 1px solid #fc5a5a;
}
select.form-control.border-light,
select.border-light.asColorPicker-input,
.dataTables_wrapper select.border-light,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-light,
.select2-container--default select.border-light.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-light.select2-search__field,
select.border-light.typeahead,
select.border-light.tt-query,
select.border-light.tt-hint {
  outline: 1px solid #aab2bd;
}
select.form-control.border-light:focus,
select.border-light.asColorPicker-input:focus,
.dataTables_wrapper select.border-light:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-light:focus,
.select2-container--default select.border-light.select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  select.border-light.select2-search__field:focus,
select.border-light.typeahead:focus,
select.border-light.tt-query:focus,
select.border-light.tt-hint:focus {
  outline: 1px solid #aab2bd;
}
select.form-control.border-dark,
select.border-dark.asColorPicker-input,
.dataTables_wrapper select.border-dark,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-dark,
.select2-container--default select.border-dark.select2-selection--single,
.select2-container--default
  .select2-selection--single
  select.border-dark.select2-search__field,
select.border-dark.typeahead,
select.border-dark.tt-query,
select.border-dark.tt-hint {
  outline: 1px solid #001737;
}
select.form-control.border-dark:focus,
select.border-dark.asColorPicker-input:focus,
.dataTables_wrapper select.border-dark:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-dark:focus,
.select2-container--default select.border-dark.select2-selection--single:focus,
.select2-container--default
  .select2-selection--single
  select.border-dark.select2-search__field:focus,
select.border-dark.typeahead:focus,
select.border-dark.tt-query:focus,
select.border-dark.tt-hint:focus {
  outline: 1px solid #001737;
}
select.form-select {
  padding: 0.4375rem 0.75rem;
  border: 0;
  outline: 1px solid rgba(151, 151, 151, 0.3);
  color: #00406a;
  appearance: none;
  /* background: url("../../../assets/images/arrow-down.svg"); */
  background-repeat: no-repeat;
  background-position: 99% center;
}
select.form-select:focus {
  outline: 1px solid rgba(151, 151, 151, 0.3);
}
select.form-select.border-primary {
  outline: 1px solid #3762c0;
}
select.form-select.border-primary:focus {
  outline: 1px solid #3762c0;
}
select.form-select.border-secondary,
select.form-select.loader-demo-box {
  outline: 1px solid #8e94a9;
}
select.form-select.border-secondary:focus,
select.form-select.loader-demo-box:focus {
  outline: 1px solid #8e94a9;
}
select.form-select.border-success,
.swal2-modal
  .swal2-buttonswrapper
  select.form-select.swal2-styled.swal2-confirm {
  outline: 1px solid #44ce42;
}
select.form-select.border-success:focus,
.swal2-modal
  .swal2-buttonswrapper
  select.form-select.swal2-styled.swal2-confirm:focus {
  outline: 1px solid #44ce42;
}
select.form-select.border-info {
  outline: 1px solid #0062ff;
}
select.form-select.border-info:focus {
  outline: 1px solid #0062ff;
}
select.form-select.border-warning {
  outline: 1px solid #ffc542;
}
select.form-select.border-warning:focus {
  outline: 1px solid #ffc542;
}
select.form-select.border-danger {
  outline: 1px solid #fc5a5a;
}
select.form-select.border-danger:focus {
  outline: 1px solid #fc5a5a;
}
select.form-select.border-light {
  outline: 1px solid #aab2bd;
}
select.form-select.border-light:focus {
  outline: 1px solid #aab2bd;
}
select.form-select.border-dark {
  outline: 1px solid #001737;
}
select.form-select.border-dark:focus {
  outline: 1px solid #001737;
}

.form-group label {
  font-size: 0.875rem;
  line-height: 1;
  vertical-align: top;
  margin-bottom: 0.5rem;
}
.form-group.has-danger .form-control,
.form-group.has-danger .asColorPicker-input,
.form-group.has-danger .dataTables_wrapper select,
.dataTables_wrapper .form-group.has-danger select,
.form-group.has-danger
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-group.has-danger
  input[type="text"],
.form-group.has-danger .jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-filter-row .form-group.has-danger select,
.form-group.has-danger
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-group.has-danger
  input[type="number"],
.form-group.has-danger .select2-container--default .select2-selection--single,
.select2-container--default .form-group.has-danger .select2-selection--single,
.form-group.has-danger
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .form-group.has-danger
  .select2-search__field,
.form-group.has-danger .typeahead,
.form-group.has-danger .tt-query,
.form-group.has-danger .tt-hint {
  border-color: #fc5a5a;
}
.form-group .file-upload-default {
  visibility: hidden;
  position: absolute;
}
.form-group .file-upload-info {
  background: transparent;
}

.form-control-sm {
  height: 2.875rem;
}

/* Icons */
.icons-list {
  border-left: 1px solid rgba(151, 151, 151, 0.3);
  border-top: 1px solid rgba(151, 151, 151, 0.3);
}
.icons-list > div {
  background: #ffffff;
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  border-right: 1px solid rgba(151, 151, 151, 0.3);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px 15px;
  
  font-size: 0.875rem;
}
.icons-list > div i {
  display: inline-block;
  font-size: 20px;
  width: 40px;
  text-align: left;
  color: #0062ff;
}

/* Lists */
ul,
ol,
dl {
  padding-left: 1rem;
  font-size: 0.875rem;
}
ul li,
ol li,
dl li {
  line-height: 1.8;
}

.list-ticked,
.list-arrow,
.list-star {
  list-style: none;
  padding: 0;
}
.list-ticked li,
.list-arrow li,
.list-star li {
  padding-left: 1.5rem;
}
.list-ticked li:before,
.list-arrow li:before,
.list-star li:before {
  font-family: "Material Design Icons";
  margin-left: -1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}

.list-ticked li:before {
  content: "\f012d";
  color: #fc5a5a;
}

.list-arrow li:before {
  content: "\f0142";
  color: #44ce42;
}

.list-star li:before {
  content: "\f04ce";
  color: #ffc542;
}

.gradient-bullet-list {
  padding-left: 0;
}
.rtl .gradient-bullet-list {
  padding-right: 0;
}
.gradient-bullet-list li {
  position: relative;
  list-style-type: none;
  padding-left: 25px;
  line-height: 1;
  padding-bottom: 25px;
}
.gradient-bullet-list li:before,
.gradient-bullet-list li:after {
  content: "";
  position: absolute;
}
.gradient-bullet-list li:before {
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 100%;
}
.gradient-bullet-list li:after {
  width: 11px;
  height: 11px;
  top: 2px;
  left: 2px;
  background: #fff;
  border-radius: 100%;
}
.gradient-bullet-list li:nth-child(1):before {
  background: linear-gradient(to right, #ffbf96, #fe7096);
}
.gradient-bullet-list li:nth-child(2):before {
  background: linear-gradient(to right, #90caf9, #047edf 99%);
}
.gradient-bullet-list li:nth-child(3):before {
  background: linear-gradient(to right, #f6e384, #ffd500);
}
.gradient-bullet-list li:nth-child(4):before {
  background: linear-gradient(to right, #84d9d2, #07cdae);
}
.gradient-bullet-list li:nth-child(5):before {
  background: linear-gradient(to right, #da8cff, #9a55ff);
}

.bullet-line-list {
  padding-left: 30px;
  margin-bottom: 0;
  position: relative;
  list-style-type: none;
}
.rtl .bullet-line-list {
  padding-right: 0px;
}
.bullet-line-list li {
  position: relative;
  line-height: 1;
  padding-bottom: 10px;
}
.bullet-line-list li:before {
  content: "";
  position: absolute;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  left: -28px;
  top: 6px;
  border: 3px solid #0062ff;
  margin-right: 15px;
  z-index: 2;
}
.bullet-line-list li:after {
  content: "";
  border: 1px solid rgba(151, 151, 151, 0.3);
  position: absolute;
  bottom: 0;
  left: -23px;
  height: 100%;
}
.bullet-line-list li:first-child:after {
  content: "";
  height: 80%;
}
.bullet-line-list li:last-child {
  padding-bottom: 0;
}
.bullet-line-list li:last-child:after {
  content: "";
  top: 0;
  height: 30%;
}

/* Pagination */
.pagination .page-item .page-link,
.jsgrid .jsgrid-pager .page-item .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-page a {
  border-color: rgba(151, 151, 151, 0.3);
  color: #a7afb7;
  font-size: 0.875rem;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.pagination .page-item .page-link:focus,
.jsgrid .jsgrid-pager .page-item .page-link:focus,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-link:focus,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-link:focus,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:focus,
.jsgrid .jsgrid-pager .jsgrid-pager-page a:focus {
  background: inherit;
}
.pagination .page-item .page-link i:before,
.jsgrid .jsgrid-pager .page-item .page-link i:before,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-link i:before,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-link i:before,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a i:before,
.jsgrid .jsgrid-pager .jsgrid-pager-page a i:before {
  font-size: inherit;
  line-height: 1;
  vertical-align: middle;
}
.pagination .page-item.active .page-link,
.jsgrid .jsgrid-pager .page-item.active .page-link,
.pagination .jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button .page-link,
.jsgrid .jsgrid-pager .pagination .active.jsgrid-pager-nav-button .page-link,
.jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button .page-link,
.pagination .jsgrid .jsgrid-pager .active.jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .pagination .active.jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .active.jsgrid-pager-page .page-link,
.pagination .page-item.active .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .pagination .page-item.active a,
.jsgrid .jsgrid-pager .page-item.active .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item.active a,
.pagination .jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination .active.jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button a,
.pagination
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  .jsgrid-pager-nav-button
  a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .active.jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .pagination
  .active.jsgrid-pager-page
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination
  .active.jsgrid-pager-page
  a,
.jsgrid .jsgrid-pager .active.jsgrid-pager-page .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .active.jsgrid-pager-page a,
.pagination .page-item.active .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination .page-item.active a,
.jsgrid .jsgrid-pager .page-item.active .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item.active a,
.pagination
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .jsgrid-pager-page
  a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .active.jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination
  .active.jsgrid-pager-nav-button
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination
  .active.jsgrid-pager-nav-button
  a,
.jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .active.jsgrid-pager-nav-button a,
.pagination .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.pagination .page-item:hover .page-link,
.jsgrid .jsgrid-pager .page-item:hover .page-link,
.pagination .jsgrid .jsgrid-pager .jsgrid-pager-nav-button:hover .page-link,
.jsgrid .jsgrid-pager .pagination .jsgrid-pager-nav-button:hover .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:hover .page-link,
.pagination .jsgrid .jsgrid-pager .jsgrid-pager-page:hover .page-link,
.jsgrid .jsgrid-pager .pagination .jsgrid-pager-page:hover .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-page:hover .page-link,
.pagination .page-item:hover .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .pagination .page-item:hover a,
.jsgrid .jsgrid-pager .page-item:hover .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item:hover a,
.pagination .jsgrid .jsgrid-pager .jsgrid-pager-nav-button:hover a,
.jsgrid .jsgrid-pager .pagination .jsgrid-pager-nav-button:hover a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:hover a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:hover
  .jsgrid-pager-nav-button
  a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .jsgrid-pager-page:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination
  .jsgrid-pager-page:hover
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination
  .jsgrid-pager-page:hover
  a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:hover .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .jsgrid-pager-page:hover a,
.pagination .page-item:hover .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination .page-item:hover a,
.jsgrid .jsgrid-pager .page-item:hover .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item:hover a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .jsgrid-pager-page
  a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination
  .jsgrid-pager-nav-button:hover
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:hover .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .jsgrid-pager-nav-button:hover a,
.pagination .jsgrid .jsgrid-pager .jsgrid-pager-page:hover a,
.jsgrid .jsgrid-pager .pagination .jsgrid-pager-page:hover a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:hover a,
.pagination .page-item:focus .page-link,
.jsgrid .jsgrid-pager .page-item:focus .page-link,
.pagination .jsgrid .jsgrid-pager .jsgrid-pager-nav-button:focus .page-link,
.jsgrid .jsgrid-pager .pagination .jsgrid-pager-nav-button:focus .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:focus .page-link,
.pagination .jsgrid .jsgrid-pager .jsgrid-pager-page:focus .page-link,
.jsgrid .jsgrid-pager .pagination .jsgrid-pager-page:focus .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-page:focus .page-link,
.pagination .page-item:focus .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .pagination .page-item:focus a,
.jsgrid .jsgrid-pager .page-item:focus .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item:focus a,
.pagination .jsgrid .jsgrid-pager .jsgrid-pager-nav-button:focus a,
.jsgrid .jsgrid-pager .pagination .jsgrid-pager-nav-button:focus a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:focus a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:focus
  .jsgrid-pager-nav-button
  a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .jsgrid-pager-page:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination
  .jsgrid-pager-page:focus
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination
  .jsgrid-pager-page:focus
  a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:focus .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .jsgrid-pager-page:focus a,
.pagination .page-item:focus .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination .page-item:focus a,
.jsgrid .jsgrid-pager .page-item:focus .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item:focus a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .jsgrid-pager-page
  a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination
  .jsgrid-pager-nav-button:focus
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:focus .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .jsgrid-pager-nav-button:focus a,
.pagination .jsgrid .jsgrid-pager .jsgrid-pager-page:focus a,
.jsgrid .jsgrid-pager .pagination .jsgrid-pager-page:focus a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:focus a,
.pagination .page-item:active .page-link,
.jsgrid .jsgrid-pager .page-item:active .page-link,
.pagination .jsgrid .jsgrid-pager .jsgrid-pager-nav-button:active .page-link,
.jsgrid .jsgrid-pager .pagination .jsgrid-pager-nav-button:active .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:active .page-link,
.pagination .jsgrid .jsgrid-pager .jsgrid-pager-page:active .page-link,
.jsgrid .jsgrid-pager .pagination .jsgrid-pager-page:active .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-page:active .page-link,
.pagination .page-item:active .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .pagination .page-item:active a,
.jsgrid .jsgrid-pager .page-item:active .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item:active a,
.pagination .jsgrid .jsgrid-pager .jsgrid-pager-nav-button:active a,
.jsgrid .jsgrid-pager .pagination .jsgrid-pager-nav-button:active a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:active a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:active
  .jsgrid-pager-nav-button
  a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .jsgrid-pager-page:active
  a,
.jsgrid
  .jsgrid-pager
  .pagination
  .jsgrid-pager-page:active
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination
  .jsgrid-pager-page:active
  a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:active .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .jsgrid-pager-page:active a,
.pagination .page-item:active .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination .page-item:active a,
.jsgrid .jsgrid-pager .page-item:active .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item:active a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:active
  .jsgrid-pager-page
  a,
.pagination
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .jsgrid-pager-nav-button:active
  a,
.jsgrid
  .jsgrid-pager
  .pagination
  .jsgrid-pager-nav-button:active
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination
  .jsgrid-pager-nav-button:active
  a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:active .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .jsgrid-pager-nav-button:active a,
.pagination .jsgrid .jsgrid-pager .jsgrid-pager-page:active a,
.jsgrid .jsgrid-pager .pagination .jsgrid-pager-page:active a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:active a {
  background: #3762c0;
  border-color: #3762c0;
  color: #ffffff;
}
.pagination.flat .page-item .page-link,
.jsgrid .flat.jsgrid-pager .page-item .page-link,
.pagination.flat .jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-link,
.jsgrid .jsgrid-pager .pagination.flat .jsgrid-pager-nav-button .page-link,
.jsgrid .flat.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.flat .jsgrid .jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .pagination.flat .jsgrid-pager-page .page-link,
.jsgrid .flat.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .pagination.flat .page-item a,
.pagination.flat .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination.flat .jsgrid-pager-nav-button a,
.jsgrid .flat.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination.flat .page-item a,
.pagination.flat .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.flat .jsgrid-pager-page a,
.jsgrid .flat.jsgrid-pager .jsgrid-pager-page a {
  border: none;
  border-radius: 2px;
}
.pagination.separated .page-item,
.jsgrid .separated.jsgrid-pager .page-item,
.pagination.separated .jsgrid .jsgrid-pager .jsgrid-pager-nav-button,
.jsgrid .jsgrid-pager .pagination.separated .jsgrid-pager-nav-button,
.jsgrid .separated.jsgrid-pager .jsgrid-pager-nav-button,
.pagination.separated .jsgrid .jsgrid-pager .jsgrid-pager-page,
.jsgrid .jsgrid-pager .pagination.separated .jsgrid-pager-page,
.jsgrid .separated.jsgrid-pager .jsgrid-pager-page {
  margin-left: 2px;
  margin-right: 2px;
}
.pagination.separated .page-item:first-child,
.jsgrid .separated.jsgrid-pager .page-item:first-child,
.pagination.separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:first-child,
.jsgrid
  .jsgrid-pager
  .pagination.separated
  .jsgrid-pager-nav-button:first-child,
.jsgrid .separated.jsgrid-pager .jsgrid-pager-nav-button:first-child,
.pagination.separated .jsgrid .jsgrid-pager .jsgrid-pager-page:first-child,
.jsgrid .jsgrid-pager .pagination.separated .jsgrid-pager-page:first-child,
.jsgrid .separated.jsgrid-pager .jsgrid-pager-page:first-child {
  margin-left: 0;
}
.pagination.separated .page-item:last-child,
.jsgrid .separated.jsgrid-pager .page-item:last-child,
.pagination.separated .jsgrid .jsgrid-pager .jsgrid-pager-nav-button:last-child,
.jsgrid .jsgrid-pager .pagination.separated .jsgrid-pager-nav-button:last-child,
.jsgrid .separated.jsgrid-pager .jsgrid-pager-nav-button:last-child,
.pagination.separated .jsgrid .jsgrid-pager .jsgrid-pager-page:last-child,
.jsgrid .jsgrid-pager .pagination.separated .jsgrid-pager-page:last-child,
.jsgrid .separated.jsgrid-pager .jsgrid-pager-page:last-child {
  margin-right: 0;
}
.pagination.separated .page-item .page-link,
.jsgrid .separated.jsgrid-pager .page-item .page-link,
.pagination.separated .jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-link,
.jsgrid .jsgrid-pager .pagination.separated .jsgrid-pager-nav-button .page-link,
.jsgrid .separated.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.separated .jsgrid .jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .pagination.separated .jsgrid-pager-page .page-link,
.jsgrid .separated.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.separated
  .page-item
  a,
.pagination.separated .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination.separated .jsgrid-pager-nav-button a,
.jsgrid .separated.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination.separated .page-item a,
.pagination.separated .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.separated .jsgrid-pager-page a,
.jsgrid .separated.jsgrid-pager .jsgrid-pager-page a {
  border-radius: 2px;
}
.pagination.rounded .page-item:first-child .page-link,
.jsgrid .rounded.jsgrid-pager .page-item:first-child .page-link,
.pagination.loader-demo-box .page-item:first-child .page-link,
.jsgrid .jsgrid-pager.loader-demo-box .page-item:first-child .page-link,
.pagination.rounded
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:first-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.rounded
  .jsgrid-pager-nav-button:first-child
  .page-link,
.jsgrid .rounded.jsgrid-pager .jsgrid-pager-nav-button:first-child .page-link,
.pagination.loader-demo-box
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:first-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.loader-demo-box
  .jsgrid-pager-nav-button:first-child
  .page-link,
.jsgrid
  .jsgrid-pager.loader-demo-box
  .jsgrid-pager-nav-button:first-child
  .page-link,
.pagination.rounded
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:first-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.rounded
  .jsgrid-pager-page:first-child
  .page-link,
.jsgrid .rounded.jsgrid-pager .jsgrid-pager-page:first-child .page-link,
.pagination.loader-demo-box
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:first-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.loader-demo-box
  .jsgrid-pager-page:first-child
  .page-link,
.jsgrid .jsgrid-pager.loader-demo-box .jsgrid-pager-page:first-child .page-link,
.pagination.rounded
  .page-item:first-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.rounded
  .page-item:first-child
  a,
.jsgrid .rounded.jsgrid-pager .page-item:first-child .jsgrid-pager-nav-button a,
.jsgrid .rounded.jsgrid-pager .jsgrid-pager-nav-button .page-item:first-child a,
.pagination.loader-demo-box
  .page-item:first-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.loader-demo-box
  .page-item:first-child
  a,
.jsgrid
  .jsgrid-pager.loader-demo-box
  .page-item:first-child
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager.loader-demo-box
  .jsgrid-pager-nav-button
  .page-item:first-child
  a,
.pagination.rounded
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:first-child
  a,
.jsgrid
  .jsgrid-pager
  .pagination.rounded
  .jsgrid-pager-nav-button:first-child
  a,
.jsgrid .rounded.jsgrid-pager .jsgrid-pager-nav-button:first-child a,
.pagination.loader-demo-box
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:first-child
  a,
.jsgrid
  .jsgrid-pager
  .pagination.loader-demo-box
  .jsgrid-pager-nav-button:first-child
  a,
.jsgrid .jsgrid-pager.loader-demo-box .jsgrid-pager-nav-button:first-child a,
.pagination.rounded
  .page-item:first-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.rounded
  .page-item:first-child
  a,
.jsgrid .rounded.jsgrid-pager .page-item:first-child .jsgrid-pager-page a,
.jsgrid .rounded.jsgrid-pager .jsgrid-pager-page .page-item:first-child a,
.pagination.loader-demo-box
  .page-item:first-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.loader-demo-box
  .page-item:first-child
  a,
.jsgrid
  .jsgrid-pager.loader-demo-box
  .page-item:first-child
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager.loader-demo-box
  .jsgrid-pager-page
  .page-item:first-child
  a,
.pagination.rounded .jsgrid .jsgrid-pager .jsgrid-pager-page:first-child a,
.jsgrid .jsgrid-pager .pagination.rounded .jsgrid-pager-page:first-child a,
.jsgrid .rounded.jsgrid-pager .jsgrid-pager-page:first-child a,
.pagination.loader-demo-box
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:first-child
  a,
.jsgrid
  .jsgrid-pager
  .pagination.loader-demo-box
  .jsgrid-pager-page:first-child
  a,
.jsgrid .jsgrid-pager.loader-demo-box .jsgrid-pager-page:first-child a {
  border-radius: 25px 0 0 25px;
}
.pagination.rounded .page-item:last-child .page-link,
.jsgrid .rounded.jsgrid-pager .page-item:last-child .page-link,
.pagination.loader-demo-box .page-item:last-child .page-link,
.jsgrid .jsgrid-pager.loader-demo-box .page-item:last-child .page-link,
.pagination.rounded
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:last-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.rounded
  .jsgrid-pager-nav-button:last-child
  .page-link,
.jsgrid .rounded.jsgrid-pager .jsgrid-pager-nav-button:last-child .page-link,
.pagination.loader-demo-box
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:last-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.loader-demo-box
  .jsgrid-pager-nav-button:last-child
  .page-link,
.jsgrid
  .jsgrid-pager.loader-demo-box
  .jsgrid-pager-nav-button:last-child
  .page-link,
.pagination.rounded
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:last-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.rounded
  .jsgrid-pager-page:last-child
  .page-link,
.jsgrid .rounded.jsgrid-pager .jsgrid-pager-page:last-child .page-link,
.pagination.loader-demo-box
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:last-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.loader-demo-box
  .jsgrid-pager-page:last-child
  .page-link,
.jsgrid .jsgrid-pager.loader-demo-box .jsgrid-pager-page:last-child .page-link,
.pagination.rounded
  .page-item:last-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.rounded
  .page-item:last-child
  a,
.jsgrid .rounded.jsgrid-pager .page-item:last-child .jsgrid-pager-nav-button a,
.jsgrid .rounded.jsgrid-pager .jsgrid-pager-nav-button .page-item:last-child a,
.pagination.loader-demo-box
  .page-item:last-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.loader-demo-box
  .page-item:last-child
  a,
.jsgrid
  .jsgrid-pager.loader-demo-box
  .page-item:last-child
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager.loader-demo-box
  .jsgrid-pager-nav-button
  .page-item:last-child
  a,
.pagination.rounded .jsgrid .jsgrid-pager .jsgrid-pager-nav-button:last-child a,
.jsgrid .jsgrid-pager .pagination.rounded .jsgrid-pager-nav-button:last-child a,
.jsgrid .rounded.jsgrid-pager .jsgrid-pager-nav-button:last-child a,
.pagination.loader-demo-box
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:last-child
  a,
.jsgrid
  .jsgrid-pager
  .pagination.loader-demo-box
  .jsgrid-pager-nav-button:last-child
  a,
.jsgrid .jsgrid-pager.loader-demo-box .jsgrid-pager-nav-button:last-child a,
.pagination.rounded
  .page-item:last-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.rounded
  .page-item:last-child
  a,
.jsgrid .rounded.jsgrid-pager .page-item:last-child .jsgrid-pager-page a,
.jsgrid .rounded.jsgrid-pager .jsgrid-pager-page .page-item:last-child a,
.pagination.loader-demo-box
  .page-item:last-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.loader-demo-box
  .page-item:last-child
  a,
.jsgrid
  .jsgrid-pager.loader-demo-box
  .page-item:last-child
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager.loader-demo-box
  .jsgrid-pager-page
  .page-item:last-child
  a,
.pagination.rounded .jsgrid .jsgrid-pager .jsgrid-pager-page:last-child a,
.jsgrid .jsgrid-pager .pagination.rounded .jsgrid-pager-page:last-child a,
.jsgrid .rounded.jsgrid-pager .jsgrid-pager-page:last-child a,
.pagination.loader-demo-box
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:last-child
  a,
.jsgrid
  .jsgrid-pager
  .pagination.loader-demo-box
  .jsgrid-pager-page:last-child
  a,
.jsgrid .jsgrid-pager.loader-demo-box .jsgrid-pager-page:last-child a {
  border-radius: 0 25px 25px 0;
}
.pagination.rounded-flat .page-item,
.jsgrid .rounded-flat.jsgrid-pager .page-item,
.pagination.rounded-flat .jsgrid .jsgrid-pager .jsgrid-pager-nav-button,
.jsgrid .jsgrid-pager .pagination.rounded-flat .jsgrid-pager-nav-button,
.jsgrid .rounded-flat.jsgrid-pager .jsgrid-pager-nav-button,
.pagination.rounded-flat .jsgrid .jsgrid-pager .jsgrid-pager-page,
.jsgrid .jsgrid-pager .pagination.rounded-flat .jsgrid-pager-page,
.jsgrid .rounded-flat.jsgrid-pager .jsgrid-pager-page {
  margin-right: 3px;
  margin-left: 3px;
}
.pagination.rounded-flat .page-item .page-link,
.jsgrid .rounded-flat.jsgrid-pager .page-item .page-link,
.pagination.rounded-flat
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-flat
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid .rounded-flat.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.rounded-flat .jsgrid .jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .pagination.rounded-flat .jsgrid-pager-page .page-link,
.jsgrid .rounded-flat.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.rounded-flat
  .page-item
  a,
.pagination.rounded-flat .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination.rounded-flat .jsgrid-pager-nav-button a,
.jsgrid .rounded-flat.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination.rounded-flat .page-item a,
.pagination.rounded-flat .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.rounded-flat .jsgrid-pager-page a,
.jsgrid .rounded-flat.jsgrid-pager .jsgrid-pager-page a {
  border: none;
  border-radius: 50px;
}
.pagination.rounded-separated .page-item,
.jsgrid .rounded-separated.jsgrid-pager .page-item,
.pagination.rounded-separated .jsgrid .jsgrid-pager .jsgrid-pager-nav-button,
.jsgrid .jsgrid-pager .pagination.rounded-separated .jsgrid-pager-nav-button,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-nav-button,
.pagination.rounded-separated .jsgrid .jsgrid-pager .jsgrid-pager-page,
.jsgrid .jsgrid-pager .pagination.rounded-separated .jsgrid-pager-page,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-page {
  margin-left: 2px;
  margin-right: 2px;
}
.pagination.rounded-separated .page-item:first-child,
.jsgrid .rounded-separated.jsgrid-pager .page-item:first-child,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:first-child,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-nav-button:first-child,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-nav-button:first-child,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:first-child,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-page:first-child,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-page:first-child {
  margin-left: 0;
}
.pagination.rounded-separated .page-item:first-child .page-link,
.jsgrid .rounded-separated.jsgrid-pager .page-item:first-child .page-link,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:first-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-nav-button:first-child
  .page-link,
.jsgrid
  .rounded-separated.jsgrid-pager
  .jsgrid-pager-nav-button:first-child
  .page-link,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:first-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-page:first-child
  .page-link,
.jsgrid
  .rounded-separated.jsgrid-pager
  .jsgrid-pager-page:first-child
  .page-link,
.pagination.rounded-separated
  .page-item:first-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.rounded-separated
  .page-item:first-child
  a,
.jsgrid
  .rounded-separated.jsgrid-pager
  .page-item:first-child
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .rounded-separated.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:first-child
  a,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:first-child
  a,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-nav-button:first-child
  a,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-nav-button:first-child a,
.pagination.rounded-separated
  .page-item:first-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.rounded-separated
  .page-item:first-child
  a,
.jsgrid
  .rounded-separated.jsgrid-pager
  .page-item:first-child
  .jsgrid-pager-page
  a,
.jsgrid
  .rounded-separated.jsgrid-pager
  .jsgrid-pager-page
  .page-item:first-child
  a,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:first-child
  a,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-page:first-child
  a,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-page:first-child a {
  border-radius: 10px 0 0 10px;
}
.pagination.rounded-separated .page-item:last-child,
.jsgrid .rounded-separated.jsgrid-pager .page-item:last-child,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:last-child,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-nav-button:last-child,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-nav-button:last-child,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:last-child,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-page:last-child,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-page:last-child {
  margin-right: 0;
}
.pagination.rounded-separated .page-item:last-child .page-link,
.jsgrid .rounded-separated.jsgrid-pager .page-item:last-child .page-link,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:last-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-nav-button:last-child
  .page-link,
.jsgrid
  .rounded-separated.jsgrid-pager
  .jsgrid-pager-nav-button:last-child
  .page-link,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:last-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-page:last-child
  .page-link,
.jsgrid
  .rounded-separated.jsgrid-pager
  .jsgrid-pager-page:last-child
  .page-link,
.pagination.rounded-separated
  .page-item:last-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.rounded-separated
  .page-item:last-child
  a,
.jsgrid
  .rounded-separated.jsgrid-pager
  .page-item:last-child
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .rounded-separated.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:last-child
  a,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:last-child
  a,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-nav-button:last-child
  a,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-nav-button:last-child a,
.pagination.rounded-separated
  .page-item:last-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.rounded-separated
  .page-item:last-child
  a,
.jsgrid
  .rounded-separated.jsgrid-pager
  .page-item:last-child
  .jsgrid-pager-page
  a,
.jsgrid
  .rounded-separated.jsgrid-pager
  .jsgrid-pager-page
  .page-item:last-child
  a,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:last-child
  a,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-page:last-child
  a,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-page:last-child a {
  border-radius: 0 10px 10px 0;
}
.pagination.rounded-separated .page-item .page-link,
.jsgrid .rounded-separated.jsgrid-pager .page-item .page-link,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.rounded-separated
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.rounded-separated
  .jsgrid-pager-page
  .page-link,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.rounded-separated
  .page-item
  a,
.pagination.rounded-separated .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination.rounded-separated .jsgrid-pager-nav-button a,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.rounded-separated
  .page-item
  a,
.pagination.rounded-separated .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.rounded-separated .jsgrid-pager-page a,
.jsgrid .rounded-separated.jsgrid-pager .jsgrid-pager-page a {
  border-radius: 2px;
}

/* pagination variations */
.pagination-primary .page-item.active .page-link,
.pagination-primary
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination-primary
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination-primary .jsgrid .jsgrid-pager .active.jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .pagination-primary .active.jsgrid-pager-page .page-link,
.pagination-primary
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-primary
  .page-item.active
  a,
.pagination-primary .jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination-primary .active.jsgrid-pager-nav-button a,
.pagination-primary
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination-primary
  .page-item.active
  a,
.pagination-primary .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination-primary .active.jsgrid-pager-page a {
  background: #3762c0;
  border-color: #3762c0;
}
.pagination-primary .page-item .page-link:hover,
.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination-primary
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination-primary .jsgrid .jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid .jsgrid-pager .pagination-primary .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-primary
  .page-item
  a:hover,
.pagination-primary .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .pagination-primary .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-primary .page-item a:hover,
.pagination-primary .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination-primary .jsgrid-pager-page a:hover {
  background: #b075dd;
  border-color: #3762c0;
  color: #ffffff;
}

.pagination-secondary .page-item.active .page-link,
.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination-secondary
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination-secondary
  .active.jsgrid-pager-page
  .page-link,
.pagination-secondary
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-secondary
  .page-item.active
  a,
.pagination-secondary .jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination-secondary .active.jsgrid-pager-nav-button a,
.pagination-secondary
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination-secondary
  .page-item.active
  a,
.pagination-secondary .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination-secondary .active.jsgrid-pager-page a {
  background: #8e94a9;
  border-color: #8e94a9;
}
.pagination-secondary .page-item .page-link:hover,
.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination-secondary
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination-secondary .jsgrid .jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid .jsgrid-pager .pagination-secondary .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-secondary
  .page-item
  a:hover,
.pagination-secondary .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .pagination-secondary .jsgrid-pager-nav-button a:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination-secondary
  .page-item
  a:hover,
.pagination-secondary .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination-secondary .jsgrid-pager-page a:hover {
  background: #9ca2b4;
  border-color: #8e94a9;
  color: #ffffff;
}

.pagination-success .page-item.active .page-link,
.pagination-success
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination-success
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination-success .jsgrid .jsgrid-pager .active.jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .pagination-success .active.jsgrid-pager-page .page-link,
.pagination-success
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-success
  .page-item.active
  a,
.pagination-success .jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination-success .active.jsgrid-pager-nav-button a,
.pagination-success
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination-success
  .page-item.active
  a,
.pagination-success .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination-success .active.jsgrid-pager-page a {
  background: #44ce42;
  border-color: #44ce42;
}
.pagination-success .page-item .page-link:hover,
.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination-success
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination-success .jsgrid .jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid .jsgrid-pager .pagination-success .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-success
  .page-item
  a:hover,
.pagination-success .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .pagination-success .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-success .page-item a:hover,
.pagination-success .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination-success .jsgrid-pager-page a:hover {
  background: #58d356;
  border-color: #44ce42;
  color: #ffffff;
}

.pagination-info .page-item.active .page-link,
.pagination-info
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination-info
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination-info .jsgrid .jsgrid-pager .active.jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .pagination-info .active.jsgrid-pager-page .page-link,
.pagination-info
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-info
  .page-item.active
  a,
.pagination-info .jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination-info .active.jsgrid-pager-nav-button a,
.pagination-info .page-item.active .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-info .page-item.active a,
.pagination-info .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination-info .active.jsgrid-pager-page a {
  background: #0062ff;
  border-color: #0062ff;
}
.pagination-info .page-item .page-link:hover,
.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination-info
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination-info .jsgrid .jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid .jsgrid-pager .pagination-info .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-info
  .page-item
  a:hover,
.pagination-info .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .pagination-info .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-info .page-item a:hover,
.pagination-info .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination-info .jsgrid-pager-page a:hover {
  background: #1a72ff;
  border-color: #0062ff;
  color: #ffffff;
}

.pagination-warning .page-item.active .page-link,
.pagination-warning
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination-warning
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination-warning .jsgrid .jsgrid-pager .active.jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .pagination-warning .active.jsgrid-pager-page .page-link,
.pagination-warning
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-warning
  .page-item.active
  a,
.pagination-warning .jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination-warning .active.jsgrid-pager-nav-button a,
.pagination-warning
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination-warning
  .page-item.active
  a,
.pagination-warning .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination-warning .active.jsgrid-pager-page a {
  background: #ffc542;
  border-color: #ffc542;
}
.pagination-warning .page-item .page-link:hover,
.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination-warning
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination-warning .jsgrid .jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid .jsgrid-pager .pagination-warning .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-warning
  .page-item
  a:hover,
.pagination-warning .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .pagination-warning .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-warning .page-item a:hover,
.pagination-warning .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination-warning .jsgrid-pager-page a:hover {
  background: #ffcd5c;
  border-color: #ffc542;
  color: #ffffff;
}

.pagination-danger .page-item.active .page-link,
.pagination-danger
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination-danger
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination-danger .jsgrid .jsgrid-pager .active.jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .pagination-danger .active.jsgrid-pager-page .page-link,
.pagination-danger
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-danger
  .page-item.active
  a,
.pagination-danger .jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination-danger .active.jsgrid-pager-nav-button a,
.pagination-danger .page-item.active .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-danger .page-item.active a,
.pagination-danger .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination-danger .active.jsgrid-pager-page a {
  background: #fc5a5a;
  border-color: #fc5a5a;
}
.pagination-danger .page-item .page-link:hover,
.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination-danger
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination-danger .jsgrid .jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid .jsgrid-pager .pagination-danger .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-danger
  .page-item
  a:hover,
.pagination-danger .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .pagination-danger .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-danger .page-item a:hover,
.pagination-danger .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination-danger .jsgrid-pager-page a:hover {
  background: #fc7373;
  border-color: #fc5a5a;
  color: #ffffff;
}

.pagination-light .page-item.active .page-link,
.pagination-light
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination-light
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination-light .jsgrid .jsgrid-pager .active.jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .pagination-light .active.jsgrid-pager-page .page-link,
.pagination-light
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-light
  .page-item.active
  a,
.pagination-light .jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination-light .active.jsgrid-pager-nav-button a,
.pagination-light .page-item.active .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-light .page-item.active a,
.pagination-light .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination-light .active.jsgrid-pager-page a {
  background: #aab2bd;
  border-color: #aab2bd;
}
.pagination-light .page-item .page-link:hover,
.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination-light
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination-light .jsgrid .jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid .jsgrid-pager .pagination-light .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-light
  .page-item
  a:hover,
.pagination-light .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .pagination-light .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-light .page-item a:hover,
.pagination-light .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination-light .jsgrid-pager-page a:hover {
  background: #b8bfc8;
  border-color: #aab2bd;
  color: #ffffff;
}

.pagination-dark .page-item.active .page-link,
.pagination-dark
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination-dark
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination-dark .jsgrid .jsgrid-pager .active.jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .pagination-dark .active.jsgrid-pager-page .page-link,
.pagination-dark
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-dark
  .page-item.active
  a,
.pagination-dark .jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination-dark .active.jsgrid-pager-nav-button a,
.pagination-dark .page-item.active .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-dark .page-item.active a,
.pagination-dark .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination-dark .active.jsgrid-pager-page a {
  background: #001737;
  border-color: #001737;
}
.pagination-dark .page-item .page-link:hover,
.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination-dark
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination-dark .jsgrid .jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid .jsgrid-pager .pagination-dark .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-dark
  .page-item
  a:hover,
.pagination-dark .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .pagination-dark .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-dark .page-item a:hover,
.pagination-dark .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination-dark .jsgrid-pager-page a:hover {
  background: #002251;
  border-color: #001737;
  color: #ffffff;
}

/* Popovers */
.popover-static-demo .popover {
  width: 185px;
  position: relative;
  display: block;
  float: left;
  margin: 1rem 0.5rem;
}
.popover-static-demo .popover.bs-popover-bottom-demo .arrow,
.popover-static-demo .popover.bs-popover-top-demo .arrow {
  left: 50%;
}
.popover-static-demo .popover.bs-popover-left-demo .arrow,
.popover-static-demo .popover.bs-popover-right-demo .arrow {
  top: 50%;
}

.popover {
  z-index: 1029;
}
.popover.bs-popover-top-demo .arrow:before {
  border-top-color: var(--bs-border-color-translucent);
}
.popover.bs-popover-top-demo .arrow:after {
  border-top-color: var(--bs-body-bg);
}
.popover.bs-popover-right-demo .arrow:before {
  border-right-color: var(--bs-border-color-translucent);
}
.popover.bs-popover-right-demo .arrow:after {
  border-right-color: var(--bs-body-bg);
}
.popover.bs-popover-bottom-demo .arrow:before {
  border-bottom-color: var(--bs-border-color-translucent);
}
.popover.bs-popover-bottom-demo .arrow:after {
  border-bottom-color: var(--bs-body-bg);
}
.popover.bs-popover-bottom-demo .popover-header:before {
  border-bottom: 0;
}
.popover.bs-popover-left-demo .arrow:before {
  border-left-color: var(--bs-border-color-translucent);
}
.popover.bs-popover-left-demo .arrow:after {
  border-left-color: var(--bs-body-bg);
}
.popover .popover-header {
  font-size: 0.9375rem;
  border-bottom: 0;
  background: #ffffff;
  color: inherit;
  border-bottom: 1px solid var(--bs-border-color-translucent);
}
.popover .popover-body {
  color: #a7afb7;
}
.popover.left .arrow:before {
  border-left-color: var(--bs-border-color-translucent);
}
.popover.left .arrow:after {
  border-left-color: var(--bs-body-bg);
}
.popover.right .arrow:before {
  border-right-color: var(--bs-border-color-translucent);
}
.popover.right .arrow:after {
  border-right-color: var(--bs-body-bg);
}
.popover.top .arrow:before {
  border-top-color: var(--bs-border-color-translucent);
}
.popover.top .arrow:after {
  border-top-color: var(--bs-body-bg);
}
.popover.bottom .arrow:before {
  border-bottom-color: var(--bs-border-color-translucent);
}
.popover.bottom .arrow:after {
  border-bottom-color: var(--bs-body-bg);
}

.popover-primary {
  border-color: primary;
}
.popover-primary .popover-header {
  background: linear-gradient(to right, #da8cff, #9a55ff);
  color: #ffffff;
  border-bottom: 0;
}
.popover-primary.bs-popover-top .arrow:before,
.popover-primary.bs-popover-auto[data-popper-placement^="top"] .arrow:before,
.popover-primary.bs-popover-top .arrow:after,
.popover-primary.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: primary;
}
.popover-primary.bs-popover-right .arrow:before,
.popover-primary.bs-popover-right .arrow:after {
  border-right-color: primary;
}
.popover-primary.bs-popover-bottom .arrow:before,
.popover-primary.bs-popover-auto[data-popper-placement^="bottom"] .arrow:before,
.popover-primary.bs-popover-bottom .arrow:after,
.popover-primary.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: primary;
}
.popover-primary.bs-popover-left .arrow:before,
.popover-primary.bs-popover-left .arrow:after {
  border-left-color: primary;
}

.popover-secondary {
  border-color: secondary;
}
.popover-secondary .popover-header {
  background: linear-gradient(to right, #e7ebf0, #868e96);
  color: #ffffff;
  border-bottom: 0;
}
.popover-secondary.bs-popover-top .arrow:before,
.popover-secondary.bs-popover-auto[data-popper-placement^="top"] .arrow:before,
.popover-secondary.bs-popover-top .arrow:after,
.popover-secondary.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: secondary;
}
.popover-secondary.bs-popover-right .arrow:before,
.popover-secondary.bs-popover-right .arrow:after {
  border-right-color: secondary;
}
.popover-secondary.bs-popover-bottom .arrow:before,
.popover-secondary.bs-popover-auto[data-popper-placement^="bottom"]
  .arrow:before,
.popover-secondary.bs-popover-bottom .arrow:after,
.popover-secondary.bs-popover-auto[data-popper-placement^="bottom"]
  .arrow:after {
  border-bottom-color: secondary;
}
.popover-secondary.bs-popover-left .arrow:before,
.popover-secondary.bs-popover-left .arrow:after {
  border-left-color: secondary;
}

.popover-success {
  border-color: success;
}
.popover-success .popover-header {
  background: linear-gradient(to right, #84d9d2, #07cdae);
  color: #ffffff;
  border-bottom: 0;
}
.popover-success.bs-popover-top .arrow:before,
.popover-success.bs-popover-auto[data-popper-placement^="top"] .arrow:before,
.popover-success.bs-popover-top .arrow:after,
.popover-success.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: success;
}
.popover-success.bs-popover-right .arrow:before,
.popover-success.bs-popover-right .arrow:after {
  border-right-color: success;
}
.popover-success.bs-popover-bottom .arrow:before,
.popover-success.bs-popover-auto[data-popper-placement^="bottom"] .arrow:before,
.popover-success.bs-popover-bottom .arrow:after,
.popover-success.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: success;
}
.popover-success.bs-popover-left .arrow:before,
.popover-success.bs-popover-left .arrow:after {
  border-left-color: success;
}

.popover-info {
  border-color: info;
}
.popover-info .popover-header {
  background: linear-gradient(to right, #90caf9, #047edf 99%);
  color: #ffffff;
  border-bottom: 0;
}
.popover-info.bs-popover-top .arrow:before,
.popover-info.bs-popover-auto[data-popper-placement^="top"] .arrow:before,
.popover-info.bs-popover-top .arrow:after,
.popover-info.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: info;
}
.popover-info.bs-popover-right .arrow:before,
.popover-info.bs-popover-right .arrow:after {
  border-right-color: info;
}
.popover-info.bs-popover-bottom .arrow:before,
.popover-info.bs-popover-auto[data-popper-placement^="bottom"] .arrow:before,
.popover-info.bs-popover-bottom .arrow:after,
.popover-info.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: info;
}
.popover-info.bs-popover-left .arrow:before,
.popover-info.bs-popover-left .arrow:after {
  border-left-color: info;
}

.popover-warning {
  border-color: warning;
}
.popover-warning .popover-header {
  background: linear-gradient(to right, #f6e384, #ffd500);
  color: #ffffff;
  border-bottom: 0;
}
.popover-warning.bs-popover-top .arrow:before,
.popover-warning.bs-popover-auto[data-popper-placement^="top"] .arrow:before,
.popover-warning.bs-popover-top .arrow:after,
.popover-warning.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: warning;
}
.popover-warning.bs-popover-right .arrow:before,
.popover-warning.bs-popover-right .arrow:after {
  border-right-color: warning;
}
.popover-warning.bs-popover-bottom .arrow:before,
.popover-warning.bs-popover-auto[data-popper-placement^="bottom"] .arrow:before,
.popover-warning.bs-popover-bottom .arrow:after,
.popover-warning.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: warning;
}
.popover-warning.bs-popover-left .arrow:before,
.popover-warning.bs-popover-left .arrow:after {
  border-left-color: warning;
}

.popover-danger {
  border-color: danger;
}
.popover-danger .popover-header {
  background: linear-gradient(to right, #ffbf96, #fe7096);
  color: #ffffff;
  border-bottom: 0;
}
.popover-danger.bs-popover-top .arrow:before,
.popover-danger.bs-popover-auto[data-popper-placement^="top"] .arrow:before,
.popover-danger.bs-popover-top .arrow:after,
.popover-danger.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: danger;
}
.popover-danger.bs-popover-right .arrow:before,
.popover-danger.bs-popover-right .arrow:after {
  border-right-color: danger;
}
.popover-danger.bs-popover-bottom .arrow:before,
.popover-danger.bs-popover-auto[data-popper-placement^="bottom"] .arrow:before,
.popover-danger.bs-popover-bottom .arrow:after,
.popover-danger.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: danger;
}
.popover-danger.bs-popover-left .arrow:before,
.popover-danger.bs-popover-left .arrow:after {
  border-left-color: danger;
}

.popover-light {
  border-color: light;
}
.popover-light .popover-header {
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
  color: #ffffff;
  border-bottom: 0;
}
.popover-light.bs-popover-top .arrow:before,
.popover-light.bs-popover-auto[data-popper-placement^="top"] .arrow:before,
.popover-light.bs-popover-top .arrow:after,
.popover-light.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: light;
}
.popover-light.bs-popover-right .arrow:before,
.popover-light.bs-popover-right .arrow:after {
  border-right-color: light;
}
.popover-light.bs-popover-bottom .arrow:before,
.popover-light.bs-popover-auto[data-popper-placement^="bottom"] .arrow:before,
.popover-light.bs-popover-bottom .arrow:after,
.popover-light.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: light;
}
.popover-light.bs-popover-left .arrow:before,
.popover-light.bs-popover-left .arrow:after {
  border-left-color: light;
}

.popover-dark {
  border-color: dark;
}
.popover-dark .popover-header {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
  color: #ffffff;
  border-bottom: 0;
}
.popover-dark.bs-popover-top .arrow:before,
.popover-dark.bs-popover-auto[data-popper-placement^="top"] .arrow:before,
.popover-dark.bs-popover-top .arrow:after,
.popover-dark.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: dark;
}
.popover-dark.bs-popover-right .arrow:before,
.popover-dark.bs-popover-right .arrow:after {
  border-right-color: dark;
}
.popover-dark.bs-popover-bottom .arrow:before,
.popover-dark.bs-popover-auto[data-popper-placement^="bottom"] .arrow:before,
.popover-dark.bs-popover-bottom .arrow:after,
.popover-dark.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: dark;
}
.popover-dark.bs-popover-left .arrow:before,
.popover-dark.bs-popover-left .arrow:after {
  border-left-color: dark;
}

.nav-tabs .nav-link {
  background: #f6f8fa;
  color: #000000;
  border-radius: 0;
  border: 1px solid rgba(151, 151, 151, 0.3);
  padding: 0.75rem 1.5rem;
}
.nav-tabs .nav-item:first-child .nav-link {
  border-radius: 4px 0 0 0;
}
.nav-tabs .nav-item:last-child .nav-link {
  border-radius: 0 4px 0 0;
}
.nav-tabs.nav-tabs-vertical,
.nav-tabs.nav-tabs-vertical-custom {
  border-bottom: 0;
}
.nav-tabs.nav-tabs-vertical .nav-link.active,
.nav-tabs.nav-tabs-vertical-custom .nav-link.active {
  border-bottom-color: rgba(151, 151, 151, 0.3);
}
.nav-tabs.nav-tabs-vertical .nav-item:first-child .nav-link,
.nav-tabs.nav-tabs-vertical-custom .nav-item:first-child .nav-link {
  border-radius: 4px 4px 0 0;
}
.nav-tabs.nav-tabs-vertical .nav-item:last-child .nav-link,
.nav-tabs.nav-tabs-vertical-custom .nav-item:last-child .nav-link {
  border-radius: 0 0 4px 4px;
}
.nav-tabs.nav-tabs-vertical-custom {
  background: #0062ff;
  border-radius: 4px;
}
.nav-tabs.nav-tabs-vertical-custom .nav-link {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.2);
  color: #002766;
  
  padding: 1.75rem;
}
.nav-tabs.nav-tabs-vertical-custom .nav-link.active {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.2);
}
.nav-tabs.tab-transparent .nav-item .nav-link {
  background: transparent;
  color: #aab2bd;
  border-radius: 0;
  border: none;
  padding: 0.875rem 1.25rem;
  position: relative;
}
@media (max-width: 991px) {
  .nav-tabs.tab-transparent .nav-item .nav-link {
    padding: 0.875rem 0.5rem;
  }
}
.nav-tabs.tab-transparent .nav-item .nav-link.active {
  color: #001737;
  font-weight: bold;
}
.nav-tabs.tab-transparent .nav-item .nav-link.active:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #44ce42;
  position: absolute;
  left: 0;
  bottom: 0;
}
.nav-tabs.tab-transparent .nav-item:first-child .nav-link:first-child {
  padding-left: 0;
}
.rtl .nav-tabs.tab-transparent .nav-item:first-child .nav-link:first-child {
  padding-right: 0;
  padding-left: 1.25rem;
}

.nav-pills {
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  padding-bottom: 1rem;
}
.nav-pills .nav-link {
  border: 1px solid rgba(151, 151, 151, 0.3);
  padding: 0.5rem 2rem;
}
.nav-pills .nav-item {
  margin-right: 1rem;
}
.nav-pills.nav-pills-vertical {
  width: 150px;
  border-bottom: 0;
}
.nav-pills.nav-pills-vertical .nav-item {
  margin-right: 0;
  margin-bottom: 1rem;
}
.nav-pills.nav-pills-custom {
  border-bottom: 0;
}
.nav-pills.nav-pills-custom .nav-link {
  border-radius: 20px;
  padding: 0.5rem 2.5rem;
  background: #fcfcfd;
  color: #000000;
}
.nav-pills.nav-pills-custom .nav-link.active {
  background: #fc5a5a;
  color: #ffffff;
}

.tab-content {
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-top: 0;
  padding: 2rem 1rem;
  text-align: justify;
}
.tab-content.tab-content-vertical {
  border-top: 1px solid rgba(151, 151, 151, 0.3);
}
.tab-content.tab-content-vertical-custom {
  border: 0;
  padding-top: 0;
}
.tab-content.tab-content-custom-pill {
  border: 0;
  padding-left: 0;
}
.tab-content.tab-transparent-content {
  border: none;
  padding: 1.5rem 0 0 0;
}

.rtl .nav-tabs.tab-transparent {
  padding-right: 0;
}

/* Timeline */
.timeline {
  list-style: none;
  padding: 0;
  position: relative;
}
.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #8e94a9;
  left: 50%;
  margin-left: -1.5px;
}
.timeline .timeline-wrapper {
  display: block;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  padding-right: 90px;
}
.timeline .timeline-wrapper:before {
  content: " ";
  display: table;
}
.timeline .timeline-wrapper:after {
  content: " ";
  display: table;
  clear: both;
}
.timeline .timeline-wrapper .timeline-panel {
  border-radius: 2px;
  padding: 20px;
  position: relative;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 1px 2px 35px 0 rgba(1, 1, 1, 0.1);
  width: 35%;
  margin-left: 15%;
}
.timeline .timeline-wrapper .timeline-panel:before {
  position: absolute;
  top: 0;
  width: 100%;
  height: 2px;
  content: "";
  left: 0;
  right: 0;
}
.timeline .timeline-wrapper .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #ffffff;
  border-right: 0 solid #ffffff;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline .timeline-wrapper .timeline-panel .timeline-title {
  margin-top: 0;
  color: #001737;
  text-transform: uppercase;
}
.timeline .timeline-wrapper .timeline-panel .timeline-body p {
  margin-bottom: 0;
}
.timeline .timeline-wrapper .timeline-panel .timeline-body p + p {
  margin-top: 5px;
}
.timeline .timeline-wrapper .timeline-panel .timeline-body ul {
  margin-bottom: 0;
}
.timeline .timeline-wrapper .timeline-panel .timeline-footer span {
  font-size: 0.6875rem;
}
.timeline .timeline-wrapper .timeline-panel .timeline-footer i {
  font-size: 1.5rem;
}
.timeline .timeline-wrapper .timeline-badge {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 16px;
  left: calc(50% - 7px);
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border: 2px solid #ffffff;
}
.timeline .timeline-wrapper .timeline-badge i {
  color: #ffffff;
}
.timeline .timeline-wrapper.timeline-inverted {
  padding-right: 0;
  padding-left: 90px;
}
.timeline .timeline-wrapper.timeline-inverted .timeline-panel {
  margin-left: auto;
  margin-right: 15%;
}
.timeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

@media (max-width: 767px) {
  .timeline .timeline-wrapper {
    padding-right: 150px;
  }
  .timeline .timeline-wrapper.timeline-inverted {
    padding-left: 150px;
  }
  .timeline .timeline-wrapper .timeline-panel {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.timeline-wrapper-primary .timeline-panel:before {
  background: linear-gradient(to right, #da8cff, #9a55ff);
}
.timeline-wrapper-primary .timeline-badge {
  background: linear-gradient(to right, #da8cff, #9a55ff);
}

.timeline-wrapper-secondary .timeline-panel:before {
  background: linear-gradient(to right, #e7ebf0, #868e96);
}
.timeline-wrapper-secondary .timeline-badge {
  background: linear-gradient(to right, #e7ebf0, #868e96);
}

.timeline-wrapper-success .timeline-panel:before {
  background: linear-gradient(to right, #84d9d2, #07cdae);
}
.timeline-wrapper-success .timeline-badge {
  background: linear-gradient(to right, #84d9d2, #07cdae);
}

.timeline-wrapper-info .timeline-panel:before {
  background: linear-gradient(to right, #90caf9, #047edf 99%);
}
.timeline-wrapper-info .timeline-badge {
  background: linear-gradient(to right, #90caf9, #047edf 99%);
}

.timeline-wrapper-warning .timeline-panel:before {
  background: linear-gradient(to right, #f6e384, #ffd500);
}
.timeline-wrapper-warning .timeline-badge {
  background: linear-gradient(to right, #f6e384, #ffd500);
}

.timeline-wrapper-danger .timeline-panel:before {
  background: linear-gradient(to right, #ffbf96, #fe7096);
}
.timeline-wrapper-danger .timeline-badge {
  background: linear-gradient(to right, #ffbf96, #fe7096);
}

.timeline-wrapper-light .timeline-panel:before {
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
}
.timeline-wrapper-light .timeline-badge {
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
}

.timeline-wrapper-dark .timeline-panel:before {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
}
.timeline-wrapper-dark .timeline-badge {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
}

.calendar-aside {
  display: block;
}
.calendar-aside .list {
  position: relative;
  padding: 10px 8px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition-duration: 0.25s;
  transition-property: background;
}
.calendar-aside .list .user-text {
  margin-bottom: 0;
  margin-left: 20px;
  font-size: 0.875rem;
}
.calendar-aside .list .count {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 50px;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-property: opacity;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar-aside .list .count:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  display: block;
  height: 10px;
  width: 10px;
  margin: auto;
  border-radius: 50px;
  transform: translateY(-50%);
  transition-duration: 0.25s;
  transition-timing-function: ease;
}
.calendar-aside .list .count.online:before {
  background: #44ce42;
}
.calendar-aside .list .count.offline:before {
  background: #fc5a5a;
}
.calendar-aside .list .count .close {
  font-size: 15px;
  margin: auto;
  opacity: 0;
  color: #fc5a5a;
  transition-duration: 0.25s;
  transition-timing-function: ease;
}
.calendar-aside .list:hover {
  background: #aab2bd;
}
.calendar-aside .list:hover .count:before {
  z-index: 0;
  height: 20px;
  width: 20px;
  background: #aab2bd;
}
.calendar-aside .list:hover .count .close {
  z-index: 2;
  opacity: 1;
}

.data-list {
  width: 100%;
  overflow: auto;
}
.data-list .list-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
}
.data-list .list-item:last-child {
  border-bottom: none;
}
.data-list .list-item .graph {
  margin-left: auto;
  width: 110px;
}

#sourceLineChartLegend {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
#sourceLineChartLegend li {
  display: inline-block;
  margin-right: 20px;
  color: #a7afb7;
}
#sourceLineChartLegend li .chart-color {
  height: 10px;
  width: 10px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 100%;
}
.rtl #sourceLineChartLegend li .chart-color {
  margin-right: 0px;
  margin-left: 5px;
}
#sourceLineChartLegend li:last-child {
  margin-right: 0;
}
.rtl #sourceLineChartLegend li {
  margin-right: 0px;
  margin-left: 20px;
}
.rtl #sourceLineChartLegend li:last-child {
  margin-left: 0px;
}

.social-card.card-default .action-bar i {
  color: #8e94a9;
}
.social-card.card-default .comment {
  color: #a7afb7;
}
.social-card.card-default .posted-date {
  color: #0062ff;
}
.social-card.card-colored .card-header {
  background: inherit;
}
.social-card.card-colored.facebook-card {
  background: #3b579d;
}
.social-card.card-colored.facebook-card .card-header {
  border-bottom-color: #344d8a;
}
.social-card.card-colored.twitter-card {
  background: #2caae1;
}
.social-card.card-colored.twitter-card .card-header {
  border-bottom-color: #1e9ed5;
}
.social-card.card-colored.google-card {
  background: #dc4a38;
}
.social-card.card-colored.google-card .card-header {
  border-bottom-color: #d53925;
}
.social-card.card-colored.linkedin-card {
  background: #0177b5;
}
.social-card.card-colored.linkedin-card .card-header {
  border-bottom-color: #01669c;
}
.social-card.card-colored a,
.social-card.card-colored .media-info,
.social-card.card-colored .user-name,
.social-card.card-colored .comment,
.social-card.card-colored .posted-date,
.social-card.card-colored .platform-name,
.social-card.card-colored .headline,
.social-card.card-colored .action-bar {
  color: #ffffff;
}

.top-selling-card .column-wrapper {
  display: flex;
  justify-content: space-around;
}
.top-selling-card .column-wrapper .column {
  width: 45%;
  padding: 25px 26px;
  border-radius: 3px;
  background: white;
  transform: translateY(-25px);
  box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.top-selling-card .column-wrapper .column i {
  font-size: 20px;
}
.top-selling-card .item-wrapper {
  max-height: 210px;
  overflow-y: auto;
}

.review-card .comment-text {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  max-width: 70%;
}

.list-item {
  display: flex;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
}
.list-item:first-child {
  padding-top: 30px;
}
.list-item:last-child {
  border-bottom: none;
}
.list-item .preview-image {
  max-width: 80px;
  width: 80px;
  min-width: 80px;
}
.list-item .preview-image img {
  display: block;
  margin: auto;
}
.list-item .content {
  width: calc(100% - 80px);
  padding-right: 30px;
}
.list-item .content .user-name {
  margin-bottom: 0;
  margin-right: 10px;
  color: #a7afb7;
  white-space: nowrap;
}
.list-item .content .time {
  color: #a7afb7;
  margin-bottom: 3px;
}
.list-item .content .product-name {
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}
.list-item .content .review-text {
  margin-bottom: 0;
  font-weight: 300;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  max-width: 95%;
}

.todo-list .todo-item {
  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
}
.todo-list .todo-item form {
  display: flex;
}
.todo-list .todo-item form .checkbox {
  width: 40px;
}
.todo-list .todo-item form .checkbox .form-check {
  margin-left: 10px;
}
.todo-list .todo-item form .checkbox .form-check .input-helper:after {
  line-height: 0px;
}
.todo-list .todo-item form .field-wrapper {
  width: calc(100% - 40px);
}
.todo-list .todo-item form .field-wrapper .form-group .form-control,
.todo-list .todo-item form .field-wrapper .form-group .asColorPicker-input,
.todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .dataTables_wrapper
  select,
.dataTables_wrapper
  .todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  select,
.todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  input[type="text"],
.todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  select,
.todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  input[type="number"],
.todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .select2-container--default
  .select2-selection--single,
.select2-container--default
  .todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .select2-selection--single,
.todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .select2-search__field,
.todo-list .todo-item form .field-wrapper .form-group .typeahead,
.todo-list .todo-item form .field-wrapper .form-group .tt-query,
.todo-list .todo-item form .field-wrapper .form-group .tt-hint {
  font-weight: 500;
}
.todo-list .todo-item form .field-wrapper .form-group .tagsinput .tag {
  color: #a7afb7;
}
.todo-list .todo-item form .field-wrapper .form-group .tagsinput .tag:before {
  content: "#";
  display: inline-block;
}
.todo-list .todo-item form .field-wrapper .form-group .btn,
.todo-list .todo-item form .field-wrapper .form-group .fc button,
.fc .todo-list .todo-item form .field-wrapper .form-group button,
.todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .ajax-upload-dragdrop
  .ajax-file-upload,
.ajax-upload-dragdrop
  .todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .ajax-file-upload,
.todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .swal2-styled,
.todo-list .todo-item form .field-wrapper .form-group .wizard > .actions a,
.wizard > .actions .todo-list .todo-item form .field-wrapper .form-group a {
  margin-right: 10px;
}
.todo-list .todo-item form .field-wrapper .form-group .btn:last-child,
.todo-list .todo-item form .field-wrapper .form-group .fc button:last-child,
.fc .todo-list .todo-item form .field-wrapper .form-group button:last-child,
.todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .ajax-upload-dragdrop
  .ajax-file-upload:last-child,
.ajax-upload-dragdrop
  .todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .ajax-file-upload:last-child,
.todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled:last-child,
.swal2-modal
  .swal2-buttonswrapper
  .todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .swal2-styled:last-child,
.todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  .wizard
  > .actions
  a:last-child,
.wizard
  > .actions
  .todo-list
  .todo-item
  form
  .field-wrapper
  .form-group
  a:last-child {
  margin-right: 0;
}
.todo-list .todo-item:not(.edit-mode) {
  position: relative;
}
.todo-list .todo-item:not(.edit-mode) .edit-icon {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}
.todo-list .todo-item:not(.edit-mode):hover {
  background: #aab2bd;
}
.todo-list .todo-item:not(.edit-mode):hover .edit-icon {
  display: block;
}
.todo-list .todo-item:not(.edit-mode):hover * {
  background: #aab2bd;
}
.todo-list .todo-item:not(.edit-mode) form .field-wrapper .form-group {
  margin-bottom: 0;
}
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .tagsinput {
  border: none;
  height: 25px;
  max-height: 25px !important;
  min-height: 25px !important;
  padding: 0px 13px !important;
}
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .tagsinput
  .tag {
  background: transparent;
  padding: 0;
  margin-bottom: 0;
}
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .tagsinput
  .tag
  a {
  display: none;
}
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .tagsinput
  #todo-tags-1_addTag,
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .tagsinput
  .tags_clear {
  display: none;
}
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .form-control,
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .asColorPicker-input,
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .dataTables_wrapper
  select,
.dataTables_wrapper
  .todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  select,
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  input[type="text"],
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  select,
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  input[type="number"],
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .select2-container--default
  .select2-selection--single,
.select2-container--default
  .todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .select2-selection--single,
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .select2-search__field,
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .typeahead,
.todo-list .todo-item:not(.edit-mode) form .field-wrapper .form-group .tt-query,
.todo-list .todo-item:not(.edit-mode) form .field-wrapper .form-group .tt-hint {
  border: none;
}
.todo-list .todo-item:not(.edit-mode) form .field-wrapper .form-group .btn,
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .fc
  button,
.fc
  .todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  button,
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .ajax-upload-dragdrop
  .ajax-file-upload,
.ajax-upload-dragdrop
  .todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .ajax-file-upload,
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .swal2-modal
  .swal2-buttonswrapper
  .swal2-styled,
.swal2-modal
  .swal2-buttonswrapper
  .todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .swal2-styled,
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  .wizard
  > .actions
  a,
.wizard
  > .actions
  .todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group
  a {
  display: none;
}
.todo-list
  .todo-item:not(.edit-mode)
  form
  .field-wrapper
  .form-group.hidden-field {
  display: none;
}
.todo-list
  .todo-item.edit-mode
  form
  .field-wrapper
  .form-group
  .tagsinput
  .tag {
  color: #ffffff;
}

.activity-timeline .activity {
  position: relative;
}
.activity-timeline .activity:before {
  content: "";
  position: absolute;
  left: 21px;
  top: 5px;
  bottom: 0px;
  height: 100%;
  display: block;
  width: 2px;
  background: #dee2e6;
}
.activity-timeline .activity .icon-wrapper {
  border: 3px solid #fff;
}
.activity-timeline .activity:last-child:before {
  display: none;
}

.product-info-card .product-image {
  height: 100px;
}

.tickets-tab {
  border-bottom: none;
}
.tickets-tab .nav-item {
  display: block;
  width: 33.33%;
  padding: 0 0px;
}
@media (max-width: 991px) {
  .tickets-tab .nav-item {
    width: 100%;
  }
}
.tickets-tab .nav-item .nav-link {
  border: none;
  background: transparent;
  display: flex;
  justify-content: space-between;
}
.tickets-tab .nav-item .nav-link .badge {
  background: #0062ff;
  color: #ffffff;
}
.tickets-tab .nav-item .nav-link.active {
  background: #8e94a9;
  color: #ffffff;
}
.tickets-tab .nav-item .nav-link.active .badge {
  background: #ffc542;
}

.tickets-tab-content {
  border: none;
  padding: 2rem 0;
}
.tickets-tab-content .tickets-card {
  text-decoration: none;
  color: #a7afb7;
  border: 1px solid rgba(151, 151, 151, 0.3);
  display: block;
  padding: 0.875rem;
  margin: 1rem 0;
}
.tickets-tab-content .tickets-card .assigned-to {
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
.tickets-tab-content .tickets-card:hover {
  background: #f2f3f5;
}

/*-------------------------------------------------------------------*/
/* === Landing screens === */
/* Auth */
.auth .login-half-bg {
  /* background: url("../../images/auth/login-bg.jpg"); */
  background-size: cover;
}
.auth .register-half-bg {
  /* background: url("../../images/auth/register-bg.jpg"); */
  background-size: cover;
}
.auth.lock-full-bg {
  /* background: url("../../images/auth/lockscreen-bg.jpg"); */
  background-size: cover;
}
.auth .lock-profile-img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}
.auth .auth-form-dark {
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
}
.auth .auth-form-dark .form-control,
.auth .auth-form-dark .asColorPicker-input,
.auth .auth-form-dark .dataTables_wrapper select,
.dataTables_wrapper .auth .auth-form-dark select,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  input[type="text"],
.auth .auth-form-dark .jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-filter-row .auth .auth-form-dark select,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  input[type="number"],
.auth .auth-form-dark .select2-container--default .select2-selection--single,
.select2-container--default .auth .auth-form-dark .select2-selection--single,
.auth
  .auth-form-dark
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-dark
  .select2-search__field,
.auth .auth-form-dark .typeahead,
.auth .auth-form-dark .tt-query,
.auth .auth-form-dark .tt-hint {
  border-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
.auth .auth-form-dark .form-control:-ms-input-placeholder,
.auth .auth-form-dark .asColorPicker-input:-ms-input-placeholder,
.auth .auth-form-dark .dataTables_wrapper select:-ms-input-placeholder,
.dataTables_wrapper .auth .auth-form-dark select:-ms-input-placeholder,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:-ms-input-placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  input[type="text"]:-ms-input-placeholder,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:-ms-input-placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  select:-ms-input-placeholder,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:-ms-input-placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  input[type="number"]:-ms-input-placeholder,
.auth
  .auth-form-dark
  .select2-container--default
  .select2-selection--single:-ms-input-placeholder,
.select2-container--default
  .auth
  .auth-form-dark
  .select2-selection--single:-ms-input-placeholder,
.auth
  .auth-form-dark
  .select2-container--default
  .select2-selection--single
  .select2-search__field:-ms-input-placeholder,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-dark
  .select2-search__field:-ms-input-placeholder,
.auth .auth-form-dark .typeahead:-ms-input-placeholder,
.auth .auth-form-dark .tt-query:-ms-input-placeholder,
.auth .auth-form-dark .tt-hint:-ms-input-placeholder {
  color: #ffffff;
}
.auth .auth-form-dark .form-control:-moz-placeholder,
.auth .auth-form-dark .asColorPicker-input:-moz-placeholder,
.auth .auth-form-dark .dataTables_wrapper select:-moz-placeholder,
.dataTables_wrapper .auth .auth-form-dark select:-moz-placeholder,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:-moz-placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  input[type="text"]:-moz-placeholder,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:-moz-placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  select:-moz-placeholder,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:-moz-placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  input[type="number"]:-moz-placeholder,
.auth
  .auth-form-dark
  .select2-container--default
  .select2-selection--single:-moz-placeholder,
.select2-container--default
  .auth
  .auth-form-dark
  .select2-selection--single:-moz-placeholder,
.auth
  .auth-form-dark
  .select2-container--default
  .select2-selection--single
  .select2-search__field:-moz-placeholder,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-dark
  .select2-search__field:-moz-placeholder,
.auth .auth-form-dark .typeahead:-moz-placeholder,
.auth .auth-form-dark .tt-query:-moz-placeholder,
.auth .auth-form-dark .tt-hint:-moz-placeholder {
  color: #ffffff;
}
.auth .auth-form-dark .form-control::-moz-placeholder,
.auth .auth-form-dark .asColorPicker-input::-moz-placeholder,
.auth .auth-form-dark .dataTables_wrapper select::-moz-placeholder,
.dataTables_wrapper .auth .auth-form-dark select::-moz-placeholder,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]::-moz-placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  input[type="text"]::-moz-placeholder,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select::-moz-placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  select::-moz-placeholder,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]::-moz-placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  input[type="number"]::-moz-placeholder,
.auth
  .auth-form-dark
  .select2-container--default
  .select2-selection--single::-moz-placeholder,
.select2-container--default
  .auth
  .auth-form-dark
  .select2-selection--single::-moz-placeholder,
.auth
  .auth-form-dark
  .select2-container--default
  .select2-selection--single
  .select2-search__field::-moz-placeholder,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-dark
  .select2-search__field::-moz-placeholder,
.auth .auth-form-dark .typeahead::-moz-placeholder,
.auth .auth-form-dark .tt-query::-moz-placeholder,
.auth .auth-form-dark .tt-hint::-moz-placeholder {
  color: #ffffff;
}
.auth .auth-form-dark .form-control::-webkit-input-placeholder,
.auth .auth-form-dark .asColorPicker-input::-webkit-input-placeholder,
.auth .auth-form-dark .dataTables_wrapper select::-webkit-input-placeholder,
.dataTables_wrapper .auth .auth-form-dark select::-webkit-input-placeholder,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]::-webkit-input-placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  input[type="text"]::-webkit-input-placeholder,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select::-webkit-input-placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  select::-webkit-input-placeholder,
.auth
  .auth-form-dark
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]::-webkit-input-placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-dark
  input[type="number"]::-webkit-input-placeholder,
.auth
  .auth-form-dark
  .select2-container--default
  .select2-selection--single::-webkit-input-placeholder,
.select2-container--default
  .auth
  .auth-form-dark
  .select2-selection--single::-webkit-input-placeholder,
.auth
  .auth-form-dark
  .select2-container--default
  .select2-selection--single
  .select2-search__field::-webkit-input-placeholder,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-dark
  .select2-search__field::-webkit-input-placeholder,
.auth .auth-form-dark .typeahead::-webkit-input-placeholder,
.auth .auth-form-dark .tt-query::-webkit-input-placeholder,
.auth .auth-form-dark .tt-hint::-webkit-input-placeholder {
  color: #ffffff;
}
.auth .auth-form-light {
  background: #ffffff;
}
.auth .auth-form-light select {
  color: #a7afb7;
  border-radius: 0;
}
.auth .auth-form-light select option:nth-child(1) {
  color: #000000;
}
.auth .auth-form-light .form-select {
  background-color: transparent;
  border-radius: 0;
  color: #a7afb7;
  font-size: 0.9375rem;
  padding: 15.04px 31.04px;
}
.auth .auth-form-light .input-group .form-control:focus,
.auth .auth-form-light .asColorPicker-wrap .form-control:focus,
.auth .auth-form-light .input-group .asColorPicker-input:focus,
.auth .auth-form-light .asColorPicker-wrap .asColorPicker-input:focus,
.auth .auth-form-light .input-group .dataTables_wrapper select:focus,
.dataTables_wrapper .auth .auth-form-light .input-group select:focus,
.auth .auth-form-light .asColorPicker-wrap .dataTables_wrapper select:focus,
.dataTables_wrapper .auth .auth-form-light .asColorPicker-wrap select:focus,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  input[type="text"]:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  input[type="text"]:focus,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  select:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  select:focus,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  input[type="number"]:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  input[type="number"]:focus,
.auth
  .auth-form-light
  .input-group
  .select2-container--default
  .select2-selection--single:focus,
.select2-container--default
  .auth
  .auth-form-light
  .input-group
  .select2-selection--single:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single:focus,
.select2-container--default
  .auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-selection--single:focus,
.auth
  .auth-form-light
  .input-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-light
  .input-group
  .select2-search__field:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-search__field:focus,
.auth .auth-form-light .input-group .typeahead:focus,
.auth .auth-form-light .asColorPicker-wrap .typeahead:focus,
.auth .auth-form-light .input-group .tt-query:focus,
.auth .auth-form-light .asColorPicker-wrap .tt-query:focus,
.auth .auth-form-light .input-group .tt-hint:focus,
.auth .auth-form-light .asColorPicker-wrap .tt-hint:focus,
.auth .auth-form-light .input-group .form-control:active,
.auth .auth-form-light .asColorPicker-wrap .form-control:active,
.auth .auth-form-light .input-group .asColorPicker-input:active,
.auth .auth-form-light .asColorPicker-wrap .asColorPicker-input:active,
.auth .auth-form-light .input-group .dataTables_wrapper select:active,
.dataTables_wrapper .auth .auth-form-light .input-group select:active,
.auth .auth-form-light .asColorPicker-wrap .dataTables_wrapper select:active,
.dataTables_wrapper .auth .auth-form-light .asColorPicker-wrap select:active,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  input[type="text"]:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  input[type="text"]:active,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  select:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  select:active,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  input[type="number"]:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  input[type="number"]:active,
.auth
  .auth-form-light
  .input-group
  .select2-container--default
  .select2-selection--single:active,
.select2-container--default
  .auth
  .auth-form-light
  .input-group
  .select2-selection--single:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single:active,
.select2-container--default
  .auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-selection--single:active,
.auth
  .auth-form-light
  .input-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field:active,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-light
  .input-group
  .select2-search__field:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single
  .select2-search__field:active,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-search__field:active,
.auth .auth-form-light .input-group .typeahead:active,
.auth .auth-form-light .asColorPicker-wrap .typeahead:active,
.auth .auth-form-light .input-group .tt-query:active,
.auth .auth-form-light .asColorPicker-wrap .tt-query:active,
.auth .auth-form-light .input-group .tt-hint:active,
.auth .auth-form-light .asColorPicker-wrap .tt-hint:active {
  border-color: rgba(151, 151, 151, 0.3);
}
.auth .auth-form-transparent {
  background: transparent;
}
.auth .auth-form-transparent .form-control,
.auth .auth-form-transparent .asColorPicker-input,
.auth .auth-form-transparent .dataTables_wrapper select,
.dataTables_wrapper .auth .auth-form-transparent select,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="text"],
.auth .auth-form-transparent .jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-filter-row .auth .auth-form-transparent select,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="number"],
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single,
.select2-container--default
  .auth
  .auth-form-transparent
  .select2-selection--single,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-transparent
  .select2-search__field,
.auth .auth-form-transparent .typeahead,
.auth .auth-form-transparent .tt-query,
.auth .auth-form-transparent .tt-hint,
.auth .auth-form-transparent .input-group-text {
  border-color: #8e94a9;
  height: 100%;
}
.auth .auth-form-transparent .form-control:focus,
.auth .auth-form-transparent .asColorPicker-input:focus,
.auth .auth-form-transparent .dataTables_wrapper select:focus,
.dataTables_wrapper .auth .auth-form-transparent select:focus,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="text"]:focus,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  select:focus,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="number"]:focus,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single:focus,
.select2-container--default
  .auth
  .auth-form-transparent
  .select2-selection--single:focus,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-transparent
  .select2-search__field:focus,
.auth .auth-form-transparent .typeahead:focus,
.auth .auth-form-transparent .tt-query:focus,
.auth .auth-form-transparent .tt-hint:focus,
.auth .auth-form-transparent .form-control:active,
.auth .auth-form-transparent .asColorPicker-input:active,
.auth .auth-form-transparent .dataTables_wrapper select:active,
.dataTables_wrapper .auth .auth-form-transparent select:active,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="text"]:active,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  select:active,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="number"]:active,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single:active,
.select2-container--default
  .auth
  .auth-form-transparent
  .select2-selection--single:active,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single
  .select2-search__field:active,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-transparent
  .select2-search__field:active,
.auth .auth-form-transparent .typeahead:active,
.auth .auth-form-transparent .tt-query:active,
.auth .auth-form-transparent .tt-hint:active,
.auth .auth-form-transparent .input-group-text:focus,
.auth .auth-form-transparent .input-group-text:active {
  border-color: #8e94a9;
}
.auth .auth-form-transparent select {
  outline-color: #8e94a9;
}
.auth .auth-form-transparent .form-select {
  background-color: transparent;
  border: 1px solid;
  border-radius: 0;
  color: #a7afb7;
  font-size: 0.9375rem;
  border-color: #8e94a9;
  padding: 15.04px 31.04px;
}
.auth.auth-img-bg {
  padding: 0;
}
@media (min-width: 768px) {
  .auth.auth-img-bg .auth-form-transparent {
    width: 55%;
    margin: auto;
  }
}
.auth .brand-logo {
  margin-bottom: 2rem;
}
.auth .brand-logo img {
  width: 150px;
}
.auth form .form-group {
  margin-bottom: 1.5rem;
}
.auth form .form-group label {
  font-size: 0.8125rem;
}
.auth form .form-group .form-control,
.auth form .form-group .asColorPicker-input,
.auth form .form-group .dataTables_wrapper select,
.dataTables_wrapper .auth form .form-group select,
.auth
  form
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  form
  .form-group
  input[type="text"],
.auth form .form-group .jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-filter-row .auth form .form-group select,
.auth
  form
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  form
  .form-group
  input[type="number"],
.auth form .form-group .select2-container--default .select2-selection--single,
.select2-container--default .auth form .form-group .select2-selection--single,
.auth
  form
  .form-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .auth
  form
  .form-group
  .select2-search__field,
.auth form .form-group .typeahead,
.auth form .form-group .tt-query,
.auth form .form-group .tt-hint {
  background: transparent;
  border-radius: 0;
  font-size: 0.9375rem;
}
.auth form .form-group .form-control::placeholder,
.auth form .form-group .asColorPicker-input::placeholder,
.auth form .form-group .dataTables_wrapper select::placeholder,
.dataTables_wrapper .auth form .form-group select::placeholder,
.auth
  form
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]::placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  form
  .form-group
  input[type="text"]::placeholder,
.auth
  form
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select::placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  form
  .form-group
  select::placeholder,
.auth
  form
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]::placeholder,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  form
  .form-group
  input[type="number"]::placeholder,
.auth
  form
  .form-group
  .select2-container--default
  .select2-selection--single::placeholder,
.select2-container--default
  .auth
  form
  .form-group
  .select2-selection--single::placeholder,
.auth
  form
  .form-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field::placeholder,
.select2-container--default
  .select2-selection--single
  .auth
  form
  .form-group
  .select2-search__field::placeholder,
.auth form .form-group .typeahead::placeholder,
.auth form .form-group .tt-query::placeholder,
.auth form .form-group .tt-hint::placeholder {
  color: #a7afb7 !important;
}
.auth form .auth-form-btn {
  line-height: 1.5;
}
.auth form .auth-link {
  font-size: 0.875rem;
}
.auth form .auth-link:hover {
  color: initial;
}
.auth.multi-step-login {
  height: 100%;
  min-height: 100vh;
  background: #f0f1f6;
}
.auth.multi-step-login .step-form {
  position: relative;
  width: 530px;
  margin: 50px auto;
  text-align: center;
}
.auth.multi-step-login .step-form .step-progress {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 30px;
  overflow: hidden;
  counter-reset: step;
  display: flex;
  justify-content: space-around;
}
.auth.multi-step-login .step-form .step-progress li {
  position: relative;
  list-style-type: none;
  color: #a7afb7;
  font-size: 18px;
  font-weight: 500;
  float: left;
}
.auth.multi-step-login .step-form .step-progress li:before {
  display: flex;
  align-items: center;
  justify-content: center;
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #a7afb7;
  background: #e0e2ec;
  border-radius: 100px;
  margin: 0 auto 5px auto;
}
.auth.multi-step-login .step-form .step-progress li.active:before {
  background: #44ce42;
  color: #fff;
}
.auth.multi-step-login .step-form .step-progress li:first-child:after {
  content: none;
}
.auth.multi-step-login .step-form fieldset {
  background: #fff;
  border-radius: 10px;
  padding: 30px 30px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  position: relative;
}
.auth.multi-step-login .step-form fieldset:not(:first-of-type) {
  display: none;
}
.auth.multi-step-login .step-form .form-control,
.auth.multi-step-login .step-form .asColorPicker-input,
.auth.multi-step-login .step-form .dataTables_wrapper select,
.dataTables_wrapper .auth.multi-step-login .step-form select,
.auth.multi-step-login
  .step-form
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth.multi-step-login
  .step-form
  input[type="text"],
.auth.multi-step-login
  .step-form
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth.multi-step-login
  .step-form
  select,
.auth.multi-step-login
  .step-form
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth.multi-step-login
  .step-form
  input[type="number"],
.auth.multi-step-login
  .step-form
  .select2-container--default
  .select2-selection--single,
.select2-container--default
  .auth.multi-step-login
  .step-form
  .select2-selection--single,
.auth.multi-step-login
  .step-form
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .auth.multi-step-login
  .step-form
  .select2-search__field,
.auth.multi-step-login .step-form .typeahead,
.auth.multi-step-login .step-form .tt-query,
.auth.multi-step-login .step-form .tt-hint {
  height: 40px;
  border-radius: 4px;
}

/* Lock Screen */
.lock-screen {
  position: relative;
  overflow: hidden;
}
.lock-screen .backhground-bg {
  position: absolute;
  top: -20px;
  right: -20px;
  left: -20px;
  bottom: -20px;
  /* background: url("../images/samples/lockscreen-bg.jpg") no-repeat center center; */
  background-size: cover;
  overflow: hidden;
  min-height: 100%;
}
.lock-screen .card {
  background: rgba(0, 0, 0, 0);
  border-radius: 6px;
}
.lock-screen .card .card-body img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  border: 3px solid rgba(255, 255, 255, 0.2);
}
.lock-screen .card .card-body input[type="text"] {
  background: rgba(255, 255, 255, 0.55);
  border: none;
  width: 80%;
  margin: auto;
}

/* Settings Panel */
.settings-panel {
  display: block;
  position: fixed;
  top: 0;
  right: -300px;
  bottom: 0;
  width: 300px;
  height: 100vh;
  min-height: 100%;
  background: #ffffff;
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: right, box-shadow;
  -moz-transition-property: right, box-shadow;
  -o-transition-property: right, box-shadow;
  transition-property: right, box-shadow;
  z-index: 9;
}
.settings-panel .nav-tabs {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  width: auto;
  margin: 0;
  border-bottom: none;
  padding: 0;
}
.settings-panel .nav-tabs .nav-item {
  border: none;
}
.settings-panel .nav-tabs .nav-item .nav-link {
  text-align: center;
  border: none;
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  transition-property: color;
  -webkit-transition-property: color;
  -webkit-justify-content: center;
  justify-content: center;
}
.settings-panel .nav-tabs .nav-item .nav-link.active {
  background: transparent;
  color: #ffffff;
}
.settings-panel .tab-content {
  border: none;
  padding: 20px 0 0px 0;
}
.settings-panel .tab-content .tab-pane.scroll-wrapper {
  position: relative;
  max-height: 100vh;
  height: 100%;
  padding-bottom: 100px;
}
.settings-panel .settings-heading {
  padding: 16px 0 13px 35px;
  font-size: 0.875rem;
  
  line-height: 1;
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.9;
  margin-bottom: 0;
  border-top: 1px solid rgba(151, 151, 151, 0.3);
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
}
.rtl .settings-panel .settings-heading {
  padding: 16px 35px 13px 0;
}
.rtl .settings-panel small.settings-heading,
.rtl .settings-panel .settings-heading.small {
  padding: 16px 0 13px 12px;
}
.settings-panel .sidebar-bg-options {
  padding: 13px 35px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1;
  color: #595959;
  background: #ffffff;
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-property: background;
  -moz-transition-property: background;
  -o-transition-property: background;
  transition-property: background;
}
.settings-panel .sidebar-bg-options.selected {
  background: #e6e9ed;
}
.settings-panel .color-tiles {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0px 35px 10px 35px;
  padding-top: 15px;
}
.settings-panel .color-tiles .tiles {
  margin: 10px 18px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.settings-panel .color-tiles .tiles:before {
  content: "";
  width: 0px;
  height: 0px;
  opacity: 0;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  border: 0px solid rgba(255, 255, 255, 0.4);
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
}
.settings-panel .color-tiles .tiles.selected:before {
  width: 10px;
  height: 10px;
  opacity: 1;
  border-width: 1px;
}
.settings-panel .color-tiles .tiles.light {
  border: 1px solid #e2e4e7;
}
.settings-panel .chat-list {
  padding-left: 0;
}
.settings-panel .chat-list .list {
  padding: 0.4rem 0.8rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
}
.settings-panel .chat-list .list:last-child {
  border-bottom: none;
}
.settings-panel .chat-list .list .profile {
  position: relative;
  margin-right: 1rem;
}
.settings-panel .chat-list .list .profile img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
}
.settings-panel .chat-list .list .profile span {
  height: 0.75rem;
  width: 0.75rem;
  position: absolute;
  bottom: 0.34rem;
  right: 0;
  border: 0.13rem solid #ffffff;
  border-radius: 100%;
}
.settings-panel .chat-list .list .profile span.online {
  background: #44ce42;
}
.settings-panel .chat-list .list .profile span.offline {
  background: #ffc542;
}
.settings-panel .chat-list .list .info {
  margin-right: auto;
}
.settings-panel .chat-list .list .info p {
  display: block;
  margin-bottom: 0;
}
.settings-panel .chat-list .list .info p:last-child {
  opacity: 0.5;
  font-size: 0.8rem;
}
.settings-panel .chat-list .list.active {
  background: #aab2bd;
}
.settings-panel.open {
  right: 0;
  -webkit-box-shadow: 7px 0px 80px -9px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 7px 0px 80px -9px rgba(0, 0, 0, 0.15);
  box-shadow: 7px 0px 80px -9px rgba(0, 0, 0, 0.15);
}
.settings-panel .settings-close {
  position: absolute;
  top: 8px;
  right: 10px;
  color: #ffffff;
  background: transparent;
  border-radius: 4px;
  padding: 0 3px;
  cursor: pointer;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  z-index: 9;
}
.settings-panel .settings-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.rtl .settings-panel .settings-close {
  right: unset;
  left: 10px;
}
.settings-panel .events p {
  
}
.rtl .settings-panel {
  right: unset;
  left: -300px;
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  transition-property: left;
}
.rtl .settings-panel .chat-list {
  padding-right: 0;
}
.rtl .settings-panel .chat-list .list .profile {
  margin-right: 0;
  margin-left: 1rem;
}
.rtl .settings-panel .chat-list .list .info {
  margin-right: 0;
  margin-left: auto;
}
.rtl .settings-panel .chat-list .list .info .badge {
  margin-right: 10px;
}
.rtl .settings-panel.open {
  left: 0;
  right: unset;
}

#theme-settings .settings-close {
  top: 12px;
}

#settings-trigger {
  position: fixed;
  bottom: 40px;
  right: 30px;
  height: 45px;
  width: 45px;
  background: #0062ff;
  z-index: 9;
  -webkit-box-shadow: 0px 0px 31px 6px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px 0px 31px 6px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 0px 31px 6px rgba(0, 0, 0, 0.17);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  border-radius: 100%;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -ms-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
#settings-trigger i {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1rem;
}
@media (max-width: 991px) {
  #settings-trigger {
    display: none;
  }
}

/* Navbar */
.navbar {
  background: #ffffff;
  transition: background 0.25s ease;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease;
}
.navbar .navbar-brand {
  width: 110px;
}
.navbar .navbar-brand-wrapper {
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  background: #00528a;
  width: 258px;
  height: 64px;
}
@media (max-width: 991px) {
  .navbar .navbar-brand-wrapper {
    width: 55px;
  }
}
.navbar .navbar-brand-wrapper .navbar-brand {
  color: #27367f;
  font-size: 1.5rem;
  line-height: 48px;
  margin-right: 0;
  padding: 0.25rem 0;
  width: 100%;
}
.navbar .navbar-brand-wrapper .navbar-brand:active,
.navbar .navbar-brand-wrapper .navbar-brand:focus,
.navbar .navbar-brand-wrapper .navbar-brand:hover {
  color: #1b2658;
}
.navbar .navbar-brand-wrapper .navbar-brand img {
  width: calc(258px - 120px);
  max-width: 100%;
  height: 28px;
  margin: auto;
  vertical-align: middle;
}
.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
  display: none;
}
.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
  width: auto;
  max-width: 100%;
  height: 45px;
  margin: auto;
  object-fit: contain;
}
.navbar .navbar-menu-wrapper {
  transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -ms-transition: width 0.25s ease;
  color: #111111;
  padding-left: 24px;
  padding-right: 24px;
  width: calc(100% - 258px);
  height: 64px;
}
@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.navbar .navbar-menu-wrapper .navbar-toggler {
  border: 0;
  color: #8e94a9;
  height: 64px;
  border-radius: 0px;
  padding-left: 5px;
  padding-right: 20px;
}
.navbar .navbar-menu-wrapper .navbar-toggler:focus {
  border: none;
  box-shadow: none;
}
.navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
  font-size: 0.875rem;
}
@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
    display: none;
  }
}
@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
    padding-left: 15px;
    padding-right: 11px;
    border-right: none;
  }
}
.navbar .navbar-menu-wrapper .search-field .input-group,
.navbar .navbar-menu-wrapper .search-field .asColorPicker-wrap {
  background: #eef0fa;
  width: 305px;
}
.navbar .navbar-menu-wrapper .search-field .input-group input,
.navbar .navbar-menu-wrapper .search-field .asColorPicker-wrap input {
  font-size: 0.875rem;
  padding: 0.5rem;
}
.navbar
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-ms-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-ms-input-placeholder {
  font-size: 0.875rem;
  color: #111111;
  
}
.navbar .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-moz-placeholder {
  font-size: 0.875rem;
  color: #111111;
  
}
.navbar .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder,
.navbar
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-moz-placeholder {
  font-size: 0.875rem;
  color: #111111;
  
}
.navbar
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-webkit-input-placeholder,
.navbar
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-webkit-input-placeholder {
  font-size: 0.875rem;
  color: #111111;
  
}
.navbar .navbar-menu-wrapper .search-field .input-group i,
.navbar .navbar-menu-wrapper .search-field .asColorPicker-wrap i {
  font-size: 14px;
  margin-right: 0;
  color: #8e94a9;
}
.navbar .navbar-menu-wrapper .search-field .input-group .input-group-text,
.navbar
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  .input-group-text {
  background: transparent;
}
.navbar .navbar-menu-wrapper .count-indicator {
  position: relative;
}
.navbar .navbar-menu-wrapper .count-indicator .count-symbol,
.navbar .navbar-menu-wrapper .count-indicator .count-number {
  position: absolute;
  border-radius: 100%;
  border: 2px solid #ffffff;
  animation: pulse 3s ease-out;
  animation-iteration-count: infinite;
}
@keyframes pulse {
  40% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.5, 1.5, 1.5);
  }
  55% {
    transform: scale3d(1, 1, 1);
  }
  60% {
    transform: scale3d(1.5, 1.5, 1.5);
  }
  65% {
    transform: scale3d(1, 1, 1);
  }
}
.navbar .navbar-menu-wrapper .count-indicator .count-symbol {
  top: 20px;
  right: 0px;
  width: 10px;
  height: 10px;
}
.navbar .navbar-menu-wrapper .count-indicator .count-number {
  min-width: 14px;
  height: 14px;
  font-size: 0.5rem;
  color: #ffffff;
  bottom: 16px;
  right: -5px;
  line-height: 1;
  text-align: center;
}
.navbar .navbar-menu-wrapper .count-indicator:after {
  display: none;
}
.navbar .navbar-menu-wrapper .navbar-nav {
  flex-direction: row;
  align-items: center;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
  color: inherit;
  font-size: 0.875rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  height: 64px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
@media (max-width: 767px) {
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link i {
  font-size: 1.25rem;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-toggle:after {
  color: #aab2bd;
  font-size: 1.25rem;
  content: "\f035d";
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
  border: none;
  -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  min-width: 220px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown {
  position: absolute;
  font-size: 0.9rem;
  margin-top: 0;
  padding: 0;
}
.rtl
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown {
  right: auto;
  left: 0;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item {
  margin-bottom: 0;
  padding: 11px 13px;
  cursor: pointer;
  color: #111111;
  font-size: 0.875rem;
  font-style: normal;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item
  .badge {
  padding: 2px 6px;
  font-size: 10px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item
  i {
  font-size: 17px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item
  .ellipsis,
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item
  .chat-app-wrapper
  .chat-list-wrapper
  .chat-list-item-wrapper
  .list-item
  .user-name,
.chat-app-wrapper
  .chat-list-wrapper
  .chat-list-item-wrapper
  .list-item
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item
  .user-name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rtl
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item
  i {
  margin-left: 5px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-divider {
  margin: 0;
}
@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown {
    position: static;
  }
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
    left: 20px;
    right: 20px;
    top: 64px;
    width: calc(100% - 40px);
  }
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings {
  padding-left: 20px;
  padding-right: 5px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings .nav-link {
  margin-right: 0;
  margin-left: 0;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-img {
  position: relative;
  width: 32px;
  height: 32px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-img
  img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-text {
  margin-left: 0.875rem;
}
.rtl
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-text {
  margin-left: 0;
  margin-right: 0.875rem;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-text
  p {
  line-height: 1;
}
@media (max-width: 767px) {
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-profile
    .nav-link
    .nav-profile-text {
    display: none;
  }
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link.dropdown-toggle:after {
  line-height: 2;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-language
  .nav-link
  .nav-language-icon {
  position: relative;
  width: 22px;
  height: 28px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-language
  .nav-link
  .nav-language-icon
  i {
  font-size: 1rem;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-language
  .nav-link
  .nav-language-text {
  margin-left: 0.875rem;
}
.rtl
  .navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-language
  .nav-link
  .nav-language-text {
  margin-left: 0;
  margin-right: 0.875rem;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-language
  .nav-link
  .nav-language-text
  p {
  line-height: 1;
}
@media (max-width: 767px) {
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-language
    .nav-link
    .nav-language-text {
    display: none;
  }
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-language
  .nav-link.dropdown-toggle:after {
  line-height: 2;
}
@media (min-width: 992px) {
  .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    margin-left: auto;
  }
  .rtl .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    margin-left: 0;
    margin-right: auto;
  }
}

@media (max-width: 991px) {
  .navbar {
    flex-direction: row;
  }
  .navbar .navbar-brand-wrapper {
    width: 75px;
  }
  .navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
    display: none;
  }
  .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
    display: inline-block;
  }
  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}
@media (max-width: 480px) {
  .navbar .navbar-brand-wrapper {
    width: 55px;
  }
  .navbar .navbar-brand-wrapper .brand-logo-mini {
    padding-top: 0px;
  }
}
/* Navbar color variations */
.navbar.navbar-primary .navbar-menu-wrapper {
  background: linear-gradient(to right, #da8cff, #9a55ff);
  color: #ffffff;
}
.navbar.navbar-primary
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-ms-input-placeholder,
.navbar.navbar-primary
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-ms-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-primary
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-moz-placeholder,
.navbar.navbar-primary
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-primary
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-moz-placeholder,
.navbar.navbar-primary
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-primary
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-webkit-input-placeholder,
.navbar.navbar-primary
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-webkit-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group i,
.navbar.navbar-primary
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  i {
  color: #ffffff;
}

.navbar.navbar-secondary .navbar-menu-wrapper {
  background: linear-gradient(to right, #e7ebf0, #868e96);
  color: #ffffff;
}
.navbar.navbar-secondary
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-ms-input-placeholder,
.navbar.navbar-secondary
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-ms-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-secondary
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-moz-placeholder,
.navbar.navbar-secondary
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-secondary
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-moz-placeholder,
.navbar.navbar-secondary
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-secondary
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-webkit-input-placeholder,
.navbar.navbar-secondary
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-webkit-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group i,
.navbar.navbar-secondary
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  i {
  color: #ffffff;
}

.navbar.navbar-success .navbar-menu-wrapper {
  background: linear-gradient(to right, #84d9d2, #07cdae);
  color: #ffffff;
}
.navbar.navbar-success
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-ms-input-placeholder,
.navbar.navbar-success
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-ms-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-success
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-moz-placeholder,
.navbar.navbar-success
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-success
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-moz-placeholder,
.navbar.navbar-success
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-success
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-webkit-input-placeholder,
.navbar.navbar-success
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-webkit-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-success .navbar-menu-wrapper .search-field .input-group i,
.navbar.navbar-success
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  i {
  color: #ffffff;
}

.navbar.navbar-info .navbar-menu-wrapper {
  background: linear-gradient(to right, #90caf9, #047edf 99%);
  color: #ffffff;
}
.navbar.navbar-info
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-ms-input-placeholder,
.navbar.navbar-info
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-ms-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-info
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-moz-placeholder,
.navbar.navbar-info
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-info
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-moz-placeholder,
.navbar.navbar-info
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-info
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-webkit-input-placeholder,
.navbar.navbar-info
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-webkit-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-info .navbar-menu-wrapper .search-field .input-group i,
.navbar.navbar-info .navbar-menu-wrapper .search-field .asColorPicker-wrap i {
  color: #ffffff;
}

.navbar.navbar-warning .navbar-menu-wrapper {
  background: linear-gradient(to right, #f6e384, #ffd500);
  color: #ffffff;
}
.navbar.navbar-warning
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-ms-input-placeholder,
.navbar.navbar-warning
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-ms-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-warning
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-moz-placeholder,
.navbar.navbar-warning
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-warning
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-moz-placeholder,
.navbar.navbar-warning
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-warning
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-webkit-input-placeholder,
.navbar.navbar-warning
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-webkit-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group i,
.navbar.navbar-warning
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  i {
  color: #ffffff;
}

.navbar.navbar-danger .navbar-menu-wrapper {
  background: linear-gradient(to right, #ffbf96, #fe7096);
  color: #ffffff;
}
.navbar.navbar-danger
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-ms-input-placeholder,
.navbar.navbar-danger
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-ms-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-danger
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-moz-placeholder,
.navbar.navbar-danger
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-danger
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-moz-placeholder,
.navbar.navbar-danger
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-danger
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-webkit-input-placeholder,
.navbar.navbar-danger
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-webkit-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group i,
.navbar.navbar-danger .navbar-menu-wrapper .search-field .asColorPicker-wrap i {
  color: #ffffff;
}

.navbar.navbar-light .navbar-menu-wrapper {
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
  color: #ffffff;
}
.navbar.navbar-light
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-ms-input-placeholder,
.navbar.navbar-light
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-ms-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-light
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-moz-placeholder,
.navbar.navbar-light
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-light
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-moz-placeholder,
.navbar.navbar-light
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-light
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-webkit-input-placeholder,
.navbar.navbar-light
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-webkit-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-light .navbar-menu-wrapper .search-field .input-group i,
.navbar.navbar-light .navbar-menu-wrapper .search-field .asColorPicker-wrap i {
  color: #ffffff;
}

.navbar.navbar-dark .navbar-menu-wrapper {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
  color: #ffffff;
}
.navbar.navbar-dark
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-ms-input-placeholder,
.navbar.navbar-dark
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-ms-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-dark
  .navbar-menu-wrapper
  .search-field
  .input-group
  input:-moz-placeholder,
.navbar.navbar-dark
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input:-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-dark
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-moz-placeholder,
.navbar.navbar-dark
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-moz-placeholder {
  color: #ffffff;
}
.navbar.navbar-dark
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::-webkit-input-placeholder,
.navbar.navbar-dark
  .navbar-menu-wrapper
  .search-field
  .asColorPicker-wrap
  input::-webkit-input-placeholder {
  color: #ffffff;
}
.navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group i,
.navbar.navbar-dark .navbar-menu-wrapper .search-field .asColorPicker-wrap i {
  color: #ffffff;
}

/* Sidebar */
.sidebar {
  min-height: calc(100vh - 64px);
  background: #00528a;
  padding: 0;
  width: 0px;
  z-index: 9;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
}
.sidebar.active {
  width: 258px;
  /* width: 298px; */
}

.sidebar .nav {
  overflow: auto;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-bottom: 60px;
}

.sidebar .nav::-webkit-scrollbar {
  width: 0px;
}

.sidebar .nav::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.sidebar .nav::-webkit-scrollbar-thumb:hover {
  background: #015289;
}

.sidebar .nav .nav-item {
  padding: 0 2.375rem;
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  transition-property: background;
  -webkit-transition-property: background;
  position: relative;
}
.sidebar .nav .nav-item .collapse {
  z-index: 9;
}
.sidebar .nav .nav-item .nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  white-space: nowrap;
  padding: 0.625rem 0 0.625rem 0;
  color: #8e94a9;
  -webkit-transition-duration: 0.45s;
  -moz-transition-duration: 0.45s;
  -o-transition-duration: 0.45s;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
  text-overflow: ellipsis;
}
.sidebar .nav .nav-item .nav-link .icon-bg {
  margin-right: 0.875rem;
  background: rgba(194, 244, 219, 0.12);
  height: 24px;
  width: 24px;
  position: relative;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  border-radius: 3px;
  text-align: center;
}
.rtl .sidebar .nav .nav-item .nav-link .icon-bg {
  margin-left: 1rem;
  margin-right: 0;
}
.sidebar .nav .nav-item .nav-link .icon-bg .menu-icon {
  color: #33c92d;
}
.sidebar .nav .nav-item .nav-link .icon-bg .menu-icon:after,
.sidebar .nav .nav-item .nav-link .icon-bg .menu-icon:before {
  font-size: 1rem;
  color: #fff;
}
.sidebar .nav .nav-item .nav-link i {
  color: #fff;
}
.sidebar .nav .nav-item .nav-link i.menu-icon {
  font-size: 1.125rem;
  line-height: 1;
  margin-right: 0;
  color: #8e94a9;
}
.rtl .sidebar .nav .nav-item .nav-link i.menu-icon {
  margin-left: 0;
  margin-right: 0;
}
.sidebar .nav .nav-item .nav-link i.menu-icon:before {
  vertical-align: middle;
}
.sidebar .nav .nav-item .nav-link i.menu-arrow {
  font: normal normal normal 24px/1 "Material Design Icons";
  line-height: 1;
  font-size: 1.125rem;
  margin-left: auto;
  color: rgba(255, 255, 255, 0.36);
}
.rtl .sidebar .nav .nav-item .nav-link i.menu-arrow {
  margin-left: 0;
  margin-right: auto;
}
.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  content: "\f0141";
  font-size: inherit;
  color: inherit;
}
.sidebar .nav .nav-item .nav-link i.menu-arrow + .menu-icon {
  margin-left: 0.25rem;
}
.rtl .sidebar .nav .nav-item .nav-link i.menu-arrow + .menu-icon {
  margin-left: 0;
  margin-right: 0.25rem;
}
.sidebar .nav .nav-item .nav-link .menu-title {
  color: white;
  display: inline-block;
  font-size: 0.937rem;
  line-height: 1;
  vertical-align: middle;
}
.sidebar .nav .nav-item .nav-link .badge {
  margin-right: auto;
  margin-left: 1rem;
}
.sidebar .nav .nav-item .nav-link[aria-expanded="true"] .menu-arrow:before {
  content: "\f0140";
}
.sidebar .nav .nav-item.active {
  background: transparent;
}
.sidebar .nav .nav-item.active > .nav-link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.5rem;
  bottom: 0;
  background: #fff;
  height: 24px;
  width: 4px;
}
.rtl .sidebar .nav .nav-item.active > .nav-link:before {
  left: auto;
  right: 0;
}
.sidebar .nav .nav-item.active > .nav-link .menu-title {
  color: #fff;
}
.sidebar .nav .nav-item.active > .nav-link i {
  color: #fff;
}
.sidebar .nav .nav-item:hover {
  background: #0364a6;
}
.sidebar .nav .nav-item.nav-profile .nav-link {
  height: auto;
  line-height: 1;
  border-top: 0;
  padding: 1.25rem 0;
}
.sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-image {
  width: 44px;
  height: 44px;
}
.sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-image img {
  width: 44px;
  height: 44px;
  border-radius: 100%;
}
.sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-text {
  margin-left: 1rem;
}
.rtl .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-text {
  margin-left: auto;
  margin-right: 1rem;
}
.sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-badge {
  font-size: 1.125rem;
  margin-left: auto;
}
.rtl .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-badge {
  margin-left: 0;
  margin-right: auto;
}
.sidebar .nav .nav-item.sidebar-actions {
  margin-top: 1rem;
}
.sidebar .nav .nav-item.sidebar-actions .nav-link {
  border-top: 0;
  display: block;
  height: auto;
}
.sidebar .nav .nav-item.sidebar-actions:hover {
  background: initial;
}
.sidebar .nav .nav-item.sidebar-actions:hover .nav-link {
  color: initial;
}
.sidebar .nav .nav-item.nav-category {
  color: #001737;
  font-size: 0.8125rem;
  line-height: 16px;
  margin: 25px 0px 10px 0px;
  color: #8e94a9;
  
}
.sidebar .nav .nav-item.nav-category .nav-link {
  padding: 1.2rem 2rem 0.2rem;
}
.sidebar .nav .nav-item.nav-category .nav-link:hover {
  color: #fff;
}
.sidebar .nav .nav-item.documentation-link {
  margin: 2.5rem 0 !important;
}
.sidebar .nav .nav-item.documentation-link .nav-link {
  background: #0062ff;
  color: #ffffff;
  border-radius: 2px;
  padding: 0.875rem 0;
  font-size: 1.125rem;
  font-weight: bold;
}
.sidebar .nav .nav-item.documentation-link .nav-link .icon-bg {
  display: none;
}
.sidebar .nav .nav-item.documentation-link .nav-link .menu-title {
  margin: auto;
  background: transparent !important;
  color: #ffffff !important;
}
.sidebar .nav .nav-item.sidebar-user-actions {
  padding-left: 0;
  padding-right: 0;
}
.sidebar .nav .nav-item.sidebar-user-actions .user-details {
  padding: 1rem 2.375rem;
  width: 100%;
  border-top: 1px solid rgba(151, 151, 151, 0.27);
  border-bottom: 1px solid rgba(151, 151, 151, 0.27);
  margin-bottom: 20px;
}
.sidebar
  .nav
  .nav-item.sidebar-user-actions
  .user-details
  .sidebar-profile-img {
  width: 31px;
  height: 31px;
  border-radius: 100%;
  margin-right: 12px;
}
.rtl
  .sidebar
  .nav
  .nav-item.sidebar-user-actions
  .user-details
  .sidebar-profile-img {
  margin-left: 1rem;
  margin-right: 0;
}
.sidebar
  .nav
  .nav-item.sidebar-user-actions
  .user-details
  .sidebar-profile-text {
  color: #ffffff;
}
.sidebar .nav .nav-item.sidebar-user-actions .user-details .badge {
  margin-right: 0;
}
.sidebar .nav .nav-item.sidebar-user-actions .sidebar-user-menu {
  padding: 0 2.375rem;
}
.sidebar .nav .nav-item.sidebar-user-actions .sidebar-user-menu .nav-link {
  font-size: 15px;
}
.sidebar .nav .nav-item.sidebar-user-actions .sidebar-user-menu .nav-link i {
  font-size: 18px;
  margin-right: 12px;
}
.rtl
  .sidebar
  .nav
  .nav-item.sidebar-user-actions
  .sidebar-user-menu
  .nav-link
  i {
  margin-right: 0;
  margin-left: 12px;
}
.sidebar
  .nav:not(.sub-menu)
  > .nav-item:hover:not(.nav-category):not(.nav-profile)
  > .nav-link {
  color: #fff;
}
.sidebar .nav.sub-menu {
  margin-bottom: 20px;
  margin-top: 0;
  list-style: none;
}
.sidebar .nav.sub-menu .nav-item {
  padding: 0;
}
.sidebar .nav.sub-menu .nav-item .nav-link {
  color: #fff;
  padding: 0.75rem 2rem 0.75rem 2rem;
  position: relative;
  font-size: 0.8125rem;
  line-height: 1;
  height: auto;
  border-top: 0;
}
.sidebar .nav.sub-menu .nav-item .nav-link:before {
  content: "\f0054";
  font-family: "Material Design Icons";
  display: block;
  position: absolute;
  left: 0px;
  top: 50%;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #a2a2a2;
  font-size: 0.75rem;
}
.rtl .sidebar .nav.sub-menu .nav-item .nav-link:before {
  left: auto;
  right: 0;
  content: "\f004d";
}
.sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: white;
  background: transparent;
}
.sidebar .nav.sub-menu .nav-item .nav-link.active:before {
  color: white;
}
.sidebar .nav.sub-menu .nav-item .nav-link:hover {
  color: #b5e1ff;
}
.sidebar .nav.sub-menu .nav-item:hover {
  background: transparent;
}

.sidebar-light .sidebar {
  background: #fff;
}
.sidebar-light .sidebar .nav .nav-item .nav-link {
  color: white;
}
.sidebar-light .sidebar .nav .nav-item .nav-link i {
  color: inherit;
}
.sidebar-light .sidebar .nav .nav-item .nav-link i.menu-icon {
  color: #fff;
}
.sidebar-light .sidebar .nav .nav-item .nav-link i.menu-arrow {
  color: #9e9da0;
}
.sidebar-light .sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  color: inherit;
}
.sidebar-light .sidebar .nav .nav-item .nav-link .menu-title {
  color: white;
}
.sidebar-light .sidebar .nav .nav-item .nav-link .icon-bg {
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-light .sidebar .nav .nav-item.active {
  background: #fff;
}
.sidebar-light .sidebar .nav .nav-item.active > .nav-link .menu-title {
  color: white;
}
.sidebar-light .sidebar .nav .nav-item.active > .nav-link i {
  color: #fff;
}
.sidebar-light .sidebar .nav .nav-item:hover {
  background: white;
}
.sidebar-light .sidebar .nav .nav-item.sidebar-actions:hover {
  background: initial;
}
.sidebar-light .sidebar .nav .nav-item.sidebar-actions:hover .nav-link {
  color: initial;
}
.sidebar-light
  .sidebar
  .nav:not(.sub-menu)
  > .nav-item:hover:not(.nav-category):not(.nav-profile)
  > .nav-link {
  color: #27367f;
}
.sidebar-light .sidebar .nav.sub-menu .nav-item .nav-link {
  color: #444343;
}
.sidebar-light .sidebar .nav.sub-menu .nav-item .nav-link:before {
  color: #a2a2a2;
}
.sidebar-light .sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: #444343;
  background: transparent;
}
.sidebar-light .sidebar .nav.sub-menu .nav-item .nav-link:hover {
  color: black;
}
.sidebar-light .sidebar .nav.sub-menu .nav-item:hover {
  background: transparent;
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - 64px);
    top: 64px;
    bottom: 0;
    overflow: auto;
    right: -258px;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
  }
  .sidebar-offcanvas.active {
    right: 0;
  }
}
/* Layouts */
.navbar.fixed-top + .page-body-wrapper {
  padding-top: 64px;
}

@media (min-width: 992px) {
  .sidebar-mini .navbar .navbar-brand-wrapper {
    width: 185px;
  }
  .sidebar-mini .navbar .navbar-menu-wrapper {
    width: calc(100% - 185px);
  }
  .sidebar-mini .sidebar {
    width: 185px;
  }
  .sidebar-mini .sidebar .nav .nav-item {
    padding: 10px 0;
    margin: 0;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
    text-align: center;
    position: relative;
    border-bottom: none;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link .menu-title {
    display: block;
    margin: auto;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link .menu-sub-title {
    margin: auto;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link .badge {
    margin-left: 5px;
    display: none;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link .icon-bg {
    margin-top: 8px;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link .icon-bg i.menu-icon {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 5px;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link i.menu-arrow {
    display: inline-block;
    margin-left: 5px;
    position: absolute;
    top: 50%;
    right: 10px;
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link i.menu-arrow:before {
    content: "\f0140";
  }
  .sidebar-mini
    .sidebar
    .nav
    .nav-item
    .nav-link[aria-expanded="true"]
    .menu-arrow:before {
    content: "\f0143";
  }
  .sidebar-mini .sidebar .nav .nav-item.nav-category {
    text-align: center;
  }
  .sidebar-mini
    .sidebar
    .nav
    .nav-item.sidebar-user-actions
    .sidebar-profile-text {
    display: none;
  }
  .sidebar-mini
    .sidebar
    .nav
    .nav-item.sidebar-user-actions.hover-open
    .nav-link
    .menu-title {
    left: 45px;
  }
  .sidebar-mini .main-panel {
    width: calc(100% - 185px);
  }
  .sidebar-mini:not(.sidebar-icon-only)
    .sidebar
    .nav:not(.sub-menu)
    > .nav-item {
    border-bottom: 1px solid rgba(68, 67, 67, 0.2);
  }
  .sidebar-mini:not(.sidebar-icon-only)
    .sidebar
    .nav:not(.sub-menu)
    > .nav-item
    .nav-link {
    height: auto;
    padding: 0.8125rem 1rem 0.8125rem 1rem;
  }
  .sidebar-mini:not(.sidebar-icon-only)
    .sidebar
    .nav:not(.sub-menu)
    > .nav-item
    .nav-link
    i.menu-icon {
    margin-bottom: 0.5rem;
  }
  .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav.sub-menu {
    padding: 0;
    border-top: none;
  }
  .sidebar-mini:not(.sidebar-icon-only) .sidebar .nav.sub-menu .nav-item {
    padding: 5px 0;
  }
  .sidebar-mini:not(.sidebar-icon-only)
    .sidebar
    .nav.sub-menu
    .nav-item
    .nav-link {
    padding: 7px 0 7px 25px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: left;
    width: 66%;
  }
}

@media (min-width: 992px) {
  .sidebar-icon-only .navbar .navbar-brand-wrapper {
    width: 70px;
  }
  .sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo {
    display: none;
  }
  .sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo-mini {
    display: inline-block;
  }
  .sidebar-icon-only .navbar .navbar-menu-wrapper {
    width: calc(100% - 70px);
  }
  .sidebar-icon-only .sidebar {
    width: 70px;
  }
  .sidebar-icon-only .sidebar .nav {
    overflow: visible;
  }
  .sidebar-icon-only .sidebar .nav .nav-item {
    position: relative;
    padding: 0;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
    display: block;
    text-align: center;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title,
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge,
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-sub-title {
    display: none;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    border-radius: 0 5px 5px 0px;
  }
  .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    border-radius: 5px 0 0 5px;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .icon-bg {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .icon-bg i {
    margin-left: auto;
    margin-right: auto;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
    margin-right: 0;
    margin-left: 0;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
    display: none;
  }
  .sidebar-icon-only
    .sidebar
    .nav
    .nav-item
    .nav-link[aria-expanded]
    .menu-title {
    border-radius: 0 5px 0 0px;
  }
  .rtl.sidebar-icon-only
    .sidebar
    .nav
    .nav-item
    .nav-link[aria-expanded]
    .menu-title {
    border-radius: 5px 0 0 0;
  }
  .sidebar-icon-only .sidebar .nav .nav-item.nav-profile {
    display: none;
  }
  .sidebar-icon-only .sidebar .nav .nav-item.nav-category {
    display: none;
  }
  .sidebar-icon-only .sidebar .nav .nav-item.nav-doc {
    margin: 0;
  }
  .sidebar-icon-only .sidebar .nav .nav-item.nav-doc i {
    display: block;
  }
  .sidebar-icon-only .sidebar .nav .nav-item .collapse {
    display: none;
  }
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background: #161621;
    padding: 0.5rem 1.4rem;
    left: 70px;
    position: absolute;
    text-align: left;
    top: 0;
    bottom: 0;
    width: 190px;
    z-index: 1;
    line-height: 1.8;
  }
  .sidebar-light.sidebar-icon-only
    .sidebar
    .nav
    .nav-item.hover-open
    .nav-link
    .menu-title {
    background: #fff;
  }
  .rtl.sidebar-icon-only
    .sidebar
    .nav
    .nav-item.hover-open
    .nav-link
    .menu-title {
    left: auto;
    right: 70px;
    text-align: left;
  }
  .sidebar-light.sidebar-icon-only
    .sidebar
    .nav
    .nav-item.hover-open
    .nav-link
    .menu-title {
    color: #001737;
  }
  .sidebar-icon-only
    .sidebar
    .nav
    .nav-item.hover-open
    .nav-link
    .menu-title:after {
    display: none;
  }
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
    display: block;
    padding: 0.5rem 0;
    background: #161621;
    border-radius: 0 0 5px 0;
    position: absolute;
    left: 70px;
    width: 190px;
  }
  .sidebar-light.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
  .sidebar-light.sidebar-icon-only
    .sidebar
    .nav
    .nav-item.hover-open
    .collapsing {
    background: #fff;
  }
  .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
  .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
    left: auto;
    right: 70px;
    border-radius: 0 0 0 5px;
  }
  .sidebar-icon-only .sidebar .nav .nav-item.documentation-link {
    margin: 0 !important;
  }
  .sidebar-icon-only .sidebar .nav .nav-item.documentation-link .nav-link {
    background: transparent;
    color: #ffffff;
    border-radius: 0px;
  }
  .sidebar-icon-only
    .sidebar
    .nav
    .nav-item.documentation-link
    .nav-link
    .icon-bg {
    display: -webkit-flex;
    display: flex;
  }
  .sidebar-icon-only
    .sidebar
    .nav
    .nav-item.documentation-link
    .nav-link
    .menu-title {
    margin: auto;
    background: #fff;
    color: #444343;
    font-weight: 400;
  }
  .sidebar-icon-only
    .sidebar
    .nav
    .nav-item.sidebar-user-actions
    .user-details {
    display: none;
  }
  .sidebar-icon-only
    .sidebar
    .nav
    .nav-item.sidebar-user-actions
    .sidebar-user-menu {
    padding: 0 1.5rem;
  }
  .sidebar-icon-only .sidebar .nav.sub-menu {
    padding: 0 0 0 1.5rem;
  }
  .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
    text-align: left;
    padding-left: 20px;
  }
  .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item {
    margin-right: 0;
    margin-left: auto;
  }
  .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
    text-align: left;
  }
  .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link:before {
    left: unset;
    right: 0;
  }
  .sidebar-icon-only .sidebar .sidebar-actions {
    display: none;
  }
  .sidebar-icon-only .main-panel {
    width: calc(100% - 70px);
  }
}

@media (min-width: 992px) {
  .sidebar-hidden .sidebar {
    transition: width 0.25s ease;
    -webkit-transition: width 0.25s ease;
    -moz-transition: width 0.25s ease;
    -ms-transition: width 0.25s ease;
    width: 0;
  }
  .sidebar-hidden .main-panel {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .sidebar-absolute .page-body-wrapper {
    position: relative;
  }
  .sidebar-absolute .page-body-wrapper .sidebar {
    transition: none;
  }
  .sidebar-absolute:not(.sidebar-hidden) .sidebar {
    position: absolute;
    height: 100%;
    -webkit-box-shadow: 0 0 3px 1px #a7a3a3;
    /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 0 0 3px 1px #a7a3a3;
    /* Firefox 3.5 - 3.6 */
    box-shadow: 0 0 3px 1px #a7a3a3;
    /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }
  .sidebar-absolute .main-panel {
    width: 100%;
    transition: none;
  }
}

@media (min-width: 992px) {
  .sidebar-fixed .sidebar {
    position: fixed;
    max-height: auto;
  }
  .sidebar-fixed .sidebar .nav {
    max-height: calc(100vh - 64px);
    overflow: auto;
    position: relative;
  }
  .sidebar-fixed .sidebar .nav.sub-menu {
    max-height: none;
  }
  .sidebar-fixed .main-panel {
    margin-left: 258px;
  }
  .sidebar-fixed.sidebar-icon-only .main-panel {
    margin-left: 70px;
  }
}

@media (min-width: 992px) {
  .boxed-layout .container-scroller {
    background: #e5e7f1;
    padding: 0 calc((100% - 1200px) / 2);
  }
  .boxed-layout .navbar.fixed-top {
    margin: auto;
    max-width: 1200px;
  }
}

.rtl {
  direction: rtl;
  text-align: right;
}
.rtl .sidebar .nav {
  padding-right: 0;
}
.rtl.sidebar-icon-only .sidebar .nav.sub-menu {
  padding: 0 1.5rem 0 1.5rem;
}
.rtl .product-chart-wrapper::-webkit-scrollbar,
.rtl .settings-panel .tab-content .tab-pane .scroll-wrapper::-webkit-scrollbar,
.rtl .sidebar-fixed .nav::-webkit-scrollbar,
.rtl .table-responsive::-webkit-scrollbar,
.rtl ul.chats::-webkit-scrollbar {
  width: 0em;
}
.rtl .product-chart-wrapper::-webkit-scrollbar-track,
.rtl
  .settings-panel
  .tab-content
  .tab-pane
  .scroll-wrapper::-webkit-scrollbar-track,
.rtl .sidebar-fixed .nav::-webkit-scrollbar-track,
.rtl .table-responsive::-webkit-scrollbar-track,
.rtl ul.chats::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.rtl .product-chart-wrapper::-webkit-scrollbar-thumb,
.rtl
  .settings-panel
  .tab-content
  .tab-pane
  .scroll-wrapper::-webkit-scrollbar-thumb,
.rtl .sidebar-fixed .nav::-webkit-scrollbar-thumb,
.rtl .table-responsive::-webkit-scrollbar-thumb,
.rtl ul.chats::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.page-body-wrapper {
  min-height: calc(100vh - 64px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
}
.page-body-wrapper.full-page-wrapper {
  width: 100%;
  min-height: 100vh;
}
.main-panel-wraper {
  width: 100%;
}
.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
  width: calc(100% - 258px);
  min-height: calc(100vh - 64px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

@media screen and (min-width: 992px){
  /* Add for desktop toggle  */
  .main-panel-wraper .main-panel{
    width: calc(100% - 0px);
  }
  .main-panel-wraper.active{
    width: calc(100% - 258px);
  }
  .main-panel-wraper.active .main-panel{
    width: 100%;
  }

}




@media (max-width: 991px) {
  .main-panel {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 800px) {
  .main-panel {
    margin-left: 0;
    width: 100%;
    min-height: calc(100vh - 100px);
  }
}

.content-wrapper {
  background: #f0f1f6;
  padding: 1.5rem 2.875rem 0 2rem;
  width: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
@media (max-width: 991px) {
  .content-wrapper {
    padding: 2rem 1rem;
  }
}

/* === Custom css === */
.transaparent-tab-border {
  border-bottom: 1px solid #e3e5ef;
}

#device-sales-legend ul,
#page-view-analytic-legend ul,
#device-sales-rtl-legend ul,
#page-view-analytic-rtl-legend ul {
  display: none;
  align-items: center;
  float: right;
}
#device-sales-legend ul li,
#page-view-analytic-legend ul li,
#device-sales-rtl-legend ul li,
#page-view-analytic-rtl-legend ul li {
  display: flex;
  align-items: center;
  list-style: none;
  margin-left: 1rem;
}
#device-sales-legend ul li span,
#page-view-analytic-legend ul li span,
#device-sales-rtl-legend ul li span,
#page-view-analytic-rtl-legend ul li span {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-right: 10px;
  display: inline-block;
}
#device-sales-legend ul li:first-child,
#page-view-analytic-legend ul li:first-child,
#device-sales-rtl-legend ul li:first-child,
#page-view-analytic-rtl-legend ul li:first-child {
  margin-left: 0px;
}
@media (max-width: 991px) {
  #device-sales-legend ul,
  #page-view-analytic-legend ul,
  #device-sales-rtl-legend ul,
  #page-view-analytic-rtl-legend ul {
    float: left;
    padding-left: 0;
  }
}
#device-sales-legend ul:first-child,
#page-view-analytic-legend ul:first-child,
#device-sales-rtl-legend ul:first-child,
#page-view-analytic-rtl-legend ul:first-child {
  display: flex;
}
#device-sales-legend.primary-dot ul li .legend-box,
#page-view-analytic-legend.primary-dot ul li .legend-box,
#device-sales-rtl-legend.primary-dot ul li .legend-box,
#page-view-analytic-rtl-legend.primary-dot ul li .legend-box {
  background: #0062ff;
}

.card-danger-gradient {
  background: linear-gradient(to bottom, #d41459, #911a6c);
}

.recent-activity .activity-info {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  color: #ffffff;
  padding-top: 5px;
  font-weight: bold;
  margin-right: 1.25rem;
  position: relative;
}
.recent-activity .activity-info:after {
  content: "";
  width: 2px;
  height: 54px;
  position: absolute;
  left: 50%;
  top: 33px;
  border-right: 1px dashed rgba(151, 151, 151, 0.3);
}
.recent-activity .activity-info.hide-border:after {
  display: none;
}

.rtl .graph-custom-legend ul {
  float: left;
  padding-left: 0;
}
.rtl .graph-custom-legend ul li {
  margin-left: 0;
  margin-right: 1rem;
}
.rtl .graph-custom-legend ul li .legend-box {
  margin-right: 0;
  margin-left: 0.625rem;
}
.rtl .activity-info {
  margin-left: 1.25rem;
  margin-right: 0;
}

.card-weather {
  background: #fff;
}
.card-weather .card-body {
  background: #ffffff;
}
.card-weather .card-body:first-child {
  /* background: url("../../images/samples/weather.svg") no-repeat center; */
  background-size: cover;
}
.card-weather .weather-date-location {
  padding: 0 0 38px;
}
.card-weather .weather-data {
  padding: 0 0 4.75rem;
}
.card-weather .weather-data i {
  font-size: 5.313rem;
  line-height: 1;
}
.card-weather .weakly-weather {
  background: #fff;
  overflow-x: auto;
}
.card-weather .weakly-weather .weakly-weather-item {
  flex: 0 0 14.28%;
  border-right: 1px solid rgba(151, 151, 151, 0.3);
  padding: 1rem;
  text-align: center;
}
.card-weather .weakly-weather .weakly-weather-item i {
  font-size: 1.2rem;
}
.card-weather .weakly-weather .weakly-weather-item:last-child {
  border-right: 0;
}
.card-weather .weakly-weather .weakly-weather-item .symbol {
  color: #a7afb7;
  font-size: 1.875rem;
  font-weight: 300;
}

.dotted-border {
  border: 1px dashed rgba(151, 151, 151, 0.3);
}

.doughnutjs-wrapper {
  height: 250px !important;
}
@media (max-width: 991px) {
  .doughnutjs-wrapper {
    height: auto !important;
  }
}
/*# sourceMappingURL=../maps/vertical-light-layout/style.css.map */


.modal-ware-house {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* make sure the modal is on top */
}

.modal-warehouse-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 1500px;
  width: 100%;
  padding: 20px;
  position: relative;
}

/* Close button */
.modal-warehouse-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}
.mainBarcode {
  width: 100%;
  height: 250px;
  border: 1px solid #cccccc;
  opacity: 1;
  position: relative;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 1px;
}

.barcodeName {
  font-size: 1.9rem;
}

@media print {
  body {
    margin: 0;

    -webkit-print-color-adjust: exact;
  }
  .print-barcode,
  .print-barcode * {
    visibility: visible;
  }
  .print-barcode {
    width: 75mm;
    height: 38mm;
    border: 1px solid grey;
    opacity: 1;
    position: relative;

    left: 0px;
  }

  /* .mainBarcode {
    width: 75mm;
    height: 38mm;
    border: 1px solid grey;
    opacity: 1;
    position: relative;
    overflow: hidden;
    padding: 10px;
    margin-bottom: 1px;
  } */
}

.responsive-table {
  width: 100%;
  height: 63vh;

  overflow-y: scroll;
}
@media (max-width: 991px) {
  .responsive-table {
    width: 100%;
    height: 75vh;

    overflow-y: scroll;
  }
}

.responsive-table2 {
  width: 100%;
  height: 68vh;

  overflow-y: scroll;
}
@media (max-width: 991px) {
  .responsive-table2 {
    width: 100%;
    height: 75vh;

    overflow-y: scroll;
  }
}

.table-head {
  position: sticky;
  top: 0.1px;
  text-align: left;
  z-index: 1;
}






#invoice-POS , #invoice-Vertical{
  padding: 2mm;
  margin: 0 auto;
  width: 80mm;
  color: black;
  background: #fff;

  /* h1 {
    font-size: 0.6rem;
  }
  h2 {
    font-size: 0.6rem;
  }
  h5 {
    font-size: 0.65rem;
  }
  h3 {
    font-size: 0.65rem;
    font-weight: 500;
  }
  p {
    font-size: 0.6rem;
    line-height: 1.2em;
  } */

  /* #top,
  #bot {
    border-bottom: 1px solid #000;
  } */

  #bot {
    min-height: 50px;
  }

  .info {
    display: block;

    margin-left: 0;
    margin-top: 5px;
  }
  .title {
    float: right;
  }
  .title p {
    text-align: right;
  }
  table {
    width: 100%;
  }

  .tabletitle {
    font-size: 10px;
    padding-top: 10px;
  }
  .service {
    padding-top: 5px;
    margin-top: 5px;
  }
  .item {
    width: 5mm;
  }
  .itemtext {
    font-size: 1em;
  }

  #legalcopy {
    margin-top: 5mm;
  }
}


/* .table .table-dark th {
  font-family: "Lexend", sans-serif;
} */

.wareBarcode {
  width: 75mm;
  height: 30mm;
  border: 1px solid grey;
  opacity: 1;
  position: relative;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 1px;
}

@media print {




  body {
    margin: 0;

    -webkit-print-color-adjust: exact;
  }
  .print-warebarcode,
  .print-warebarcode * {
    visibility: visible;
  }
  .print-warebarcode {
    width: 75mm;
    height: 38mm;
    border: 1px solid grey;
    opacity: 1;
    position: relative;

    left: 0px;
  }
}

::-webkit-scrollbar {
  width: 3px !important;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: #ebebeb !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9 !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #000;
}

@media (max-width: 1000px) {
  #desktop_scan {
    display: none;
  }
}

@media (min-width: 1000px) {
  #mobile_scan {
    display: none;
  }
}

.ser_number {
  background-color: #ededed;
  padding: 5px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: "4px 0px 8px rgba(0, 0, 0, 0.2)";
  cursor: pointer;
}

.ser_number:hover {
  background-color: #e0f1ff;
}

#backbtn {
  padding: 5px;
  font-size: 22px;
  margin-right: 3px;
}

#backbtn:hover {
  background-color: #d8d8d8;
  cursor: pointer;
  border-radius: 3px;
}

.customer-card {
  background-color: #e0f1ffb0;
  border-radius: 5px;
  /* box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0); */
  color: #535353;
  padding-top: 30px;
  position: relative;
  width: 350px;
  max-width: 100%;
  text-align: center;
}

.customer-card .pro {
  color: #231e39;
  background-color: #febb0b;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 7px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.customer-card .round {
  border: 1px solid #03bfcb;
  border-radius: 50%;
  padding: 7px;
  width: 130px;
  margin-bottom: 15px;
}

button.primary {
  background-color: #254268;
  border: 1px solid #254268;
  border-radius: 3px;
  color: #fafafa;
  font-weight: 500;
  padding: 10px 25px;
}

button.primary.ghost {
  background-color: transparent;
  color: #000;
  margin-left: 5px;
}

.skills {
  background-color: #254268;
  text-align: left;
  padding: 15px;
  margin-top: 30px;
}
.skills h6 {
  color: rgba(255, 255, 255, 0.692);
  letter-spacing: 0.5px;
}

.skills ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.skills ul li {
  border: 1px solid #325b91;
  border-radius: 2px;
  color: white;

  font-size: 12px;
  margin: 0 7px 7px 0;
  padding: 7px;
}

.addresses_card {
  border-radius: 5px;
  font-size: 16px;
  color: #3d3d3d;
}


.products_table_class {
  max-height: 53vh;
  overflow-y: scroll;
}

.products_add_adjustment {
  width: 100%;
  max-height: 65vh;
  overflow-y: scroll;
}



.responsive-table1 {
  width: 100%;
  max-height: 74.5vh;
  /* min-height: 72vh; */
  overflow-y: scroll;
}
@media (max-width: 991px) {
  .responsive-table1 {
    width: 100%;
    height: 70vh;
    overflow-y: scroll;
  }
}

.responsive-mobile-table{
  width: 100%;
  max-height: 67vh;
  /* height: 100%; */
  overflow-y: scroll;
}

th {
  resize: horizontal;
  overflow: auto;
}

.tbody_hint {
  display: none;
}



@media (max-width: 767px) {
  #c_table tbody tr #hide_table_td {
    display: none;
  }

  #c_table thead {
    display: none;
  }

  .tbody_hint {
    display: inline;
    color: grey;
  }

  #c_table tbody tr {
    display: none;
  }

  .mb_btn_hide {
    visibility: hidden;
    height: 0px;
    display: none;
    width: 0px;
  }

}

@media (min-width: 768px) {
.pc_btn_hide{
  visibility: hidden ;
  height: 0px;
  display: none;
  width: 0px;
}}



.highlighted-row {
  background-color: orange; /* or any other color you prefer */
}


.custom_textareafield {
  /* Basic styles for the text area */
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;

  /* Remove the default error underline */
  outline: none;

  /* Optional: Add focus style to remove the underline when focused */
  &:focus {
    border-color: #80bdff;
    box-shadow: none;
  }
}



.product_name_class{
  height: fit-content !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  line-height: 18px !important;
}