.dashboard .single-row {
  margin-bottom: 1rem;
}

.dashboard .single-row h4 {
  color: #2f3030;
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
  text-transform: uppercase;
}

.dashboard .grid-4 {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(50px, 1fr)); */
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
}

.dashboard .single-link a,
.dashboard .single-link .link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  text-align: center;
  border-radius: 10px;
  background-color: #f9f9f9;
  transition: background-color 0.3s;
}

.dashboard .single-link svg {
  background-color: #00528a;
  color: white;
  border-radius: 5px;
  padding: 0.5rem;
  width: 30px;
  height: 30px;
  margin-bottom: 0.3rem;
}

.dashboard .single-link h5 {
  color: #00528a;
  font-size: 0.6rem;
  margin: 0;
  text-transform: uppercase;
  padding-top: 0.2rem;
}








/* =====  Sales Dash board ====  */

.dash-bg-image {
  position: relative;
  z-index: 0;
}

.dash-bg-image:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  background-image: url("./dash-bg.png");
  background-position: center;
  background-repeat: repeat;
  z-index: -1;
  opacity: 0.6;
  background-size: cover;
}

.dash-absolute-icon {}

.avatar {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.avatar.bg-primary {
  background: #5c67f7 !important;
}


.custom-card .card-title {
  font-weight: 600;
}

.custom-card .card-header {
  border-width: 1px;
  padding: 0.5rem 0.7rem;
}